// @flow
import Gallery from 'components/Gallery';
import ProductParameters from 'components/ProductParameters';
import {BottomSpace, Label} from 'componentsStyled/Typography';
import {type ProductItem} from 'data/productItem/types';
import React from 'react';

type Props = {
  data: ProductItem,
};

const InventoryDetail = ({data}: Props) => (
  <div>
    <Label>Asset ID</Label>
    <p>{data.code}</p>
    <Label>Name</Label>
    <p>{data.productVariant.product.name}</p>
    <Label>Desciption</Label>
    <p>{data.productVariant.product.description}</p>
    <BottomSpace>
      <Label>images</Label>
      <Gallery data={data.productVariant.product.images} />
    </BottomSpace>
    <Label>Params</Label>
    <p>
      <ProductParameters data={data.productVariant.parameters} />
    </p>
    <Label>Pricing template name</Label>
    <p>{data.pricingTemplate.name}</p>
  </div>
);

export default InventoryDetail;
