// @flow
import {type AuthAction} from './actions';
import {type User} from './graphql/types';

export type AuthReducerState = {
  user: ?User,
};

const initialState: AuthReducerState = {
  user: null,
};

function authReducer(state: AuthReducerState = initialState, action: AuthAction): AuthReducerState {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: action.payload,
      };
    case 'UPDATE':
      return {
        user: action.payload,
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
