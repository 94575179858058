// @flow
import Gallery from 'components/Gallery';
import TableStat from 'components/TableStat';
import {BottomSpace, Label} from 'componentsStyled/Typography';
import type {Bundle, BundleComponent} from 'data/bundle/types';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import Section from '../../../components/Section';
import AddComponent from './AddComponent';
import variantsTableDefinition from './bundleComponentsTableDefinition';

const BundleDetail = ({data, hideCreateVariant, toggleHideCreateVariant}) => {
  const variantsInfo: BundleComponent[] = data.components;

  return (
    <Section>
      <Label>Name</Label>
      <p>{data.name}</p>
      <Label>Description</Label>
      <code>{data.description}</code>
      <BottomSpace>
        <Label>images</Label>
        <Gallery data={data.images} />
      </BottomSpace>
      <TableStat
        title="Bundled Items"
        tableDefinition={variantsTableDefinition}
        value={variantsInfo}
      />
      <AddComponent data={data} />
    </Section>
  );
};

type Enhancer = {
  data: Bundle,
};

const enhancer: HOC<*, Enhancer> = compose(
  withStateHandlers(
    {hideCreateVariant: true},
    {
      toggleHideCreateVariant: props => () => ({
        hideCreateVariant: !props.hideCreateVariant,
      }),
    }
  )
);

export default enhancer(BundleDetail);
