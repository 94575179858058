// @flow
import {map} from 'ramda';

export const breakpoints = {
  tablet: 767,
  laptop: 1080,
  desktop: 1440,
};

export const emify = (px: number) => `${px / 16}em`;

export const mq = map(value => `@media (min-width: ${emify(value)})`, breakpoints);

export const mqMax = map(value => `@media (max-width: ${emify(value)})`, breakpoints);
