import type {ProductItemStatus} from 'data/productItem/types';
import theme from 'global/theme';

export const getProductItemStatusConfig = (
  status: ProductItemStatus
): {text: string, color: string} => {
  switch (status) {
    case 'checked_out':
      return {
        text: 'rented',
        color: theme.palette.success,
      };

    case 'out_for_repair':
      return {
        text: 'in repair',
        color: theme.palette.error,
      };

    case 'damaged':
      return {
        text: 'damaged',
        color: theme.palette.error,
      };

    case 'offline':
      return {
        text: 'out of service',
        color: theme.palette.error,
      };

    case 'online':
      return {
        text: 'in stock',
        color: theme.palette.success,
      };

    case 'pre_processing':
      return {
        text: 'pre-processing',
        color: theme.palette.warning,
      };

    case 'post_processing':
      return {
        text: 'post-processing',
        color: theme.palette.warning,
      };

    case 'outbound':
      return {
        text: 'outbound',
        color: theme.palette.warning,
      };

    case 'inbound':
      return {
        text: 'inbound',
        color: theme.palette.warning,
      };

    case 'delayed':
      return {
        text: 'delayed',
        color: theme.palette.error,
      };

    case 'overdue':
      return {
        text: 'overdue',
        color: theme.palette.error,
      };

    default:
      return {
        text: 'unknown',
        color: theme.palette.greyDark,
      };
  }
};
