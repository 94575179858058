// @flow
import {ButtonGroup} from 'componentsStyled/Shared';
import styled from 'styled-components';

export const InputPriceWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding-left: 2rem;
`;

export const StyledInputWrap = styled.div`
  padding-left: 2rem;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
