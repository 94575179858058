// @flow
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import ActiveLink from './ActiveLink';

type propTypes = {
  paragraph?: {error: boolean},
  to?: string,
  redirect?: boolean,
  component?: string,
  hideExternalLinkIndicator?: boolean,
  // $Import
  children: React.Node,
};

export const linker =
  (StyledComponent: any) =>
  ({
    paragraph,
    to,
    redirect = false,
    component = 'button',
    hideExternalLinkIndicator = false,
    children,
    ...rest
  }: propTypes) => {
    if (paragraph) {
      return (
        <StyledComponent
          children={children}
          {...rest}
          component="p"
          p={paragraph.error === true ? 'error' : 'walkin'}
        />
      );
    }
    if (to) {
      if (to.startsWith('mailto:') || to.startsWith('tel:')) {
        return <StyledComponent children={children} {...rest} component="a" href={to} />;
      }
      if (to.startsWith('http')) {
        return (
          <StyledComponent
            {...rest}
            component="a"
            href={to}
            rel="noreferrer noopener"
            target="_blank"
          >
            {children}
            {!hideExternalLinkIndicator && (
              <FontAwesomeIcon icon={faExternalLink} style={{paddingLeft: '0.25em'}} />
            )}
          </StyledComponent>
        );
      }
      if (redirect) {
        return <StyledComponent children={children} {...rest} component="a" href={to} />;
      }

      return <ActiveLink children={children} {...rest} to={to} component={StyledComponent} />;
    }

    return <StyledComponent children={children} {...rest} component={component} />;
  };
