// @flow
import gql from 'graphql-tag';

export const dashboardStatisicsQuery = {
  gql: gql`
    fragment statsResults on DaysBookedForPeriod {
      total
      days {
        date
        value
        index
      }
    }
    query dashboardStatisicsQuery(
      $currentFilter: StatisticsFilter
      $previousFilter: StatisticsFilter
      $affiliateId: Int
    ) {
      statistics {
        daysBookedForCurrentPeriod: daysBookedForPeriod(
          filter: $currentFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
        daysBookedForPreviousPeriod: daysBookedForPeriod(
          filter: $previousFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
        revenueForCurrentPeriod: revenueForPeriod(
          filter: $currentFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
        revenueForPreviousPeriod: revenueForPeriod(
          filter: $previousFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
        reservationsActiveForCurrentPeriod: reservationsActiveForPeriod(
          filter: $currentFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
        reservationsActiveForPreviousPeriod: reservationsActiveForPeriod(
          filter: $previousFilter
          affiliateId: $affiliateId
        ) {
          ...statsResults
        }
      }
    }
  `,
  selector: ['statistics'],
};
