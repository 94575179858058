import DayPicker from 'components/DayPicker';
import type {FilterConfig} from 'components/Filter';
import TextInput from 'components/TextInput';
import TenantSelect from 'containers/TenantSelect';
import withLabel from 'hoc/withLabel';

const filterConfig: FilterConfig = {
  query: {
    type: TextInput,
    props: {
      placeholder: 'Search Emails...',
    },
  },
  start: {
    type: withLabel(DayPicker),
    props: {
      label: 'Sent Date Range',
      placeholder: 'From Date...',
    },
  },
  end: {
    type: withLabel(DayPicker),
    props: {
      label: '\u00a0', //nbsp for layout purposes
      placeholder: 'To Date...',
    },
  },
  tenantIds: {
    type: withLabel(TenantSelect),
    props: {
      label: '\u00a0', //nbsp for layout purposes
      isMulti: true,
      placeholder: 'Merchants...',
    },
  },
};

export default filterConfig;
