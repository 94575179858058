// @flow
import {paginationFragment} from 'data/other/fragments';
import {customerFragmentMinimal} from 'data/user/fragments';
import gql from 'graphql-tag';
import {path, pathOr} from 'ramda';
import type {Query} from 'types/Graphql';

import {bookingFragment} from '../booking/fragments';
import {
  reservationFragmentFull,
  reservationFragmentMinimal,
  reservationPricingFragment,
} from './fragments';
import type {ProductDamage, ReservationFull, ReservationMinimal} from './types';

export type DetailResult = {
  reservation: ReservationFull,
  damages: ProductDamage[],
  // payments: Payment[],
};

export const reservationDetailQuery: Query<DetailResult> = {
  gql: gql`
    query reservationDetailQuery($id: Int!) {
      reservation {
        get(id: $id) {
          ...reservationFragmentFull
          booking {
            ...bookingFragment
          }
          pricingDetail {
            ...reservationPricingFragment
          }
        }
      }
      damage {
        listDamageObjects(filter: {reservationId: $id}) {
          data {
            id
            damageType {
              id
              type
            }
          }
        }
      }
    }
    ${reservationFragmentFull}
    ${customerFragmentMinimal}
    ${reservationPricingFragment}
    ${bookingFragment}
  `,
  transform: (result: any) => ({
    reservation: path(['reservation', 'get'])(result),
    damages: pathOr([], ['damage', 'listDamageObjects', 'data'])(result),
  }),
};

export const reservationListQuery: Query<ReservationMinimal[]> = {
  gql: gql`
    query reservationListQuery($filter: ListReservationFilter) {
      reservation {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...reservationFragmentMinimal
            booking {
              ...bookingFragment
            }
          }
        }
      }
    }
    ${reservationFragmentMinimal}
    ${customerFragmentMinimal}
    ${paginationFragment}
    ${bookingFragment}
  `,
  selector: ['reservation', 'list'],
  pagination: true,
  debounceKey: 'reservationListQuery',
};
