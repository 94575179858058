import {StyledFieldWrap} from 'components/Inputs/FieldWrap/styled';
import Section from 'components/Section';
import {Divider} from 'componentsStyled/Layout';
import styled, {css} from 'styled-components';

export const CheckboxWrapper = styled.span``;

export const StyledBufferSection = styled(Section)`
  ${StyledFieldWrap} {
    min-height: 0;
    margin-bottom: 4rem;
  }

  ${CheckboxWrapper} ${StyledFieldWrap} {
    margin-bottom: 2rem;
  }

  ${Divider} {
    margin: 1rem 0 3rem 0;
  }

  label {
    text-transform: none;
  }
`;

export const StyledParagraph = styled.p`
  max-width: 640px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;

  p {
    margin: 0;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const StyledBufferInput = styled.div`
  label {
    margin-left: 1rem;
  }
`;

export const TopLabel = styled.span`
  transition: color ${props => props.theme.durations.inputColors};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.palette.grey};
    `};
`;
