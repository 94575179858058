// @flow
import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'componentsStyled/Typography';
import EditProductItem from 'containers/Modals/EditProductItem';
import {openModal} from 'data/modals/actions';
import {editProductItemMutation} from 'data/productItem/mutations';
import type {ProductItem} from 'data/productItem/types';
import withMutation from 'hoc/withMutation';
import {path} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

const EditItemLink = ({data, editItem}) => (
  <Link onClick={editItem} component="a" redirect={false}>
    {data.code} <FontAwesomeIcon icon={faEdit} size="lg" />
  </Link>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: ProductItem,
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withMutation(editProductItemMutation),
  withHandlers({
    editItem: props => () =>
      props.openModal(EditProductItem, {
        data: {
          id: path(['data', 'id'], props),
          code: path(['data', 'code'], props),
          buyItRrp: path(['data', 'buyItRrp'], props),
          allowed: path(['data', 'allowed'], props),
          affiliateId: path(['data', 'affiliate', 'id'], props),
          pricingTemplateId: path(['data', 'pricingTemplateId'], props),
          category: path(['data', 'category'], props),
          manufacturerId: path(['data', 'manufacturerId'], props),
          productId: path(['data', 'productId'], props),
          variantId: path(['data', 'variantId'], props),
        },
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(EditItemLink);
