// @flow
export const roles = {
  AFFILIATE: 'affiliate',
  AFFILIATE_EMPLOYEE: 'affiliate_employee',
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
};

const categories = {
  SURF: 'surfs',
  SKI: 'skis',
  SNOWBOARD: 'snowboards',
  SPLITBOARD: 'splitboards',
  SUP: 'sups',
  ROAD_BIKE: 'road bikes',
  MOUNTAIN_BIKE: 'mountain bikes',
};

const parameterNames = {
  WIDTH: 'width',
  THICKNESS: 'thickness',
  LENGTH: 'length',
  HEIGHT: 'height',
};

export const productGroupParameterUnits = {
  [categories.SURF]: {
    [parameterNames.WIDTH]: 'ft',
    [parameterNames.THICKNESS]: 'ft',
    [parameterNames.LENGTH]: 'ft',
  },
  [categories.SUP]: {
    [parameterNames.WIDTH]: 'ft',
    [parameterNames.THICKNESS]: 'ft',
    [parameterNames.LENGTH]: 'ft',
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  roles,
  categories,
  parameterNames,
  productGroupParameterUnits,
};
