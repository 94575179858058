// @flow
import {dayMap} from 'common/days';
import {Label} from 'componentsStyled/Typography';
import type {ExceptionDay, OpeningHours, RegularDay} from 'data/affiliate/types';
import {formatDate} from 'data/units/date/formatters';
import {mapObjIndexed, values} from 'ramda';
import React from 'react';
import Aux from 'react-aux';

import {Key, OpeningHoursTable, Pair, Val} from './styled';

type Props = {
  data: OpeningHours,
  special: ExceptionDay[],
};

const formatHours = (regularDay: RegularDay) => {
  if (regularDay.closed || !regularDay.hours) {
    return 'CLOSED';
  }

  if (regularDay.hours.pauseStart && regularDay.hours.pauseEnd) {
    return `${regularDay.hours.start} - ${regularDay.hours.pauseStart}, ${regularDay.hours.pauseEnd} - ${regularDay.hours.end}`;
  }

  return `${regularDay.hours.start} - ${regularDay.hours.end}`;
};

const Hours = ({data, special}: Props) => {
  if (!data) {
    return <p>Not available</p>;
  }

  return (
    <Aux>
      <OpeningHoursTable data-cy={'opening-hours'}>
        {values(
          mapObjIndexed(
            (day, key) => (
              <Pair key={key}>
                <Key>{day}</Key>
                <Val>{formatHours(data[key])}</Val>
              </Pair>
            ),
            dayMap
          )
        )}
      </OpeningHoursTable>
      {special.length > 0 && (
        <Aux>
          <Label>Special cases</Label>
          <OpeningHoursTable data-cy={'special-open-days'}>
            {special.map(exceptionDay => (
              <Pair key={exceptionDay.date}>
                <Key>{formatDate(exceptionDay.date)}</Key>
                <Val>{formatHours(exceptionDay)}</Val>
              </Pair>
            ))}
          </OpeningHoursTable>
        </Aux>
      )}
    </Aux>
  );
};

export default Hours;
