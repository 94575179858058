// @flow
import Table from 'components/Table';
import {listProductItemInventoryQuery} from 'data/productItem/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';

import {tableDefinition} from './tableDefinition';

const ProductItemsTable = ({data}) => {
  if (!data) {
    return <div>No results.</div>;
  }

  return <Table tableDefinition={tableDefinition} data={data} />;
};

export default withQuery(listProductItemInventoryQuery, undefined, undefined, props => ({
  affiliateId: props.affiliateId,
}))(ProductItemsTable);
