// @flow
import styled from 'styled-components';

/* TODO(T04435): Migrate this to ./Layout.js */
export const Flex = styled.div`
  display: flex;
`;

export const Spread = styled.div`
  display: flex;
  justify-content: space-around;
`;
