import Select from 'components/Select';
import {tenantListQuery} from 'data/tenant/queries';
import withQuery from 'hoc/withQuery';

const TenantSelect = ({data, onChange, value, isMulti, placeholder, isFilter}) => (
  <Select
    options={data}
    onChange={onChange}
    value={value}
    isMulti={isMulti}
    placeholder={placeholder}
    isFilter={isFilter}
  />
);

export default withQuery(tenantListQuery)(TenantSelect);
