// @flow
import {productFragment} from 'data/product/fragments';
import type {Product} from 'data/product/types';
import {
  productVariantFragment,
  productVariantWithProductFragment,
} from 'data/productVariant/fragments';
import gql from 'graphql-tag';
import {flatten} from 'ramda';
import type {Query} from 'types/Graphql';

import {productItemFragment} from './fragments';
import type {ProductItemInventory} from './types';

const transformListInventory = (results: Product[]): ProductItemInventory[] => {
  const products = results.map(prod =>
    prod.variants.map(variant =>
      variant.items.map((pi): ProductItemInventory => ({
        id: pi.id,
        code: pi.code,
        buyItRrp: pi.buyItRrp,
        variantId: variant.id,
        variantName: variant.name,
        productId: prod.id,
        productItemId: pi.id,
        productName: prod.name,
        pricingTemplateName: pi.pricingTemplate.name,
        pricingTemplateId: pi.pricingTemplate.id,
        affiliateId: pi.affiliate.id,
        online: !pi.deletedAt,
        allowed: pi.allowed,
        parameters: variant.parameters,
        // TODO(Jude): handle multiple categories
        categoryName: prod.categories[0].name,
        categoryId: prod.categories[0].categoryId,
        manufacturerId: prod.manufacturer.id,
        status: pi.status,
      }))
    )
  );

  // `flatten` definition doesn't handle nested types well
  return flatten((products: any));
};

export const listProductItemInventoryQuery: Query<ProductItemInventory[]> = {
  gql: gql`
    query listProductItemInventoryQuery($filter: listInventoryFilter) {
      product {
        listInventory(filter: $filter) {
          ...productFragment
          variants {
            ...productVariantFragment
          }
        }
      }
    }
    ${productFragment}
    ${productVariantFragment}
  `,
  transform: transformListInventory,
  fetchPolicy: 'network-only',
  selector: ['product', 'listInventory'],
  debounceKey: 'listProductItemInventoryQuery',
};

export const listProductItemInventoryByAffiliateQuery: Query<ProductItemInventory[]> = {
  gql: gql`
    query listProductItemInventoryByAffiliateQuery($id: Int!) {
      product {
        listInventory(filter: {affiliateId: $id}) {
          ...productFragment
          variants {
            ...productVariantFragment
          }
        }
      }
    }
    ${productVariantFragment}
    ${productFragment}
  `,
  fetchPolicy: 'network-only',
  transform: transformListInventory,
  debounceKey: 'listProductItemInventoryByAffiliateQuery',
};

export const listProductInventoryQuery: Query<Product[]> = {
  gql: gql`
    query listProductInventoryQuery($filter: listInventoryFilter) {
      product {
        listInventory(filter: $filter) {
          ...productFragment
          variants {
            ...productVariantFragment
          }
        }
      }
    }
    ${productFragment}
    ${productVariantFragment}
  `,
  selector: ['product', 'listInventory'],
  fetchPolicy: 'network-only',
};

export const productItemDetailQuery: Query<ProductItemInventory> = {
  gql: gql`
    query productItemDetailQuery($id: Int!) {
      product {
        getProductItemDetail(id: $id) {
          ...productItemFragment
          productVariant {
            ...productVariantWithProductFragment
          }
        }
      }
    }
    ${productItemFragment}
    ${productVariantWithProductFragment}
  `,
  selector: ['product', 'getProductItemDetail'],
  fetchPolicy: 'network-only',
};
