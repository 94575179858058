// @flow
import {type Component, withStateHandlers} from 'recompose';

type Added = {|
  isOpen: boolean,
  toggleOpen: void => mixed,
  close: void => mixed,
  open: void => mixed,
  setIsOpen: boolean => mixed,
|};

function withOpen<T>(
  // $Dunno
  WrappedComponent: Component<{...$Exact<T>, ...$Exact<Added>}>
  // $Dunno
): Component<T> {
  return withStateHandlers(
    {isOpen: false},
    {
      toggleOpen:
        ({isOpen}) =>
        () => ({isOpen: !isOpen}),
      close: () => () => ({isOpen: false}),
      open: () => () => ({isOpen: true}),
      setIsOpen: () => v => ({isOpen: v}),
    }
  )(WrappedComponent);
}

export default withOpen;
