// @flow
// @author: T04435
// Date: 22/7/21

import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {openModal} from 'data/modals/actions';
import withMutation from 'hoc/withMutation';
import {path} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

import Button from '../../../../components/Button';
import CreateTenantAffiliatesModal from '../../../../containers/Modals/TenantAffiliates/TenantAffiliatesCreate';
import {tenantAffiliateCreateMutation} from '../../../../data/tenant-affiliate/mutations';

const TenantAffiliateCreateButton = ({createItem, data}) => (
  <Button
    onClick={createItem}
    kind="primary"
    iconStart={faPlus}
    type="button"
    disabled={data.tenantId === undefined}
  >
    affiliate
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: {tenantId?: number},
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withMutation(tenantAffiliateCreateMutation),
  withHandlers({
    createItem: props => () =>
      props.openModal(CreateTenantAffiliatesModal, {
        data: {
          tenantId: path(['data', 'tenantId'], props),
        },
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(TenantAffiliateCreateButton);
