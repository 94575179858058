// @flow
import Table from 'components/Table';
import type {TableDefinition} from 'components/Table/types';
import {BottomSpace, Label} from 'componentsStyled/Typography';
import * as React from 'react';

type Props<T> = {
  title: string,
  value: T[],
  tableDefinition: TableDefinition<T>,
};

/* TODO(T04435): remove this component it should be a section with a table */
const TableStat = ({title, value, tableDefinition}: Props<*>) => (
  <BottomSpace>
    <Label>
      {title} ({(value && value.length) || 0})
    </Label>
    <Table data={value} tableDefinition={tableDefinition} />
  </BottomSpace>
);

export default TableStat;
