import style, {css} from 'styled-components';

export const center = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
`;
export const ButtonGroup = style.div`
  > * + * {
    margin-left: 1rem;
  }
`;

export const hoverStyle = css`
  color: ${props => props.theme.palette.primary};
`;
