// @flow
import {paginationFragment} from 'data/other/fragments';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {bundleFragment, bundleWithComponentsFragment} from './fragments';
import type {Bundle} from './types';

export const bundleListQuery: Query<Bundle[]> = {
  gql: gql`
    query bundleListQuery($filter: BundleFilter) {
      bundle {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...bundleFragment
          }
        }
      }
    }
    ${bundleFragment}
    ${paginationFragment}
  `,
  selector: ['bundle', 'list'],
  pagination: true,
  debounceKey: 'bundleListQuery',
};

export const bundleDetailQuery: Query<Bundle> = {
  gql: gql`
    query bundleDetailQuery($id: Int!) {
      bundle {
        getBundleDetail(bundleId: $id) {
          ...bundleFragment
        }
      }
    }
    ${bundleWithComponentsFragment}
  `,
  selector: ['bundle', 'getBundleDetail'],
};
