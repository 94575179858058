// @flow
import Tabs from 'components/Tabs';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import AffiliateUsersTable from './AffiliateUsers';
import ProductItemsTable from './ProductItemsTable';
import StoreInfo from './StoreInfo';

const AffiliateDetail = ({affiliateDetail}) => {
  const {id} = affiliateDetail;

  const tabs = {
    'Store Info': <StoreInfo data={affiliateDetail} />,
    Inventory: <ProductItemsTable affiliateId={id} />,
    Users: <AffiliateUsersTable affiliateId={id} route={urls.user} />,
  };

  return <Tabs tabs={tabs} />;
};

const enhancer: HOC<*, {data: Object}> = compose(
  withStateHandlers(
    ({data}) => ({
      affiliateDetail: data,
    }),
    {}
  )
);

export default enhancer(AffiliateDetail);
