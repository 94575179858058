import FieldWrap from 'components/Inputs/FieldWrap';
import {TimePicker} from 'components/TimePicker';
import withField from 'hoc/withField';

const InputTime = ({
  name,
  id,
  defaultValue,
  disabled,
  onChange,
  value,
  error,
  label,
  labelPosition,
  errors,
  hint,
  alignment,
}) => {
  return (
    <FieldWrap
      error={error}
      errors={errors}
      label={label}
      labelPosition={labelPosition}
      hint={hint}
      alignment={alignment}
    >
      <TimePicker
        id={id}
        name={name}
        type="time"
        placeholder="HH:MM"
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
    </FieldWrap>
  );
};

export default withField(InputTime);
