// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {Product} from 'data/product/types';
import urls from 'pages/urls';
import {path, prop, props} from 'ramda';

import Pill from '../../components/Pills';
import {getPillPropsForVisibility} from '../../components/Table/helper';
import ProductListImage from './ProductListImage';

const tableDefinition: TableDefinition<Product> = {
  columns: [
    {
      title: 'product',
      value: props(['name', 'images']),

      // $Ramda
      titleAttribute: prop('name'),
      type: ProductListImage,
      props: v => {
        const image = v.images[0] || {url: ''};
        return {
          label: v.name,
          imageUrl: image.url,
        };
      },
    },
    {
      title: 'categories',
      titleAttribute: v =>
        v.categories ? v.categories.map((c, index) => `${index > 0 ? ', ' : ''}${c.name}`) : '',
      value: v =>
        v.categories ? v.categories.map((c, index) => `${index > 0 ? ', ' : ''}${c.name}`) : '',
    },
    {
      title: 'vendor',
      // $Ramda
      titleAttribute: path(['manufacturer', 'name']),
      // $Ramda
      value: path(['manufacturer', 'name']),
    },
    {
      title: 'visibility',
      titleAttribute: v =>
        v.isVisible
          ? 'This product will be shown to your customers if the product is available'
          : 'This product will not be shown to your customers',
      value: prop('isVisible'),
      type: Pill,
      props: v => getPillPropsForVisibility(v.isVisible),
    },
  ],
  rowLink: v => urls.product.link.update(v.id),
};

export default tableDefinition;
