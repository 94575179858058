// @flow
// @author: t04435
// Date: 7/7/21

// $Import
import {PropsWithChildren} from 'react';
import React from 'react';

import {StyledPill} from './styled';

export type Props = {
  // Should display the Big version
  isBig?: boolean,
  // Determines the color used, if not provided it defaults to base(grey)
  status?: 'success' | 'warning' | 'alert',
  // Alternatively provide a CSS color to use
  color?: string,
  className?: string,
};

const Pill = (props: PropsWithChildren<Props>) => {
  const {isBig, status, children, color, className} = props;

  return (
    <StyledPill
      component={isBig ? 'h2' : 'p'}
      className={className}
      isBig={isBig}
      status={status}
      color={color}
    >
      {children}
    </StyledPill>
  );
};

export default Pill;
