// @flow
import Button from 'components/Button';
import DayPicker from 'components/DayPicker';
import Switch from 'components/Switch';
import type {ExceptionDay, RegularDay} from 'data/affiliate/types';
import {isEmpty, pathOr} from 'ramda';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import InputTimePicker from '../../InputTimePicker';
import {DayInputWrap, DayName, InputLabel, InputWrap, SeparatorStyled} from './styled';

// @todo Expand to include all props. These props were created to satisfy the
// flow errors relating to the work being undertaken on the date picker
type DayInputProps = any & {
  value: RegularDay | ExceptionDay,
};

const DayInput = ({
  id,
  value,
  toggleOpen,
  handleDateChange,
  handleHoursChange,
  onRemove,
  label,
  special,
  'data-cy': dataCy,
}: DayInputProps) => {
  const isOpen = !value.closed;
  return (
    <DayInputWrap>
      {special ? (
        <DayPicker
          id={id}
          value={value.date}
          onChange={handleDateChange}
          dayPickerProps={{
            disabledDays: {
              before: new Date(),
            },
          }}
          data-cy={dataCy}
        />
      ) : (
        <DayName>{label}</DayName>
      )}
      <InputWrap>
        <InputLabel>open</InputLabel>
        <Switch value={isOpen} onChange={toggleOpen} data-cy={`${dataCy}-switch`} />
      </InputWrap>
      {isOpen && (
        <React.Fragment>
          <InputTimePicker
            name="start"
            // $Ramda
            defaultValue={pathOr('', ['hours', 'start'], value)}
            handleChange={handleHoursChange}
            data-cy={`${dataCy}-opening-time`}
          />
          {/* Divider btw start - end times */}
          <SeparatorStyled>-</SeparatorStyled>
          <InputTimePicker
            name="end"
            // $Ramda
            defaultValue={pathOr('', ['hours', 'end'], value)}
            handleChange={handleHoursChange}
            data-cy={`${dataCy}-closing-time`}
          />
        </React.Fragment>
      )}
      {special && (
        <Button
          kind="primary"
          danger
          onClick={onRemove}
          data-cy={`remove-special-day-button`}
          type="button"
        >
          X
        </Button>
      )}
    </DayInputWrap>
  );
};

type Enhancer = {
  label: string,
  value: RegularDay | ExceptionDay,
  onChange: Function,
  onRemove?: Function,
  special?: boolean,
  'data-cy'?: string,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleDateChange: props => v =>
    props.onChange({
      ...props.value,
      date: v,
    }),
  handleHoursChange: props => e => {
    e.stopPropagation();
    props.onChange({
      ...props.value,
      hours: {
        ...props.value.hours,
        [e.target.name]: isEmpty(e.target.value) ? null : e.target.value,
      },
    });
  },
  toggleOpen: props => () =>
    props.onChange({
      ...props.value,
      closed: !props.value.closed,
      hours: null,
    }),
});

export default enhancer(DayInput);
