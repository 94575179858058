import {images, optional, required} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  id: optional,
  name: required,
  description: required,
  categoryId: required,
  images: images,
  // order: integerRequired,
});
