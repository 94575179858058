// @flow
import Button from 'components/Button';
import InputSwitch from 'components/Inputs/InputSwitch';
import {Label} from 'componentsStyled/Typography';
import type {EditProps} from 'factories/createEntityCRUD';
import withForm from 'hoc/withForm';
import React from 'react';
import type {HOC} from 'recompose';

import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../../components/Inputs/InputFileUpload';
import schema from './schema';

const AccessoriesUpdate = ({data, isSubmitting}) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <InputFileUpload name="url" label="url" bucket="accessoryIcons" accept={IMAGE_MIME_TYPE} />
    <InputSwitch name="preselect" label="Is pre-selected on checkout?" />
    <Button kind="primary" loading={isSubmitting} big success type="submit">
      save
    </Button>
  </div>
);

const enhancer: HOC<*, EditProps> = withForm({
  schema,
  mapProps: props => ({
    url: [{url: props.data.url}],
    preselect: props.data.preselect,
  }),
  onSubmit: props => v => {
    const values = {
      name: props.data.name,
      ...v,
      url: v.url[0].url,
    };
    return props.submit({
      input: values,
    });
  },
});

export default enhancer(AccessoriesUpdate);
