// @flow
import styled from 'styled-components';

export const ProductListImageStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 4.8rem;
  height: 4.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2.4rem;
  border-radius: 0.4rem;
`;
