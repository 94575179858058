// @flow
import React from 'react';
import styled from 'styled-components';

import {formatNumber} from '../../../common/formatters';
import Section from '../../../components/Section';
import {formatCurrencyFromCents} from '../../../data/units/money/formatters';

/* TODO(T04435): Refactor this so styles are in its own file. */
/* TODO(T04435): Add types/docs to component props */
const StatCard = styled(
  ({
    title,
    currentPeriodValue,
    previousPeriodValue,
    comment,
    className,
    onClick,
    showChange = true,
    type,
  }) => {
    const change = ((currentPeriodValue - previousPeriodValue) / previousPeriodValue) * 100;
    const changeSign = change > 0 ? '+' : '';
    const changeClass = change > 0 ? 'primary' : 'error';

    if (previousPeriodValue === 0) {
      showChange = false;
    }

    const getValue = () => {
      switch (type) {
        case 'currency':
          return formatCurrencyFromCents(currentPeriodValue);
        default:
          return formatNumber(currentPeriodValue);
      }
    };

    return (
      <Section title={title} isTitle onClick={onClick} className={className}>
        <h1>
          {getValue()}
          {showChange && (
            <strong className={changeClass}>
              {changeSign}
              {change.toFixed(2)} %
            </strong>
          )}
        </h1>
        <p>{comment}</p>
      </Section>
    );
  }
)`
  ${props =>
    props.onClick &&
    `
  cursor: pointer;
  `}

  ${props =>
    props.statName === props.spotlightStat &&
    `
  border-color: ${props.theme.palette.primary};
  `}
`;

export default StatCard;
