//react-select's styling using 'emotion'
//Documentation available at: https://react-select.com/styles
export const styles = {
  //The select input element itself
  control: (defaultStyles, state) => {
    const {isFilter, isSearchable} = state.selectProps;
    const {isFocused} = state;
    return {
      ...defaultStyles,
      minWidth: '23rem',
      cursor: 'pointer',
      ...(isFilter && {
        border: 'none',
        background: 'none',
      }),
      ...(isSearchable &&
        isFocused && {
          cursor: 'text',
        }),
    };
  },
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: 'var(--grey)',
    };
  },
  //Option in dropdown
  option: defaultStyles => {
    return {
      ...defaultStyles,
      cursor: 'pointer',
    };
  },
  //Multiselect tag
  multiValue: defaultStyles => {
    return {
      ...defaultStyles,
      cursor: 'default',
    };
  },
  //Remove button on multiselect tag
  multiValueRemove: defaultStyles => {
    return {
      ...defaultStyles,
      cursor: 'pointer',
      transition: 'color 250ms ease-in, background-color 250ms ease-in',
    };
  },
  //Arrow icon
  dropdownIndicator: (defaultStyles, state) => {
    const {menuIsOpen} = state.selectProps;
    return {
      ...defaultStyles,
      cursor: 'pointer',
      transition: 'transform 250ms ease-in',
      ...(menuIsOpen && {transform: 'var(--rotationOpen)'}),
    };
  },
  //Close icon
  clearIndicator: defaultStyles => {
    return {
      ...defaultStyles,
      transition: 'color 250ms ease-in',
      cursor: 'pointer',
      '&:hover': {
        color: 'var(--error)',
      },
    };
  },
};

//Theme colours
export const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary)',
    primary50: 'var(--primaryLight)',
    primary25: 'var(--primaryLight)',
    neutral80: 'var(--dark)',
    neutral50: 'var(--dark)',
    neutral40: 'var(--greyDark)',
    neutral20: 'var(--greyDarker)',
    neutral10: 'var(--greyLight)',
    danger: 'var(--error)',
    dangerLight: 'var(--errorLight)',
  },
});
