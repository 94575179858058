// @flow
import {
  affiliateCreateMutation,
  affiliateDeleteMutation,
  affiliateUpdateMutation,
} from 'data/affiliate/mutations';
import {affiliateDetailQuery, affiliatesListQuery} from 'data/affiliate/queries';
import {updateOptOutsMutation} from 'data/discounts/mutations';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import UpdateOptOuts from '../Discounts/UpdateOptOuts';
import AffiliateDetail from './AffiliateDetail';
import AffiliateEdit from './AffiliateEdit';
import filterConfig from './filterConfig';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: AffiliateEdit,
  detail: AffiliateDetail,
  detailQuery: affiliateDetailQuery,
  listQuery: affiliatesListQuery,
  updateMutation: affiliateUpdateMutation,
  deleteMutation: affiliateDeleteMutation,
  createMutation: affiliateCreateMutation,
  tableDefinition,
  filterConfig,
  title: 'Stores',
  entityName: 'Store',
  route: urls.shop,
  editAll: UpdateOptOuts,
  updateAllMutation: updateOptOutsMutation,
});

const enhancer = withHelpCenter({category: 'stores'});

export default enhancer(Page);
