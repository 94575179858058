import theme from 'global/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${theme.palette.greyLight};
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  button {
    max-width: 18rem;
    align-self: center;
  }
`;
