import {boolean, dateRequired, stringOptional, stringRequired} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  date: dateRequired,
  time: stringRequired,
  timezone: stringOptional,
  bookingId: stringOptional,
  actionToDebug: stringRequired,
  runeAsLive: boolean,
});
