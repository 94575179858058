/**
 * Given the page title, and the error error object, return a user friendly error message
 * @param {String} pageTitle
 * @param {Error} error
 */
export const createErrorMessage = (pageTitle: String, error: Error): String => {
  if (pageTitle === 'Category') {
    if (error.message.includes('violates foreign key constraint'))
      return "Please ensure that this category has no related product groups and isn't a related product of another category";
  }
  console.log('*** Name of page', pageTitle);
  return 'Not at category detail';
};
