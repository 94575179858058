// @flow
import config from 'global/config';
import React from 'react';

import {Image, ProductListImageStyled} from './styled';

type Props = {
  label: string,
  imageUrl: string,
};

const ProductListImage = ({imageUrl, label}: Props) => (
  <ProductListImageStyled>
    <Image
      src={imageUrl || `${config.brandUrl}/fallback-product-image.png`}
      width={44}
      height={44}
    />
    <span>{label}</span>
  </ProductListImageStyled>
);

export default ProductListImage;
