import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const StyledLogoLink = styled(Link)`
  color: ${props => props.theme.palette.dark};

  svg {
    fill: ${props => props.theme.palette.dark};
    transition: transform 0.25s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

export const StyledLogo = styled.img`
  width: 97px;
  height: 37px;
  object-fit: contain;
`;
