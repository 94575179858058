// @flow
import Button from 'components/Button';
import AddProductItem from 'containers/Modals/AddProductItem';
import {openModal} from 'data/modals/actions';
import {addProductItemMutation} from 'data/productItem/mutations';
import {type ProductVariant} from 'data/productVariant/types';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

const AddItem = ({addItem}) => (
  <Button kind="primary" onClick={addItem}>
    Add Stock Item
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: ProductVariant,
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withMutation(addProductItemMutation),
  withHandlers({
    addItem: props => () =>
      props.openModal(AddProductItem, {
        data: {productVariantId: props.data.id},
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(AddItem);
