// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {affiliateDetailQuery, affiliatesListQuery} from './queries';

export const affiliateUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation affiliateUpdateMutation($input: AffiliateUpdate!) {
      affiliate {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [affiliateDetailQuery],
};

export const affiliateDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation affiliateDeleteMutation($id: Int!) {
      affiliate {
        update(input: {id: $id, allowed: false}) {
          id
        }
      }
    }
  `,
  refetch: [affiliatesListQuery],
};

export const affiliateCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation affiliateCreateMutation($input: AffiliateCreate!) {
      affiliate {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [affiliatesListQuery],
  selector: ['affiliate', 'create'],
};
