// @flow
import {Label} from 'componentsStyled/Typography';
import {type Location} from 'data/location/types';
import React from 'react';

type Props = {
  data: Location,
};

const LocationDetail = ({data}: Props) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <Label>Latitude</Label>
    <p>{data.lat}</p>
    <Label>Longitude</Label>
    <p>{data.lng}</p>
    <Label>Country name</Label>
    <p>{data.country.name}</p>
  </div>
);

export default LocationDetail;
