// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Link, Route} from 'react-router-dom';

const ActiveLink = ({component: StyledComponent, to, location, ...rest}) => (
  <Route
    path={to}
    location={location}
    children={({match}) => {
      return <StyledComponent component={Link} to={to} active={Boolean(match)} {...rest} />;
    }}
  />
);

const mapStateToProps = state => ({
  location: state.router.location,
});

export default connect(mapStateToProps, {})(ActiveLink);
