// @flow
import React from 'react';

import LandingContent from '../../components/LandingParent';

const SettingsPage = () => (
  <React.Fragment>
    <LandingContent landing="Settings" />
  </React.Fragment>
);

export default SettingsPage;
