// @flow
import {normalize} from 'polished';
import {injectGlobal} from 'styled-components';

import theme from './theme';

/* eslint-disable */
injectGlobal`
  ${normalize()}

  @import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins:wght@300;400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&family=Roboto:wght@400;500;700&display=swap');

  /* Keep in sync with admin/src/global/theme.js*/
  :root {
    --dark: hsl(220, 26%, 14%);
    --greyDarker: hsl(218, 17%, 35%);
    --greyDark: hsl(216, 15%, 52%);
    --grey: hsl(214, 20%, 69%);
    --greyLight: hsl(214, 32%, 91%);
    --greyLighter: hsl(204, 45%, 98%);
    --light: hsl(0, 0%, 100%);

    --primary: hsl(170, 79%, 37%);
    --primaryLight: hsl(170, 79%, 97%);
    --primaryDark: hsl(170, 79%, 17%);

    --success: hsl(153, 57%, 36%);
    --successDark: hsl(153, 57%, 17%);
    --successLight: hsl(153, 57%, 97%);
    --warning: hsl(27, 84%, 57%);
    --warningDark: hsl(27, 84%, 17%);
    --warningLight: hsl(27, 84%, 97%);
    --error: hsl(0, 76%, 57%);
    --errorDark: hsl(0, 76%, 17%);
    --errorLight: hsl(0, 76%, 97%);

    --boxShadow-heavy: 0 0.8rem 2.8rem rgba(0, 0, 0, 0.25);
    --rotationOpen: rotateZ(180deg);
  }

  html {
    font-size: 10px;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    overflow: hidden;
    color: ${theme.palette.dark};
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin-top: 0;
  }

  h1,
  h2,
  button {
    font-family: 'Poppins', sans-serif;
  }

  h1 {
    font-size: 2.4rem;
    line-height: 4rem;
    font-weight: ${theme.fontWeights.semiBold};
  }

  h2 {
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: ${theme.fontWeights.medium};
  }

  h3 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: ${theme.fontWeights.medium};
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: ${theme.fontWeights.bold};
    text-transform: uppercase;
  }

  strong {
    font-weight: ${theme.fontWeights.bold};
  }
// Colours
  a {
    color: inherit;
  }
  
  /* Helper classes for text tags */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  label,
  p {
    &.white {
      color: ${theme.palette.light};
    }

    &.primary {
      color: ${theme.palette.primary};
    }

    &.success {
      color: ${theme.palette.success};
    }

    &.error {
      color: ${theme.palette.error};
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.inline {
      display: inline-block;
      margin: 0;
    }

    &.center {
      text-align: center;
    }

    &.noMargin {
      margin: 0;
    }

    &.noMarginTop {
      margin-top: 0;
    }

    &.noMarginBottom {
      margin-bottom: 0;
    }

    &.noMarginLeft {
      margin-left: 0;
    }

    &.noMarginRight {
      margin-right: 0;
    }
    
    &.capitalized {
      text-transform: capitalize;
    }
  }

  // overrides react-tooltip
  .__react_component_tooltip {
    border-radius: 4px !important;
    font-size: 12px !important;
    padding: 8px 12px !important;
  }

  // remove scrollbars
  html {
    overflow: scroll;
  }
  
  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }

  ::-webkit-scrollbar {
    width: 0;
    //background: transparent; /* make scrollbar transparent */
  }
  
  // react-quill
  .ql-editor {
    min-height: 114px;
  }
`;
