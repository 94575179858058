// @flow
import gql from 'graphql-tag';

const dayFragment = gql`
  fragment dayFragment on OpeningInfoForDay {
    closed
    hours {
      start
      end
      pauseStart
      pauseEnd
    }
  }
`;

export const affiliateFragment = gql`
  fragment affiliateFragment on Affiliate {
    id
    isDefaultShop
    name
    description
    email
    phone
    website
    address
    addressForTaxPurposes {
      street1
      city
      state
      country
      zip
    }
    logo
    inTimezone
    allowed
    logo
    images {
      id
      url
    }
    location {
      id
      name
      lat
      lng
      country {
        id
        currency
      }
    }
    openingHours {
      mon {
        ...dayFragment
      }
      tue {
        ...dayFragment
      }
      wed {
        ...dayFragment
      }
      thu {
        ...dayFragment
      }
      fri {
        ...dayFragment
      }
      sat {
        ...dayFragment
      }
      sun {
        ...dayFragment
      }
    }
    specialOpeningDates {
      date
      ...dayFragment
    }
    categories {
      categoryId
      name
    }
    cancelHrAfterCreate
    discountOptOuts {
      id
      discountId
    }
    preBookingBufferDays
    postBookingBufferDays
    automaticallyEndPostProcessing
    enableDeliveryBuffers
    outboundBufferDays
    inboundBufferDays
    allowInStoreFulfilment
    allowInStoreBookingExtension
    inStoreExtensionCutoffDays
    inStoreFulfilmentInstructions
    allowDeliveryFulfilment
    allowDeliveryBookingExtension
    deliveryExtensionCutoffDays
    deliveryFulfilmentInstructions
    deliveryCharge
    enableDeliveryChargeThreshold
    deliveryChargeThreshold
    deliverySummary
    logisticsSummary
    deliveryExplanation
    returnInstructions
  }
  ${dayFragment}
`;
