// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import type {EligibleDiscount} from './types';

export const discountsListQuery: Query<EligibleDiscount[]> = {
  gql: gql`
    query discountsListQuery($filter: ListDiscountsFilter) {
      discount {
        list(filter: $filter) {
          data {
            id
            percentage
          }
        }
      }
    }
  `,
  selector: ['discount', 'list', 'data'],
};
