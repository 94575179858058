// @flow
import React from 'react';
import * as yup from 'yup';

import {optional} from '../../common/validators';
import Pill from '../../components/Pills';
import withForm from '../../hoc/withForm';
import {withStory} from './withStory';

const PillsStory = ({values}) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: '2rem',
      justifyItems: 'flex-start',
      alignItems: 'flex-start',
      backgroundColor: '#fff',
      padding: '2rem',
    }}
  >
    <Pill status="success">Success</Pill>
    <Pill status="warning">Warning</Pill>
    <Pill status="alert">Alert</Pill>
    <Pill>Default</Pill>
    <Pill isBig status="success">
      Success
    </Pill>
    <Pill isBig status="warning">
      Warning
    </Pill>
    <Pill isBig status="alert">
      Alert
    </Pill>
    <Pill isBig>Default</Pill>
  </div>
);

const enhancer = withForm({
  schema: yup.object().shape({
    loading: optional,
  }),
  mapProps: () => ({
    loading: false,
  }),
});

export default enhancer(withStory(PillsStory));
