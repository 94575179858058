// @flow
import React from 'react';

import {Helper, Hint, Label, Link, Number} from '../../componentsStyled/Typography';
import {withStory} from './withStory';

// $Import
class WithFontStyles extends React.Component<{children: React.ReactElement}> {
  containerRef: HTMLElement;

  style: CSSStyleDeclaration;

  componentDidMount(): void {
    this.style = window.getComputedStyle(this.containerRef);
  }

  outputStyles() {
    if (this.style) {
      return (
        <React.Fragment>
          <pre>font-family: {this.style.fontFamily}</pre>
          <pre>font-weight: {this.style.fontWeight}</pre>
          <pre>font-size: {this.style.fontSize}</pre>
          <pre>line-height: {this.style.lineHeight}</pre>
        </React.Fragment>
      );
    }

    return null;
  }

  render() {
    const {children} = this.props;
    const child = React.Children.only(children);
    const childElement = React.cloneElement(child, {
      ref: el => {
        this.containerRef = el;
      },
    });

    return (
      <div>
        {childElement}
        {this.outputStyles()}
      </div>
    );
  }
}

const TypographyStory = () => (
  <React.Fragment>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '2rem',
      }}
    >
      <WithFontStyles>
        <h1>H1</h1>
      </WithFontStyles>
      <WithFontStyles>
        <h2>H2</h2>
      </WithFontStyles>
      <WithFontStyles>
        <h3>H3</h3>
      </WithFontStyles>
      <WithFontStyles>
        <h4>H4</h4>
      </WithFontStyles>
      <WithFontStyles>
        <p>p</p>
      </WithFontStyles>
      <WithFontStyles>
        <strong>strong</strong>
      </WithFontStyles>
      <WithFontStyles>
        <button>button</button>
      </WithFontStyles>
    </div>
    <h3>HELPERS</h3>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '2rem',
      }}
    >
      <Link component="a" redirect={false} href="#">
        Link
      </Link>
      <Hint>Hint</Hint>
      <Label>Label</Label>
      <Helper>Helper</Helper>
      <Number>Number</Number>
    </div>
    <h3>P HELPER</h3>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '2rem',
      }}
    >
      <p className="white">Lorem ipsum dolor sit amet.</p>
      <p className="primary">Lorem ipsum dolor sit amet.</p>
      <p className="green">Lorem ipsum dolor sit amet.</p>
      <p className="red">Lorem ipsum dolor sit amet.</p>
      <p className="uppercase">Lorem ipsum dolor sit amet.</p>
      <p className="inline">Lorem ipsum dolor sit amet.</p>
      <p className="center">Lorem ipsum dolor sit amet.</p>
      <p className="noMargin">Lorem ipsum dolor sit amet.</p>
      <p className="noMarginTop">Lorem ipsum dolor sit amet.</p>
      <p className="noMarginRight">Lorem ipsum dolor sit amet.</p>
      <p className="noMarginBottom">Lorem ipsum dolor sit amet.</p>
      <p className="noMarginLeft">Lorem ipsum dolor sit amet.</p>
    </div>
  </React.Fragment>
);

export default withStory(TypographyStory);
