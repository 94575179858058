// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import InputTextArea from 'components/Inputs/InputTextArea';
import CategorySelect from 'containers/CategorySelect';
import withForm from 'hoc/withForm';
import {path} from 'ramda';
import React from 'react';

import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../components/Inputs/InputFileUpload';
import Section from '../../../components/Section';
import schema from './schema';

type Props = {
  isSubmitting: boolean,
  create: boolean,
};

const BundleEdit = ({isSubmitting, create}: Props) => (
  <div>
    <Section>
      <InputText name="name" label="name" />
      <InputTextArea
        name="description"
        label="description"
        hint="&#9432; Only embedded videos from youtube and vimeo will be displayed in the description box below; all other videos will fail to render"
      />
      <InputFileUpload
        name="images"
        label="images"
        bucket="productImages"
        multiple
        accept={IMAGE_MIME_TYPE}
      />
      <CategorySelect name="categoryId" label="category" />
    </Section>
    <Button kind="primary" loading={isSubmitting}>
      {create ? 'Create bundle' : 'save'}
    </Button>
  </div>
);

export default withForm({
  schema,
  mapProps: props => ({
    ...props.data,
    categoryId: path(['data', 'category', 'categoryId'], props),
  }),
  onSubmit: props => values => {
    return props.submit({input: values});
  },
})(BundleEdit);
