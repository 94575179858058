// @flow
import {formatBool} from 'common/formatters';
import ProductItemStatusPill from 'components/Pills/ProductItemStatusPill';
import type {TableDefinition} from 'components/Table/types';
import type {ProductItemStatus} from 'data/productItem/types';
import config from 'global/config';
import {prop} from 'ramda';

import {formatCurrencyFromCents} from '../../../../data/units/money/formatters';
import EditItemLink from './EditItemLink';

type TableItems = {
  code: string,
  buyItRrp: ?number,
  variantId: number,
  variantName: string,
  productId: number,
  productName: string,
  productItemId: number,
  online: boolean,
  allowed: boolean,
  affiliateId: number,
  pricingTemplateId: number,
  categoryName: string,
  categoryId: number,
  manufacturerId: number,
  status: ProductItemStatus,
};

/* TODO(t04435): TABLE_ACTIONS? edit */
const tableDefinition: TableDefinition<TableItems> = {
  columns: [
    {
      title: 'Asset ID',
      value: v => v.code,
      // $Dunno
      type: EditItemLink,
      props: v => ({
        data: {
          code: v.code,
          buyItRrp: v.buyItRrp,
          id: v.productItemId,
          affiliateId: v.affiliateId,
          allowed: v.allowed,
          pricingTemplateId: v.pricingTemplateId,
          category: v.categoryName,
          manufacturerId: v.manufacturerId,
          productId: v.productId,
          variantId: v.variantId,
        },
      }),
    },
    {
      title: 'product',
      value: prop('productName'),
    },
    {
      title: 'variant',
      value: prop('variantName'),
    },
    {
      title: 'In Store Status',
      value: v => v.status,
      type: ProductItemStatusPill,
      props: v => ({status: v.status}),
    },
    {
      title: 'available',
      value: v => formatBool(v.allowed),
    },
    {
      title: 'Buy It RRP',
      // TODO(ray): Wire in the currency
      value: v => formatCurrencyFromCents(v.buyItRrp),
    },
  ],
};

if (config.featureFlags.enableBuyIt) {
  tableDefinition.columns.push({
    title: 'Buy It RRP',
    // TODO(ray): Wire in the currency
    value: v => {
      return formatCurrencyFromCents(v.buyItRrp);
    },
  });
}

export {tableDefinition};
