// @flow
import {formatBool} from 'common/formatters';
import ImageItem from 'components/ImageItem';
import {Label} from 'componentsStyled/Typography';
import type {Accessory} from 'data/accessories/types';
import React from 'react';

type Props = {
  data: Accessory,
};

const AccessoryDetail = ({data}: Props) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <Label>Is Preselected On Checkout</Label>
    <p>{formatBool(data.preselect)}</p>
    <ImageItem url={data.url} />
  </div>
);

export default AccessoryDetail;
