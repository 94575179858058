// @flow
import {type PageInfo} from 'data/other/types';
import {append, path} from 'ramda';

export const pageSize = 50;

export const getPageInfo = (selector: string[], data?: {}): PageInfo =>
  // $Ramda
  path(selector.concat('pageInfo'), data);

export const getPaginationVariables = (selector?: string[], pagination: boolean, data?: {}) => {
  if (!pagination || !selector) return {};

  const pageInfo = getPageInfo(selector, data);

  return {
    limit: pageSize,
    offset: pageInfo ? pageInfo.nextOffset : undefined,
  };
};

export const getPaginationPath = append('pageInfo');
