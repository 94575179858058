// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {Location} from 'data/location/types';
import urls from 'pages/urls';
import {path, prop} from 'ramda';

const tableDefinition: TableDefinition<Location> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'country name',
      // $Ramda
      value: path(['country', 'name']),
    },
  ],
  rowLink: v => urls.location.link.detail(v.id),
};
export default tableDefinition;
