// @flow
import {minimalPaymentFragment} from 'data/payment/fragments';
import {productItemFragment} from 'data/productItem/fragments';
import gql from 'graphql-tag';

export const reservationPricingFragment = gql`
  fragment reservationPricingFragment on ReservationPricing {
    totalPrice
    fullPrice
    currency
    discounts {
      percentage
      type
      detail
    }
    tax
  }
`;

export const reservationFragmentMinimal = gql`
  fragment reservationFragmentMinimal on Reservation {
    id
    status
    externalId
    basketCode
    deletedAt
  }
`;

export const reservationFragmentFull = gql`
  fragment reservationFragmentFull on Reservation {
    ...reservationFragmentMinimal
    productItem {
      ...productItemFragment
    }
    reservationTransactions {
      reservationTransactionId
      amount
      isRefund
      createdAt
      details
      payment {
        ...minimalPaymentFragment
      }
    }
    booking {
      bookingId
      start
      end
      externalId
      order {
        orderId
        externalId
      }
    }
  }
  ${reservationFragmentMinimal}
  ${productItemFragment}
  ${minimalPaymentFragment}
`;
