// @flow
import {productDetailQuery} from 'data/product/queries';
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {getVariantParametersQuery, productVariantDetailQuery} from './queries';

export const productVariantUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productVariantUpdateMutation($input: ProductVariantUpdateInput!) {
      product {
        updateProductVariant(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productVariantDetailQuery],
};

export const productVariantDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productVariantDeleteMutation($id: Int!) {
      product {
        deleteProductVariant(id: $id)
      }
    }
  `,
};

// NOTE: Unused due to removal of parameters:
// https://app.clubhouse.io/awayco/story/1230/differentiate-variants-by-name-not-parameters
export const productVariantParametersUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productVariantParametersUpdateMutation($id: Int!, $input: [ParameterUpsertInput]!) {
      product {
        upsertParameters(productVariantId: $id, input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productVariantDetailQuery, getVariantParametersQuery],
};

export const productVariantCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productVariantCreateMutation($id: Int!, $name: String!) {
      product {
        createProductVariant(input: {productId: $id, name: $name}) {
          productVariant {
            id
          }
        }
      }
    }
  `,
  selector: ['product', 'createProductVariant', 'productVariant'],
  refetch: [productDetailQuery],
};
