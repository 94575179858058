// @flow
import {StyledTimePicker} from 'components/TimePicker/styled';
import * as React from 'react';

export type Props = {
  name?: string,
  id?: string,
  defaultValue?: string,
  disabled?: Boolean,
  onChange: string => any,
  value: string,
};

export const TimePicker = ({name, id, defaultValue, disabled, onChange, value}: Props) => {
  const handleChange = e => {
    return onChange && onChange(e.target.value);
  };

  return (
    <StyledTimePicker
      id={id}
      name={name}
      type="time"
      placeholder="HH:MM"
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );
};
