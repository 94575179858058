import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import type {Legal} from './types';

export const getLegalQuery: Query<Legal> = {
  gql: gql`
    query getLegalQuery {
      legal {
        getLegal {
          rentalAgreement
        }
      }
    }
  `,
  selector: ['legal', 'getLegal'],
};
