import {StyledInput} from 'componentsStyled/Forms';
import styled from 'styled-components';

export const GooglePlacesAutoCompleteWrapper = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    color: ${props => props.theme.palette.greyLight};
  }
`;

export const PlaceAutocomplete = styled(StyledInput)`
  padding-left: 4rem;
`;

export const PlacesList = styled.ul`
  position: absolute;
  z-index: ${props => props.theme.zIndices.minimal};
  list-style: none;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  border: solid 0.1rem ${props => props.theme.palette.lightGray};
  color: ${props => props.theme.palette.text};
  background: ${props => props.theme.palette.light};
  font-size: 1.6rem;
  font-weight: ${props => props.theme.fontWeights.book};
  outline: none;
  border: 0.2rem solid ${props => props.theme.palette.greyLight};
  border-top: none;
  background: ${props => props.theme.palette.light};
  border-radius: 0;
  color: ${props => props.theme.palette.greyDark};

  &:focus {
    border-color: ${props => props.theme.palette.primaryAccent};
    z-index: ${props => props.theme.zIndices.minimal};
  }
`;

export const PlacesListItem = styled.li`
  margin: 0;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background-color 200ms linear;

  &:hover {
    background-color: ${props => props.theme.palette.greyLight};
  }
`;
