// @flow
import Button from 'components/Button';
import InputControl from 'components/Inputs/InputControl';
import InputText from 'components/Inputs/InputText';
import Section from 'components/Section';
import {LayoutFlex} from 'componentsStyled/Layout';
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import {saveThemeMutation} from 'data/theme/mutations';
import {getThemeQuery} from 'data/theme/queries';
import type {Theme, ThemeInput} from 'data/theme/types';
import withForm from 'hoc/withForm';
import withHelpCenter from 'hoc/withHelpCenter';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import {StyledCheckbox} from 'pages/Theme/styled';
import {clone} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import InputColor from '../../components/Inputs/InputColor';
import InputFileUpload, {IMAGE_MIME_TYPE} from '../../components/Inputs/InputFileUpload';
import schema from './schema';

const simpleHeader = (
  <React.Fragment>
    <InputFileUpload name="logo" label="logo" bucket="tenantLogos" accept={IMAGE_MIME_TYPE} />
    <InputText
      name="logoLink"
      label="Logo Link"
      hint="This link will redirect the user when the click on your header, we recommend you set this to be your brand's main site. The link will be disabled when advanced header options are used."
    />
  </React.Fragment>
);

const advancedHeader = (
  <React.Fragment>
    <InputText
      name="headerHtml"
      label="Header HTML"
      hint="This will overwrite the HTML in the User App"
    />
    <InputText
      name="headerCss"
      label="Header CSS"
      hint="This will overwrite the CSS in the User App"
    />
  </React.Fragment>
);

const ThemeEditor = ({isSubmitting}) => {
  return (
    <React.Fragment>
      <h1>Theme Configuration</h1>
      <div>
        <Section title="Header">
          <LayoutFlex>
            <LayoutFlex col column half>
              {simpleHeader}
            </LayoutFlex>
            <LayoutFlex col column half>
              <InputControl
                name="advancedHeader"
                label="Advanced header options"
                on={advancedHeader}
                off={null}
              />
            </LayoutFlex>
          </LayoutFlex>
        </Section>

        <Section title="Footer">
          <InputText name="footerHtml" label="Footer Html" />
          <InputText name="footerCss" label="Footer Css" />
        </Section>

        <Section title="Store Carousel">
          <p>
            This will show a list of stores at the top of your product list page. It will only show
            if you have more than one store.
          </p>
          <StyledCheckbox label="Enable" labelPosition="right" name="enableStoreCarousel" />
        </Section>

        <Section title="Colors">
          <InputColor name="primaryAccentColor" label="Primary accent color" />
          <InputColor name="primaryAccentDarkColor" label="Primary Accent Dark Color" />
          <InputColor name="primaryTextColor" label="Primary Text Color" />
          <InputColor name="secondaryTextColor" label="Secondary Text Color" />
          <InputColor name="backgroundColor" label="Background Color" />
        </Section>
        <Section title="Styling">
          <InputText
            name="buttonBorderRadius"
            label="Button Border Radius"
            hint="This changes the rounding on the corners of the buttons."
          />
          <InputText
            name="filterButtonBorderRadius"
            label="Filter Button Border Radius"
            hint="This changes the rounding on the corners of the filter buttons."
          />
          <InputText
            name="inputBorderRadius"
            label="Input Border Radius"
            hint="This changes the rounding on the corners of the input fields."
          />
        </Section>
        <Section title="Fonts">
          <InputText name="externalFontCssUrl" label="External Font Css Url" />
          <InputText name="bodyFontFamily" label="Body Font Family" />
          <InputText name="headingFontFamily" label="Heading Font Family" />
        </Section>
        <Button kind="primary" loading={isSubmitting}>
          save
        </Button>
      </div>
    </React.Fragment>
  );
};

const enhancer = compose(
  withQuery(getThemeQuery),
  withMutation(saveThemeMutation),
  connect(null, {notificationSuccess, notificationError}),
  withForm({
    schema,
    mapProps: (props: {data: Theme}): ThemeInput => ({
      advancedHeader: props.data.advancedHeader,
      enableStoreCarousel: props.data.enableStoreCarousel,
      // $Dunno
      logo: [{url: props.data.logo.logo}],
      logoLink: props.data.logo.logoLink,
      headerHtml: props.data.header.html,
      headerCss: props.data.header.css,
      footerHtml: props.data.footer.html,
      footerCss: props.data.footer.css,
      primaryAccentColor: props.data.colors.primaryAccent,
      primaryAccentDarkColor: props.data.colors.primaryAccentDark,
      primaryTextColor: props.data.colors.primaryText,
      secondaryTextColor: props.data.colors.secondaryText,
      backgroundColor: props.data.colors.background,
      buttonBorderRadius: props.data.borderRadiuses.button,
      filterButtonBorderRadius: props.data.borderRadiuses.filterButton,
      inputBorderRadius: props.data.borderRadiuses.input,
      externalFontCssUrl: props.data.fonts.externalFontCssUrl,
      bodyFontFamily: props.data.fonts.bodyFontFamily,
      headingFontFamily: props.data.fonts.headingFontFamily,
    }),
    onSubmit: props => async values => {
      const input = clone(values);
      input.logo = values.logo[0].url;
      await props.submitMutation({
        input,
      });
    },
    onSuccess: props => () => {
      props.notificationSuccess('Success');
    },
    onError: props => () => {
      props.notificationError('Error');
    },
  }),
  withHelpCenter({category: 'marketing'})
);

const EnhancedThemeEditor = enhancer(ThemeEditor);

const ThemePage = () => <EnhancedThemeEditor />;

export default ThemePage;
