// @flow
import {readableColor} from 'polished';
import React from 'react';

import theme from '../../global/theme';
import {withStory} from './withStory';

const PaletteStory = () => (
  <React.Fragment>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 16,
      }}
    >
      {Object.entries(theme.palette).map(([name, color]) => (
        <div
          style={{
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            height: 80,
            backgroundColor: color,
            color: readableColor(color, theme.palette.dark, theme.palette.light),
          }}
        >
          <React.Fragment>
            {name} => {color}
          </React.Fragment>
        </div>
      ))}
    </div>
  </React.Fragment>
);

export default withStory(PaletteStory);
