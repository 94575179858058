// @flow
import {
  productCreateMutation,
  productDeleteMutation,
  productUpdateMutation,
} from 'data/product/mutations';
import {productDetailQuery, productListQuery} from 'data/product/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import filterConfig from './filterConfig';
import ProductEdit from './ProductEdit';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: ProductEdit,
  detailQuery: productDetailQuery,
  listQuery: productListQuery,
  updateMutation: productUpdateMutation,
  createMutation: productCreateMutation,
  deleteMutation: productDeleteMutation,
  deleteMessage:
    'WARNING - Are you sure you want to permanantly delete this product and all variants and items associated with it?',
  tableDefinition,
  filterConfig,
  title: 'Products',
  entityName: 'Product',
  route: urls.product,
});

const enhancer = withHelpCenter({category: 'products'});

export default enhancer(Page);
