// @flow
import {pathEq} from 'ramda';
import * as React from 'react';

const defaultPredicate = pathEq(['data', 'length'], 0);

function withEmpty<Props>(
  // $Ramda
  predicate?: Props => boolean = defaultPredicate,
  message?: string
): (React.ComponentType<Props>) => React.ComponentType<Props> {
  return Component => (props: Props) => {
    if (predicate(props))
      return <p className={'capitalized'}>{message ? message : 'no results found'}</p>;

    return <Component {...props} />;
  };
}

export default withEmpty;
