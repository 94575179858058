// @flow
import {faCloudUploadAlt, faFileArchive, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
// @author: t04435
// Date: 2/7/21
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// $Import
import {clone, concat, endsWith, head, last, remove, replace, split} from 'ramda';
import React from 'react';
import {DropzoneInputProps, useDropzone} from 'react-dropzone';
import {connect} from 'react-redux';
import type {HOC} from 'recompose';
import {compose, withHandlers, withStateHandlers} from 'recompose';

import {uploadFile} from '../../../common/gcp';
import {Hint} from '../../../componentsStyled/Typography';
import {notificationError} from '../../../data/notifications/actions';
import withField from '../../../hoc/withField';
import Loader from '../../Loader';
import FieldWrap from '../FieldWrap';
import {
  AllowedFilesInfo,
  FilePreview,
  FilePreviewDelete,
  FileUploadGrid,
  StyledDropzone,
} from './styled';

// DOC: MIME TYPES
// Reference MIME Types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const IMAGE_MIME_TYPE = 'image/png,image/jpeg,image/webp';
export const ZIP_MIME_TYPE = 'application/zip';

// Available values => backend/src/api/services/http/graphql/schemas/storage/storage.graphql
export type StorageBucketType =
  | 'signatures'
  | 'productImages'
  | 'affiliateImages'
  | 'damageImages'
  | 'accessoryIcons'
  | 'tenantLogos'
  | 'bulkImports';

type Props = DropzoneInputProps & {
  // name for the input also used as id
  name: string,
  // Label for the input
  label: string,
  // Bucket to upload to GCP
  bucket: StorageBucketType,
  'data-cy'?: string,
};

type Image = {
  url: string,
  id?: string,
};

type PropsAllowedHint = {
  extensions: string,
};

const AllowedHint = ({extensions}: PropsAllowedHint) => (
  <AllowedFilesInfo>
    {/* TODO(t04435): ADMIN_UI replace with LayoutHelper => displayInline  */}
    <Hint>Allowed file extensions:</Hint> <h4 style={{display: 'inline'}}>{extensions}</h4>
  </AllowedFilesInfo>
);

const InputFileUpload = (props: Props) => {
  const {
    bucket,
    value,
    addFiles,
    updateFiles,
    removeFile,
    metadata,
    setMetadata,
    draggedIndex,
    setDraggedIndex,
    notificationError,
    // This(onChange) is not used only extracted here so is not passed with `rest` to FieldWrap.
    onChange,
    multiple,
    accept,
    name,
    'data-cy': dataCy,
    ...rest
  } = props;
  const hasValue = value && Array.isArray(value) && value.length > 0;

  const onDrop = async (accepted: File[], rejected: File[]) => {
    if (accepted.length === 0) {
      notificationError('Failed to upload files');
      /* TODO(t04435): Check if we want to give a more detailed feedback on why the files failed to upload */
      console.info(`Rejected: ${JSON.stringify(rejected, null, 2)}`);
      return;
    }

    const images = [];
    const metaData = [];

    // avoids single fileUpload from showing a second FilePreview > Loader
    if (!multiple) {
      updateFiles([]);
    }

    for (const file of accepted) {
      metaData.push({name: file.name, size: file.size});
    }
    setMetadata(metaData);

    for (const file of accepted) {
      const url = await uploadFile(file, bucket);
      metaData.shift();
      setMetadata(metaData);
      images.push({url});
    }
    addFiles({images, multiple});
    setMetadata([]);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  const renderAllowedFiles = (accept?: string) => {
    if (!accept) {
      return null;
    }

    const repl = replace(/,/g, '/', accept);
    const ext = split('/', repl);
    const extensions = ext.filter((e, i) => (i + 1) % 2 === 0).join(', ');
    return <AllowedHint extensions={extensions} />;
  };

  /**
   * Returns the GCP Storage id from the URL
   *
   * @param url: file URL like: https://static.release.awayco.com/product-images/XLJjORWl6.png
   * @returns {string} XLJjORWl6
   */
  // $Ramda
  const getIdFromUrl = (url: string): string => head(split('.', last(split('/', url)))) || url;

  const onFileDrop = (ev, index) => {
    ev.preventDefault();
    const temp = clone(value);

    if (index === draggedIndex) {
      return;
    }
    // swap files order
    // $Dunno
    [temp[index], temp[draggedIndex]] = [temp[draggedIndex], temp[index]];
    updateFiles(temp);
  };

  return (
    <FieldWrap {...rest} component="div">
      <FileUploadGrid data-cy={dataCy}>
        {hasValue &&
          value.map((v, i) => {
            const isZip = endsWith('.zip', v.url);
            return (
              <FilePreview
                key={getIdFromUrl(v.url)}
                style={{backgroundImage: `url(${v.url})`}}
                draggable={multiple}
                onDragStart={() => setDraggedIndex(i)}
                onDrop={e => onFileDrop(e, i)}
              >
                {isZip && <FontAwesomeIcon icon={faFileArchive} size="4x" />}
                <FilePreviewDelete onClick={removeFile(i)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </FilePreviewDelete>
              </FilePreview>
            );
          })}
        {metadata.map(meta => (
          <FilePreview key={meta.name}>
            <Loader />
          </FilePreview>
        ))}
        <StyledDropzone
          {...getRootProps({refKey: 'innerRef'})}
          isActive={isDragActive}
          id={name}
          name={name}
        >
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
          {/* TODO(t04435): ADMIN_UI replace with LayoutHelper => displayInline  */}
          <Hint>
            Drop your file{multiple && 's'} here or click to{' '}
            {multiple || !hasValue ? 'upload' : <h4 style={{display: 'inline'}}>replace</h4>}
          </Hint>
          {renderAllowedFiles(accept)}
        </StyledDropzone>
      </FileUploadGrid>
    </FieldWrap>
  );
};

const mapDispatchToProps = {
  notificationError,
};

const enhancer: HOC<*, Props> = compose(
  withField,
  connect(null, mapDispatchToProps),
  withHandlers({
    addFiles: props => (payload: {images: Image[], multiple: boolean}) => {
      const {multiple, images} = payload;
      const prevValue = props.value || [];
      if (multiple) {
        props.onChange(concat(prevValue, images));
      } else {
        const [url] = images;
        props.onChange([url]);
      }
    },
    updateFiles: props => images => {
      props.onChange(images);
    },
    removeFile: props => i => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      props.onChange(remove(i, 1, props.value));
    },
  }),
  withStateHandlers(
    {
      metadata: [],
      draggedIndex: 0,
    },
    {
      setMetadata: () => value => ({metadata: value}),
      setDraggedIndex: () => value => ({draggedIndex: value}),
    }
  )
);

export default enhancer(InputFileUpload);
