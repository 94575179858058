// @flow
import Button from 'components/Button';
import Table from 'components/Table';
import {userListQuery} from 'data/user/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';

import {tableDefinition} from './tableDefinition';

type Props = {|
  affiliateId: number,
  route: Object | string,
  data?: any,
|};

const AffiliateUsersTable = ({data, affiliateId, route}: Props) => {
  if (!data) {
    return <div>No results.</div>;
  }

  return (
    <div>
      <Button kind="primary" component="div" to={`${route.link.create}/${affiliateId}`}>
        Create User
      </Button>
      <Table tableDefinition={tableDefinition} data={data} />
    </div>
  );
};

const enhancer: HOC<*, Props> = withQuery(userListQuery, undefined, undefined, props => ({
  affiliateId: props.affiliateId,
}));

export default enhancer(AffiliateUsersTable);
