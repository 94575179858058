// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {ReservationTransaction} from 'data/reservations/types';
import {formatDateTime} from 'data/units/date/formatters';
import {formatCurrencyFromCents} from 'data/units/money/formatters';
import {sortCurrency} from 'data/units/money/helpers';
import urls from 'pages/urls';
import {prop} from 'ramda';

const getTransactionAmount = ({amount, isRefund, payment}: ReservationTransaction): string => {
  const currencyValue = formatCurrencyFromCents(amount, undefined, payment.currency);
  return isRefund ? `-${currencyValue}` : currencyValue;
};

const tableDefinition: TableDefinition<ReservationTransaction> = {
  columns: [
    {
      title: 'ID',
      value: prop('reservationTransactionId'),
    },
    {
      title: 'amount',
      value: getTransactionAmount,
      sortFunction: sortCurrency,
    },
    {
      title: 'status',
      value: rt => rt.payment.status,
    },
    {
      title: 'currency',
      value: rt => rt.payment.currency,
    },
    {
      title: 'payment ID',
      value: rt => rt.payment.id,
    },
    {
      title: 'paid at',
      value: ({createdAt}) => formatDateTime(createdAt),
    },
    {
      title: 'details',
      value: ({details}) => (details || {}).additionalInfo || 'N/A',
    },
  ],
  rowLink: v => urls.payment.link.detail(v.payment.id),
};
export default tableDefinition;
