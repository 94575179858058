// @flow
import {faCircleMinus, faCirclePlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Switch from 'components/Switch';
import {difference, flatten, isEmpty} from 'ramda';
import React from 'react';
import {Collapse} from 'react-collapse';
import {compose, withHandlers, withStateHandlers} from 'recompose';

import ProductVariantSelect from '../ProductVariantSelect';
import {ListText, RowWrap, StyledLi, StyledUl} from '../styled';

const ProductSelect = ({
  data,
  handleChange,
  handleVariantChange,
  productItemIds,
  toggleCollapse,
  isCollapse,
}) => {
  const itemIds = data.variants.map(variant => variant.items.map(item => item.id));
  const switchValue = isEmpty(difference(flatten(itemIds), productItemIds));
  const icon = isCollapse ? faCircleMinus : faCirclePlus;

  return (
    <StyledLi>
      <RowWrap>
        <FontAwesomeIcon icon={icon} size="lg" onClick={toggleCollapse} />
        <Switch value={switchValue} onChange={handleChange} />
        <ListText inline>{data.name}</ListText>
      </RowWrap>
      <Collapse isOpened={isCollapse}>
        <StyledUl>
          {data.variants.map((variant, index) => (
            <ProductVariantSelect
              key={index}
              data={variant}
              productItemIds={productItemIds}
              onChange={handleVariantChange(index)}
            />
          ))}
        </StyledUl>
      </Collapse>
    </StyledLi>
  );
};

export default compose(
  withStateHandlers(
    {isCollapse: false},
    {toggleCollapse: props => () => ({isCollapse: !props.isCollapse})}
  ),
  withHandlers({
    handleChange: props => (v: boolean) => props.onChange(v),
    handleVariantChange: props => (k: number) => (value: boolean) => {
      const objects = props.data.variants[k].items.map(item => ({
        itemId: item.id,
        value,
      }));
      props.onItemChange(objects);
    },
  })
)(ProductSelect);
