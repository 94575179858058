// @flow
import {compose, head, prop} from 'ramda';
import type {Selector} from 'types/Redux';

import type {Modal} from './reducer';

// selects the appropriate substore from root store
const base: Selector<*> = prop('modals');

// composes upon the base selector to select specific properties
export const selectModal: Selector<?Modal> = compose(head, base);
