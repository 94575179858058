// @flow
import type {TableDefinition} from 'components/Table/types';
import {Link} from 'componentsStyled/Typography';
import type {EmailIdentifier} from 'data/emailTemplate/types';
import urls from 'pages/urls';

const tableDefinition: TableDefinition<EmailIdentifier> = {
  columns: [
    {
      title: 'template',
      value: v => v.name,
      type: Link,
      props: v => ({
        to: urls.emailTemplates.link.detail(v.id),
        children: v.name,
      }),
    },
  ],
};

export default tableDefinition;
