// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import AffiliateSelect from 'containers/AffiliateSelect';
import withForm from 'hoc/withForm';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
  data: {affiliateId: number},
};

const CreateAffiliateUser = ({isSubmitting, data}: Props) => (
  <div>
    <InputText name="email" label="email" />
    <InputText name="password" label="password" type="password" />
    {!data ? <AffiliateSelect name="affiliateId" label="store" /> : null}
    <Button kind="primary" loading={isSubmitting}>
      Create store user
    </Button>
  </div>
);

const enhancer: HOC<*, Props> = compose(
  withProps(props => {
    const p = window.location.pathname;
    const affiliateId = parseInt(p.substring(p.lastIndexOf('/') + 1), 10);

    if (affiliateId) {
      return {data: {affiliateId}};
    }
  }),
  withForm({
    schema,
    onSubmit: props => values =>
      props.submit({
        input: {
          ...values,
          // Create users with `affiliate_employee` role instead of `affiliate` (owner).
          // The "owner" role will be decommissioned.
          // See: https://app.clubhouse.io/awayco/story/1543/remove-affiliate-owner-field-from-admin-app
          isAffiliateOwner: false,
        },
      }),
  })
);

export default enhancer(CreateAffiliateUser);
