// @flow
import {paginationFragment} from 'data/other/fragments';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {manufacturerFragment} from './fragments';
import type {Manufacturer} from './types';

export const manufacturerListQuery: Query<Manufacturer[]> = {
  gql: gql`
    query manufacturerListQuery($filter: ManufacturerFilter) {
      manufacturer {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...manufacturerFragment
          }
        }
      }
    }
    ${manufacturerFragment}
    ${paginationFragment}
  `,
  selector: ['manufacturer', 'list'],
  pagination: true,
  defaultFilter: {limit: 999},
};

export const manufacturerDetailQuery: Query<Manufacturer> = {
  gql: gql`
    query manufacturerDetailQuery($id: Int!) {
      manufacturer {
        detail(id: $id) {
          ...manufacturerFragment
        }
      }
    }
    ${manufacturerFragment}
  `,
  selector: ['manufacturer', 'detail'],
};
