// @flow
import TableStat from 'components/TableStat';
import VariantVariablesDisplay from 'components/VariantVariablesDisplay';
import {Label} from 'componentsStyled/Typography';
import type {ProductVariant} from 'data/productVariant/types';
import React from 'react';

import Section from '../../../components/Section';
import AddItem from './AddItem';
import productItemsTableDefinition from './productItemsTableDefinition';

type Props = {
  data: ProductVariant,
};

const VariantDetail = ({data}: Props) => (
  <Section>
    <Label>{data.product.variantVariables ? 'Variant Variables' : 'Variant Name'}</Label>
    <p>
      {data.product.variantVariables ? (
        <VariantVariablesDisplay
          variantVariableLabels={data.product.variantVariables}
          variantVariablesOptions={data.name}
        />
      ) : (
        data.name
      )}
    </p>
    {data.shelfLocation && (
      <div>
        <Label>Stock Location</Label>
        <p>{data.shelfLocation}</p>
      </div>
    )}
    <TableStat
      title="Stock items"
      value={data.items}
      tableDefinition={productItemsTableDefinition}
    />
    <AddItem data={data} />
  </Section>
);

export default VariantDetail;
