// @flow
import DayPicker from 'components/DayPicker';
import FieldWrap from 'components/Inputs/FieldWrap';
import {StyledInput} from 'componentsStyled/Forms';
import {Col, Row} from 'componentsStyled/Layout';
import {Label} from 'componentsStyled/Typography';
import withField, {type ReqFieldProps} from 'hoc/withField';
import moment from 'moment';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {HourWrap} from './styled';

const InputDayPicker = ({value, defaultValue, handleTimeChange, withHour, ...props}) => (
  <Row>
    <Col>
      <FieldWrap {...props}>
        <DayPicker value={!value && defaultValue ? defaultValue : value} {...props} />
      </FieldWrap>
    </Col>
    {withHour && (
      <Col>
        <HourWrap>
          <Label>Time</Label>
          <StyledInput
            name="time"
            label="time"
            maxWidth="15"
            placeholder="HH:MM"
            type="time"
            min="00:00"
            max="59:59"
            defaultValue="00:00"
            disabled={!value}
            onChange={handleTimeChange}
          />
        </HourWrap>
      </Col>
    )}
  </Row>
);

type Enhancer = {
  withHour?: boolean,
  defaultValue?: ?string,
};

const enhancer: HOC<*, {...$Exact<Enhancer>, ...$Exact<ReqFieldProps>}> = compose(
  withField,
  withHandlers({
    handleTimeChange:
      ({value, onChange}) =>
      e => {
        if (!value) return;

        const time = e.target.valueAsDate;
        const expirationDate = moment
          .utc(value)
          .set({
            hour: time.getUTCHours(),
            minute: time.getUTCMinutes(),
            second: 0,
          })
          .toISOString();
        return onChange(expirationDate);
      },
  })
);

export default enhancer(InputDayPicker);
