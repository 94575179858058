// @flow
// @author: t04435
// Date: 7/7/21

import theme from 'global/theme';
import styled, {css} from 'styled-components';

import {FromProp} from '../../helpers/FromProp';

export const StyledPill = styled(FromProp)`
  display: inline-block;
  margin: 0;
  padding: 0 0.8em;
  border-radius: 32px;
  background-color: ${props => props.theme.palette.greyLighter};
  color: ${props => props.theme.palette.greyDark};
  text-transform: capitalize;

  ${props =>
    props.isBig &&
    css`
      padding: 0.4rem 1.6rem;
    `}
  ${props =>
    props.status === 'success' &&
    !props.color &&
    css`
      background-color: ${props => props.theme.palette.successLight};
      color: ${props => props.theme.palette.success};
    `}
  ${props =>
    props.status === 'warning' &&
    !props.color &&
    css`
      background-color: ${props => props.theme.palette.warningLight};
      color: ${props => props.theme.palette.warning};
    `}
  ${props =>
    props.status === 'alert' &&
    !props.color &&
    css`
      background-color: ${props => props.theme.palette.errorLight};
      color: ${props => props.theme.palette.error};
    `}
  ${props =>
    props.color &&
    css`
      background: ${theme.lighten(0.9, props.color)};
      color: ${props.color};
    `}
`;
