// @flow
import withForm from 'hoc/withForm';
import {path} from 'ramda';
import React from 'react';
import {type HOC, compose, renameProp} from 'recompose';

import Button from '../../../../components/Button';
import {affiliatesListQuery} from '../../../../data/affiliate/queries';
import withQuery from '../../../../hoc/withQuery';
import AffiliateSelect from '../../../AffiliateSelect';
import ManufacturerSelect from '../../../ManufacturerSelect';
import schema from './schema';

const CreateTenantAffiliatesModal = ({affiliateList, values}) => {
  return (
    <div>
      <h2>Add Brand Affiliate </h2>
      <AffiliateSelect name="affiliateIds" label="Affiliate" isMulti />
      {values.affiliateIds &&
        values.affiliateIds.map((affiliateId, index) => {
          const affiliateName =
            affiliateList.filter(affiliate => affiliate.id === affiliateId)[0].name ||
            `Affiliate ${index + 1}`;
          return (
            <ManufacturerSelect
              name={`manufacturerIds[${index}]`}
              label={`Vendors for ${affiliateName}`}
              affiliateId={affiliateId ? [affiliateId] : []}
              isMulti
            />
          );
        })}
      <Button kind="primary">Add</Button>
    </div>
  );
};

type Enhancer = {
  close: Function,
};

const buildInput = (props, values) => {
  const affiliateIds = path(['affiliateIds'], values);
  const manufacturerIds = path(['manufacturerIds'], values);

  return {
    tenantId: path(['data', 'tenantId'], props),
    manufacturerByAffiliateId: affiliateIds.map((affiliateId, index) => {
      return {
        affiliateId,
        manufacturerIds: manufacturerIds[index],
      };
    }),
  };
};

const enhancer: HOC<*, Enhancer> = compose(
  withForm({
    schema,
    onSubmit: props => values => {
      return props.submit({input: buildInput(props, values)});
    },
    onSuccess: props => {
      /* FIXME(t04435): Hack to trigger mapProps */
      window.location.reload(false);
      return props.close;
    },
  }),
  withQuery(affiliatesListQuery),
  renameProp('data', 'affiliateList')
);

export default enhancer(CreateTenantAffiliatesModal);
