// @flow
// @author: T04435
// Date: 15/7/21

import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import type {TableDefinition} from 'components/Table/types';
import type {Tenant} from 'data/tenant/types';
import {path, prop} from 'ramda';

import {TABLE_COLUMN_ACTION_TITLE} from '../../../components/Table/Td';
import {tenantAffiliateRemoveMutation} from '../../../data/tenant-affiliate/mutations';
import apolloClient from '../../../global/apolloClient';
import theme from '../../../global/theme';
import withTooltip from '../../../hoc/withTooltip';
import EditItemLink from './TenantAffiliateEditLink';

const tableDefinition: TableDefinition<Tenant> = {
  columns: [
    {
      title: 'tenant name',
      value: prop('tenantName'),
    },
    {
      title: 'affiliate name',
      value: prop('affiliateName'),
    },
    {
      title: TABLE_COLUMN_ACTION_TITLE,
      value: prop('id'),
      type: EditItemLink,
      props: v => ({
        data: {
          id: path(['id'], v),
          tenantId: path(['tenantId'], v),
          affiliateId: path(['affiliateId'], v),
          manufacturerIds: path(['manufacturerIds'], v),
        },
      }),
    },
    /* TODO(T04435): merge this on a single action => action[] */
    {
      title: TABLE_COLUMN_ACTION_TITLE,
      value: prop('id'),
      type: withTooltip(FontAwesomeIcon),
      props: v => ({
        icon: faTrash,
        size: 'lg',
        color: theme.palette.error,
        style: {cursor: 'pointer'},
        'data-tip': 'Remove',
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          apolloClient
            .mutate({
              mutation: tenantAffiliateRemoveMutation.gql,
              variables: {
                input: {
                  id: v.id,
                },
              },
            })
            .then(() => {
              /* FIXME(t04435): Hack to trigger mapProps */
              window.location.reload(false);
            });
        },
      }),
    },
  ],
};
export default tableDefinition;
