// @flow
import React from 'react';
import {compose, withStateHandlers} from 'recompose';
import * as yup from 'yup';

import {email, optional} from '../../common/validators';
import AddressAutocomplete from '../../components/Inputs/AddressAutocomplete';
import InputColor from '../../components/Inputs/InputColor';
import InputControl from '../../components/Inputs/InputControl';
import InputSwitch from '../../components/Inputs/InputSwitch';
import InputText from '../../components/Inputs/InputText';
import Select from '../../components/Select';
import CategorySelect from '../../containers/CategorySelect';
import LocationsSelect from '../../containers/LocationsSelect';
import withForm from '../../hoc/withForm';
import {withStory} from './withStory';

const singleOptionOptions = [{label: 'Single option', value: 0}];

const InputsStory = ({singleOption, setSingleOption}) => (
  <React.Fragment>
    <InputText name="name" label="Label" placeholder="Placeholder" />
    <InputText name="nameFilled" label="Label" placeholder="Placeholder" />
    <InputText name="nameError" label="Label" placeholder="Placeholder" hint="Hint text" />
    <InputText name="nameError" label="Label" placeholder="Placeholder" hint="Hint text" disabled />
    <InputColor name="color1" label="Label" />
    <h2>Selects</h2>
    <LocationsSelect name="locationId" label="location" />
    <LocationsSelect name="locationId" label="location" isDisabled />
    <CategorySelect name="categoryIds" label="categories" isMulti canCreate />
    <CategorySelect name="categoryIds" label="categories" isMulti isDisabled />
    <h3>Single Item select</h3>
    <Select
      name="singleOption"
      label="singleOption"
      value={singleOption}
      options={singleOptionOptions}
      onChange={setSingleOption}
      map={false}
    />
    <br />
    <Select
      name="singleOption"
      label="singleOption"
      value={singleOption}
      options={singleOptionOptions}
      onChange={setSingleOption}
      map={false}
      isMulti
    />
    <h2>Address</h2>
    <AddressAutocomplete
      name="address"
      label="address"
      types={['address']}
      onChangeCoordinates={() => {}}
    />
    <h2>Toggle</h2>
    <InputControl name="toggle1" label="Label" on={<pre>ON</pre>} off={<pre>OFF</pre>} />
    <InputSwitch name="toggle2" label="Label" />
  </React.Fragment>
);

const enhancer = compose(
  withForm({
    schema: yup.object().shape({
      name: optional,
      nameFilled: optional,
      nameError: email,
      color: optional,
      locationId: optional,
      categoryIds: optional,
    }),
    mapProps: () => ({
      name: '',
      nameFilled: 'Value',
      color: '#289061',
      locationId: 32,
      categoryIds: [102, 103, 101],
    }),
  }),
  withStateHandlers(
    {
      singleOption: null,
    },
    {
      setSingleOption: () => value => ({singleOption: value}),
    }
  )
);

export default enhancer(withStory(InputsStory));
