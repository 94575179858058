import {ApolloLink} from 'apollo-link';
import {path} from 'ramda';

import crypto from './crypto';

// saves tokens to local storage if they come in response
const linkExtractTokens = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const ctx = operation.getContext();
    const headers = path(['response', 'headers'], ctx);

    if (headers) {
      const accessToken = headers.get('x-access-token');
      const refreshToken = headers.get('x-refresh-token');

      if (accessToken) crypto.setAccessToken(accessToken);
      if (refreshToken) crypto.setRefreshToken(refreshToken);
    }

    return response;
  })
);

export default linkExtractTokens;
