// @flow
import {addProductItemMutationByAffiliate} from 'data/productItem/mutations';
import {listProductItemInventoryQuery, productItemDetailQuery} from 'data/productItem/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import urls from 'pages/urls';

import InventoryDetail from './InventoryDetail';
import ProductItemCreate from './ProductItemCreate';
import tableDefinition from './tableDefinition';

export default createEntityCRUD({
  edit: ProductItemCreate,
  listQuery: listProductItemInventoryQuery,
  detail: InventoryDetail,
  detailQuery: productItemDetailQuery,
  createMutation: addProductItemMutationByAffiliate,
  tableDefinition,
  title: 'Inventory',
  entityName: 'Inventory',
  route: urls.inventory,
});
