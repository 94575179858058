// @flow
import type {ReduxAction} from 'types/Redux';

import type {User} from './graphql/types';

type AuthActionType = 'LOGIN' | 'LOGOUT_REDIRECT' | 'LOGOUT' | 'MASQUERADE' | 'UPDATE';

type LoginAction = ReduxAction<AuthActionType, 'LOGIN', User>;
type LogoutRedirectAction = ReduxAction<AuthActionType, 'LOGOUT_REDIRECT', *>;
type LogoutAction = ReduxAction<AuthActionType, 'LOGOUT', *>;
type MasqueradeAction = ReduxAction<AuthActionType, 'MASQUERADE', User>;
export type UpdateAction = ReduxAction<AuthActionType, 'UPDATE', User>;

export type AuthAction =
  | LoginAction
  | LogoutRedirectAction
  | LogoutAction
  | MasqueradeAction
  | UpdateAction;

export const login = (x: User): LoginAction => ({
  type: 'LOGIN',
  payload: x,
});

export const logoutRedirect = (): LogoutRedirectAction => ({
  type: 'LOGOUT_REDIRECT',
  payload: null,
});

export const logout = (): LogoutAction => ({
  type: 'LOGOUT',
  payload: null,
});

export const masquerade = (x: User): MasqueradeAction => ({
  type: 'MASQUERADE',
  payload: x,
});

export const update = (user: User): UpdateAction => {
  return {
    type: 'UPDATE',
    payload: user,
  };
};
