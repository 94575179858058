// @flow
import type {TableDefinition} from 'components/Table/types';
import {buildPriceFromObject} from 'data/units/money/formatters';
import {sortCurrency} from 'data/units/money/helpers';
import {prop} from 'ramda';

type TableRow = {
  day: number,
  price: number,
  currency: string,
};

const tableDefinition: TableDefinition<TableRow> = {
  columns: [
    {
      title: 'day',
      value: prop('day'),
    },
    {
      title: 'price',
      value: buildPriceFromObject,
      sortFunction: sortCurrency,
    },
    {
      title: 'currency',
      value: prop('currency'),
    },
  ],
};
export default tableDefinition;
