// @flow
import Button from 'components/Button';
import InventoryProductSelect from 'containers/InventoryProductSelect';
import withForm from 'hoc/withForm';
import {pick} from 'ramda';
import React from 'react';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
  create: boolean,
  data: any,
};

const PricingTemplateProductEdit = ({isSubmitting, create, data}: Props) => (
  <div>
    <InventoryProductSelect
      name="productItemIds"
      label="productItemId"
      filter={{affiliateId: data.affiliate.id}}
    />
    <Button kind="primary" loading={isSubmitting}>
      save
    </Button>
  </div>
);

export default withForm({
  schema,
  mapProps: props => ({
    ...props.data,
    productItemIds: props.data.productItems.map(item => item.id),
  }),
  onSubmit: props => values =>
    props.submit({
      input: pick(['id', 'productItemIds'], values),
    }),
})(PricingTemplateProductEdit);
