// @flow
// @author: t04435
// Date: 6/7/21

import {
  faAngleDown,
  faArrowRightFromBracket,
  faSlidersVSquare,
  faStore,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {logout} from 'data/auth/actions';
import type {User} from 'data/auth/graphql/types';
import withRouter from 'hoc/withRouter';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, renameProp} from 'recompose';

import {getThemeLogoQuery} from '../../../data/theme/queries';
import withOpen from '../../../hoc/withOpen';
import withQuery from '../../../hoc/withQuery';
import urls from '../../../pages/urls';
import LogoLink from './LogoLink';
import {
  Avatar,
  HeaderSection,
  Menu,
  MenuItem,
  MenuToggle,
  Overlay,
  StyledHeader,
  TenantLogo,
  TenantLogoDivider,
} from './styled';

const Header = ({user, logout, close, toggleOpen, isOpen, logo}) => {
  const getTenantLogo = () => {
    return (
      logo.logo.logo && (
        <Fragment>
          <TenantLogoDivider />
          <TenantLogo src={logo.logo.logo} alt="Tenant logo" loading="lazy" />
        </Fragment>
      )
    );
  };

  const getAvatarContent = () => {
    return user.email.charAt(0).toUpperCase();
  };

  /**
   *
   * @returns {*} Url to ShopApp from current domain
   * https://demo.admin.app.awayco.com/ => https://demo.shop.app.awayco.com/
   */
  const getShopAppUrl = (): string => {
    if (process.env.NODE_ENV === 'development') {
      return window.location.origin.replace('3002', '3001');
    }
    const hostname = window.location.origin;
    return hostname.replace('admin', 'shop');
  };

  return (
    <StyledHeader data-cy={'logged-in-header'}>
      <HeaderSection>
        <LogoLink />
        {getTenantLogo()}
      </HeaderSection>
      <HeaderSection>
        <Avatar>{getAvatarContent()}</Avatar>
        <MenuToggle isOpen={isOpen}>
          <FontAwesomeIcon icon={faAngleDown} size="lg" onClick={toggleOpen} />
        </MenuToggle>
        <Menu onClick={close} isOpen={isOpen}>
          <MenuItem component="a" redirect={false} to={urls.account}>
            Account
            <FontAwesomeIcon icon={faSlidersVSquare} size="lg" onClick={logout} />
          </MenuItem>
          <MenuItem component="a" redirect={true} to={getShopAppUrl()} hideExternalLinkIndicator>
            Point of Sale
            <FontAwesomeIcon icon={faStore} size="lg" onClick={logout} />
          </MenuItem>
          <MenuItem component="a" redirect={false} onClick={logout}>
            Logout
            <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
          </MenuItem>
        </Menu>
        {/* Use this to close the menu on click outside */}
        <Overlay onClick={close} isOpen={isOpen} />
      </HeaderSection>
    </StyledHeader>
  );
};

const mapDispatchToProps = {
  logout,
};

type Enhancer = {
  user: User,
};

const enhancer: HOC<*, Enhancer> = compose(
  withRouter,
  withOpen,
  withQuery(getThemeLogoQuery),
  renameProp('data', 'logo'),
  connect(null, mapDispatchToProps)
);

export default enhancer(Header);
