// @flow
import React from 'react';
import {compose} from 'recompose';
import * as yup from 'yup';

import {optional} from '../../common/validators';
import InputFileUpload, {
  IMAGE_MIME_TYPE,
  ZIP_MIME_TYPE,
} from '../../components/Inputs/InputFileUpload';
import withForm from '../../hoc/withForm';
import {withStory} from './withStory';

const FileUploadStory = () => (
  <React.Fragment>
    <InputFileUpload
      multiple={true}
      name="fileUploadImage"
      label="File upload"
      accept={IMAGE_MIME_TYPE}
      bucket={'productImages'}
    />
    <InputFileUpload
      multiple={true}
      name="fileUploadZip"
      label="File upload"
      accept={ZIP_MIME_TYPE}
      bucket={'productImages'}
    />
    <InputFileUpload
      multiple={false}
      name="fileUploadSingle"
      label="File upload - Single"
      bucket={'productImages'}
    />
  </React.Fragment>
);

const enhancer = compose(
  withForm({
    schema: yup.object().shape({
      fileUploadImage: optional,
      fileUploadZip: optional,
      fileUploadSingle: optional,
    }),
    mapProps: () => ({
      fileUpload: [],
    }),
  })
);

export default enhancer(withStory(FileUploadStory));
