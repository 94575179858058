import {StyledFieldWrap} from 'components/Inputs/FieldWrap/styled';
import {inputStyle} from 'componentsStyled/Forms';
import {Col} from 'componentsStyled/Layout';
import styled from 'styled-components';

export const StyledFilterGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;
`;

export const StyledFiltersSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-right: 2rem;
  }

  //Override InputDayPicker styles
  ${Col} {
    width: initial;
    margin: 0;
  }

  ${StyledFieldWrap} {
    width: auto;

    input {
      ${inputStyle};
      margin: 0;
      width: 20rem;
    }

    //Applies the height and width applied to other inputs
    //in selector above to the select components
    .select-component {
      &__control {
        width: 20rem;
        min-width: auto;
      }

      input {
        height: calc(4rem - 14px);
      }
    }
  }
`;
