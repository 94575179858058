// @flow
import withEmpty from 'hoc/withEmpty';
import React from 'react';

import {ImageWrap, StyledImage} from './styled';

type Props = {
  order?: number,
  url: string,
};

const ImageItem = ({order, url}: Props) => (
  <ImageWrap order={order}>
    <StyledImage src={url} />
  </ImageWrap>
);

export default withEmpty(props => !props.url, 'no image')(ImageItem);
