// @flow
import {faCircleMinus, faCirclePlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Switch from 'components/Switch';
import {difference, isEmpty} from 'ramda';
import React from 'react';
import {Collapse} from 'react-collapse';
import {compose, withHandlers, withStateHandlers} from 'recompose';

import {ListText, RowWrap, StyledLi} from '../styled';

const ProductVariantSelect = ({data, handleChange, productItemIds, toggleCollapse, isCollapse}) => {
  const itemIds = data.items.map(item => item.id);
  const switchValue = isEmpty(difference(itemIds, productItemIds));
  const icon = isCollapse ? faCircleMinus : faCirclePlus;

  return (
    <StyledLi>
      <RowWrap>
        <FontAwesomeIcon icon={icon} size="lg" onClick={toggleCollapse} />
        <Switch value={switchValue} onChange={handleChange} />
        <ListText inline>{data.name}</ListText>
      </RowWrap>
      <Collapse isOpened={isCollapse}>
        <RowWrap>
          <RowWrap>
            <p>Product Items to assign:</p>
          </RowWrap>
        </RowWrap>
        {data.items.map(item => (
          <RowWrap>
            <RowWrap>
              <p>
                {item.code} @ {item.affiliate.name}
              </p>
            </RowWrap>
          </RowWrap>
        ))}
      </Collapse>
    </StyledLi>
  );
};

export default compose(
  withStateHandlers(
    {isCollapse: false},
    {toggleCollapse: props => () => ({isCollapse: !props.isCollapse})}
  ),
  withHandlers({
    handleChange: props => (v: boolean) => props.onChange(v),
  })
)(ProductVariantSelect);
