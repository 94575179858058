// @flow
import Button from 'components/Button';
import {StyledInput} from 'componentsStyled/Forms';
import {Label} from 'componentsStyled/Typography';
import withField, {type ReqFieldProps} from 'hoc/withField';
import {clone, times} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {InputPriceWrap, StyledButtonGroup as ButtonGroup, StyledInputWrap} from './styled';

const InputPrices = props => (
  <div>
    <Label>{props.label}</Label>
    {times(
      index => (
        <InputPriceWrap key={index}>
          <Label>{`Day ${index + 1})`}</Label>
          <StyledInputWrap>
            <StyledInput
              value={props.value[index]}
              onChange={props.handleChange(index)}
              type="number"
              inputmode="numeric"
              min="0.01"
              step="0.01"
            />
          </StyledInputWrap>
          &nbsp;
          <Label>{props.currency}</Label>
        </InputPriceWrap>
      ),
      props.value.length
    )}
    <ButtonGroup>
      <Button kind="primary" onClick={props.add} disabled={props.value.length > 13}>
        Add Day
      </Button>
      <Button kind="primary" onClick={props.remove} disabled={props.value.length < 2}>
        Remove Day
      </Button>
    </ButtonGroup>
  </div>
);

type Enhancer = {
  currency: string,
};

const enhancer: HOC<*, {...$Exact<ReqFieldProps>, ...$Exact<Enhancer>}> = compose(
  withField,
  withHandlers({
    handleChange: props => k => e => {
      const value = clone(props.value);
      value[k] = e.target.value ? Number(e.target.value) : null;
      props.onChange(value);
    },
    remove: props => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      const value = clone(props.value);
      value.pop();
      props.onChange(value);
    },
    add: props => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      const value = clone(props.value);
      value.push(props.value[props.value.length - 1]);
      props.onChange(value);
    },
  })
);

export default enhancer(InputPrices);
