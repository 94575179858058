// @flow
import {manufacturerFragment} from 'data/manufacturer/fragments';
import gql from 'graphql-tag';

export const productFragment = gql`
  fragment productFragment on Product {
    id
    name
    order
    description
    variantVariables
    images {
      id
      alt
      url
      order
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    isVisible
  }
  ${manufacturerFragment}
`;

export const productFragmentWithVariants = gql`
  fragment productFragment on Product {
    id
    name
    description
    images {
      id
      alt
      url
      order
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    variants {
      id
      name
    }
  }
  ${manufacturerFragment}
`;

export const productMinimalFragment = gql`
  fragment productMinimalFragment on Product {
    id
    name
  }
`;

export const categoryFragment = gql`
  fragment categoryFragment on Category {
    categoryId
    name
    relatedCategories {
      categoryId
      name
    }
    discountOptOuts {
      id
      discountId
    }
  }
`;
