import {FromProp} from 'helpers/FromProp';
import {linker} from 'hoc/linker';
import styled, {css} from 'styled-components';

import {hoverStyle} from '../../../componentsStyled/Shared';

export const SidebarWrap = styled.div`
  padding: 40px 16px 40px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 256px;
`;

const menuItemBaseStyle = css`
  position: relative;
  margin-bottom: 16px;

  min-height: 48px;

  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding-left: 32px;

  cursor: pointer;
  color: ${props => props.theme.palette.dark};
  text-decoration: none;
  transition: color 0.25s ease, background 0.25s ease;

  &:hover {
    ${hoverStyle}
  }

  ${props =>
    props.active &&
    css`
      ${hoverStyle}
    `}
  svg:first-of-type {
    margin-right: 8px;
    font-size: 24px;
  }
`;

export const StyledTopLevelItem = linker(
  styled(FromProp)`
    ${menuItemBaseStyle}
    ${props =>
      props.active &&
      css`
        font-weight: 900;
        &:before {
          content: '';
          height: 24px;
          width: 3px;
          background-color: ${props => props.theme.palette.primary};
          position: absolute;
          left: 0;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      `}
  `
);

export const StyledSecondLevelItem = linker(
  styled(FromProp)`
    ${menuItemBaseStyle};
    margin-left: 32px;
  `
);

export const StyledTopLevelMenu = styled.div``;
