import {
  faBookOpen,
  faCompress,
  faExpand,
  faEye,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {Col, Row, RowStart} from '../../componentsStyled/Layout';
import Button from '../Button';
import HtmlViewer from '../HtmlViewer';
import TextEditor from '../TextEditor';
import {WindowControlViewsSelectorWrapper, WindowControlViewsWrapper} from './styled';

const WindowControlViews = {
  both: 'both',
  editor: 'editor',
  preview: 'preview',
};

type Props = {
  // name passed to TextEditor to be used by withField
  name: string,
  // label passed to TextEditor to be used by FieldWrap
  label: string,
  // The content of the editor
  previewValue: string,
};

/**
 * This component is a wrapper on {@link TextEditor} to add different editing views
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const TextEditorHtmlViewerWindowControls = (props: Props) => {
  const {name, label, previewValue, view, setView, fullscreen, setFullscreen, className} = props;
  return (
    <WindowControlViewsWrapper isFullscreen={fullscreen} className={className}>
      <Row>
        <WindowControlViewsSelectorWrapper className={className}>
          <Button
            iconStart={faPenToSquare}
            onClick={() => setView(WindowControlViews.editor)}
            kind={'tertiary'}
            isActive={view === WindowControlViews.editor}
          >
            Editor
          </Button>
          <Button
            iconStart={faBookOpen}
            onClick={() => setView(WindowControlViews.both)}
            kind={'tertiary'}
            isActive={view === WindowControlViews.both}
          >
            Both
          </Button>
          <Button
            iconStart={faEye}
            onClick={() => setView(WindowControlViews.preview)}
            kind={'tertiary'}
            isActive={view === WindowControlViews.preview}
          >
            Preview
          </Button>
        </WindowControlViewsSelectorWrapper>
        <WindowControlViewsSelectorWrapper isFullscreen={fullscreen} className={className}>
          <Button
            iconStart={fullscreen ? faCompress : faExpand}
            onClick={() => setFullscreen(fullscreen)}
            kind={'tertiary'}
          >
            {fullscreen ? 'Exit fullscreen' : 'Fullscreen'}
          </Button>
        </WindowControlViewsSelectorWrapper>
      </Row>
      <RowStart>
        {view === WindowControlViews.editor || view === WindowControlViews.both ? (
          <Col style={{width: '100%', margin: 0}}>
            <TextEditor name={name} label={label} useAdvancedToolbar />
          </Col>
        ) : null}
        {view === WindowControlViews.preview || view === WindowControlViews.both ? (
          <Col style={{width: '100%'}}>
            <HtmlViewer html={previewValue} />
          </Col>
        ) : null}
      </RowStart>
    </WindowControlViewsWrapper>
  );
};

const enhancer: HOC<*, Props> = compose(
  withStateHandlers(
    {
      view: WindowControlViews.both,
      fullscreen: false,
    },
    {
      setView: () => view => ({view}),
      setFullscreen: () => fullscreen => ({fullscreen: !fullscreen}),
    }
  )
);
export default enhancer(TextEditorHtmlViewerWindowControls);
