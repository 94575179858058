// @flow
import InputSelect from 'components/Inputs/InputSelect';
import {listProductVariantsInventoryQuery} from 'data/productVariant/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';

const VariantSelect = ({data, ...rest}) => <InputSelect options={data} {...rest} />;

export default withQuery(listProductVariantsInventoryQuery, undefined, undefined, props => ({
  productId: props.productId,
}))(VariantSelect);
