// @flow
import type {ColumnDef} from 'components/Table/types';

import {currencySymbols} from './formatters';

// $Ramda
export const sortCurrency = (column: ColumnDef<*>) => (x: number | string) => {
  if (!column.value(x)) return '';

  let value = column.value(x);
  const currencies = Object.values(currencySymbols);

  if (currencies.includes(value.charAt(0))) value = value.slice(1);
  return +value;
};
