// @flow
import {StyledNumberInput} from 'componentsStyled/Forms';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

const NumberInput = ({
  value = '',
  handleChange,
  maxWidth,
  min,
  max,
  interval,
  disabled,
  ...rest
}) => (
  <StyledNumberInput
    {...rest}
    disabled={disabled}
    inputmode="numeric"
    type="number"
    min={min}
    max={max}
    value={value}
    maxWidth={maxWidth}
    onChange={handleChange}
  />
);

type Outter = {
  value: boolean,
  onChange: Function,
  min?: number,
  max?: number,
  maxWidth?: number,
  interval?: number,
  disabled?: boolean,
};

const enhancer: HOC<*, Outter> = withHandlers({
  handleChange: props => e => {
    const value = e.target.value;
    const valueAsNumber = Number(value);
    //value will be a string so as long as it's non-empty and converts to a number we will set
    //it to the number, else set to null.
    if (value != null && value !== '' && !Number.isNaN(valueAsNumber)) {
      props.onChange(valueAsNumber);
    } else {
      props.onChange(null);
    }
  },
});

export default enhancer(NumberInput);
