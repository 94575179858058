// @flow
import {Spread} from 'componentsStyled/Flex';
import styled from 'styled-components';

export const StyledSpread = styled(Spread)`
  display: flex;
  justify-content: left;

  > * {
    margin-right: 4rem;
  }

  margin-bottom: 2rem;
`;
