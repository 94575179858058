// @flow
import type {CRUDRoute} from 'pages/urls';
import React from 'react';
import {Route, Switch} from 'react-router-dom';

type Props = {
  route: CRUDRoute,
  nameSingular: string,
  name: string,
};

const Title = ({nameSingular, name, route}: Props) => (
  <Switch>
    <Route exact path={route.path.list} render={() => <h1 className={'capitalized'}>{name}</h1>} />
    <Route
      exact
      path={route.path.detail}
      render={() => <h1 className={'capitalized'}>{nameSingular} detail</h1>}
    />
    <Route
      exact
      path={route.path.create}
      render={() => <h1 className={'capitalized'}>add {nameSingular}</h1>}
    />
    <Route
      exact
      path={route.path.update}
      render={() => <h1 className={'capitalized'}>edit {nameSingular}</h1>}
    />
    <Route
      exact
      path={route.path.editAll}
      render={() => <h1 className={'capitalized'}>edit all {name}</h1>}
    />
  </Switch>
);

export default Title;
