// @flow
import Button from 'components/Button';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import {type Tenant} from 'data/tenant/types';
import withForm from 'hoc/withForm';
import React from 'react';
import {compose, renameProp} from 'recompose';

import Section from '../../../components/Section';
import Table from '../../../components/Table';
import {tenantAffiliatesListQuery} from '../../../data/tenant-affiliate/queries';
import type {TenantAffiliate} from '../../../data/tenant-affiliate/types';
import withFilter from '../../../hoc/withFilter';
import withQuery from '../../../hoc/withQuery';
import filterConfig from '../TenantAffiliate/filterConfig';
import tableDefinition from '../TenantAffiliate/tableDefinition';
import TenantAffiliateCreateButton from '../TenantAffiliate/TenantAffiliateCreateLink';
import schema from './schema';

type Props = {
  tenantData?: Tenant,
  tenantAffiliatesData?: TenantAffiliate[],
  values: Tenant,
  isSubmitting: boolean,
  filter: {},
  updateFilter: (k: string, v: any) => void,
};

function TenantEdit(props: Props) {
  const {tenantData, tenantAffiliatesData, filter, updateFilter} = props;
  const isCreating = !tenantData;
  return (
    <div>
      <Section title="tenant contact details">
        <InputText name="name" label="Name" />
        <InputText name="title" label="Title" />
        <InputText name="website" label="Website" />
        <InputText name="helloEmailAddress" label="Hello Email Address" />
        <InputText name="bccEmailAddress" label="BCC Email Address" />
        {isCreating && (
          <React.Fragment>
            <h2>Admin User Details</h2>
            <p>
              Please specify the details of the admin user that will be automatically created and
              linked to this tenant. The email address can be the same or different from the "Hello
              Email Address" field above.
            </p>
            <InputText name="adminUserEmail" label="Admin User Email" />
            <InputText name="adminUserPassword" label="Admin User Password" />
          </React.Fragment>
        )}
      </Section>
      <Section title="tenant settings">
        {!isCreating && (
          <React.Fragment>
            <InputSwitch name="isEmailVerified" label="Is Email Verified" />
            <InputSwitch name="allowed" label="Allowed" />
            <InputSwitch name="groupReservations" label="Group Reservations" />
            <InputSwitch name="showMinimalProductTileInfo" label="Show Minimal Product Tile Info" />
            <InputSwitch name="areBundlesEnabled" label="Are Bundles Enabled" />
          </React.Fragment>
        )}
      </Section>
      {!isCreating && (
        <Section title="brand affiliates">
          <Table
            data={tenantAffiliatesData || []}
            tableDefinition={tableDefinition}
            filterConfig={filterConfig}
            filter={filter}
            updateFilter={updateFilter}
          />
          <TenantAffiliateCreateButton data={{tenantId: tenantData ? tenantData.id : undefined}} />
        </Section>
      )}
      <Button kind="primary" loading={props.isSubmitting} type={'submit'}>
        save
      </Button>
    </div>
  );
}

const enhancer = compose(
  withForm({
    schema,
    onSubmit: props => values =>
      props.submit({
        input: {
          ...values,
          // Clear the website if it's an empty field
          website: values.website !== '' ? values.website : null,
        },
      }),
  }),
  renameProp('data', 'tenantData'),
  withFilter,
  withQuery(tenantAffiliatesListQuery, {
    noLoader: true,
    noEmpty: true,
    config: {
      skip: props => !Boolean(props.tenantData),
    },
  }),
  renameProp('data', 'tenantAffiliatesData')
);

export default enhancer(TenantEdit);
