import styled from 'styled-components';

export const AppWrap = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.greyLighter};
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

export const SidebarWrap = styled.div`
  overflow: auto;
`;
