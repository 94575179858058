// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import type {SignedUrl} from './types';

export const getSignedUrlQuery: Query<SignedUrl> = {
  gql: gql`
    query getSignedUrlQuery($bucket: StorageBucket!, $mimeType: MimeType!) {
      storage {
        getSignedUrl(bucket: $bucket, mimeType: $mimeType) {
          signedUrl
          futureUrl
        }
      }
    }
  `,
  selector: ['storage', 'getSignedUrl'],
};
