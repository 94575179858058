// @flow
import Button from 'components/Button';
import React from 'react';

type Props = {
  error: {} | string,
};

const ErrorPage = ({error}: Props) => (
  <React.Fragment>
    <h1>Error</h1>
    <p>Something broke, we logged you out.</p>
    <p>
      <Button kind="primary" onClick={() => window.location.replace('/')}>
        back to home page
      </Button>
    </p>
    <p>Information about the error: </p>
    <code>{JSON.stringify(error)}</code>
  </React.Fragment>
);

export default ErrorPage;
