// @flow
// @author: t04435
// Date: 7/7/21

import type {Props as PillProps} from '../Pills';

/**
 * Gets the {@link Pill} props from value
 * @param value: a value on a td
 * @param success boolean for color true:green false: red
 * @returns {{children: string, value: string}|{children: string}}
 */
export const getPillPropsFromTableValue = (value: string, success?: boolean): PillProps => {
  switch (value) {
    case 'pre_processing':
    case 'confirmed':
      return {
        children: 'confirmed',
        status: 'success',
      };
    case 'post_processing':
    case 'finished':
      return {
        children: 'finished',
        status: 'success',
      };
    case 'passed_to_finalization_worker':
      return {
        children: 'returned',
        status: 'success',
      };
    case 'outbound':
    case 'checked_out':
    case 'inbound':
    case 'delayed':
    case 'overdue':
      return {
        children: 'fulfilled',
        status: 'success',
      };
    case 'cancelled':
      return {
        children: 'cancelled',
        status: 'alert',
      };
    case 'OnlineMeta':
      return {
        children: success ? 'Online' : 'Offline',
        status: success ? 'success' : 'alert',
      };
    case 'ConsistentMeta':
      return {
        children: success ? 'Online' : 'Offline - Requires editing',
        status: success ? 'success' : 'alert',
      };
    case 'success':
      return {
        children: 'success',
        status: 'success',
      };
    case 'pending':
      return {
        children: 'pending',
        status: 'warning',
      };
    case 'fail':
      return {
        children: 'failed',
        status: 'alert',
      };
    default:
      return {
        children: value,
      };
  }
};

/**
 * Gets the {@link Pill} props from value
 * @param value: determines if visible or hidden
 * @returns {{children: string, value: string}|{children: string}}
 */
export const getPillPropsForVisibility = (value: boolean): PillProps => {
  return value
    ? {
        children: 'Visible',
        status: 'success',
      }
    : {
        children: 'Hidden',
        status: 'warning',
      };
};
