// @flow
import {formatBool} from 'common/formatters';
import Button from 'components/Button';
import {Label} from 'componentsStyled/Typography';
import {approveSignupMutation} from 'data/tenant/mutations';
import {type Tenant} from 'data/tenant/types';
import withForm from 'hoc/withForm';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {type HOC, compose} from 'recompose';

import schema from './schema';

const TenantDetail = ({data, isSubmitting}) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <Label>Title</Label>
    <p>{data.title}</p>
    <Label>Website</Label>
    <p>{data.website}</p>
    <Label>Hello Email Address</Label>
    <p>{data.helloEmailAddress}</p>
    <Label>BCC Email Address</Label>
    <p>{data.bccEmailAddress}</p>
    <Label>Is Email Verified</Label>
    <p>{formatBool(data.isEmailVerified)}</p>
    <Label>Allowed</Label>
    <p>{formatBool(data.allowed)}</p>
    <Label>Group Reservations</Label>
    <p>{formatBool(data.groupReservations)}</p>
    <Label>Show Minimal Product Tile Information</Label>
    <p>{formatBool(data.showMinimalProductTileInfo)}</p>
    <Label>Are Bundles Enabled </Label>
    <p>{formatBool(data.areBundlesEnabled)}</p>
    {!data.allowed && (
      <React.Fragment>
        <h2>Approve Signup</h2>
        <p>
          The tenant has not yet been approved. Approving the tenant signup will create an admin
          user to allow them to log in. They will be notified via email.
        </p>
        <Button kind="primary" loading={isSubmitting} big success type="submit">
          Approve Signup
        </Button>
      </React.Fragment>
    )}
  </div>
);

type Outer = {
  data: Tenant,
};

const enhancer: HOC<*, Outer> = compose(
  withMutation(approveSignupMutation),
  withForm({
    schema,
    onSubmit: props => values => {
      return props.submitMutation({
        input: {
          id: props.data.id,
        },
      });
    },
  })
);

export default enhancer(TenantDetail);
