import decode from 'jwt-decode';
import {path} from 'ramda';
import store from 'store/dist/store.modern';

export const ACCESS_TOKEN_KEY = 'CRYPTO/ACCESS_TOKEN';
export const REFRESH_TOKEN_KEY = 'CRYPTO/REFRESH_TOKEN';

const getEmailFromAccessToken = () => {
  try {
    const result = decode(store.get(ACCESS_TOKEN_KEY));
    return path(['email'], result);
  } catch (e) {
    return null;
  }
};

const getRoleFromAccessToken = () => {
  try {
    const result = decode(store.get(ACCESS_TOKEN_KEY));
    return path(['role'], result);
  } catch (e) {
    return null;
  }
};

const getParameterFromAccessToken = (parameter: string) => {
  try {
    const result = decode(store.get(ACCESS_TOKEN_KEY));
    return path([parameter], result);
  } catch (e) {
    return null;
  }
};

const purge = () => {
  store.remove(ACCESS_TOKEN_KEY);
  store.remove(REFRESH_TOKEN_KEY);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setAccessToken: token => store.set(ACCESS_TOKEN_KEY, token),
  setRefreshToken: token => store.set(REFRESH_TOKEN_KEY, token),
  getAccessToken: () => store.get(ACCESS_TOKEN_KEY),
  getRefreshToken: () => store.get(REFRESH_TOKEN_KEY),
  getEmailFromAccessToken,
  getRoleFromAccessToken,
  getParameterFromAccessToken,
  purge,
};
