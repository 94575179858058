// @flow
import React from 'react';

type Props = {
  data: any[],
};

const NameList = ({data}: Props) => (
  <div>
    {data.map((v, i) => (
      <p key={i}>{v.name}</p>
    ))}
  </div>
);

export default NameList;
