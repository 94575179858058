// @flow
import DetailStat from 'components/DetailStat';
import {LayoutFlex} from 'componentsStyled/Layout';
import type {UserFull} from 'data/user/types';
import paymentsTableDefinition from 'pages/Payment/tableDefinition';
import React from 'react';

import Section from '../../../components/Section';
import Table from '../../../components/Table';
import reservationsTableDefinition from './reservationsTableDefinition';

type Outter = {
  data: UserFull,
};

const showCustomerTableStats = customer => {
  if (!customer) return null;

  const reservations = customer.orders.reduce((acc, o) => {
    return o.bookings.reduce((acc, b) => {
      return b.reservations.reduce((acc, r) => {
        acc.push({
          id: r.id,
          code: r.externalId,
          start: b.start,
          end: b.end,
          status: r.status,
        });
        return acc;
      }, acc);
    }, acc);
  }, []);

  return (
    <React.Fragment>
      <Section
        title={`Reservations ${
          reservations && reservations.length > 0 ? `(${reservations.length})` : ''
        }`}
      >
        <Table data={reservations} tableDefinition={reservationsTableDefinition} />
      </Section>
      <Section
        title={`Payments ${
          customer.payments && customer.payments.length > 0 ? `(${customer.payments.length})` : ''
        }`}
      >
        <Table data={customer.payments} tableDefinition={paymentsTableDefinition} />
      </Section>
    </React.Fragment>
  );
};

const CustomerDetail = ({data}: Outter) => {
  const {email, role, customer, profiles} = data;
  return (
    <React.Fragment>
      <Section title="contact details">
        <LayoutFlex>
          <LayoutFlex col oneQuater>
            <DetailStat title="Email" value={email} />
          </LayoutFlex>
          {!!profiles[0].phoneNumber && (
            <LayoutFlex col oneQuater>
              <DetailStat title="Phone Number" value={profiles[0].phoneNumber} />
            </LayoutFlex>
          )}
        </LayoutFlex>
      </Section>
      {role === 'user' && showCustomerTableStats(customer)}
    </React.Fragment>
  );
};

export default CustomerDetail;
