import {
  boolean,
  bufferDays,
  coordinates,
  deliveryCharge,
  deliveryChargeThreshold,
  images,
  nonNegativeInteger,
  openingHours,
  openingHoursExceptions,
  required,
  stringOptional,
} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  name: required,
  description: required,
  email: required,
  phone: required,
  website: required,
  address: required,
  logo: required,
  coordinates: coordinates,
  locationId: required,
  images: images,
  openingHours,
  specialOpeningDates: openingHoursExceptions,
  categoryIds: required,
  //In-store Fulfilment
  allowInStoreFulfilment: boolean,
  allowInStoreBookingExtension: boolean,
  inStoreExtensionCutoffDays: nonNegativeInteger,
  inStoreFulfilmentInstructions: stringOptional,
  //Delivery Fulfilment
  allowDeliveryFulfilment: boolean,
  allowDeliveryBookingExtension: boolean,
  deliveryExtensionCutoffDays: nonNegativeInteger,
  deliveryFulfilmentInstructions: stringOptional,
  deliveryCharge: deliveryCharge,
  enableDeliveryChargeThreshold: boolean,
  deliveryChargeThreshold: deliveryChargeThreshold,
  deliverySummary: stringOptional,
  logisticsSummary: stringOptional,
  deliveryExplanation: stringOptional,
  returnInstructions: stringOptional,
  //Rental Buffers
  preBookingBufferDays: bufferDays(0, 7),
  postBookingBufferDays: bufferDays(0, 7),
  automaticallyEndPostProcessing: boolean,
  enableDeliveryBuffers: boolean,
  outboundBufferDays: nonNegativeInteger,
  inboundBufferDays: nonNegativeInteger,
});
