// @flow
import type {RouterLocation} from 'hoc/withRouter';
import React from 'react';

import withRouter from '../../hoc/withRouter';
import FileUploadStory from './FileUploadStory';
import FormatsStory from './FormatsStory';
import InputsStory from './InputsStory';
import PaletteStory from './PaletteStory';
import PillsStory from './PillsStory';
import TableStory from './TableStory';
import TooltipStory from './TooltipStory';
import TypographyStory from './TypographyStory';

const renderStory = (location: RouterLocation) => {
  const {pathname} = location;
  const paths = pathname.split('/');
  // get the last /path
  let path = paths[paths.length - 1];
  switch (path) {
    case 'palette':
      return <PaletteStory title={path} />;
    case 'typography':
      return <TypographyStory title={path} />;
    case 'inputs':
      return <InputsStory title={path} />;
    case 'fileUpload':
      return <FileUploadStory title={path} />;
    case 'pills':
      return <PillsStory title={path} />;
    case 'table':
      return <TableStory title={path} />;
    case 'tooltip':
      return <TooltipStory title={path} />;
    case 'formatters':
      return <FormatsStory title={path} />;
    default:
      return (
        <React.Fragment>
          <pre>TODO(t04435): add guide here</pre>
        </React.Fragment>
      );
  }
};

const StorybookPage = ({location}) => <React.Fragment>{renderStory(location)}</React.Fragment>;

export default withRouter(StorybookPage);
