// @flow
// @author: t04435
// Date: 8/7/21

import {formatBool} from 'common/formatters';
import type {TableDefinition} from 'components/Table/types';
import type {Tenant} from 'data/tenant/types';
import {formatDateTime} from 'data/units/date/formatters';
import urls from 'pages/urls';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<Tenant> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'allowed',
      value: v => formatBool(v.allowed),
    },
    {
      title: 'email verified',
      value: v => formatBool(v.isEmailVerified),
    },
    {
      title: 'minimal product tile informations',
      value: v => formatBool(v.showMinimalProductTileInfo),
    },
    {
      title: 'bundles enabled',
      value: v => formatBool(v.areBundlesEnabled),
    },
    {
      title: 'created at',
      value: v => formatDateTime(v.createdAt),
    },
  ],
  rowLink: v => urls.tenant.link.update(v.id),
};
export default tableDefinition;
