//@flow

/**
 * Category numbers for content categories on awayco.helpcenter.io.
 * Used to link to the appropriate category depending on the page shown.
 * This can be found by navigating to the category contents page and looking at
 * the URL.
 */
export const HelpCenterCategories = {
  startHere: 797,
  stores: 800,
  products: 801,
  accessories: 802,
  pricing: 803,
  marketing: 804,
  renting: 806,
  users: 866,
};
