// @flow
import {faClose} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Label} from 'componentsStyled/Typography';
import {accessoriesListQuery} from 'data/accessories/queries';
import withField, {type ReqFieldProps} from 'hoc/withField';
import withQuery from 'hoc/withQuery';
import {prop, remove} from 'ramda';
import React from 'react';
import Select from 'react-select';
import {type HOC, compose, withHandlers} from 'recompose';

import InputPrices from './InputPrices';
import {AccessoriesWrap, Error, LabelWrap} from './styled';

const mapOptions = options => options.map(o => ({value: o.name, label: o.name}));

const AccessoriesEditForm = ({handleSelect, value, removeAccessory, onChange, data, ...rest}) => (
  <AccessoriesWrap>
    <Label>Add Accessories</Label>
    <Select
      onChange={handleSelect}
      options={mapOptions(data)}
      name="accessories"
      label="accessories"
    />
    <br />
    {value.map((val, i) => {
      const accessoryName = prop('name')(val);
      return (
        <div key={i}>
          <LabelWrap>
            <Label>{accessoryName} Price</Label>
            <FontAwesomeIcon icon={faClose} size="lg" onClick={() => removeAccessory(i)} />
          </LabelWrap>
          <InputPrices
            prices={val.prices}
            accessoryName={accessoryName}
            onChange={onChange}
            value={value}
          />
          <Error>{rest.error}</Error>
        </div>
      );
    })}
  </AccessoriesWrap>
);

const enhancer: HOC<*, ReqFieldProps> = compose(
  withQuery(accessoriesListQuery),
  withField,
  withHandlers({
    handleSelect: props => v => {
      const accessory = v.value;
      const isDuplicate = props.value.some(x => x.name === accessory);
      if (!isDuplicate) {
        const newValue = [...props.value, {name: accessory, prices: [1]}];
        props.onChange(newValue);
      }
    },
    removeAccessory: props => idx => {
      const shortenedList = remove(idx, 1, props.value);
      props.onChange(shortenedList);
    },
  })
);

export default enhancer(AccessoriesEditForm);
