import {StyledInput} from 'componentsStyled/Forms';
import {Hint} from 'componentsStyled/Typography';
import styled, {css} from 'styled-components';

export const DayInputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  height: 7rem;
`;

export const DayName = styled.p`
  width: 10rem;
  flex-shrink: 0;
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

export const InputLabel = styled(Hint)`
  margin-right: 0.5rem;
`;

export const InputStyled = styled(StyledInput)`
  width: 7rem;
  padding: 1rem;
  height: 4rem;
  border: 0.1rem solid ${props => props.theme.palette.greyLight};

  ${props =>
    props.big &&
    css`
      width: 22rem;
      margin-right: 1rem;
    `};
`;

export const SeparatorStyled = styled.span`
  padding: 1rem;
`;
