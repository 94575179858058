// @flow
import displayName from 'react-display-name';

import type {ModalAction, ModalType} from './actions';

export type Modal = {
  type: ModalType,
  props: {},
  displayName: string,
};

export type ModalReducerState = Modal[];

const initialState: ModalReducerState = [];

type Reducer = (ModalReducerState, ModalAction) => ModalReducerState;
const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return state.concat({
        displayName: displayName(action.payload.type),
        ...action.payload,
      });
    case 'CLOSE_MODAL':
      return state.slice(1);
    default:
      return state;
  }
};

export default reducer;
