// @flow
import {selectUser} from 'data/auth/selectors';
import crypto from 'global/apolloClient/crypto';
import urls from 'pages/urls';
import * as React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import type {HOC} from 'recompose';

const LoggedOutRoute = ({component, user, ...rest}) => {
  const role = crypto.getRoleFromAccessToken();
  if (user && role) {
    return <Redirect to={urls.root} />;
  }

  return <Route component={component} {...rest} />;
};

const mapStateToProps = state => ({
  user: selectUser(state),
});

type Enhancer = {
  component: React.ComponentType<{}>,
  path?: string,
};

const enhancer: HOC<*, Enhancer> = connect(mapStateToProps);

export default enhancer(LoggedOutRoute);
