// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import {StyledInput} from 'componentsStyled/Forms';
import withField, {type ReqFieldProps} from 'hoc/withField';
import React from 'react';
import type {HOC} from 'recompose';

// TODO(Anyone): discard helper text in this component and replace its uses with `hint`, which is
// defined in the `FieldWrap`
const InputText = props => (
  <FieldWrap {...props}>
    <StyledInput {...props} />
  </FieldWrap>
);
const enhancer: HOC<*, ReqFieldProps> = withField;

export default enhancer(InputText);
