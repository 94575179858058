// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import Select from 'components/Select';
import withField from 'hoc/withField';
import React from 'react';

const InputSelect = ({options, defaultValue, ...rest}) => (
  <FieldWrap {...rest}>
    <Select options={options} defaultValue={defaultValue} {...rest} />
  </FieldWrap>
);

export default withField(InputSelect);
