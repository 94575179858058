// @flow
import type {TableDefinition} from 'components/Table/types';
import type {ProductItem} from 'data/productItem/types';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<ProductItem> = {
  columns: [
    {
      title: 'Asset ID',
      value: prop('code'),
    },
  ],
};
export default tableDefinition;
