// @flow
import type {ProductFloatParameter} from 'data/productVariant/types';
import {identity, isEmpty, isNil, prop} from 'ramda';

import {mmToFeetInch} from './converters';

const ZERO_PLACEHOLDER = 'N/A';

export const safelyGetRenderableText = (text: string = '') => {
  if (typeof text === 'string') {
    return text;
  }

  // in case we return object accidentaly
  return JSON.stringify(text);
};

// $Fixme - CRA migration
export const formatNullableValue = (v: number | string, formatter = identity) =>
  isNil(v) || isEmpty(v) ? ZERO_PLACEHOLDER : formatter(v);

export const formatBool = (x: boolean): string => (x ? 'Yes' : 'No');

export const formatEnabledDisabled = (x: boolean): string => (x ? 'Enabled' : 'Disabled');

export const formatBSL = (bsl: number) => formatNullableValue(bsl, x => `${x} mm`);

// $Fixme - CRA migration
export const formatMmToImperial = mm => {
  if (isNil(mm)) {
    return ZERO_PLACEHOLDER;
  }

  const tmp = mmToFeetInch(mm);

  // just zero
  if (isNil(mm) || (tmp.feet === 0 && tmp.inches === 0)) {
    return ZERO_PLACEHOLDER;
  }

  // inches only
  if (tmp.feet === 0) {
    return `${tmp.inches}″`;
  }

  // feet only
  if (tmp.inches === 0) {
    return `${tmp.feet}′`;
  }

  return `${tmp.feet}′ ${tmp.inches}″`;
};

export const parametersToName = (parameters: ProductFloatParameter[]) => {
  const lengthPar = parameters.find(par => par.name === 'length');
  const widthPar = parameters.find(par => par.name === 'width');
  const thicknessPar = parameters.find(par => par.name === 'thickness');

  return `${formatMmToImperial(prop('floatValue', lengthPar))} x ${formatMmToImperial(
    prop('floatValue', widthPar)
  )} x ${formatMmToImperial(prop('floatValue', thicknessPar))}`;
};

export const formatNumber = (num: number, locale: string = 'en-US'): string => {
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(num);
};

export const formatDays = (days?: number) => {
  const daysNum = days || 0;
  return `${daysNum} day${daysNum === 1 ? '' : 's'}`;
};
