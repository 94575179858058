// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {CenterContent} from 'componentsStyled/Layout';
import {getRecoveryEmailMutation} from 'data/user/mutations';
import withForm from 'hoc/withForm';
import withMutation from 'hoc/withMutation';
import React from 'react';
import Aux from 'react-aux';
import {type HOC, compose, withStateHandlers} from 'recompose';

import schema from './schema';
import {TextWrap} from './styled';

function ForgotPasswordModal(props) {
  return (
    <Aux>
      <h2>Reset password</h2>
      {props.emailSent ? (
        <Success close={props.close} />
      ) : (
        <Form isSubmitting={props.isSubmitting} />
      )}
    </Aux>
  );
}

function Form({isSubmitting}) {
  return (
    <div>
      <TextWrap>
        <p>We will send you an email with further instructions to reset your password.</p>
      </TextWrap>
      <InputText name="email" type="email" placeholder="email" label="email" />
      <CenterContent>
        <Button kind="primary" loading={isSubmitting} big success type="submit">
          Reset
        </Button>
      </CenterContent>
    </div>
  );
}

function Success({close}) {
  return (
    <div>
      <TextWrap>
        <p>We've just sent password resetting instructions to your email.</p>
      </TextWrap>
      <CenterContent>
        <Button kind="primary" fullwidth onClick={close}>
          Okay
        </Button>
      </CenterContent>
    </div>
  );
}

type Outter = {|
  close: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers({emailSent: false}, {setEmailSent: () => v => ({emailSent: v})}),
  withMutation(getRecoveryEmailMutation),
  withForm({
    schema,
    onSubmit: props => values => {
      return props.submitMutation({
        email: values.email,
        appType: 'admin',
      });
    },
    onSuccess: props => () => props.setEmailSent(true),
  })
);

export default enhancer(ForgotPasswordModal);
