import {CurrencyInputWrapper} from 'components/CurrencyInput/styled';
import {StyledFieldMessage, StyledFieldWrap} from 'components/Inputs/FieldWrap/styled';
import Section from 'components/Section';
import TextEditor from 'components/TextEditor';
import {Divider} from 'componentsStyled/Layout';
import {Label} from 'componentsStyled/Typography';
import styled, {css} from 'styled-components';

export const CheckboxWrapper = styled.span``;

export const StyledTextEditor = styled(TextEditor)`
  max-width: 640px;

  ${StyledFieldMessage} {
    position: relative;
  }
`;

export const StyledDeliverySection = styled(Section)`
  ${StyledFieldWrap} {
    min-height: 0;
    margin-bottom: 2.4rem;
  }

  ${StyledTextEditor} {
    margin-bottom: 2rem;
  }

  label {
    text-transform: none;
  }

  ${CurrencyInputWrapper} {
    max-width: 25rem;
  }

  ${Divider} {
    margin-bottom: 3rem;
  }
`;

export const InlineNumberLabel = styled(Label)`
  margin-bottom: 2.4em;

  ${StyledFieldWrap} {
    display: inline;
    margin: 0 0.8rem;
  }
`;

export const CurrencyLabel = styled.span`
  transition: color ${props => props.theme.durations.inputColors};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.palette.grey};
    `};
`;
