import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 57rem;
  margin: 0 auto;
`;

export const ErrorMessage = styled.div`
  padding: 3.2rem;
  color: ${p => p.theme.palette.light};
  background-color: ${p => p.theme.palette.error};
`;

export const SuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 3rem;

  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
`;

export const SuccessTitleRow = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0;
    margin-left: 3.2rem;
  }
`;

export const SuccessTick = styled.div`
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${p => p.theme.palette.light};
  background-color: ${p => p.theme.palette.success};
  border-radius: 1.5rem;
`;

export const SuccessBodyContainer = styled.div`
  margin-left: 6rem;

  p:last-of-type {
    margin-bottom: 0;
  }
  padding: 3.2rem;
  background-color: ${props => props.theme.palette.light};
`;
