// @flow
import styled, {css} from 'styled-components';

import {mq} from '../global/queries';

export const PageMain = styled.div`
  padding: 4rem 1.6rem;
  margin: 0 auto;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${mq.tablet} {
    padding: 4rem;
  }
`;

export const PageContent = styled.div`
  position: relative;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
`;

// TODO(anyone): Replace this with {LayoutFlex} defined below
export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowStart = styled.div`
  display: flex;
`;

export const Col = styled.div`
  display: inline;
  margin: 0 1rem;
  width: calc(100% / 2 - 1rem);
`;

export const Br = styled.div`
  margin-top: ${p => `${p.margin || 0}rem`};
`;

// Grid

export const LayoutFlex = styled.div`
  display: flex;

  & + & {
    margin-top: 1rem;
  }

  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `}
  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `}
  ${props =>
    props.justifyContentSpaceBetween &&
    css`
      justify-content: space-between;
    `}
  ${props =>
    props.justifyContentCenter &&
    css`
      justify-content: center;
    `}
  ${props =>
    props.alignItemsRight &&
    css`
      align-items: flex-end;
    `}
  ${props =>
    props.col &&
    css`
      margin-left: 2.4rem;
      & + & {
        margin-top: 0;
      }

      &:first-of-type {
        margin-left: 0;
      }
    `}
  ${props =>
    props.oneQuater &&
    css`
      flex: 0 1 25%;
    `}
  ${props =>
    props.half &&
    css`
      flex: 0 1 50%;
    `}
  ${props =>
    props.fullWidth &&
    css`
      flex: 1;
    `}
  ${props =>
    props.centerContent &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    `}
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 2rem 0;
  border: 1px solid ${props => props.theme.palette.greyLight};
`;
