import {css} from 'styled-components';

import {StyledButtonLoaderWrap} from './index';

const generateTheme = (color, colorHover) => css`
  ${props =>
    props.kind === 'primary' &&
    css`
      background: ${color};
      border-color: ${color};
      color: ${props.theme.palette.light};

      ${StyledButtonLoaderWrap} {
        background: ${color};
        border-color: ${color};
      }

      &:active,
      &:hover {
        background: ${colorHover};
        border-color: ${colorHover};
      }

      :disabled {
        background: ${props => props.theme.palette.grey};
        border-color: ${props => props.theme.palette.grey};
        color: ${props => props.theme.palette.light};
        cursor: default;
        box-shadow: none;

        ${StyledButtonLoaderWrap} {
          background: ${props => props.theme.palette.grey};
          border-color: ${props => props.theme.palette.grey};
        }
      }
    `}

  ${props =>
    props.kind === 'secondary' &&
    css`
      background: ${props.theme.palette.light};
      color: ${color};
      border-color: currentColor;
      box-shadow: inset 0 0 0 1px currentColor;

      ${StyledButtonLoaderWrap} {
        background: ${props.theme.palette.light};
        border-color: currentColor;
        box-shadow: inset 0 0 0 1px currentColor;
      }

      &:active,
      &:hover {
        color: ${colorHover};
      }

      :disabled {
        background: ${props => props.theme.palette.greyLighter};
        border-color: ${props => props.theme.palette.grey};
        color: ${props => props.theme.palette.grey};
        cursor: default;

        ${StyledButtonLoaderWrap} {
          background: ${props => props.theme.palette.greyLighter};
          border-color: ${props => props.theme.palette.grey};
        }
      }
    `}
  ${props =>
    props.kind === 'tertiary' &&
    css`
      background: ${props.theme.palette.light};
      color: ${props.theme.palette.greyDark};
      border-color: currentColor;

      ${StyledButtonLoaderWrap} {
        background: ${props.theme.palette.light};
        border-color: currentColor;
      }

      :hover {
        background: ${props.theme.palette.greyLighter};
        color: ${props.theme.palette.greyDark};
      }

      :active {
        background: ${props.theme.palette.primaryLight};
        color: ${props.theme.palette.primary};
      }
      ${props.isActive &&
      css`
        background: ${props.theme.palette.primaryLight};
        color: ${props.theme.palette.primary};
      `}

      :disabled {
        background: ${props => props.theme.palette.greyLighter};
        border-color: ${props => props.theme.palette.grey};
        color: ${props => props.theme.palette.grey};
        cursor: default;

        ${StyledButtonLoaderWrap} {
          background: ${props => props.theme.palette.greyLighter};
          border-color: ${props => props.theme.palette.grey};
        }
      }
    `}
`;

const getTheme = props => {
  if (props.danger) return generateTheme(props.theme.palette.error, props.theme.palette.errorDark);

  if (props.warning)
    return generateTheme(props.theme.palette.warning, props.theme.palette.warningDark);

  return generateTheme(props.theme.palette.primary, props.theme.palette.successDark);
};

export default getTheme;
