// @flow
import {
  bundleCreateMutation,
  bundleDeleteMutation,
  bundleUpdateMutation,
} from 'data/bundle/mutations';
import {bundleDetailQuery, bundleListQuery} from 'data/bundle/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import BundleDetail from './BundleDetail';
import BundleEdit from './BundleEdit';
import filterConfig from './filterConfig';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: BundleEdit,
  detail: BundleDetail,
  detailQuery: bundleDetailQuery,
  listQuery: bundleListQuery,
  updateMutation: bundleUpdateMutation,
  createMutation: bundleCreateMutation,
  deleteMutation: bundleDeleteMutation,
  tableDefinition,
  filterConfig,
  title: 'Bundles',
  entityName: 'Bundle',
  route: urls.bundle,
});

// TODO(Jude): Add bundles help center article
const enhancer = withHelpCenter({category: 'products'});

export default enhancer(Page);
