// @flow
import type {NotificationType} from 'data/notifications/actions';
import React from 'react';

import IconCheck from './IconCheck';
import IconError from './IconError';
import IconWarn from './IconWarn';

type Props = {
  type: NotificationType,
};

const NotificationIcon = ({type}: Props) => {
  if (type === 'SUCCESS') return <IconCheck />;
  if (type === 'WARNING') return <IconWarn />;
  if (type === 'ERROR') return <IconError />;
  return null;
};

export default NotificationIcon;
