// @flow
import type {Tenant} from 'data/tenant/types';

export type EmailProjectionFilter = {
  date?: string,
  time?: string,
  timezone?: string,
  bookingId?: string,
  actionToDebug?: string,
  runLive?: boolean,
};

export type Email = {|
  emailId?: string,
  tenant: Tenant,
  to: string,
  from: string,
  bcc: string,
  subject: string,
  createdAt: string,
  type: string,
  content: string,
|};

export type EmailProjectionAction =
  | 'scheduleWatchdogBeforeClosingHour'
  | 'scheduleWatchdogReturnToday'
  | 'scheduleWatchdogPickup'
  | 'scheduleWatchdogPayments'
  | 'scheduleWatchdogSendExtensionDeadlineReminders'
  | 'scheduleWatchdogOverdueDeliveryReminders';

export const EMAIL_PROJECTION_ACTIONS = [
  'scheduleWatchdogBeforeClosingHour',
  'scheduleWatchdogReturnToday',
  'scheduleWatchdogPickup',
  'scheduleWatchdogPayments',
  'scheduleWatchdogSendExtensionDeadlineReminders',
  'scheduleWatchdogOverdueDeliveryReminders',
];
