// @flow
// @author: t04435
// Date: 5/7/21

import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import * as React from 'react';

import Loader from '../Loader';
import {StyledButton, StyledButtonLoaderWrap} from './styled';

export type CtaKind = 'primary' | 'secondary' | 'tertiary';

type Props = {
  children?: React.Node,
  // Icon to show at the end of the Button,
  iconEnd?: IconProp,
  // Icon to show at the start of the Button,
  // Can also be used as an Icon ONLY button
  iconStart?: IconProp,
  // Call To Action kind
  kind?: CtaKind,
  // Is the button in loading state
  loading?: boolean,
  // Shows the same UI as mouse hover
  isActive?: boolean,
  // A link to use as action instead of onClick
  to?: string,
  'data-cy'?: string,
  // $Import
} & React.ButtonHTMLAttributes;

/**
 * A Button component
 * @param children
 * @param iconEnd {IconProp} Icon to show after the label, ONLY use with label
 * @param iconStart {IconProp} Icon to show at the start of the Button,
 *  Can also be used as an Icon ONLY button
 * @param kind {CtaKind} Call To Action kind
 * @param loading {boolean} Is the button in loading state
 * @param to {string} A link to use as action instead of onClick
 * @param attrs {ButtonHTMLAttributes} HTML button attributes
 * @returns {JSX.Element} A Button with an onClick or LinkTo
 */
const Button = ({children, iconEnd, iconStart, loading, kind, isActive, ...attrs}: Props) => (
  <StyledButton
    {...attrs}
    component="button"
    kind={kind}
    loading={loading}
    isActive={isActive}
    hasLabel={children}
  >
    {loading && (
      <StyledButtonLoaderWrap color={kind === 'primary' ? 'light' : 'dark'} small>
        <Loader color={kind === 'primary' ? 'light' : 'dark'} small />
      </StyledButtonLoaderWrap>
    )}
    {iconStart && <FontAwesomeIcon icon={iconStart} size={children ? 'lg' : '2x'} />}
    {
      // NOTE(Jude): The StyledButton wrapper conditionally renders between many different components
      // Putting the `data-cy` prop here is cleaner w.r.t implementation
    }
    {children && <span data-cy={R.prop('data-cy', attrs)}>{children}</span>}
    {iconEnd && <FontAwesomeIcon icon={iconEnd} size={'lg'} />}
  </StyledButton>
);

export default Button;
