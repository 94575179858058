// @flow
import {productFragment} from 'data/product/fragments';
import {productItemFragment} from 'data/productItem/fragments';
import gql from 'graphql-tag';

export const parametersFragment = `
    ... on ParameterFloatValue {
      id
      name
      label
      type
      floatValue
      unit
      order
    }
    ... on ParameterEnumFlattenedValue {
      id
      name
      label
      enumValues
      type
      order
    }
    ... on ParameterIntValue {
      id
      name
      label
      intValue
      type
      order
    }
    ... on ParameterStringValue {
      id
      name
      label
      stringValue
      type
      order
    }
`;

export const productVariantFragment = gql`
  fragment productVariantFragment on ProductVariant {
    id
    name
    shelfLocation
    parameters {
      ${parametersFragment}
    }
    items {
      ...productItemFragment
    }
    product {
      ...productFragment
    }
  }
  ${productItemFragment},
  ${productFragment}
`;

export const productVariantWithProductFragment = gql`
  fragment productVariantWithProductFragment on ProductVariant {
    id
    shelfLocation
    parameters {
      ${parametersFragment}
    }
    product {
      ...productFragment
    }
  }
  ${productFragment}
`;

export const damageTypeFragment = gql`
  fragment damageTypeFragment on DamageType {
    id
    type
    description
    price
    isReportable
    isRepairable
    isDescriptionRequired
    name
  }
`;
