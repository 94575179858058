// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import {StyledInputTextArea} from 'componentsStyled/Forms';
import withField, {type ReqFieldProps} from 'hoc/withField';
import React from 'react';
import type {HOC} from 'recompose';

const InputTextArea = props => (
  <FieldWrap {...props}>
    <StyledInputTextArea {...props} />
  </FieldWrap>
);

const enhancer: HOC<*, ReqFieldProps> = withField;

export default enhancer(InputTextArea);
