// @flow
import Button from 'components/Button';
import InputOpeningHours from 'components/Inputs/InputOpeningHours';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import Section from 'components/Section';
import {Hint, Label} from 'componentsStyled/Typography';
import CategorySelect from 'containers/CategorySelect';
import LocationsSelect from 'containers/LocationsSelect';
import type {EditProps} from 'factories/createEntityCRUD';
import withForm from 'hoc/withForm';
import DeliveryFulfilmentSection from 'pages/Affiliate/AffiliateEdit/DeliveryFulfilmentSection';
import InStoreFulfilmentSection from 'pages/Affiliate/AffiliateEdit/InStoreFulfilmentSection';
import * as R from 'ramda';
import {assoc, omit, path, pick} from 'ramda';
import React from 'react';
import type {HOC} from 'recompose';
import {compose, withHandlers, withStateHandlers} from 'recompose';

import AddressAutocomplete from '../../../../components/Inputs/AddressAutocomplete';
import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../../components/Inputs/InputFileUpload';
import {
  INLINE_FORM_VALUES_FOR_FULFILLMENT_AND_DELIVERY,
  mapAffiliateFormValuesToUpdateParams,
} from '../affiliateFormUtil';
import RentalBuffersSection from '../RentalBuffersSection';
import schema from './schema';
import {AffiliateAddressWrap, FakeDisabledFormField} from './styled';

type Props = {
  isSubmitting: boolean,
  isTaxEnabled: boolean,
  toggleIsTaxEnabled: Function,
  onChangeCoordinates: Function,
  values: Object,
  setFieldToZeroIfEmpty: Function,
};

const AffiliateUpdate = ({
  isSubmitting,
  isTaxEnabled,
  toggleIsTaxEnabled,
  onChangeCoordinates,
  values,
  setFieldToZeroIfEmpty,
}: Props) => {
  const renderAffiliateAddressForTaxPurposes = () => (
    <AffiliateAddressWrap>
      <Hint>
        Please input a valid address in the United States. This address will be validated using the
        fields below.
      </Hint>
      <InputText name="addressForTaxStreet1" label="Street Address" />
      <InputText name="addressForTaxCity" label="City" />
      <InputText name="addressForTaxState" label="State" />
      <InputText name="addressForTaxZip" inputmode="numeric" pattern="[0-9]*" label="Zip Code" />
      <Label>Country</Label>
      <FakeDisabledFormField>United States</FakeDisabledFormField>
    </AffiliateAddressWrap>
  );
  return (
    <div>
      <Section title="Store details">
        <InputText name="name" placeholder="name" label="name" />
        <InputText name="description" placeholder="description" label="description" />
        <InputText name="email" placeholder="email" label="email" />
        <InputText name="phone" placeholder="phone" label="phone" />
        <InputText name="website" placeholder="website" label="website" />
        <AddressAutocomplete
          name="address"
          label="address"
          types={['address']}
          onChangeCoordinates={onChangeCoordinates}
        />
        {isTaxEnabled && renderAffiliateAddressForTaxPurposes()}
        <Button kind="primary" type="button" onClick={toggleIsTaxEnabled}>
          {isTaxEnabled ? 'Disable tax' : 'Enable US tax (Optional)'}
        </Button>
        <LocationsSelect name="locationId" label="location" />
        <InputSwitch name="isDefaultShop" label="is default store" />
        <InputSwitch name="allowed" label="is allowed" />
        <CategorySelect name="categoryIds" label="categories" isMulti canCreate />
        <InputFileUpload
          name="logo"
          label="logo"
          bucket="affiliateImages"
          accept={IMAGE_MIME_TYPE}
          data-cy="upload-store-logo"
        />
        <InputFileUpload
          name="images"
          label="images"
          bucket="affiliateImages"
          multiple
          accept={IMAGE_MIME_TYPE}
          data-cy="upload-store-images"
        />
      </Section>
      <Section title="Opening hours" data-cy="opening-hours">
        <InputOpeningHours name="openingHours" label="opening hours" />
        <InputOpeningHours name="specialOpeningDates" label="special opening dates" special />
      </Section>
      <InStoreFulfilmentSection formValues={values} setFieldToZeroIfEmpty={setFieldToZeroIfEmpty} />
      <DeliveryFulfilmentSection
        formValues={values}
        setFieldToZeroIfEmpty={setFieldToZeroIfEmpty}
      />
      <RentalBuffersSection formValues={values} setFieldToZeroIfEmpty={setFieldToZeroIfEmpty} />
      <Button kind="primary" loading={isSubmitting} big success type="submit">
        save
      </Button>
    </div>
  );
};

const buildInput = values => {
  const withKey = assoc('cancelHrAfterCreate', 0);
  const transformedValues = withKey(values);
  // $Fixme
  transformedValues.logo = transformedValues.logo[0].url;
  // $Fixme
  transformedValues.images = transformedValues.images.map(image => ({
    url: image.url,
    id: image.id,
  }));
  return {
    // $Ramda
    ...omit(['coordinates'], transformedValues),
    // $Ramda
    ...pick(['lat', 'lng', 'inTimezone'], transformedValues.coordinates),
  };
};
const enhancer: HOC<*, EditProps> = compose(
  // $Dunno TODO(Jude) - fix on locations fix PR
  withStateHandlers(
    props => ({
      isTaxEnabled: !!props.data.addressForTaxPurposes,
    }),
    {
      toggleIsTaxEnabled: props => () => ({
        isTaxEnabled: !props.isTaxEnabled,
      }),
    }
  ),
  withForm({
    schema,
    mapProps: props => {
      const addressForTaxPurposes = props.data.addressForTaxPurposes;
      const logo = props.data.logo;
      return {
        ...props.data,
        addressForTaxStreet1: addressForTaxPurposes && addressForTaxPurposes.street1,
        addressForTaxCity: addressForTaxPurposes && addressForTaxPurposes.city,
        addressForTaxState: addressForTaxPurposes && addressForTaxPurposes.state,
        addressForTaxZip: addressForTaxPurposes && addressForTaxPurposes.zip,
        categoryIds: props.data.categories.map(category => category.categoryId),
        locationId: path(['data', 'location', 'id'], props),
        logo: [{url: logo}],
        images: path(['data', 'images'], props),
        coordinates: {
          inTimezone: props.data.inTimezone,
          lat: props.data.location.lat,
          lng: props.data.location.lng,
        },
      };
    },
    onSubmit: props => values => {
      const finalValues = {
        ...values,

        // Construct `addressForTaxPurposes` is tax is enabled
        addressForTaxPurposes: props.isTaxEnabled
          ? {
              street1: values.addressForTaxStreet1,
              city: values.addressForTaxCity,
              state: values.addressForTaxState,
              zip: values.addressForTaxZip,
              country: 'US',
            }
          : null,

        ...mapAffiliateFormValuesToUpdateParams(values),
      };

      // Clear inline properties that should have been stored in nested objects
      const cleanedFinalValues = R.omit(
        [
          ...INLINE_FORM_VALUES_FOR_FULFILLMENT_AND_DELIVERY,
          'addressForTaxStreet1',
          'addressForTaxCity',
          'addressForTaxState',
          'addressForTaxZip',
        ],
        finalValues
      );

      return props.submit({input: buildInput(cleanedFinalValues)});
    },
  }),
  // $Dunno TODO(Jude) - fix on locations fix PR
  withHandlers({
    onChangeCoordinates:
      ({setFieldValue}) =>
      value => {
        setFieldValue('coordinates', {...value});
      },
    setFieldToZeroIfEmpty:
      ({setFieldValue, values}) =>
      fieldName => {
        //Purposefully using double equals to capture undefined
        if (values[fieldName] == null) {
          setFieldValue(fieldName, 0);
        }
      },
  })
);

export default enhancer(AffiliateUpdate);
