// @flow
import {addProductItemsMutation} from 'data/pricingTemplate/mutations';
import {pricingTemplateDetailQuery} from 'data/pricingTemplate/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import urls from 'pages/urls';

import PricingTemplateDetail from '../PricingTemplate/PricingTemplateDetail';
import PricingTemplateProductEdit from './PricingTemplateProductEdit';

export default createEntityCRUD({
  edit: PricingTemplateProductEdit,
  detailQuery: pricingTemplateDetailQuery,
  detail: PricingTemplateDetail,
  updateMutation: addProductItemsMutation,
  title: 'Pricing Template Product Bulk Assignment',
  route: urls.pricingTemplateProduct,
});
