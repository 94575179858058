import {clone} from 'ramda';
import {withHandlers} from 'recompose';

// TODO: Add types
function withPricingSchedule(WrappedComponent) {
  return withHandlers({
    addDay: props => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      const accessoryArray = clone(props.value);
      if (props.accessoryName) {
        const targetIndex = accessoryArray.findIndex(a => a.name === props.accessoryName);
        accessoryArray[targetIndex].prices.push(1);
        return props.onChange(accessoryArray);
      }
      accessoryArray.push(props.value[props.value.length - 1]);
      props.onChange(accessoryArray);
    },
    removeDay: props => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      const accessoryArray = clone(props.value);
      if (props.accessoryName) {
        const targetIndex = accessoryArray.findIndex(a => a.name === props.accessoryName);
        accessoryArray[targetIndex].prices.pop();
        return props.onChange(accessoryArray);
      }
      accessoryArray.pop();
      props.onChange(accessoryArray);
    },
  })(WrappedComponent);
}

export default withPricingSchedule;
