// @flow
import {isNil} from 'ramda';

export const currencySymbols = {
  USD: '$',
  CAD: '$',
  AUD: '$',
  NZD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  GIP: '£',
  FKP: '£',
  SHP: '£',
  CHF: 'CHF',
  BRL: 'R$',
  NOK: 'kr',
  SEK: 'kr',
  DKK: 'kr',
  BGN: 'лв',
  HRK: 'kn',
  RON: 'lei',
  CZK: 'Kč',
  HUF: 'Ft',
  PLN: 'zł',
  ALL: 'Lek',
  BYN: 'Br',
  BAM: 'KM',
  ISK: 'kr',
  MDL: 'L',
  MKD: 'Ден',
  RUB: '₽',
  RSD: 'дин',
  UAH: '₴',
  SGD: '$',
  ZAR: 'R',
};

export const specialCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const formatToCentum = (amount: number) => Math.round(amount * 100);

export const formatFromCentum = (amount: number, currency?: string) =>
  specialCurrencies.includes(currency) ? amount / 100 : (amount / 100).toFixed(2);

export const formatCurrencyFromCents = (
  amount?: number | null,
  locale: string = 'en-US',
  currency: string = 'USD'
): string => {
  const value = amount ? amount : 0;
  //Fallback mechanism to handle any browsers that are unable to support the usage of 'narrowSymbol'
  //which is unsupported on Safari & IOS versions 10-14.4.
  //https://caniuse.com/?search=currencyDisplay
  let formatter;
  try {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      // $Import => Flow Intl.NumberFormat is outdated and does not recognize 'narrowSymbol' as a valid option
      currencyDisplay: 'narrowSymbol',
    });
  } catch {
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      // $Import => Flow Intl.NumberFormat is outdated and does not recognize 'narrowSymbol' as a valid option
      currencyDisplay: 'symbol',
    });
  }
  return formatter.format(value / 100);
};

export const buildPriceFromObject = (obj: Object) => {
  const {currency, price, totalRevenue} = obj;

  if (!isNil(totalRevenue)) {
    return formatCurrencyFromCents(totalRevenue, undefined, currency);
  }
  if (!isNil(price)) {
    return formatCurrencyFromCents(price, undefined, currency);
  }
};
