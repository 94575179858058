import {emailProjectionListQuery} from 'data/email/queries';
import {query} from 'global/apolloClient/helpers';
import moment from 'moment';
import EmailProjectionFilters from 'pages/Email/EmailProjection/EmailProjectionList/EmailProjectionFilters';
import EmailProjectionTable from 'pages/Email/EmailProjection/EmailProjectionList/EmailProjectionTable';
import {EmailProjectionCard} from 'pages/Email/EmailProjection/EmailProjectionList/styled';
import {useCallback, useState} from 'react';

const dateAndTimeToDateTime = (date, time, timezone) => {
  const timeMoment = moment(time, 'HH:mm');
  const dateMoment = moment.tz(date, timezone);

  if (!date) {
    return null;
  }

  if (!time || !timeMoment.isValid()) {
    return date;
  }

  const dateTime = dateMoment.set({
    hour: timeMoment.get('hour'),
    minute: timeMoment.get('minute'),
    second: 0,
  });

  return dateTime.toISOString();
};

const EmailProjectionList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    ({date, time, timezone, bookingId, actionToDebug, runLive}) => {
      setLoading(true);
      query(emailProjectionListQuery, {
        filter: {
          projectTo: dateAndTimeToDateTime(date, time, timezone),
          bookingId,
          actionToDebug,
          runLive,
        },
      })
        .then(results => setData(results))
        .finally(() => setLoading(false));
    },
    [setLoading, setData]
  );

  return (
    <>
      <h1>Email Projection</h1>
      <EmailProjectionCard>
        <EmailProjectionFilters submit={handleSubmit} />
        {(data || loading) && <EmailProjectionTable data={data} loading={loading} />}
      </EmailProjectionCard>
    </>
  );
};

export default EmailProjectionList;
