// @flow
import styled, {css} from 'styled-components';

import {FromProp} from '../../../helpers/FromProp';
import {linker} from '../../../hoc/linker';
import theme from './theme';

export const StyledButton = linker(styled(FromProp)`
  border: 0.1rem solid;
  ${theme};
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${props => props.theme.metrics.buttonHeight};
  min-width: 4rem;
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  transition: background 0.25s ease, color 0.25s ease, border 0.25s ease;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeights.semiBold};
  align-self: flex-start;

  svg + span,
  span + svg {
    margin-left: 0.8rem;
  }

  ${props =>
    props.hasLabel &&
    css`
      padding: 0 2.4rem;
    `}
`);

export const StyledButtonLoaderWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
