// @flow
import {Label} from 'componentsStyled/Typography';
import withField, {type ReqFieldProps} from 'hoc/withField';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {HourWrap, StyledInputTime} from './styled';

const InputTimePicker = ({
  label,
  name,
  defaultValue,
  value,
  handleTimeChange,
  'data-cy': dataCy,
}) => (
  <HourWrap>
    {label && <Label>{label}</Label>}
    <StyledInputTime
      name={name}
      maxWidth="15"
      placeholder="HH:MM"
      type="time"
      min="00:00"
      max="59:59"
      value={defaultValue || value}
      onChange={handleTimeChange}
      data-cy={dataCy}
    />
  </HourWrap>
);

type Enhancer = {
  defaultValue?: ?string,
  handleChange: Function,
  'data-cy'?: string,
};

const enhancer: HOC<*, {...$Exact<Enhancer>, ...$Exact<ReqFieldProps>}> = compose(
  withField,
  withHandlers({
    handleTimeChange:
      ({handleChange}) =>
      e => {
        handleChange(e);
      },
  })
);

export default enhancer(InputTimePicker);
