// @flow
import {Label} from 'componentsStyled/Typography';
import {type CategoryObject} from 'data/product/types';
import React from 'react';

type Props = {
  data: CategoryObject,
};

const CategoryDetail = ({data}: Props) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <Label>Related Categories</Label>
    <p>
      {!(Array.isArray(data.relatedCategories) && data.relatedCategories.length === 0)
        ? // $Fixme
          data.relatedCategories.map((rc: CategoryObject) => rc.name).join(', ')
        : 'None'}
    </p>
  </div>
);

export default CategoryDetail;
