// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {tenantDetailQuery, tenantListQuery} from './queries';

export const tenantUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation tenantUpdateMutation($input: UpdateTenantInput!) {
      tenant {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantDetailQuery],
};

export const tenantCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation tenantCreateMutation($input: CreateTenantInput!) {
      tenant {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantListQuery],
  selector: ['tenant', 'create'],
};

export const verifySignupEmailMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation verifySignupEmailMutation($input: VerifySignupEmailInput!) {
      tenant {
        verifySignupEmail(input: $input)
      }
    }
  `,
  selector: ['tenant', 'verifySignupEmail'],
};

export const approveSignupMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation approveSignupMutation($input: ApproveSignupInput!) {
      tenant {
        approveSignup(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantDetailQuery],
  selector: ['tenant', 'approveSignup'],
};
