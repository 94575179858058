// @flow
import gql from 'graphql-tag';

import {customerFragmentMinimal} from '../user/fragments';

export const orderFragment = gql`
  fragment orderFragment on Order {
    orderId
    externalId
    customer {
      ...customerFragmentMinimal
    }
  }
  ${customerFragmentMinimal}
`;
