// @flow
import {
  pricingTemplateCreateMutation,
  pricingTemplateUpdateMutation,
} from 'data/pricingTemplate/mutations';
import {pricingTemplateDetailQuery, pricingTemplateListQuery} from 'data/pricingTemplate/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import enums from 'global/enums';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import filterConfig from './filterConfig';
import PricingTemplateDetail from './PricingTemplateDetail';
import PricingTemplateEdit from './PricingTemplateEdit';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: PricingTemplateEdit,
  detail: PricingTemplateDetail,
  detailQuery: pricingTemplateDetailQuery,
  listQuery: pricingTemplateListQuery,
  createMutation: pricingTemplateCreateMutation,
  updateMutation: pricingTemplateUpdateMutation,
  tableDefinition,
  filterConfig,
  filterConfigRoles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
  title: 'Pricing Templates',
  entityName: 'Pricing Template',
  route: urls.pricingTemplate,
});

const enhancer = withHelpCenter({category: 'pricing'});

export default enhancer(Page);
