// @flow
import styled, {css} from 'styled-components';

export const ImageWrap = styled.div`
  min-width: 10rem;
  max-width: 30rem;
  position: relative;
  margin-right: 2rem;

  ${props =>
    props.order &&
    css`
      &::after {
        content: 'order: ${props.order}';
        position: absolute;
        background: ${props => props.theme.palette.dark};
        color: ${props => props.theme.palette.light};
        right: 1rem;
        bottom: 1rem;
      }
    `};
`;

export const StyledImage = styled.img`
  max-width: 100%;
`;
