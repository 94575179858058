// @flow
import type {FilterConfig} from 'components/Filter';
import AffiliateSelectNoForm from 'containers/AffiliateSelectNoForm';

const filterConfig: FilterConfig = {
  affiliateId: {
    type: AffiliateSelectNoForm,
    props: {
      placeholder: 'Select store',
    },
  },
};

export default filterConfig;
