// @flow
import type {TableDefinition} from 'components/Table/types';
import type {Accessory} from 'data/pricingTemplate/types';
import {buildPriceFromObject} from 'data/units/money/formatters';
import {sortCurrency} from 'data/units/money/helpers';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<{...Accessory, day: string}> = {
  columns: [
    {
      title: 'accessory',
      value: prop('name'),
    },
    {
      title: 'day',
      value: prop('day'),
    },
    {
      title: 'price',
      value: buildPriceFromObject,
      sortFunction: sortCurrency,
    },
  ],
};
export default tableDefinition;
