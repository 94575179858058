// @flow
import {productVariantDetailQuery} from 'data/productVariant/queries';
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {listProductItemInventoryByAffiliateQuery} from './queries';

const transformItemDetail = (results): any => {
  const productId = results['product']['createProductItem']['id'];
  return {id: productId};
};

export const addProductItemMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation addProductItemMutation($input: ProductItemCreateInput!) {
      product {
        createProductItem(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productVariantDetailQuery],
};

export const addProductItemMutationByAffiliate: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation addProductItemMutationByAffiliate($input: ProductItemCreateInput!) {
      product {
        createProductItem(input: $input) {
          id
        }
      }
    }
  `,
  transform: transformItemDetail,
  selector: ['product', 'createProductItem'],
};

export const editProductItemMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation editProductItemMutation($input: ProductItemUpdateInput!) {
      product {
        updateProductItem(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productVariantDetailQuery],
};

export const editProductItemMutationByAffiliate: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation editProductItemMutationByAffiliate($input: ProductItemUpdateInput!) {
      product {
        updateProductItem(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [listProductItemInventoryByAffiliateQuery],
};
