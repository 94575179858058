import {inputStyle} from 'componentsStyled/Forms';
import styled, {css} from 'styled-components';

export const CurrencySymbolWrapper = styled.span`
  margin-right: 0.8rem;
  color: ${props => props.theme.palette.grey};
  background: transparent;
`;

export const CurrencyInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;

  //Define focus-within before inputStyle so they take precedence
  &:focus-within {
    border: 0.2rem solid ${props => props.theme.palette.primary};
  }
  ${inputStyle};

  input {
    height: 100%;
    width: 100%;
    padding: 0;
    border-style: none !important;
    outline: none;
    background: transparent;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      background: transparent;
      color: ${props => props.theme.palette.grey};
    }
  }

  ${props =>
    props.disabled &&
    css`
      border: 0.1rem solid ${props => props.theme.palette.grey};
      background: ${props => props.theme.palette.greyLighter};
      cursor: default;
    `}
`;
