// @flow
import React, {Fragment} from 'react';
import {compose} from 'recompose';
import styled from 'styled-components';

import Section from '../../components/Section';
import theme from '../../global/theme';
import withTooltip from '../../hoc/withTooltip';
import {withStory} from './withStory';

const TooltipBox = styled.div`
  width: 50px;
  height: 50px;
  border: 2px dashed ${theme.palette.dark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PillsStory = () => (
  <Fragment>
    <Section title="Docs">
      <p>
        All components support tooltips only add <code>withTooltip</code> HOC
      </p>
      <br />
      <h4>Add tooltip - required</h4>
      <code>data-tip="tooltip text here"</code>
      <br />
      <h4>Change default position(top)</h4>
      <code>data-place="left | right | bottom"</code>
    </Section>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '2rem',
        justifyItems: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        padding: '2rem',
      }}
    >
      <TooltipBox data-tip="This is the tooltip for this amazing TooltipBox" data-place="top" />
      <TooltipBox data-tip="This is the tooltip for this amazing TooltipBox" data-place="right" />
      <TooltipBox data-tip="This is the tooltip for this amazing TooltipBox" data-place="bottom" />
      <TooltipBox data-tip="This is the tooltip for this amazing TooltipBox" data-place="left" />
    </div>
  </Fragment>
);

const enhancer = compose(withTooltip);

export default enhancer(withStory(PillsStory));
