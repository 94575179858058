// @flow
import {formatBool, formatDays, formatEnabledDisabled} from 'common/formatters';
import Gallery from 'components/Gallery';
import ImageItem from 'components/ImageItem';
import NameList from 'components/NameList';
import RawHtml from 'components/RawHtml';
import {Label} from 'componentsStyled/Typography';
import type {Affiliate} from 'data/affiliate/types';
import {RawHtmlWrapper} from 'pages/Affiliate/AffiliateDetail/StoreInfo/styled';
import React from 'react';

import Hours from './Hours';

type Props = {
  data: Affiliate,
};

const RawHtmlOrNull = ({html}) =>
  html ? (
    <RawHtmlWrapper>
      <RawHtml html={html} />
    </RawHtmlWrapper>
  ) : (
    <p>-</p>
  );

const StoreInfo = ({data, passportDiscountId}: Props) => {
  return (
    <div data-cy="store-info">
      <Label>Name</Label>
      <p>{data.name}</p>
      <Label>description</Label>
      <p>{data.description}</p>
      <Label>email</Label>
      <p>{data.email}</p>
      <Label>phone</Label>
      <p>{data.phone}</p>
      <Label>website</Label>
      <p>{data.website}</p>
      <Label>address</Label>
      <p>{data.address}</p>
      <Label>location</Label>
      <p>{data.location && data.location.name}</p>
      <Label>Is Default Store</Label>
      <p>{formatBool(data.isDefaultShop)}</p>
      <Label>Is Allowed</Label>
      <p>{formatBool(data.allowed)}</p>
      <Label>Is Tax Enabled</Label>
      <p>{formatBool(!!data.addressForTaxPurposes)}</p>
      <Label>Categories</Label>
      <p>
        <NameList data={data.categories} />
      </p>
      <Label>opening hours</Label>
      <Hours data={data.openingHours} special={data.specialOpeningDates} />
      {/* In-store fulfilment values */}
      <Label>In-store fulfilment</Label>
      <p>{formatEnabledDisabled(!!data.allowInStoreFulfilment)}</p>
      <Label>Allow in-store bookings to be extended</Label>
      <p>{formatBool(!!data.allowInStoreBookingExtension)}</p>
      <Label>In-store extension cutoff</Label>
      <p>{formatDays(data.inStoreExtensionCutoffDays)}</p>
      <Label>In-store fulfilment instructions</Label>
      <RawHtmlOrNull html={data.inStoreFulfilmentInstructions} />
      {/* Delivery fulfilment values */}
      <Label>Delivery fulfilment</Label>
      <p>{formatEnabledDisabled(!!data.allowDeliveryFulfilment)}</p>
      <Label>Allow delivery bookings to be extended</Label>
      <p>{formatBool(!!data.allowDeliveryBookingExtension)}</p>
      <Label>Delivery extension cutoff</Label>
      <p>{formatDays(data.deliveryExtensionCutoffDays)}</p>
      <Label>Delivery fulfilment instructions</Label>
      <RawHtmlOrNull html={data.deliveryFulfilmentInstructions} />
      <Label>Delivery charge</Label>
      <p>{data.deliveryCharge == null ? '-' : data.deliveryCharge}</p>
      <Label>Delivery - No charge</Label>
      <p>{formatEnabledDisabled(!!data.enableDeliveryChargeThreshold)}</p>
      <Label>Delivery - No charge threshold</Label>
      <p>{data.deliveryChargeThreshold == null ? '-' : data.deliveryChargeThreshold}</p>
      <Label>Delivery summary</Label>
      <RawHtmlOrNull html={data.deliverySummary} />
      <Label>Logistics summary</Label>
      <RawHtmlOrNull html={data.logisticsSummary} />
      <Label>Delivery explanation</Label>
      <RawHtmlOrNull html={data.deliveryExplanation} />
      <Label>Return instructions</Label>
      <RawHtmlOrNull html={data.returnInstructions} />
      {/*  Rental buffers values */}
      <Label>Pre-processing buffer</Label>
      <p>{formatDays(data.preBookingBufferDays)}</p>
      <Label>Post-processing buffer</Label>
      <p>{formatDays(data.postBookingBufferDays)}</p>
      <Label>Automatically end post-processing</Label>
      <p>{formatBool(data.automaticallyEndPostProcessing)}</p>
      <Label>Additional delivery buffers</Label>
      <p>{formatBool(data.enableDeliveryBuffers)}</p>
      <Label>Outbound transit</Label>
      <p>{formatDays(data.outboundBufferDays)}</p>
      <Label>Inbound transit</Label>
      <p>{formatDays(data.inboundBufferDays)}</p>
      <Label>logo</Label>
      <p>
        <ImageItem url={data.logo} />
      </p>
      <Label>images</Label>
      <p>
        <Gallery data={data.images} />
      </p>
    </div>
  );
};

export default StoreInfo;
