import React from 'react';

import {VariantName, VariantVariableLabel, VariantVariableWrap} from './styled';

type Props = {
  variantVariableLabels: String,
  variantVariablesOptions: String,
};

const VariantVariablesDisplay = ({variantVariableLabels, variantVariablesOptions}: Props) => {
  const variantVariables = variantVariableLabels.split(' | ');
  const variantNamesList = variantVariablesOptions.split(' | ');
  const labelLength = variantVariables.length;
  const variantNamesLength = variantNamesList.length;

  const maxLengthArray = Array(Math.max(labelLength, variantNamesLength)).fill('');

  // If there are more labels than names, or vice versa, fill the corresponding element with '-'
  return (
    <React.Fragment>
      {maxLengthArray.map((_, index) => {
        return (
          <VariantVariableWrap>
            <VariantVariableLabel>
              {index < labelLength ? variantVariables[index] : '-'}
            </VariantVariableLabel>
            <VariantName>{index < variantNamesLength ? variantNamesList[index] : '-'}</VariantName>
          </VariantVariableWrap>
        );
      })}
    </React.Fragment>
  );
};

export default VariantVariablesDisplay;
