// @flow
// @author: T04435
// Date: 9/7/21

import Button from 'components/Button';
import styled from 'styled-components';

export const FilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: flex-start;
`;

export const FilterInputs = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1.6rem;

  .query {
    flex: 1 0 100%;
  }

  & input {
    width: auto;
    height: 4rem;
  }

  //select-component has 14px of margin/padding/border around the input
  & .select-component input {
    height: calc(4rem - 14px);
  }
`;

export const FilterControls = styled.div`
  display: flex;
  margin-left: 3.2rem;
  flex-direction: column;
  align-items: flex-end;

  > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const ClearFilterButton = styled(Button)``;
