// @flow
import {formatDate} from 'data/units/date/formatters';
// $Import
import * as moment from 'moment-timezone';
import {identity} from 'ramda';

export type ParamTransformer<T> = {
  transformToParam: (value: ?T) => ?string,
  transformFromParam: (param: ?string) => ?T,
};

export const booleanParamTransformer: ParamTransformer<boolean> = {
  transformFromParam: param => {
    if (param === 'true') {
      return true;
    } else if (param === 'false') {
      return false;
    }
    return null;
  },
  transformToParam: value => {
    if (value === true) {
      return 'true';
    } else if (value === false) {
      return 'false';
    }
    return null;
  },
};

export const dateStringParamTransformer: ParamTransformer<string> = {
  transformFromParam: param => {
    if (param && Date.parse(param)) {
      return new Date(formatDate(param)).toISOString();
    }
    return null;
  },
  transformToParam: value => (value ? formatDate(value) : null),
};

export const timeParamTransformer: ParamTransformer<string> = {
  transformToParam: identity,
  transformFromParam: param => {
    if (param && moment(param, 'HH:mm').isValid()) {
      return param;
    }
    return null;
  },
};

export const timezoneParamTransformer: ParamTransformer<string> = {
  transformToParam: identity,
  transformFromParam: param => {
    if (param && moment.tz(moment(), param).isValid()) {
      return param;
    }
    return null;
  },
};
