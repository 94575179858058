import {boolean, rangeOptional, required} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  id: required,
  code: required,
  allowed: boolean,
  variantId: required,
  affiliateId: required,
  pricingTemplateId: required,
  buyItRrp: rangeOptional(1, 1000000),
});
