// @flow
import React from 'react';

import AffiliateCreate from './AffiliateCreate';
import AffiliateUpdate from './AffiliateUpdate';

const AffiliateEdit = (props: any) => {
  return props.data ? <AffiliateUpdate {...props} /> : <AffiliateCreate {...props} />;
};

export default AffiliateEdit;
