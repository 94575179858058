// @flow
import React, {Fragment} from 'react';
import {compose} from 'recompose';

import {formatNumber} from '../../common/formatters';
import Section from '../../components/Section';
import {formatCurrencyFromCents} from '../../data/units/money/formatters';
import withTooltip from '../../hoc/withTooltip';
import {withStory} from './withStory';

const FormatsStory = () => (
  <Fragment>
    <Section title="Docs">Formatter helpers</Section>
    <Section>
      <pre>{formatNumber(1000000)}</pre>
      <pre>{formatNumber(1000000, 'en-IN')}</pre>
      <pre>{formatCurrencyFromCents(12345678912)}</pre>
      <pre>{formatCurrencyFromCents(12345678912, 'en-GB', 'EUR')}</pre>
    </Section>
  </Fragment>
);

const enhancer = compose(withTooltip);

export default enhancer(withStory(FormatsStory));
