// @flow
import gql from 'graphql-tag';

export const paginatedAccessoryFragment = gql`
  fragment paginatedAccessoryFragment on PaginatedListOfAccessory {
    data {
      id
      name
      url
      preselect
    }
  }
`;

export const accessoryFragment = gql`
  fragment accessoryFragment on Accessory {
    id
    name
    url
    preselect
  }
`;
