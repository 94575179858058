// @flow
import 'moment/min/locales';

import type Moment from 'moment';
import moment from 'moment';

import {momentFromDateString} from './helpers';
import type {DateString} from './types';

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

// TODO(Russell): consolidate all date formats to minimal set and localize
export const formatReadableFullYear = (date: ?DateString) => {
  if (!date) return;

  return momentFromDateString(date).format('MMM. DD, YYYY');
};

export const momentToShortFormat = (m: Moment): string => {
  return m.format('ll');
};

// TODO(Russell): consolidate all date formats to minimal set and localize
export const formatDate = (value: string) => moment(value).format('YYYY-MM-DD');

// TODO(Russell): consolidate all date formats to minimal set and localize
export const formatDateTime = (value: string) => moment(value).format('YYYY-MM-DD HH:mm:SS');

export const parseDate = (value: string) => {
  const date = moment(value, 'YYYY-MM-DD', true);

  return date.isValid() ? moment(value, 'YYYY-MM-DD').toDate() : null;
};

// TODO(Russell): consolidate all date formats to minimal set and localize
export const formatDateTimeToMinutes = (m: Moment): string => m.format('YYYY-MM-DD HH:mm');

export const toReadableDateTime = (dateTime: string): string =>
  moment(dateTime).format('DD MMM YYYY HH:mm');
