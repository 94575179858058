// @flow
import React from 'react';

import {Circular, LoaderWrap, Path, StyledLoader} from './styled';

type Props = {
  small?: boolean,
  // value from theme.palette
  color?: string,
  className?: string,
};

// SOURCE: https://codepen.io/jczimm/pen/vEBpoL
const Loader = ({color, small, className}: Props) => (
  <LoaderWrap className={className} small={small}>
    <StyledLoader>
      <Circular viewBox="25 25 50 50">
        <Path
          stroke="currentColor"
          color={color}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </Circular>
    </StyledLoader>
  </LoaderWrap>
);

export default Loader;
