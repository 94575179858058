// @flow
import InventorySelect from 'components/Inputs/InventorySelect';
import {listProductInventoryQuery} from 'data/productItem/queries';
import withField, {type ReqFieldProps} from 'hoc/withField';
import withQuery from 'hoc/withQuery';
import {clone, indexOf, isNil} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const InventoryProductSelect = ({data, value, handleItemChange, ...rest}) => {
  if (isNil(data)) return null;

  return (
    <InventorySelect data={data} productItemIds={value} onItemChange={handleItemChange} {...rest} />
  );
};

const enhancer: HOC<*, ReqFieldProps> = compose(
  withField,
  withQuery(listProductInventoryQuery),
  withHandlers({
    handleItemChange: props => data => {
      const value = clone(props.value);

      data.forEach(dataItem => {
        const index = indexOf(dataItem.itemId, value);
        if (dataItem.value && index === -1) {
          value.push(dataItem.itemId);
        }
        if (!dataItem.value && index !== -1) {
          value.splice(index, 1);
        }
      });
      props.onChange(value);
    },
  })
);

export default enhancer(InventoryProductSelect);
