// @flow
import {userCreateMutation} from 'data/user/mutations';
import {userDetailQuery, userListQuery} from 'data/user/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import EditAffiliateUser from './EditAffiliateUser';
import filterConfig from './filterConfig';
import tableDefinition from './tableDefinition';
import UserDetail from './UserDetail';

const Page = createEntityCRUD({
  createMutation: userCreateMutation,
  detail: UserDetail,
  detailQuery: userDetailQuery,
  edit: EditAffiliateUser,
  listQuery: userListQuery,
  filterConfig,
  tableDefinition,
  title: 'Users',
  entityName: 'User',
  route: urls.user,
});

const enhancer = withHelpCenter({category: 'users'});

export default enhancer(Page);
