// @flow
import {paginationFragment} from 'data/other/fragments';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {pricingTemplateDetailFragment, pricingTemplateFragment} from './fragments';
import type {PricingTemplate} from './types';

export const pricingTemplateListQuery: Query<PricingTemplate[]> = {
  gql: gql`
    query pricingTemplateListQuery($filter: PricingTemplateListFilter) {
      pricingTemplate {
        list(filter: $filter) {
          data {
            ...pricingTemplateFragment
          }
          pageInfo {
            ...paginationFragment
          }
        }
      }
    }
    ${pricingTemplateFragment}
    ${paginationFragment}
  `,
  selector: ['pricingTemplate', 'list'],
  pagination: true,
};

export const pricingTemplateDetailQuery: Query<PricingTemplate> = {
  gql: gql`
    query pricingTemplateDetailQuery($id: Int!) {
      pricingTemplate {
        get(id: $id) {
          ...pricingTemplateDetailFragment
        }
      }
    }
    ${pricingTemplateDetailFragment}
  `,
  selector: ['pricingTemplate', 'get'],
  fetchPolicy: 'cache-and-network',
};
