// @flow
// @author: t04435
// Date: 8/7/21

import {faSitemap} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatBool} from 'common/formatters';
import type {TableDefinition} from 'components/Table/types';
import type {PricingTemplate} from 'data/pricingTemplate/types';
import urls from 'pages/urls';
import {prop} from 'ramda';

import {TABLE_COLUMN_ACTION_TITLE} from '../../components/Table/Td';
import withTooltip from '../../hoc/withTooltip';

const tableDefinition: TableDefinition<PricingTemplate> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'primary',
      value: v => formatBool(v.isPrimary),
    },
    {
      title: TABLE_COLUMN_ACTION_TITLE,
      value: () => prop('id'),
      type: withTooltip(FontAwesomeIcon),
      props: v => ({
        icon: faSitemap,
        size: 'lg',
        'data-tip': 'Related products',
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          window.location = urls.pricingTemplateProduct.link.update(v.id);
        },
      }),
    },
  ],
  rowLink: v => urls.pricingTemplate.link.detail(v.id),
};
export default tableDefinition;
