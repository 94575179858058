import Pill from 'components/Pills';
import {Card} from 'componentsStyled/Card';
import {maxLines} from 'componentsStyled/Typography';
import {mq, mqMax} from 'global/queries';
import styled from 'styled-components';

export const EmailStatusPill = styled(Pill)`
  font-size: 1.4rem;
  line-height: 1.5;
`;

export const EmailTitle = styled.h2`
  ${maxLines}
  line-height: 1.25;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const EmailHeader = styled(Card)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq.tablet} {
    gap: 4rem;
    flex-direction: row;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }
`;

export const EmailDate = styled.p`
  color: ${props => props.theme.palette.greyDark};
  white-space: nowrap;
`;

export const EmailPrimaryInfo = styled.div``;

export const EmailSecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mqMax.tablet} {
    align-items: flex-start;

    & > * {
      margin-top: 0.3rem !important;
    }
  }
`;
