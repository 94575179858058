import styled, {css, keyframes} from 'styled-components';

export const modalFadeDuration = 250;

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
  animation: ${fadeIn} ${modalFadeDuration}ms ease;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  ${props =>
    props.open &&
    css`
      opacity: 1;
      pointer-events: initial;
    `};
`;

export const Body = styled.div`
  position: relative;
  width: 100%;
  background: ${props => props.theme.palette.light};
  max-width: 63rem;
  margin: auto;
  padding: 4rem;
`;

export const Cross = styled.div`
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${props => props.theme.palette.dark};
  transition: 0.25s ease color;

  &:hover {
    color: ${props => props.theme.palette.primary};
  }
`;

export const ModalBodyWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: ${props => props.theme.zIndices.modal};
`;
