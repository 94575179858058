// @flow
import type {Notification, NotificationAction} from './actions';

export type NotificationsReducerState = Notification[];

const initialState: NotificationsReducerState = [];

function notificationsReducer(
  state: NotificationsReducerState = initialState,
  action: NotificationAction
): NotificationsReducerState {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return state.concat(action.payload);
    case 'CLOSE_NOTIFICATION':
      return state.filter(x => x !== action.payload);
    default:
      return state;
  }
}

export default notificationsReducer;
