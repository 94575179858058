// @flow
import {Label} from 'componentsStyled/Typography';
import type {Manufacturer} from 'data/manufacturer/types';
import React from 'react';

type Props = {
  data: Manufacturer,
};

const ManufacturerDetail = ({data}: Props) => (
  <div>
    <Label>Name</Label>
    <p>{data.name}</p>
    <Label>Code</Label>
    <p>{data.code || 'None'}</p>
  </div>
);

export default ManufacturerDetail;
