// @flow
import Button from 'components/Button';
import AddBundleComponent from 'containers/Modals/AddBundleComponent';
import {bundleComponentCreateMutation} from 'data/bundle/mutations';
import type {Bundle} from 'data/bundle/types';
import {openModal} from 'data/modals/actions';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

const AddComponent = ({addItem}) => (
  <Button kind="primary" onClick={addItem}>
    Add Item
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: Bundle,
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withMutation(bundleComponentCreateMutation),
  withHandlers({
    addItem: props => () =>
      props.openModal(AddBundleComponent, {
        data: props.data,
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(AddComponent);
