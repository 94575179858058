import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const FormError = styled.div`
  padding: 1rem;
  background: ${props => props.theme.palette.error};
  color: ${props => props.theme.palette.light};
  margin-bottom: 1rem;
`;
