import Loader from 'components/Loader';
import React from 'react';
import {Chart as chartjs, Line} from 'react-chartjs-2';

import theme from '../../global/theme';

/* TODO(T04435): Refactor this into a component so it can be reused + storybook */
const defaultOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = theme.palette.primaryDark;
    tooltipEl.style.border = '2px solid white';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.boxShadow = '0px 8px 28px rgba(0, 0, 0, 0.25)';
    tooltipEl.style.color = 'white';
    tooltipEl.style.display = 'flex';
    tooltipEl.style.flexDirection = 'column';
    tooltipEl.style.alignItems = 'flex-start';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.zIndex = 1;

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, data) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(b => b.lines);
    const tooltipTitle = data.labels[tooltip.title[0]];

    const tooltipTitleEl = document.createElement('h3');
    tooltipTitleEl.style.margin = '0px';
    const text = document.createTextNode(tooltipTitle);
    tooltipTitleEl.appendChild(text);

    const tooltipBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tooltipScaleMarker = document.createElement('span');
      tooltipScaleMarker.style.background = colors.backgroundColor;
      tooltipScaleMarker.style.borderColor = colors.borderColor;
      tooltipScaleMarker.style.borderWidth = '2px';
      tooltipScaleMarker.style.borderRadius = '2px';
      tooltipScaleMarker.style.marginRight = '10px';
      tooltipScaleMarker.style.height = '10px';
      tooltipScaleMarker.style.width = '10px';
      tooltipScaleMarker.style.display = 'inline-block';

      const tooltipScaleLabel = document.createElement('p');
      tooltipScaleLabel.style.backgroundColor = 'inherit';
      tooltipScaleLabel.style.borderWidth = 0;
      tooltipScaleLabel.style.margin = '0px';

      const text = document.createTextNode(body);

      tooltipScaleLabel.appendChild(tooltipScaleMarker);
      tooltipScaleLabel.appendChild(text);
      tooltipBody.appendChild(tooltipScaleLabel);
    });

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    // Add new children
    tooltipEl.appendChild(tooltipTitleEl);
    tooltipEl.appendChild(tooltipBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

/**
 * Custom positioner
 * @function Tooltip.positioners.slideTop
 * @param elements {Chart.Element[]} the tooltip elements
 * @param eventPosition {Point} the position of the event in canvas coordinates
 * @returns {Point} the tooltip position
 */
const tooltipPlugin = chartjs.registry.getPlugin('tooltip');
tooltipPlugin.positioners.slideTop = function (elements, eventPosition) {
  return {
    x: eventPosition.x,
    y: 0,
  };
};

const Chart = ({data, options, loading, width = 1678, height = 838}) => {
  return (
    <div>
      {loading && <Loader />}
      <section>
        {data && (
          <Line
            data={data}
            width={width}
            height={height}
            options={
              {
                ...options,
                plugins: {
                  ...options.plugins,
                  tooltip: {
                    // disabled the default tooltip
                    enabled: false,
                    position: 'slideTop',
                    external: ctx => externalTooltipHandler(ctx, data),
                  },
                },
              } || defaultOptions
            }
          />
        )}
        {!data && <p>No Chart Data Found</p>}
      </section>
    </div>
  );
};

export default Chart;
