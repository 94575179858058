// @flow
import {compose, map, prop, reduce, slice, split} from 'ramda';
import {withRouter as withRouterHOC} from 'react-router';
import {type Component} from 'recompose';

// from: {pathname: "/reset-password", search: "?code=djkhskjdjkhhdksjhk", hash: "", state: undefined}
// into: {code: "djkhskjdjkhhdksjhk"}
export const parseSearchFromLocation: ({search: string}) => {
  [string]: string,
} = compose(
  reduce((acc, [key, value]) => ({...acc, [key]: value}), {}),
  map(split('=')),
  split('&'),
  slice(1, Infinity),
  prop('search')
);

export type RouterLocation = {
  search: string,
  pathname: string,
};

export type Added = {
  history: {
    push: Function,
  },
  match: {
    params: {
      [string]: mixed,
    },
  },
  location: RouterLocation,
};

// we had to redefine it here like this in order to be able to type it
function withRouter<Enhancer>(
  // eslint-disable-next-line no-use-before-define
  Component: Component<{
    ...$Exact<Enhancer>,
    ...$Exact<Added>,
  }>
): Component<Enhancer> {
  return withRouterHOC(Component);
}

export default withRouter;
