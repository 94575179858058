// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import NumberInput from 'components/NumberInput';
import withField from 'hoc/withField';
import React from 'react';

const InputNumber = ({fieldWrapProps, disabled, error, ...props}) => (
  <FieldWrap disabled={disabled} error={error} {...fieldWrapProps}>
    <NumberInput disabled={disabled} {...props} />
  </FieldWrap>
);

export default withField(InputNumber);
