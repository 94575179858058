// @flow
import InputSelect from 'components/Inputs/InputSelect';
import {manufacturerCreateMutation} from 'data/manufacturer/mutations';
import {manufacturerListQuery} from 'data/manufacturer/queries';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

const ManufacturerSelect = ({data, canCreate, submitMutation, ...rest}) => {
  const createProps = canCreate
    ? {
        canCreate,
        onCreateOption: name =>
          submitMutation({
            input: {name},
          }),
      }
    : {};

  return <InputSelect options={data} {...createProps} {...rest} />;
};

type Enhancer = {
  name: string,
  canCreate?: boolean,
};

const enhancer: HOC<*, Enhancer> = compose(
  withQuery(manufacturerListQuery, {noEmpty: true}, undefined, props => ({
    affiliateIds: props.affiliateId,
  })),
  withMutation(manufacturerCreateMutation)
);

export default enhancer(ManufacturerSelect);
