/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef} from 'react';

//Same as `useEffect` but will not trigger on initial mount, only on changes
//to variables in the deps array
const useUpdateEffect = (effects: () => void, deps: any[] | undefined) => {
  if (deps && !deps.length) {
    console.warn(
      "Warning: You have called useUpdateEffect with any empty deps array. Your effect won't fire."
    );
  }

  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return effects();
    }
    didMountRef.current = true;
  }, deps);
};

export default useUpdateEffect;
