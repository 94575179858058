// @flow
import {
  manufacturerCreateMutation,
  manufacturerDeleteMutation,
  manufacturerUpdateMutation,
} from 'data/manufacturer/mutations';
import {manufacturerDetailQuery, manufacturerListQuery} from 'data/manufacturer/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import ManufacturerDetail from './ManufacturerDetail';
import ManufacturerEdit from './ManufacturerEdit';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: ManufacturerEdit,
  updateMutation: manufacturerUpdateMutation,
  detail: ManufacturerDetail,
  detailQuery: manufacturerDetailQuery,
  createMutation: manufacturerCreateMutation,
  deleteMutation: manufacturerDeleteMutation,
  listQuery: manufacturerListQuery,
  tableDefinition,
  title: 'Vendors',
  entityName: 'Vendor',
  route: urls.manufacturer,
});

const enhancer = withHelpCenter({category: 'products'});

export default enhancer(Page);
