// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import Switch from 'components/Switch';
import withField, {type ReqFieldProps} from 'hoc/withField';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const InputSwitch = ({handleChange, ...props}) => (
  <FieldWrap {...props}>
    <Switch {...props} onChange={handleChange} />
  </FieldWrap>
);

const enhancer: HOC<*, ReqFieldProps> = compose(
  withField,
  withHandlers({
    handleChange: props => (v: boolean) => props.onChange(v),
  })
);

export default enhancer(InputSwitch);
