import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

type ReservationStatistic = {
  daysSum: number,
  reservationsCount: number,
};

export const upcomingReservationsQuery: Query<ReservationStatistic[]> = {
  gql: gql`
    query upcomingReservations($filter: ListReservationFilter) {
      reservation {
        list(filter: $filter) {
          data {
            id
            booking {
              bookingId
              start
              end
            }
            status
            productItem {
              id
              code
              affiliate {
                id
                location {
                  id
                  name
                }
              }
              productVariant {
                id
                product {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  selector: ['reservation', 'list', 'data'],
};
