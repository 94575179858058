// @flow
import {stopPropagation} from 'common/events';
import {closeModal} from 'data/modals/actions';
import type {Modal as ModalType} from 'data/modals/reducer';
import {selectModal} from 'data/modals/selectors';
// $ReactHooks
import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {branch, compose, renderNothing} from 'recompose';

import {Body, Cross, ModalBodyWrapper, StyledOverlay} from './styled';

type Outter = {
  modal: ?ModalType,
  closeModal: Function,
};

const Modals = ({modal, closeModal}: Outter) => {
  if (!modal) {
    throw new Error('Expected modal to be defined due to the use of branch');
  }
  const Component = modal.type;
  const escapeListener = useCallback(
    // NOTE(Barry): Kind of gross, but it looks like our version of flow does not have the type that we need for the
    // keyboard input event
    (e: any) => {
      if (e.code === 'Escape' && closeModal) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener('keydown', escapeListener);
    const body = document.body;
    if (body) {
      body.classList.add('overflowHidden');
    }

    return function cleanup() {
      window.removeEventListener('keydown', escapeListener);
      const body = document.body;
      if (body) {
        body.classList.remove('overflowHidden');
      }
    };
  }, [escapeListener]);

  return (
    <ModalBodyWrapper>
      <StyledOverlay onClick={closeModal} open dark />
      <Body onClick={stopPropagation}>
        <Cross onClick={closeModal}>x</Cross>
        <Component {...modal.props} close={closeModal} />
      </Body>
    </ModalBodyWrapper>
  );
};

const mapStateToProps = state => ({
  modal: selectModal(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(props => !props.modal, renderNothing)
)(Modals);
