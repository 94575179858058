// @flow
import type {NotificationType} from 'data/notifications/actions';
import styled, {keyframes} from 'styled-components';

const appear = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;
const disappear = keyframes`
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 100%);
  }
`;

const getColor = props => {
  const type: NotificationType = props.type;

  if (type === 'SUCCESS') {
    return props.theme.palette.success;
  }

  if (type === 'WARNING') {
    return props.theme.palette.warning;
  }

  return props.theme.palette.error;
};

export const NotificationWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 5rem;
  padding: 0 3rem;
  width: 63rem;
  max-width: 100%;
  background: ${getColor};
  color: ${props => props.theme.palette.light};
  transform: translate(-50%, 0);
  z-index: ${props => props.theme.zIndices.notification};
  animation: ${props => (props.closing ? disappear : appear)} 0.25s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 1rem;
  }
`;
