// @flow
import Switch from 'components/Switch';
import {difference, flatten, isEmpty} from 'ramda';
import React from 'react';
import {withHandlers} from 'recompose';

import ProductSelect from './ProductSelect';
import {ListText, RowWrap, StyledLi, StyledUl} from './styled';

const getItemIds = data => {
  const itemIds = data.map(product =>
    product.variants.map(variant => variant.items.map(item => item.id))
  );
  return flatten(itemIds);
};

const InventorySelect = ({handleChange, data, productItemIds, onItemChange, handleAllChange}) => {
  const switchValue = isEmpty(difference(getItemIds(data), productItemIds));
  return (
    <StyledLi>
      <RowWrap>
        <Switch value={switchValue} onChange={handleAllChange} />
        <ListText inline>All products</ListText>
      </RowWrap>
      <StyledUl>
        {data.map((product, index) => (
          <ProductSelect
            key={index}
            data={product}
            productItemIds={productItemIds}
            onChange={handleChange(index)}
            onItemChange={onItemChange}
          />
        ))}
      </StyledUl>
    </StyledLi>
  );
};

export default withHandlers({
  handleChange: props => (k: number) => (value: boolean) => {
    const objects = props.data[k].variants.map(variant =>
      variant.items.map(item => ({
        itemId: item.id,
        value,
      }))
    );
    props.onItemChange(flatten(objects));
  },
  handleAllChange: props => (v: boolean) => {
    const itemIds = v ? getItemIds(props.data) : [];
    props.onChange(itemIds);
  },
})(InventorySelect);
