// @flow
import Button from 'components/Button';
import {CenterContent} from 'componentsStyled/Layout';
import {Label} from 'componentsStyled/Typography';
import PricingTemplateSelect from 'containers/PricingTemplateSelect';
import ProductSelect from 'containers/ProductSelect';
import VariantSelect from 'containers/VariantSelect';
import type {Bundle} from 'data/bundle/types';
import withForm from 'hoc/withForm';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {VariantSelectWrap} from './styled';

const AddBundleComponent = props => {
  return (
    <div>
      <h2>Add Bundle Component</h2>
      <VariantSelectWrap>
        <Label>Product</Label>
        <ProductSelect
          // productId={props.productId}
          name="productId"
          label=""
          onChange={props.setProduct}
          value={props.productId}
        />
        <Label>Variant</Label>
        {props.productId ? (
          <VariantSelect
            productId={props.productId}
            name="variantId"
            label=""
            onChange={props.setVariant}
            value={props.variantId}
          />
        ) : (
          <p>Please select a product.</p>
        )}
      </VariantSelectWrap>
      <PricingTemplateSelect {...props} name="pricingTemplateId" label="Pricing Template" />
      <CenterContent>
        <Button kind="primary" loading={props.isSubmitting} big success type="submit">
          Add
        </Button>
      </CenterContent>
    </div>
  );
};

type Enhancer = {
  close: Function,
  bundleData: Bundle,
};

const enhancer: HOC<*, Enhancer> = compose(
  withStateHandlers(
    () => ({
      productId: undefined,
      variantId: undefined,
    }),
    {
      setProduct: props => v => {
        return {productId: v, variantId: undefined};
      },
      setVariant: props => v => ({variantId: v}),
    }
  ),
  withForm({
    onSubmit: props => values => {
      // No Schema because with form doesn't detect the values in the `VariantSelect`
      // component
      if (!props.productId || !props.variantId) {
        return Promise.resolve();
      } else {
        const transformedValues = {
          bundleId: props.data.id,
          productVariantId: props.variantId,
          pricingTemplateId: values.pricingTemplateId,
        };
        return props.submit({input: transformedValues});
      }
    },
    onSuccess: props => props.close,
  })
);

export default enhancer(AddBundleComponent);
