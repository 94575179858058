// @flow
import Button from 'components/Button';
import InputOpeningHours from 'components/Inputs/InputOpeningHours';
import InputText from 'components/Inputs/InputText';
import Section from 'components/Section';
import CategorySelect from 'containers/CategorySelect';
import LocationsSelect from 'containers/LocationsSelect';
import type {EditProps} from 'factories/createEntityCRUD';
import withForm from 'hoc/withForm';
import DeliveryFulfilmentSection from 'pages/Affiliate/AffiliateEdit/DeliveryFulfilmentSection';
import InStoreFulfilmentSection from 'pages/Affiliate/AffiliateEdit/InStoreFulfilmentSection';
import RentalBuffersSection from 'pages/Affiliate/AffiliateEdit/RentalBuffersSection';
import * as R from 'ramda';
import {assoc, omit, pick} from 'ramda';
import React from 'react';
import type {HOC} from 'recompose';
import {compose, withHandlers} from 'recompose';

import AddressAutocomplete from '../../../../components/Inputs/AddressAutocomplete';
import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../../components/Inputs/InputFileUpload';
import {
  INLINE_FORM_VALUES_FOR_FULFILLMENT_AND_DELIVERY,
  mapAffiliateFormValuesToUpdateParams,
} from '../affiliateFormUtil';
import schema from './schema';

const AffiliateCreate = ({isSubmitting, onChangeCoordinates, values, setFieldToZeroIfEmpty}) => (
  <div>
    <Section title="Store details">
      <InputText name="name" placeholder="name" label="name" />
      <InputText name="description" placeholder="description" label="description" />
      <InputText name="email" placeholder="email" label="email" />
      <InputText name="phone" placeholder="phone" label="phone" />
      <InputText name="website" placeholder="website" label="website" />
      <AddressAutocomplete
        name="address"
        label="address"
        types={['address']}
        onChangeCoordinates={onChangeCoordinates}
      />
      <LocationsSelect name="locationId" label="location" />
      <CategorySelect name="categoryIds" label="categories" isMulti canCreate />
      <InputFileUpload
        name="logo"
        label="logo"
        bucket="affiliateImages"
        accept={IMAGE_MIME_TYPE}
        data-cy="upload-store-logo"
      />
      <InputFileUpload
        name="images"
        label="images"
        bucket="affiliateImages"
        multiple
        accept={IMAGE_MIME_TYPE}
        data-cy="upload-store-images"
      />
    </Section>
    <Section title="Opening hours" data-cy="opening-hours">
      <InputOpeningHours name="openingHours" label="opening hours" />
      <InputOpeningHours name="specialOpeningDates" label="special opening dates" special />
    </Section>
    <InStoreFulfilmentSection formValues={values} setFieldToZeroIfEmpty={setFieldToZeroIfEmpty} />
    <DeliveryFulfilmentSection formValues={values} setFieldToZeroIfEmpty={setFieldToZeroIfEmpty} />
    <RentalBuffersSection formValues={values} setFieldToZeroIfEmpty={setFieldToZeroIfEmpty} />
    <Button
      kind="primary"
      loading={isSubmitting}
      big
      success
      type="submit"
      data-cy={'create-store'}
    >
      Create store
    </Button>
  </div>
);

const buildInput = values => {
  const withKey = assoc('cancelHrAfterCreate', 0);
  const transformedValues = withKey(values);
  // $Fixme
  transformedValues.logo = transformedValues.logo[0].url;
  // $Fixme
  transformedValues.images = transformedValues.images.map(image => ({
    url: image.url,
  }));
  return {
    // $Ramda
    ...omit(['coordinates'], transformedValues),
    // $Ramda
    ...pick(['lat', 'lng', 'inTimezone'], transformedValues.coordinates),
  };
};

// $Dunno - TODO(Jude): Touching this in location PR
const enhancer: HOC<*, EditProps> = compose(
  withForm({
    schema,
    mapProps: () => ({
      openingHours: {
        mon: {closed: true, hours: null},
        tue: {closed: true, hours: null},
        wed: {closed: true, hours: null},
        thu: {closed: true, hours: null},
        fri: {closed: true, hours: null},
        sun: {closed: true, hours: null},
        sat: {closed: true, hours: null},
      },
      specialOpeningDates: [],
      //In-store fulfilment
      allowInStoreFulfilment: false,
      allowInStoreBookingExtension: false,
      inStoreExtensionCutoffDays: 0,
      //Delivery Fulfilment
      allowDeliveryFulfilment: false,
      allowDeliveryBookingExtension: false,
      deliveryExtensionCutoffDays: 0,
      deliveryCharge: 0,
      enableDeliveryChargeThreshold: false,
      deliveryChargeThreshold: 0,
      //Rental Buffers
      preBookingBufferDays: 0,
      postBookingBufferDays: 0,
      automaticallyEndPostProcessing: true,
      enableDeliveryBuffers: false,
      outboundBufferDays: 0,
      inboundBufferDays: 0,
    }),
    onSubmit: props => values => {
      const reshapedValues = {
        ...values,
        ...mapAffiliateFormValuesToUpdateParams(values),
      };

      const submittedValues = R.omit(
        INLINE_FORM_VALUES_FOR_FULFILLMENT_AND_DELIVERY,
        reshapedValues
      );

      return props.submit({
        input: buildInput(submittedValues),
      });
    },
  }),
  withHandlers({
    onChangeCoordinates:
      ({setFieldValue}) =>
      value => {
        setFieldValue('coordinates', {...value});
      },
    setFieldToZeroIfEmpty:
      ({setFieldValue, values}) =>
      fieldName => {
        //Purposefully using double equals to capture undefined
        if (values[fieldName] == null) {
          setFieldValue(fieldName, 0);
        }
      },
  })
);

export default enhancer(AffiliateCreate);
