import {optional, required} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  advancedHeader: required,
  logo: optional,
  logoLink: optional,
  headerHtml: optional,
  headerCss: optional,
  footerHtml: optional,
  footerCss: optional,
  primaryAccentColor: required,
  primaryAccentDarkColor: required,
  primaryTextColor: required,
  secondaryTextColor: required,
  backgroundColor: required,
  buttonBorderRadius: required,
  filterButtonBorderRadius: required,
  inputBorderRadius: required,
  externalFontCssUrl: optional,
  bodyFontFamily: required,
  headingFontFamily: required,
  enableStoreCarousel: optional,
});
