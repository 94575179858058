import ReactQuill from 'react-quill';
import styled, {css} from 'styled-components';

export const StyledReactQuill = styled(ReactQuill)`
  //Generic transition of properties for disabled on/off transition
  &,
  .ql-editor,
  .ql-toolbar * {
    transition: all ${props => props.theme.durations.inputColors};
  }

  ${props =>
    props.readOnly &&
    css`
      background: ${props => props.theme.palette.greyLighter};

      //Because the editor can have multimedia, various colours, etc. it
      //makes more sense to set an opacity filter to achieved the disabled look.
      .ql-editor {
        filter: opacity(0.3);
      }

      //non-button elements in the toolbar aren't disabled even when set to readonly.
      //So we put an invisible element in front of the toolbar so they can't be interacted with
      .ql-toolbar {
        position: relative;

        &::before {
          position: absolute;
          inset: 0;
          content: '';
          z-index: 2;
        }
      }

      .ql-toolbar * {
        cursor: default !important;
        color: ${props => props.theme.palette.grey} !important;

        svg {
          .ql-stroke {
            stroke: ${props => props.theme.palette.grey} !important;
          }

          .ql-fill {
            fill: ${props => props.theme.palette.grey} !important;
          }
        }
      }
    `}
`;
