// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import type {AppConfig} from './types';

export const appConfigQuery: Query<AppConfig> = {
  gql: gql`
    query appConfigQuery {
      app {
        getConfig {
          tenantName
          tenantTitle
          enableBundles
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['app', 'getConfig'],
};
