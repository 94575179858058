// @flow
import DayPicker from 'components/DayPicker';
import type {FilterConfig} from 'components/Filter';
import Select from 'components/Select';
import Switch from 'components/Switch';
import TextInput from 'components/TextInput';
import AffiliateSelectNoForm from 'containers/AffiliateSelectNoForm';

const filterConfig: FilterConfig = {
  query: {
    type: TextInput,
    props: {
      placeholder: 'Search by Code...',
    },
  },
  affiliateId: {
    type: AffiliateSelectNoForm,
    props: {
      placeholder: 'Select store',
      isFilter: true,
    },
  },
  status: {
    // $Filter
    type: Select,
    props: {
      placeholder: 'Reservation status',
      isFilter: true,
      options: [
        {id: 'confirmed', name: 'confirmed'},
        {id: 'finished', name: 'finished'},
        {id: 'checked_out', name: 'checked out'},
        {id: 'cancelled', name: 'cancelled'},
      ],
    },
  },
  start: {
    type: DayPicker,
    props: {
      placeholder: 'Start date',
    },
  },
  end: {
    // $Filter
    type: DayPicker,
    props: {
      placeholder: 'End date',
      end: true,
    },
  },
  includeDeleted: {
    // $Filter
    type: Switch,
    props: {
      filterLabel: 'Include finished reservations',
      small: true,
    },
  },
};

export default filterConfig;
