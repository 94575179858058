// @flow
import gql from 'graphql-tag';
import type {Mutation} from 'types/Graphql';

import {paymentDetailQuery} from './queries';

export const refundPaymentMutation: Mutation<
  {
    paymentId: number,
    amount: number,
  },
  {}
> = {
  gql: gql`
    mutation refundPaymentMutation($paymentId: Int!, $amount: Int!) {
      payment {
        refundPayment(paymentId: $paymentId, amount: $amount) {
          payment {
            id
            refundAmount
          }
        }
      }
    }
  `,
  refetch: [paymentDetailQuery],
};
