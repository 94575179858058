// @flow
import './style.css';

import {formatDate, parseDate} from 'data/units/date/formatters';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {type HOC, withHandlers} from 'recompose';

const DayPicker = ({id, value, onBlur, handleDayChange, placeholder, ...props}) => {
  return (
    <DayPickerInput
      // Props that will be passed through to the underlying input component
      inputProps={{
        // formix requires an id on each input to track state.
        id,
        onChange: event => {
          // Stop the default propagation action on the input
          // When a user types in the input the onChange is fired with each
          // keypress which will trigger the data update, however we only
          // want to do this once a valid date has been entered. We will defer
          // the update to the blur handler.
          event.stopPropagation();
        },
        onBlur: event => {
          // When focus is lost handle the input in the field as you would a
          // day change from the DayPicker Overlay.
          handleDayChange(event.currentTarget.value);
        },
      }}
      dayPickerProps={{
        // Overwrite the onBlur handler applied to the DayPicker Overlay
        onBlur: event => {
          // Stop propagation of the onBlur event.
          // This is required because formik uses the blur event to detect
          // form changes however the DayPicker Overlay is not part of our
          // form so we do not want its events triggering formik
          event.stopPropagation();
        },
      }}
      value={value ? formatDate(value) : ''}
      placeholder={placeholder || 'YYYY-MM-DD'}
      onDayChange={handleDayChange}
      parseDate={parseDate}
    />
  );
};

type Enhancer = {
  value?: string,
  onChange: Function,
  placeholder?: string,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleDayChange: props => (day: string) => {
    // If the 'day' is a valid date
    if (day && !isNaN(Date.parse(day))) {
      // Propagate the valid date up in ISO format
      props.onChange(new Date(formatDate(day)).toISOString());
    } else {
      // Propagate invalid value up, this will show an 'Invalid date' message
      // If invalid or clear it if empty/undefined
      props.onChange(day);
    }
  },
});

export default enhancer(DayPicker);
