// @flow
import {pathOr} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import Button from '../../components/Button';
import Section from '../../components/Section';
import TextEditorHtmlViewerWindowControls from '../../components/TextEditorHtmlViewerWindowControls';
import {saveLegalMutation} from '../../data/legal/mutations';
import {getLegalQuery} from '../../data/legal/queries';
import withForm from '../../hoc/withForm';
import withMutation from '../../hoc/withMutation';
import withQuery from '../../hoc/withQuery';
import schema from './schema';

const SettingsLegalPage = ({values}) => {
  return (
    <div>
      <h1>Rental Agreement</h1>
      <Section includeTitle={false}>
        <TextEditorHtmlViewerWindowControls
          name={'rentalAgreement'}
          previewValue={values['rentalAgreement']}
          className={'collapseTopMargin'}
        />
      </Section>
      <Button kind="primary">Save</Button>
    </div>
  );
};

const enhancer: HOC<*, *> = compose(
  withQuery(getLegalQuery),
  withMutation(saveLegalMutation),
  withForm({
    schema,
    mapProps: props => ({
      rentalAgreement: pathOr('', ['data', 'rentalAgreement'], props),
    }),
    onSubmit: props => values => props.submitMutation({input: {...values}}),
  })
);

export default enhancer(SettingsLegalPage);
