// @flow
import {FromProp} from 'helpers/FromProp';
import styled, {css} from 'styled-components';

export const StyledFieldWrap = styled(FromProp)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2.4rem;
  width: 100%;
  min-height: 7.2rem;

  ${props =>
    props.alignment === 'right' &&
    css`
      align-items: flex-end;
      ${StyledFieldMessage} {
        right: 0;
        left: auto;
      }
    `}

  ${props => {
    if (props.position === 'right') {
      return css`
        flex-direction: row;
        align-items: center;

        label {
          order: 1;
          margin: 0 0 0 1.6rem;
        }
      `;
    }
  }};

  // do not used &:disabled as this & is a div
  &[disabled] {
    label {
      color: ${props => props.theme.palette.grey};
    }
  }
`;

export const StyledFieldMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`;
