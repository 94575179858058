// @flow
import {userDetailQuery, userListQuery} from 'data/user/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import CustomerDetail from './CustomerDetail';
import filterConfig from './filterConfig';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  detail: CustomerDetail,
  detailQuery: userDetailQuery,
  listQuery: userListQuery,
  filterConfig,
  tableDefinition,
  title: 'Customers',
  entityName: 'Customer',
  route: urls.customers,
});

const enhancer = withHelpCenter({category: 'users'});

export default enhancer(Page);
