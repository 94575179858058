// @flow
export type Environment = 'development' | 'dev1' | 'uat1' | 'prod1';

const prefix = 'REACT_APP_';

const keys = {
  ENVIRONMENT: 'ENVIRONMENT',
  API_URI: 'API_URI',
  GOOGLE_MAPS_API_KEY: 'GOOGLE_MAPS_API_KEY',
  SENTRY_ADMIN_APP_DSN: 'SENTRY_ADMIN_APP_DSN',
  TENANT_TITLE: 'TENANT_TITLE',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  BRAND_URL: 'AWS_BRAND_URL',
};

const getConfig = (key: string, defaultValue: string, hasPrefix = true): string => {
  const prefixedKey = `${prefix}${key}`;
  if (window.APP_CONFIG && window.APP_CONFIG[prefixedKey]) {
    return window.APP_CONFIG[prefixedKey];
  } else if (process.env[prefixedKey] && hasPrefix) {
    return process.env[prefixedKey];
  } else if (process.env[key] && !hasPrefix) {
    return process.env[key];
  } else {
    return defaultValue;
  }
};

const environment: Environment = (getConfig(keys.ENVIRONMENT, 'development'): any);
const apiUri = getConfig(keys.API_URI, window.location.origin);
const googleMapsApiKey = getConfig(keys.GOOGLE_MAPS_API_KEY, '');
const sentryDsn = getConfig(keys.SENTRY_ADMIN_APP_DSN, '');
const tenantTitle = getConfig(keys.TENANT_TITLE, '');
const featureFlags = getConfig(keys.FEATURE_FLAGS, '');
const brandUrl = getConfig(keys.BRAND_URL, '');

/* eslint-disable no-undef */
const config = {
  environment,
  api: `${apiUri}/api/graphql`,
  rest: `${apiUri}/api/rest`,
  ws: `${apiUri}/subscriptions`,
  googleMapsApiKey,
  sentryDsn,
  tenantTitle,
  brandUrl,
  featureFlags: {
    enableBuyIt: featureFlags.split(',').includes('buy-it'),
  },
};

window.CONFIG = config;

export default config;
