// @flow
import Button from 'components/Button';
import InputDayPicker from 'components/Inputs/InputDayPicker';
import InputSelect from 'components/Inputs/InputSelect';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import InputTime from 'components/Inputs/InputTime';
import EmailProjectionActionSelect from 'containers/EmailProjectionActionSelect';
import type {EmailProjectionFilter} from 'data/email/types';
import {EMAIL_PROJECTION_ACTIONS} from 'data/email/types';
import withForm from 'hoc/withForm';
import useQueryParam from 'hooks/useQueryParam';
import {
  booleanParamTransformer,
  dateStringParamTransformer,
  timeParamTransformer,
  timezoneParamTransformer,
} from 'hooks/useQueryParam/transformers';
// $Import
import * as moment from 'moment-timezone';
import schema from 'pages/Email/EmailProjection/EmailProjectionList/EmailProjectionFilters/schema';
import {
  StyledFilterGroup,
  StyledFiltersSection,
} from 'pages/Email/EmailProjection/EmailProjectionList/EmailProjectionFilters/styled';
import * as React from 'react';
// $Import
import {useEffect} from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

type Props = {
  submit: EmailProjectionFilter => any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  values: EmailProjectionFilter,
};

const transformFromActionParam = action => {
  if (action && EMAIL_PROJECTION_ACTIONS.includes(action)) {
    return action;
  }
  return null;
};

const allZones = moment.tz.names().map(zone => ({id: zone, name: zone}));

const EmailProjectionFilters = ({setFieldValue, values}: Props) => {
  const showRunAsLive = values.actionToDebug === 'scheduleWatchdogPayments';

  useQueryParam({
    value: values.bookingId,
    setValue: value => setFieldValue('bookingId', value),
    paramName: 'id',
  });

  useQueryParam({
    value: values.date,
    setValue: value => setFieldValue('date', value),
    paramName: 'd',
    ...dateStringParamTransformer,
  });

  useQueryParam({
    value: values.time,
    setValue: value => setFieldValue('time', value),
    paramName: 't',
    ...timeParamTransformer,
  });

  useQueryParam({
    value: values.timezone,
    setValue: value => setFieldValue('timezone', value),
    paramName: 'tz',
    ...timezoneParamTransformer,
  });

  useQueryParam({
    value: values.actionToDebug,
    setValue: value => setFieldValue('actionToDebug', value),
    paramName: 'a',
    transformFromParam: transformFromActionParam,
  });

  useQueryParam({
    value: values.runLive,
    setValue: value => setFieldValue('runLive', value),
    paramName: 'rl',
    ...booleanParamTransformer,
  });

  //Clear runAsLive if it's not rendered
  useEffect(() => {
    if (!showRunAsLive) {
      setFieldValue('runLive', undefined);
    }
  }, [showRunAsLive, setFieldValue]);

  return (
    <>
      <StyledFiltersSection>
        <StyledFilterGroup>
          <InputDayPicker label="Date" name="date" placeholder="Enter date..." />
          <InputTime label="Time" name="time" />
          <InputSelect label="Timezone" name="timezone" options={allZones} />
        </StyledFilterGroup>
        <StyledFilterGroup>
          <EmailProjectionActionSelect label="Debuggable Action" name="actionToDebug" />
          {showRunAsLive && <InputSwitch name="runLive" label="Run As Live" />}
        </StyledFilterGroup>
        <InputText
          label="Booking ID (optional)"
          name="bookingId"
          placeholder="Enter Booking ID..."
        />
      </StyledFiltersSection>
      <Button kind="primary">Generate</Button>
    </>
  );
};

const enhancer: HOC<*, Props> = compose(
  withForm({
    schema,
    onSubmit: props => async values => {
      props.submit(values);
    },
  })
);

export default enhancer(EmailProjectionFilters);
