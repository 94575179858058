// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {UserMinimal} from 'data/user/types';
import urls from 'pages/urls';
import {prop} from 'ramda';

import Pill from '../../../../components/Pills';
import {getPillPropsFromRole} from '../../../../data/user/helpers';

export const tableDefinition: TableDefinition<UserMinimal> = {
  columns: [
    {
      title: 'email',
      value: prop('email'),
    },
    {
      title: 'role',
      value: prop('role'),
      type: Pill,
      props: v => getPillPropsFromRole(v.role),
    },
  ],
  rowLink: v => urls.user.link.detail(v.id),
};
export default tableDefinition;
