// @flow
import {BottomSpace} from 'componentsStyled/Typography';
import * as React from 'react';
import {type HOC, withStateHandlers} from 'recompose';

import {TabSwitch, TabSwitchLink} from './styled';

const Tabs = ({setActiveTabIndex, activeTabIndex, tabs}) => (
  <div>
    <TabSwitch>
      {Object.keys(tabs).map((tab, index) => (
        <TabSwitchLink
          key={index}
          active={activeTabIndex === index}
          onClick={() => setActiveTabIndex(index)}
        >
          {tab}
        </TabSwitchLink>
      ))}
    </TabSwitch>
    <BottomSpace />
    {tabs[Object.keys(tabs)[activeTabIndex]]}
  </div>
);

type Outter = {
  tabs: {[string]: React.Node},
  startingIndex?: number,
};

const enhancer: HOC<*, Outter> = withStateHandlers(
  props => ({
    activeTabIndex: props.startingIndex || 0,
  }),
  {
    setActiveTabIndex: () => activeTabIndex => ({activeTabIndex}),
  }
);

export default enhancer(Tabs);
