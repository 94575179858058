import {optional, required} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  id: optional,
  name: required,
  lat: required,
  lng: required,
  countryId: required,
});
