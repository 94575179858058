import {formatToCentum} from 'data/units/money/formatters';

/**
 * Maps inline form values in to the shape required by the affiliate create and update mutations where expected values
 * are submitted as nested objects, i.e. fulfillment and delivery
 */
export function mapAffiliateFormValuesToUpdateParams(values) {
  return {
    fulfillment: {
      inStore: {
        enabled: values.allowInStoreFulfilment,
        extendable: {
          enabled: values.allowInStoreBookingExtension,
          cutoff: values.inStoreExtensionCutoffDays,
        },
        fulfillmentInstructions: values.inStoreFulfilmentInstructions,
      },
      delivery: {
        enabled: values.allowDeliveryFulfilment,
        extendable: {
          enabled: values.allowDeliveryBookingExtension,
          cutoff: values.deliveryExtensionCutoffDays,
        },
        fulfillmentInstructions: values.deliveryFulfilmentInstructions,
        charge: {
          amount: formatToCentum(values.deliveryCharge || 0),
          threshold: {
            enabled: values.enableDeliveryChargeThreshold,
            amount: formatToCentum(values.deliveryChargeThreshold || 0),
          },
        },
        deliverySummary: values.deliverySummary,
        logisticsSummary: values.logisticsSummary,
        deliveryExplanation: values.deliveryExplanation,
        returnInstructions: values.returnInstructions,
      },
    },
    buffers: {
      processing: {
        pre: values.preBookingBufferDays,
        post: values.postBookingBufferDays,
        autoEnd: values.automaticallyEndPostProcessing,
      },
      delivery: {
        enabled: values.enableDeliveryBuffers,
        inboundTransitDays: values.inboundBufferDays,
        outboundTransitDays: values.outboundBufferDays,
      },
    },
  };
}

/**
 * Collection of inline variables from the create and edit affiliate form that are used to populate the fulfillment and
 * buffer objects. These variables should be stripped from the data the is sent to the backend
 */
export const INLINE_FORM_VALUES_FOR_FULFILLMENT_AND_DELIVERY = [
  'allowInStoreFulfilment',
  'allowInStoreBookingExtension',
  'inStoreExtensionCutoffDays',
  'inStoreFulfilmentInstructions',
  'allowDeliveryFulfilment',
  'allowDeliveryBookingExtension',
  'deliveryExtensionCutoffDays',
  'deliveryFulfilmentInstructions',
  'deliveryCharge',
  'enableDeliveryChargeThreshold',
  'deliveryChargeThreshold',
  'deliverySummary',
  'logisticsSummary',
  'deliveryExplanation',
  'returnInstructions',
  'preBookingBufferDays',
  'postBookingBufferDays',
  'automaticallyEndPostProcessing',
  'enableDeliveryBuffers',
  'inboundBufferDays',
  'outboundBufferDays',
];
