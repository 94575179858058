import type {Environment} from 'global/config';

/**
 * Checks that `role` appears in `requiredRoles`
 * @param requiredRoles - List of roles user must have at least one of
 * @param role - Role of user
 * @returns {boolean}
 */
export const hasSufficientRoles = (requiredRoles: string[], role: string): boolean =>
  requiredRoles.includes(role);

/**
 * Checks that `environment` appears in `requiredEnvironments`.
 * Returns true if `requiredEnvironments` is falsy.
 * @param requiredEnvironments - List of environments
 * @param environment
 * @returns {boolean}
 */
export const hasSufficientEnvironment = (
  requiredEnvironments: ?(Environment[]),
  environment: Environment
) => !requiredEnvironments || requiredEnvironments.includes(environment);
