import styled, {css} from 'styled-components';

import {hoverStyle} from '../../../componentsStyled/Shared';
import {FromProp} from '../../../helpers/FromProp';
import {linker} from '../../../hoc/linker';

export const StyledHeader = styled.header`
  padding: 0 4rem;
  height: 7.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  background-color: ${props => props.theme.palette.light};
  border-bottom: 0.1rem solid ${props => props.theme.palette.grey};
`;

export const TenantLogoDivider = styled.div`
  height: 3.2rem;
  width: 0.1rem;
  background-color: ${props => props.theme.palette.grey};
  margin: 0 1.6rem;
`;
export const TenantLogo = styled.img`
  position: relative;
  max-height: 3.4rem;
  width: auto;
  transition: transform 250ms ease-in;

  &:hover {
    transform: scale(1.1);
  }
`;

export const HeaderSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
  ${props =>
    props.isOpen &&
    css`
      display: block;
    `}
`;
export const Avatar = styled.p`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.dark};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  color: ${props => props.theme.palette.light};
`;

export const MenuToggle = styled.div`
  cursor: pointer;
  transition: transform 250ms ease-in;

  ${props =>
    props.isOpen
      ? css`
          ${props => props.theme.rotation.open};
        `
      : css`
          ${props => props.theme.rotation.close};
        `}
`;
export const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${props => props.theme.palette.light};
  border-radius: 1.6rem;
  ${props => props.theme.boxShadows.heavy};
  width: 20rem;
  transition: opacity 250ms ease-in 250ms;

  ${props =>
    props.isOpen
      ? css`
          opacity: 1;
          z-index: ${props => props.theme.zIndices.header};
        `
      : css`
          opacity: 0;
          z-index: ${props => props.theme.zIndices.under};
        `}
`;

export const MenuItem = linker(styled(FromProp)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.8rem;
  text-decoration: none;
  color: ${props => props.theme.palette.dark};
  transition: color 0.25s ease, background 0.25s ease;

  &:hover {
    ${hoverStyle}
  }
`);
