import styled, {css} from 'styled-components';

import {Link} from '../../../componentsStyled/Typography';

export const ControlBarWrap = styled.nav`
  display: inline-flex;

  > *:not(:first-child) {
    margin-left: 2.4rem;
  }

  ${props =>
    props.top &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}

  ${props =>
    props.update &&
    css`
      position: absolute;
      bottom: 0;
      margin-left: 11rem;
    `}
`;

export const GoBack = styled(Link)`
  color: ${props => props.theme.palette.greyDarker};
  font-weight: normal;
  position: absolute;
  top: -2rem;

  :hover {
    text-decoration: none;
  }
`;
