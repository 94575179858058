// @flow
import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'componentsStyled/Typography';
import {openModal} from 'data/modals/actions';
import withMutation from 'hoc/withMutation';
import {path} from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

import EditTenantAffiliatesModal from '../../../../containers/Modals/TenantAffiliates/TenantAffiliatesEdit';
import {tenantAffiliateUpdateMutation} from '../../../../data/tenant-affiliate/mutations';
import {type TenantAffiliate} from '../../../../data/tenant-affiliate/types';
import withTooltip from '../../../../hoc/withTooltip';

const EditItemLink = ({editItem}) => (
  <Link onClick={editItem} component="a" redirect={false}>
    {' '}
    <FontAwesomeIcon icon={faEdit} size="lg" data-tip="Edit" />
  </Link>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: TenantAffiliate,
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withTooltip,
  withMutation(tenantAffiliateUpdateMutation),
  withHandlers({
    editItem: props => () =>
      props.openModal(EditTenantAffiliatesModal, {
        data: {
          id: path(['data', 'id'], props),
          tenantId: path(['data', 'tenantId'], props),
          affiliateId: path(['data', 'affiliateId'], props),
          manufacturerIds: path(['data', 'manufacturerIds'], props),
        },
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(EditItemLink);
