// @flow
import {paymentDetailQuery, paymentListQuery} from 'data/payment/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import PaymentDetail from './PaymentDetail';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  detail: PaymentDetail,
  detailQuery: paymentDetailQuery,
  listQuery: paymentListQuery,
  tableDefinition,
  title: 'Payments',
  entityName: 'Payment',
  route: urls.payment,
});

const enhancer = withHelpCenter({category: 'renting'});

export default enhancer(Page);
