import styled, {css} from 'styled-components';

export const TabSwitch = styled.div`
  display: flex;
  margin-top: 2.5rem;
`;

export const TabSwitchLink = styled.p`
  position: relative;
  cursor: pointer;
  margin-right: 2rem;

  &::after {
    content: '';
    left: 0;
    right: 0;
    bottom: -0.3rem;
    height: 0.2rem;
    background: ${props => props.theme.palette.primary};
    transition: transform 0.25s ease;
    transform: scaleX(0);
    position: absolute;
  }

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.palette.dark};
      cursor: default;

      &::after {
        transform: scaleX(1);
      }
    `};
`;
