// @flow

import {setLightness} from 'polished';

const primary = 'hsl(170, 79%, 37%)';
const success = 'hsl(153, 57%, 36%)';
const warning = 'hsl(27, 84%, 57%)';
const error = 'hsl(0, 76%, 57%)';

const lightnessDark = 0.17;
const lightnessLight = 0.97;

const theme = {
  lighten: setLightness,
  palette: {
    // Brand
    primary,
    primaryDark: setLightness(lightnessDark, primary).toUpperCase(),
    primaryLight: setLightness(lightnessLight, primary).toUpperCase(),

    // Colors intended to have connotations attached
    success,
    successDark: setLightness(lightnessDark, success).toUpperCase(),
    successLight: setLightness(lightnessLight, success).toUpperCase(),
    warning,
    warningDark: setLightness(lightnessDark, warning).toUpperCase(),
    warningLight: setLightness(lightnessLight, warning).toUpperCase(),
    error,
    errorDark: setLightness(lightnessDark, error).toUpperCase(),
    errorLight: setLightness(lightnessLight, error).toUpperCase(),

    dark: 'hsl(220, 26%, 14%)',
    greyDarker: 'hsl(218, 17%, 35%)',
    greyDarker35: 'hsla(218, 17%, 35%, 0.35)',
    greyDark: 'hsl(216, 15%, 52%)',
    grey: 'hsl(214, 20%, 69%)',
    greyLight: 'hsl(214, 32%, 91%)',
    greyLighter: 'hsl(204, 45%, 98%)',
    light: 'hsl(0, 0%, 100%)',
  },
  zIndices: {
    under: -1,
    minimal: 1,
    header: 2,
    modal: 3,
    notification: 4,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  boxShadows: {
    heavy: 'box-shadow: 0 0.8rem 2.8rem rgba(0, 0, 0, 0.25)',
  },
  rotation: {
    open: 'transform: rotateZ(180deg)',
    close: 'transform: rotateZ(0)',
  },
  metrics: {
    tableRowHeight: '4.8rem',
    buttonHeight: '4rem',
  },
  durations: {
    inputColors: '250ms',
  },
};

export default theme;
