// @flow
import Button from 'components/Button';
import styled from 'styled-components';

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInputWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > * {
    margin-right: 2rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  margin-right: 1rem;
`;
