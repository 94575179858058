// @flow
import Button from 'components/Button';
import InputProductVariantVariableOption from 'components/Inputs/InputProductVariantVariableOption';
import type {Product} from 'data/product/types';
import withForm from 'hoc/withForm';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
  variantVariables: string[],
};

const VariantCreate = ({isSubmitting, variantVariables}: Props) => {
  return (
    <div>
      <h2>Variant Creation</h2>
      <InputProductVariantVariableOption
        name="name"
        label={variantVariables.length > 1 ? 'Variant Variables' : ''}
        productLabels={variantVariables}
      />
      <Button kind="primary" loading={isSubmitting}>
        Create Variant
      </Button>
    </div>
  );
};

type Enhancer = {
  data: Product,
};

const enhancer: HOC<*, Enhancer> = compose(
  withProps(props => ({
    variantVariables: props.data.variantVariables ? props.data.variantVariables.split(' | ') : [''],
  })),
  withForm({
    schema,
    mapProps: props => {
      const nbLabels: number = props.variantVariables.length;
      return {
        ...props.data,
        name: Array(nbLabels).fill(''),
      };
    },
    onSubmit: props => values => {
      const trimmedNames = values.name.map(name => name.trim());
      const variantName = trimmedNames.join(' | ');
      return props.submit({id: values.id, name: variantName});
    },
    onSuccess: props => props.close,
  })
);

export default enhancer(VariantCreate);
