import {optional, required} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  id: optional,
  name: required,
  title: required,
  website: optional,
  helloEmailAddress: required,
  bccEmailAddress: optional,
  isEmailVerified: optional,
  allowed: optional,
  groupReservations: optional,
  showMinimalProductTileInfo: optional,
  areBundlesEnabled: optional,
});
