// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {accessoryFragment, paginatedAccessoryFragment} from './fragments';
import type {Accessory} from './types';

export const accessoriesListQuery: Query<Accessory[]> = {
  gql: gql`
    query accessoriesListQuery {
      accessory {
        list(filter: {limit: 999}) {
          ...paginatedAccessoryFragment
        }
      }
    }
    ${paginatedAccessoryFragment}
  `,
  selector: ['accessory', 'list', 'data'],
};

export const accessoriesDetailQuery: Query<Accessory> = {
  gql: gql`
    query accessoriesDetailQuery($id: Int!) {
      accessory {
        get(id: $id) {
          ...accessoryFragment
        }
      }
    }
    ${accessoryFragment}
  `,
  selector: ['accessory', 'get'],
};
