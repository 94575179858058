// @flow
// @author: russholio
// Date: 28/7/21

import type {TableDefinition} from 'components/Table/types';
import type {ReservationFull} from 'data/reservations/types';
import urls from 'pages/urls';
import {path, prop} from 'ramda';

// $Dunno
const tableDefinition: TableDefinition<ReservationFull> = {
  columns: [
    {
      title: 'name',
      value: path(['productItem', 'productVariant', 'product', 'name']),
    },
    {
      title: 'location',
      value: path(['productItem', 'affiliate', 'location', 'name']),
    },
    {
      title: 'status',
      value: prop('status'),
    },
  ],
  rowLink: v => urls.reservation.link.detail(v.id ? v.id : 0),
};

export default tableDefinition;
