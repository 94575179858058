// @flow
import styled from 'styled-components';

import {StyledInput} from '../../../componentsStyled/Forms';

export const HourWrap = styled.div`
  margin: 0 1rem;

  input[type='time'] {
    cursor: pointer;
  }
`;

export const StyledInputTime = styled(StyledInput)`
  padding: 0 1rem 0 2rem;
`;
