// @flow
import {safelyGetRenderableText} from 'common/formatters';
import {type Notification, closeNotification} from 'data/notifications/actions';
import * as React from 'react';

import NotificationIcon from './NotificationIcon';
import {NotificationWrap} from './styled';

type Props = {
  notification: Notification,
  closeNotification: typeof closeNotification,
};

type State = {
  closing: boolean,
};

class NotificationItem extends React.Component<Props, State> {
  state = {closing: false};
  // $Dunno
  timer: NodeJS.Timer = null;

  componentDidMount() {
    this.timer = setTimeout(this.close, 2000);
  }

  close = () => {
    // already being closed
    if (this.state.closing) return;

    // clear timeout, in case this was invoked via click
    clearTimeout(this.timer);
    this.setState({closing: true});
    setTimeout(() => this.props.closeNotification(this.props.notification), 250);
  };

  render() {
    const {type, text} = this.props.notification;
    const {closing} = this.state;
    return (
      <NotificationWrap onClick={this.close} type={type} closing={closing}>
        <NotificationIcon type={type} />
        {safelyGetRenderableText(text)}
      </NotificationWrap>
    );
  }
}

export default NotificationItem;
