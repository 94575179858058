// @flow
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
  faBalanceScale,
  faBookAlt,
  faBooks,
  faBoxes,
  faBusinessTime,
  faCalendar,
  faCog,
  faConveyorBelt,
  faEnvelope,
  faFileInvoiceDollar,
  faInventory,
  faMailBulk,
  faMapMarked,
  faPaintRoller,
  faPassport,
  faQuestionCircle,
  faSunglasses,
  faTachometer,
  faTag,
  faTags,
  faToolbox,
  faUsdCircle,
  faUserCrown,
  faUsers,
  faUsersCog,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasSufficientEnvironment, hasSufficientRoles} from 'common/permissions';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import type {Environment} from 'global/config';
import globalConfig from 'global/config';
import enums from 'global/enums';
import urls from 'pages/urls';
import React from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router-dom';

import {SidebarWrap, StyledSecondLevelItem, StyledTopLevelItem, StyledTopLevelMenu} from './styled';

const iconSize = 'lg';

const LOCAL_DEV_ONLY: Environment[] = ['development'];
const NOT_PROD: Environment[] = ['development', 'dev1', 'uat1'];

export type SideBarNames =
  | 'Accessories'
  | 'Analytics'
  | 'Bundles'
  | 'Buttons'
  | 'Customers'
  | 'Dashboard'
  | 'Email'
  | 'Email Log'
  | 'Email Projection'
  | 'Email Templates'
  | 'File Upload'
  | 'Formatters'
  | 'Help'
  | 'Inputs'
  | 'Inventory'
  | 'Rental Agreement'
  | 'Locations'
  | 'Vendors'
  | 'Marketing'
  | 'Palette'
  | 'Payments'
  | 'Pills'
  | 'Pricing Templates'
  | 'Categories'
  | 'Products'
  | 'Promo Codes'
  | 'Reservations'
  | 'Settings'
  | 'Stores'
  | 'Storybook'
  | 'Table'
  | 'Tenants'
  | 'Tooltip'
  | 'Theme'
  | 'Typography'
  | 'Users';

type SideBarItem = {
  name: SideBarNames,
  to: string,
  icon: IconProp,
  roles: Array<string>,
  environments?: Environment[],
};

export type SideBarTopLevelItem = SideBarItem & {
  subItems?: Array<SideBarItem & {description?: string}>,
};

export const generateSideBar = (appConfig: ?AppConfig): Array<SideBarTopLevelItem> => {
  const productSubItems = [];

  if (appConfig && appConfig.enableBundles) {
    productSubItems.push({
      name: 'Bundles',
      to: urls.bundle.index,
      icon: faBoxes,
      roles: [enums.roles.ADMIN],
    });
  }

  return [
    {
      name: 'Storybook',
      to: urls.storybook.index,
      icon: faBooks,
      environments: LOCAL_DEV_ONLY,
      roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
      subItems: [
        {
          name: 'Palette',
          to: urls.storybook.palette,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Typography',
          to: urls.storybook.typography,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Inputs',
          to: urls.storybook.inputs,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'File Upload',
          to: urls.storybook.fileUpload,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Pills',
          to: urls.storybook.pills,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Table',
          to: urls.storybook.table,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Tooltip',
          to: urls.storybook.tooltip,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
        {
          name: 'Formatters',
          to: urls.storybook.formatters,
          icon: faBookAlt,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.ADMIN, enums.roles.SUPERADMIN],
        },
      ],
    },
    {
      name: 'Email',
      to: urls.email,
      icon: faEnvelope,
      roles: [enums.roles.SUPERADMIN],
      subItems: [
        {
          name: 'Email Templates',
          to: urls.emailTemplates.index,
          icon: faPassport,
          environments: LOCAL_DEV_ONLY,
          roles: [enums.roles.SUPERADMIN],
          description: 'List of email templates',
        },
        {
          name: 'Email Log',
          to: urls.emailLogs.index,
          icon: faMailBulk,
          roles: [enums.roles.SUPERADMIN],
          description: 'Log of sent emails',
        },
        {
          name: 'Email Projection',
          to: urls.emailProjection.index,
          icon: faBusinessTime,
          environments: NOT_PROD,
          roles: [enums.roles.SUPERADMIN],
          description: 'List of scheduled emails',
        },
      ],
    },
    {
      name: 'Dashboard',
      to: urls.dashboard.index,
      icon: faTachometer,
      roles: [enums.roles.ADMIN],
    },
    {
      name: 'Products',
      to: urls.product.index,
      icon: faTag,
      roles: [enums.roles.ADMIN],
      subItems: productSubItems,
    },
    {
      name: 'Reservations',
      to: urls.reservation.index,
      icon: faCalendar,
      roles: [enums.roles.ADMIN],
      subItems: [
        {
          name: 'Payments',
          to: urls.payment.index,
          icon: faFileInvoiceDollar,
          roles: [enums.roles.ADMIN],
        },
        {
          name: 'Customers',
          to: urls.customers.link.list,
          icon: faUsers,
          roles: [enums.roles.ADMIN],
        },
      ],
    },
    {
      name: 'Settings',
      to: urls.settings,
      icon: faCog,
      roles: [enums.roles.ADMIN],
      subItems: [
        {
          name: 'Categories',
          to: urls.category.index,
          icon: faTags,
          roles: [enums.roles.ADMIN],
          description: 'View, update or add new categories',
        },
        {
          name: 'Locations',
          to: urls.location.index,
          icon: faMapMarked,
          roles: [enums.roles.ADMIN],
          description: 'View, add or update locations',
        },
        {
          name: 'Vendors',
          to: urls.manufacturer.index,
          icon: faConveyorBelt,
          roles: [enums.roles.ADMIN],
          description: 'View, add or update your vendors',
        },
        {
          name: 'Stores',
          to: urls.shop.index,
          icon: faToolbox,
          roles: [enums.roles.ADMIN],
          description: 'View and update your store details',
        },
        {
          name: 'Accessories',
          to: urls.accessories.index,
          icon: faSunglasses,
          roles: [enums.roles.ADMIN],
          description: 'View, add or update your product accessories',
        },
        {
          name: 'Pricing Templates',
          to: urls.pricingTemplate.index,
          icon: faUsdCircle,
          roles: [enums.roles.ADMIN, enums.roles.AFFILIATE, enums.roles.AFFILIATE_EMPLOYEE],
          description: 'View, update or add new pricing templates',
        },
        {
          name: 'Theme',
          to: urls.theme,
          icon: faPaintRoller,
          roles: [enums.roles.ADMIN],
          description: 'Update your webstore colors, fonts and header',
        },
        {
          name: 'Users',
          to: urls.user.index,
          icon: faUsersCog,
          roles: [enums.roles.ADMIN],
          description: 'View or update your users',
        },
        {
          name: 'Rental Agreement',
          to: urls.settingsLegal,
          icon: faBalanceScale,
          roles: [enums.roles.ADMIN],
          description: 'View and manage your rental agreement',
        },
      ],
    },
    {
      name: 'Inventory',
      to: urls.inventory.index,
      icon: faInventory,
      roles: [enums.roles.AFFILIATE, enums.roles.AFFILIATE_EMPLOYEE],
    },
    {
      name: 'Tenants',
      to: urls.tenant.index,
      icon: faUserCrown,
      roles: [enums.roles.SUPERADMIN],
    },
    {
      name: 'Help',
      to: urls.userGuide,
      icon: faQuestionCircle,
      roles: [
        enums.roles.AFFILIATE,
        enums.roles.AFFILIATE_EMPLOYEE,
        enums.roles.ADMIN,
        enums.roles.SUPERADMIN,
      ],
    },
  ];
};

type Location = {
  pathname: string,
};

type Props = {
  role: string,
  location: Location,
  appConfig: ?AppConfig,
};

const TopLevelItem = ({
  location,
  role,
  item,
}: {
  location: Location,
  role: string,
  item: SideBarTopLevelItem,
}) => {
  const isActive = matchPath(location.pathname, {path: item.to});
  const hasActiveSubItem =
    item.subItems &&
    item.subItems.find(subItem => matchPath(location.pathname, {path: subItem.to}));
  const hasSubItems = item.subItems && item.subItems.length > 0;
  const isExpanded = hasSubItems && (isActive || hasActiveSubItem);

  return (
    <StyledTopLevelMenu isExpanded={isExpanded}>
      <StyledTopLevelItem key={item.name} to={item.to}>
        {item.icon && <FontAwesomeIcon icon={item.icon} size={iconSize} />}
        {item.name}
      </StyledTopLevelItem>
      {isExpanded &&
        item.subItems &&
        item.subItems
          .filter(
            subItem =>
              hasSufficientRoles(subItem.roles, role) &&
              hasSufficientEnvironment(subItem.environments, globalConfig.environment)
          )
          .map(subItem => <SecondLevelItem item={subItem} />)}
    </StyledTopLevelMenu>
  );
};

const SecondLevelItem = ({item}: {item: SideBarItem}) => {
  return (
    <StyledSecondLevelItem key={item.name} to={item.to}>
      {item.name}
    </StyledSecondLevelItem>
  );
};

const Sidebar = (props: Props) => {
  const sideBarItems = generateSideBar(props.appConfig);

  return (
    <SidebarWrap data-cy={'sidebar'}>
      {sideBarItems
        .filter(
          item =>
            hasSufficientRoles(item.roles, props.role) &&
            hasSufficientEnvironment(item.environments, globalConfig.environment)
        )
        .map(item => (
          <TopLevelItem location={props.location} role={props.role} item={item} />
        ))}
    </SidebarWrap>
  );
};

const mapStateToProps = state => ({
  location: state.router.location,
  appConfig: selectAppConfig(state),
});

export default connect(mapStateToProps, {})(Sidebar);
