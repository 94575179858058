// @flow
import {locationCreateMutation, locationUpdateMutation} from 'data/location/mutations';
import {locationDetailQuery, locationListQuery} from 'data/location/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import LocationDetail from './LocationDetail';
import LocationEdit from './LocationEdit';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: LocationEdit,
  updateMutation: locationUpdateMutation,
  detail: LocationDetail,
  detailQuery: locationDetailQuery,
  listQuery: locationListQuery,
  createMutation: locationCreateMutation,
  tableDefinition,
  title: 'Locations',
  entityName: 'Location',
  route: urls.location,
});

const enhancer = withHelpCenter({category: 'stores'});

export default enhancer(Page);
