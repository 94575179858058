import type {TableDefinition} from 'components/Table/types';
import type {Email} from 'data/email/types';

const tableDefinition: TableDefinition<Email> = {
  columns: [
    {
      title: 'Tenant',
      value: v => v.tenant.name,
    },
    {
      title: 'To',
      value: v => v.to,
    },
    {
      title: 'From',
      value: v => v.from,
    },
    {
      title: 'BCC',
      value: v => v.bcc,
    },
    {
      title: 'Subject',
      value: v => v.subject,
    },
    {
      title: 'Sent',
      value: v => v.createdAt,
    },
    {
      title: 'Template',
      value: v => v.type,
    },
  ],
};

export default tableDefinition;
