// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {tenantFragment} from './fragments';
import type {Tenant} from './types';

export const tenantListQuery: Query<Tenant[]> = {
  gql: gql`
    query tenantListQuery {
      tenant {
        list {
          ...tenantFragment
        }
      }
    }
    ${tenantFragment}
  `,
  selector: ['tenant', 'list'],
  debounceKey: 'tenantListQuery',
};

export const tenantDetailQuery: Query<Tenant> = {
  gql: gql`
    query tenantQuery($id: Int!) {
      tenant {
        getById(id: $id) {
          ...tenantFragment
        }
      }
    }
    ${tenantFragment}
  `,
  selector: ['tenant', 'getById'],
};
