import styled from 'styled-components';

export const OpeningHoursTable = styled.div`
  margin-bottom: 3rem;
`;

/* TODO(t04435): ADMIN-UI remove once layout helpers are defined */
export const Pair = styled.div`
  display: flex;
`;

export const Key = styled.p`
  width: 11rem;
`;

export const Val = styled.p`
  flex-grow: 1;
`;
