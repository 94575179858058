// @flow
// @author: t04435
// Date: 8/7/21

import ImageItem from 'components/ImageItem';
import type {TableDefinition} from 'components/Table/types';
import type {Accessory} from 'data/accessories/types';
import urls from 'pages/urls';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<Accessory> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'icon',
      value: prop('url'),
      type: ImageItem,
      props: v => ({
        url: v.url,
      }),
    },
  ],
  rowLink: v => urls.accessories.link.detail(v.id),
};

export default tableDefinition;
