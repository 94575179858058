// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {BottomSpace} from 'componentsStyled/Typography';
import CategorySelect from 'containers/CategorySelect';
import ManufacturerSelect from 'containers/ManufacturerSelect';
import withForm from 'hoc/withForm';
import {clone, path, pathOr} from 'ramda';
// $Import
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import type {HOC} from 'recompose';
import {compose, withHandlers} from 'recompose';

import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../components/Inputs/InputFileUpload';
import Switch from '../../../components/Inputs/InputSwitch';
import Section from '../../../components/Section';
import Table from '../../../components/Table';
import TextEditor from '../../../components/TextEditor';
import {LayoutFlex} from '../../../componentsStyled/Layout';
import VariantCreate from '../../../containers/Modals/VariantCreate';
import variantsTableDefinition from '../../../containers/Modals/VariantCreate/variantsTableDefinition';
import {openModal} from '../../../data/modals/actions';
import type {Product} from '../../../data/product/types';
import {productVariantCreateMutation} from '../../../data/productVariant/mutations';
import type {ProductVariantTableDefintion} from '../../../data/productVariant/types';
import withMutation from '../../../hoc/withMutation';
import schema from './schema';

const ProductEdit = ({isSubmitting, create, data, createVariant, values, errors}: Enhancer) => {
  // State used to maintain the disabled stats on Visibility
  const [visibilityDisabled, setVisibilityDisabled] = useState(true);

  // Disables the Visibility Field and set it to Hidden,
  // if description or images fields are invalid
  useEffect(() => {
    const {description, images, categories} = values;
    const requiredFieldsHaveErrors = !(
      description &&
      description.length > 0 &&
      images &&
      images.length > 0 &&
      categories &&
      categories.length > 0
    );
    setVisibilityDisabled(requiredFieldsHaveErrors);
    if (requiredFieldsHaveErrors) {
      values.isVisible = false;
    }
  }, [values]);

  // check if the variant variable names are consistent with the labels
  const getVariantVariablesCount = () =>
    data.variantVariables ? data.variantVariables.split(' | ').length : 1;
  const getVariantsInfo = (): ProductVariantTableDefintion[] =>
    data.variants.map(variant => {
      const variantCount = variant.name.split(' | ').length;
      return {
        ...variant,
        isConsistent: variantCount === getVariantVariablesCount(),
        variantVariables: data.variantVariables ? data.variantVariables : null,
      };
    });

  return (
    <>
      <Section title={'product details'}>
        <LayoutFlex>
          <LayoutFlex col half>
            <InputText name="name" label="name" />
          </LayoutFlex>
          <LayoutFlex col half alignItemsRight>
            <Switch
              name={'isVisible'}
              label={'Visible'}
              disabled={visibilityDisabled}
              alignment={'right'}
            />
          </LayoutFlex>
        </LayoutFlex>
        <LayoutFlex>
          <TextEditor
            name={'description'}
            label={'Description'}
            useAdvancedToolbar
            allowVideo
            hint={'A Description is required to make a product Visible.'}
          />
        </LayoutFlex>
        <LayoutFlex>
          <LayoutFlex col half>
            <ManufacturerSelect name="manufacturerId" label="Vendor" canCreate />
          </LayoutFlex>
          <LayoutFlex col half>
            <CategorySelect name="categories" label="category" canCreate />
          </LayoutFlex>
        </LayoutFlex>
        <LayoutFlex>
          <InputFileUpload
            name="images"
            label="images"
            bucket="productImages"
            multiple
            accept={IMAGE_MIME_TYPE}
            hint={'An Image is required to make a product Visible.'}
          />
        </LayoutFlex>
      </Section>
      {!create && (
        <Section title={'variants'}>
          <Table tableDefinition={variantsTableDefinition} data={getVariantsInfo()} />
          <BottomSpace />
          <Button kind="primary" onClick={createVariant} success type={'button'}>
            Add variant
          </Button>
        </Section>
      )}
      <Button kind="primary" loading={isSubmitting}>
        {create ? 'Create product' : 'Save'}
      </Button>
    </>
  );
};

const buildInput = values => {
  if (values.images && values.images.length > 0) {
    values.images = values.images.map((image, i) => ({
      url: image.url,
      id: image.id,
      order: i,
      primary: i === 0,
      alt: `${values.name}-${i}`,
    }));
  }
  return values;
};

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: Product,
  isSubmitting: boolean,
  create: boolean,
  createVariant: Function,
  // This Object have the shape of the schema
  values: Object,
  // This Object have the shape of the schema
  errors: Object,
};

const enhancer: HOC<*, Enhancer> = compose(
  withMutation(productVariantCreateMutation),
  connect(null, mapDispatchToProps),
  withHandlers({
    createVariant: props => () =>
      props.openModal(VariantCreate, {
        data: props.data,
        submit: props.submitMutation,
      }),
  }),
  withForm({
    schema,
    mapProps: props => {
      // Currently only supporting 1 category assignment
      // TODO Remove when multiple category assignment is implemented
      const category = path(['data', 'categories'], props);
      return {
        ...props.data,
        // TODO Remove when multiple category assignment is implemented
        categories: category && category[0] && category[0].categoryId,
        manufacturerId: path(['data', 'manufacturer', 'id'], props),
        variantVariables:
          props.data && props.data.variantVariables ? props.data.variantVariables.split(' | ') : [],
        description: pathOr('', ['data', 'description'], props),
        isVisible: pathOr(false, ['data', 'isVisible'], props),
      };
    },
    onSubmit: props => values => {
      const newValues = clone(values);
      newValues.variantVariables = newValues.variantVariables.map(name => name.trim());
      newValues.variantVariables = newValues.variantVariables.join(' | ');
      // TODO Edit when multiple category assignment is implemented
      newValues.categories = newValues.categories ? [newValues.categories] : undefined;
      return props.submit({input: buildInput(newValues)});
    },
  })
);

export default enhancer(ProductEdit);
