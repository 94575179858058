import {Card} from 'componentsStyled/Card';
import styled from 'styled-components';

export const SectionWrap = styled(Card)`
  & > h4 {
    margin: 0;
    padding: 0;
    color: ${props => props.theme.palette.greyDark};
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;
