import {Hint} from 'componentsStyled/Typography';
import theme from 'global/theme';
import styled from 'styled-components';

export const AccessoriesWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Error = styled(Hint)`
  color: ${props => props.theme.palette.error};
  min-height: 1.1em;
  margin-top: 0.5rem;
`;
export const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-left: 1rem;
    &:hover {
      color: ${theme.palette.greyDark};
    }
  }
`;
