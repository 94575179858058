// @flow
import gql from 'graphql-tag';
import type {Mutation} from 'types/Graphql';

import {getThemeQuery} from './queries';
import type {ThemeInput} from './types';

export const saveThemeMutation: Mutation<ThemeInput, boolean> = {
  gql: gql`
    mutation saveThemeMutation($input: ThemeInput!) {
      theme {
        saveTheme(input: $input)
      }
    }
  `,
  selector: ['theme', 'saveTheme'],
  refetch: [getThemeQuery],
};
