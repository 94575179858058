// @flow
// @author: t04435
// Date: 8/7/21

import {formatBool} from 'common/formatters';
import type {TableDefinition} from 'components/Table/types';
import type {Affiliate} from 'data/affiliate/types';
import urls from 'pages/urls';
import {path, prop} from 'ramda';

const tableDefinition: TableDefinition<Affiliate> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'location',
      // $Ramda
      value: path(['location', 'name']),
    },
    {
      title: 'Default',
      value: v => formatBool(v.isDefaultShop),
    },
  ],
  rowLink: v => urls.shop.link.detail(v.id),
};

export default tableDefinition;
