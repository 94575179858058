// @flow
import gql from 'graphql-tag';

export const locationFragment = gql`
  fragment locationFragment on Location {
    id
    name
    lat
    lng
    country {
      id
      name
      currency
    }
  }
`;
