// @flow
import crypto from 'global/apolloClient/crypto';
import enums from 'global/enums';
import urls from 'pages/urls';
import * as React from 'react';
import {Redirect} from 'react-router-dom';

/**
 * Redirects user to the appropriate landing page depending on role
 */
const LandingRedirect = () => {
  const role = crypto.getRoleFromAccessToken();

  // Landing page defaults for roles
  if (role === enums.roles.AFFILIATE || role === enums.roles.AFFILIATE_EMPLOYEE) {
    return <Redirect to={urls.inventory.index} />;
  } else if (role === enums.roles.ADMIN) {
    return <Redirect to={urls.dashboard.index} />;
  } else if (role === enums.roles.SUPERADMIN) {
    return <Redirect to={urls.tenant.index} />;
  } else {
    // Unexpected role - do nothing
    return <div />;
  }
};

export default LandingRedirect;
