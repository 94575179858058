// @flow
import Table from 'components/Table';
import type {ReservationTransaction} from 'data/reservations/types';
import React from 'react';

import tableDefinition from './tableDefinition';

type Props = {|
  data: ReservationTransaction[],
|};

const ReservationPaymentsTable = ({data}: Props) => (
  <Table tableDefinition={tableDefinition} data={data} />
);

export default ReservationPaymentsTable;
