// @flow
import usePrevious from 'hooks/usePrevious';
// $ReactHooks
import {useEffect, useMemo} from 'react';

export type Props<T> = {|
  value: T,
  setValue?: (value: T) => void,
  paramName: string,
  transformToParam?: (a: any) => any,
  transformFromParam?: (a: any) => any,
|};

/**
 * Used to sync state with query params
 * @param paramName - The name of the parameter that appears in the URL query params
 * @param value - The value that is watched and placed into the URL query params
 * @param setValue - function used to set value. This is called with the query param value
 * on mount in order to initialise state from query params.
 * @param transformToParam - (Optional). Used to transform value into a form compatible with
 * query params (e.g. value is a Date object, we can transform it into formatted string 'YYYY-MM-DD').
 * @param transformFromParam - (Optional). Use to transform the param from the URL into the form
 * that value is expected to be (e.g. the URL Param is a date string ('YYYY-MM-DD') we can call the
 * Date constructor to transform it into a Date object before setting it.
 */
function useQueryParam<T>({
  value,
  setValue,
  paramName,
  transformToParam = value => value,
  transformFromParam = value => value,
}: Props<T>) {
  const transformedValue = useMemo(() => transformToParam(value), [transformToParam, value]);
  const prevParamName = usePrevious(paramName);

  //on mount set the value from the query param
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get(paramName);
    const transformedParam = transformFromParam(param);

    if (transformedParam && setValue) {
      setValue(transformedParam);
    }
    // Disabled as we only want to run effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set query param when value changes or paramName changes
  useEffect(() => {
    const {search, origin, pathname, hash} = window.location;
    const queryParams = new URLSearchParams(search);

    //If the param name has changed then remove the old one
    if (prevParamName !== paramName && queryParams.has(prevParamName)) {
      queryParams.delete(prevParamName);
    }

    //Set the new value or remove it if it's now empty
    if (transformedValue) {
      queryParams.set(paramName, transformedValue);
    } else {
      queryParams.delete(paramName);
    }

    //Add the query params to the URL if they exist
    const paramString = queryParams.toString();
    const newRoute = `${origin}${pathname}${paramString ? `?${paramString}` : ''}${hash}`;
    window.history.replaceState({}, '', newRoute);
  }, [paramName, transformedValue, prevParamName]);
}

export default useQueryParam;
