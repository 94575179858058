// @flow

import {FromProp} from 'helpers/FromProp';
import {linker} from 'hoc/linker';
import styled, {css} from 'styled-components';

const marginBottom = css`
  margin-bottom: 3rem;
`;

export const BottomSpace = styled.div`
  ${marginBottom};
`;

export const Link = linker(
  styled(FromProp)`
    color: ${props => props.theme.palette.primary};
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    ${props =>
      props.p &&
      css`
        color: ${props =>
          props.p === 'error' ? props.theme.palette.greyDark : props.theme.palette.grey};
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      `};

    ${props =>
      props.active &&
      css`
        color: ${props => props.theme.palette.dark};
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      `};
  `
);

/* TODO(t04435): ADMIN_UI add props for success/error */
export const Hint = styled.small`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: ${props => props.theme.palette.grey};
`;

export const Error = styled(Hint)`
  color: ${props => props.theme.palette.error};
`;

export const Label = styled.label`
  min-width: 5rem;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 2.4rem;
  font-size: 1.4rem;
  margin-bottom: 5px;
  transition: color ${props => props.theme.durations.inputColors};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.palette.grey};
    `};
`;

export const Helper = styled.p`
  color: ${props => props.theme.palette.greyDark};
  font-size: 1.2rem;
  margin-top: 5px;
`;

export const Number = styled.p`
  margin-top: 0.1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.palette.light};
`;

/* TODO(t04435): ADMIN_UI this does not belong here */
export const CircleBorder = styled.div`
  margin-left: 1rem;
  line-height: 2.1rem;
  background-color: ${props => props.theme.palette.error};
  border-radius: 100%;
  height: 2.3rem;
  width: 2.3rem;
  text-align: center;
`;

// Starts migration to new brand from marketing(Ma) webflow into Admin.
export const TitleMa = styled.h1`
  font-size: 3.6rem;
  font-family: 'Merriweather', serif;
`;

//Limits text to the provided number of lines, defaults to 1
export const maxLines = css`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.maxLines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
