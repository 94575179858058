// @flow
import gql from 'graphql-tag';

// NOTE(Barry): Not using bookingFragment here because there's a circular reference when you dig all the way down to the
// user details. I decided not to address it because I only want a few fields to fill out the reservations table on the
// payment detail page.
export const paymentFragment = gql`
  fragment paymentFragment on Payment {
    id
    chargeToken
    currency
    reservations {
      id
      externalId
      booking {
        bookingId
        start
        end
        externalId
        order {
          orderId
          externalId
          customer {
            id
            user {
              id
              email
            }
          }
        }
      }
      status
      pricingDetail {
        totalPrice
      }
    }
    lateFees {
      booking {
        bookingId
        start
        end
        externalId
        order {
          orderId
          externalId
          customer {
            id
            user {
              id
              email
            }
          }
        }
      }
    }
    damages {
      id
      reservation {
        booking {
          externalId
        }
      }
    }
    status
    amount
    refundAmount
    paidAt
    tax
  }
`;

export const minimalPaymentFragment = gql`
  fragment minimalPaymentFragment on Payment {
    id
    chargeToken
    currency
    status
    amount
    refundAmount
    paidAt
    tax
  }
`;
