// @flow
import Section from 'components/Section';
import React from 'react';

type Props = {
  data: string,
};

const EmailTemplateDetail = ({data}: Props) => (
  <>
    <Section>
      <p>Some emails can display either pickup information or delivery information</p>
      <p>
        To see the delivery version of the current email, postfix the id in the url with
        '-delivery.'
      </p>
      <p>For example '/0-delivery' </p>
      <p>If the email template has a delivery configuration, then changes will be shown.</p>
      <p>If not (or delivery is on by default), the email returned will not be any different.</p>
    </Section>
    <Section>
      <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{__html: data}} />
    </Section>
  </>
);

export default EmailTemplateDetail;
