// @flow
import {getRouterVariables} from 'common/graphql/routing';
import {transformResponse} from 'common/graphql/utils';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import NotFoundPage from 'pages/NotFound';
import {always, compose, identity} from 'ramda';
import {graphql} from 'react-apollo';
import {type Component, branch, renderComponent} from 'recompose';
// import { type PageInfo } from "data/other/types"
import {type Query} from 'types/Graphql';

import {getDebounce} from './debounce';
import {getFilterVariables} from './filtering';
import {mergeData, mergeSubscriptionData, notFound} from './helpers';
import {getPageInfo, getPaginationVariables} from './pagination';

type AddedProps<T> = {
  data: T,
  loading: boolean,
  refetch: Function,
};

type Config<Outter, Variables> = {
  noLoader?: boolean,
  noEmpty?: boolean,
  variables?: Outter => Variables,
  config?: {
    skip: Outter => boolean,
  },
  hasStringId?: boolean,
};

function withQuery<Outter, Data, Variables>(
  query: Query<Data, Variables>,
  configuration: Config<Outter, Variables> = {},
  subscription?: Query<Data>,
  customFilter?: (props: any) => {} = always({})
): (
  // $Dunno
  Component<{...$Exact<AddedProps<Q>>, ...$Exact<Outter>}>
) => Component<Outter> {
  const {
    gql,
    transform = identity,
    selector = [],
    pagination = false,
    defaultFilter = {},
    debounceKey,
    fetchPolicy = 'cache-first',
    defaultVariables = () => {},
  } = query;

  const {
    noLoader = false,
    noEmpty = false,
    variables = always({}),
    config = {},
    hasStringId = false,
  } = configuration;

  const hoc = graphql(gql, {
    props: ({data, ...rest}) => ({
      data: transformResponse(selector, transform, pagination)(data),
      refetch: () => data.refetch(),
      notFound: notFound(data),
      loading: data.loading,
      loadMore: () =>
        pagination &&
        !data.loading &&
        getPageInfo(selector, data).hasNextPage &&
        data.fetchMore({
          variables: {
            filter: {
              ...getPaginationVariables(selector, pagination, data),
              ...getFilterVariables(rest),
              ...defaultFilter,
            },
          },
          ...getDebounce(debounceKey),
          updateQuery: (prev, {fetchMoreResult}) => mergeData(prev, fetchMoreResult, selector),
        }),
      subscribeToNew: () =>
        subscription &&
        data.subscribeToMore({
          document: subscription.gql,
          updateQuery: (prev, {subscriptionData}) =>
            mergeSubscriptionData(
              prev,
              subscriptionData.data,
              selector,
              subscription && subscription.selector ? subscription.selector : []
            ),
        }),
    }),
    options: props => ({
      notifyOnNetworkStatusChange: true, // to update loading when fetching more
      fetchPolicy,
      variables: {
        ...getRouterVariables(props, hasStringId),
        ...defaultVariables(),
        ...variables(props),
        filter: {
          ...getPaginationVariables(selector, pagination),
          ...getFilterVariables(props),
          ...defaultFilter,
          ...customFilter(props),
        },
      },
      ...getDebounce(debounceKey),
    }),
    ...config,
  });

  // $Ramda
  return compose(
    hoc,
    ...(noLoader ? [] : [withLoader()]),
    branch(x => x.notFound, renderComponent(NotFoundPage)),
    ...(noEmpty ? [] : [withEmpty()])
  );
}

export default withQuery;
