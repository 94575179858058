// @flow
import {reservationFragmentMinimal} from 'data/reservations/fragments';
import gql from 'graphql-tag';

export const userPublicFragmentMinimal = gql`
  fragment userPublicFragmentMinimal on UserPublic {
    id
    email
    role
    profiles {
      firstName
      lastName
    }
  }
`;

export const userFragmentMinimal = gql`
  fragment userFragmentMinimal on User {
    id
    email
  }
`;

export const customerFragmentMinimal = gql`
  fragment customerFragmentMinimal on Customer {
    id
    user {
      ...userFragmentMinimal
    }
  }
  ${userFragmentMinimal}
`;
export const productRatingFragment = gql`
  fragment productRatingFragment on Rating {
    id
    productId
    value
  }
`;

export const profileFragment = gql`
  fragment profileFragment on Profile {
    id
    userId
    firstName
    lastName
    isPrimary
    userGender {
      id
      name: value
    }
    birthdate
    phoneNumber
    height
    weight
    bootLength
    din
    waveType {
      id
      name: value
    }
    surfStance {
      id
      name: value
    }
    snowboardStance {
      id
      name: value
    }
    frontfootRotation
    backfootRotation
    stanceWidth
    stanceOffset
    skillLevel {
      id
      name: value
    }
    skierType {
      id
      name: value
    }
    ratings {
      ...productRatingFragment
    }
    units
  }
  ${productRatingFragment}
`;

export const userFragmentFull = gql`
  fragment userFragmentFull on User {
    id
    email
    role
    profiles {
      ...profileFragment
    }
    allowedCategories
    customer {
      id
      payments {
        id
        chargeToken
        currency
        paidAt
        reservations {
          booking {
            externalId
          }
        }
        damages {
          reservation {
            booking {
              externalId
            }
          }
        }
        lateFees {
          booking {
            externalId
          }
        }
        status
        amount
        refundAmount
      }
      orders {
        orderId
        bookings {
          bookingId
          start
          end
          reservations {
            ...reservationFragmentMinimal
          }
        }
      }
    }
    phoneNumber
  }
  ${profileFragment}
  ${reservationFragmentMinimal}
`;
