// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {Payment} from 'data/payment/types';
import {formatDate} from 'data/units/date/formatters';
import {formatCurrencyFromCents} from 'data/units/money/formatters';
import {sortCurrency} from 'data/units/money/helpers';
import urls from 'pages/urls';
import * as R from 'ramda';
import {prop} from 'ramda';

import Pill from '../../components/Pills';
import {getPillPropsFromTableValue} from '../../components/Table/helper';

const getBookingCode = (v: Payment) => {
  if (v.reservations.length > 0) {
    return R.uniq(v.reservations.map(r => r.booking.externalId)).join(', ');
  }

  if (v.damages.length > 0) {
    return R.uniq(v.damages.map(d => d.reservation.booking.externalId)).join(', ');
  }

  if (v.lateFees.length > 0) {
    return R.uniq(v.lateFees.map(lf => lf.booking.externalId)).join(', ');
  }

  return undefined;
};

const tableDefinition: TableDefinition<Payment> = {
  columns: [
    {
      title: 'id',
      value: prop('id'),
    },
    {
      title: 'charge token',
      value: prop('chargeToken'),
    },
    {
      title: 'amount',
      value: ({amount, currency}) => formatCurrencyFromCents(amount, undefined, currency),
      sortFunction: sortCurrency,
    },
    {
      title: 'currency',
      value: prop('currency'),
    },
    {
      title: 'refund amount',
      value: ({refundAmount, currency}) =>
        formatCurrencyFromCents(refundAmount, undefined, currency),
      sortFunction: sortCurrency,
    },
    {
      title: 'status',
      value: prop('status'),
      type: Pill,
      props: v => getPillPropsFromTableValue(v.status),
    },
    {
      title: 'paid at',
      value: ({paidAt}) => (paidAt ? formatDate(paidAt) : '-'),
    },
    {
      title: 'booking code',
      value: getBookingCode,
    },
    // Temporarily disabled while pending booking-centric view created within admin
    // {
    //   title: 'description',
    //   // $Ramda
    //   value: ({details}) => pathOr('N/A', ['additionalInfo'], details),
    // },
  ],
  rowLink: v => urls.payment.link.detail(v.id),
};
export default tableDefinition;
