// @flow
import {HelpCenterCategories} from 'data/helpCenter/constants';
import {setHelpCenterWidgetCategory} from 'data/helpCenter/widget';
import withOnMount from 'hoc/withOnMount';
import {type HOC} from 'recompose';

/**
 * Adds interactions with the HelpCenter widget.
 */
function withHelpCenter<T>(options: {category: $Keys<typeof HelpCenterCategories>}): HOC<T, T> {
  return withOnMount(() => {
    setHelpCenterWidgetCategory(HelpCenterCategories[options.category]);
  });
}

export default withHelpCenter;
