// @flow
import gql from 'graphql-tag';

export const tenantAffiliatesFragment = gql`
  fragment tenantAffiliatesFragment on TenantAffiliate {
    id
    tenantId
    tenantName
    affiliateId
    affiliateName
    manufacturerIds
    access
  }
`;
