// @flow
import type {ProductParameter} from 'data/productVariant/types';
import {propOr} from 'ramda';

export const getValueBasedOnType = (x: ProductParameter) => {
  if (x.type === 'float_value') return x.floatValue;
  if (x.type === 'enum_value') return x.enumValues.join(', ');
  if (x.type === 'string_value') return x.stringValue;
  if (x.type === 'int_value') return x.intValue;

  return '';
};

const formatParamValue = (x: ProductParameter) =>
  // $Ramda
  `${getValueBasedOnType(x)}${propOr('', 'unit', x)}`;

export const formatParam = (x: ProductParameter) => `${x.label || x.name}: ${formatParamValue(x)}`;
