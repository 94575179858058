// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {locationDetailQuery, locationListQuery} from './queries';

export const locationCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation locationCreateMutation($input: LocationCreateInput!) {
      location {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [locationListQuery],
  selector: ['location', 'create'],
};

export const locationUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation locationUpdateMutation($input: LocationUpdateInput!) {
      location {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [locationDetailQuery],
};
