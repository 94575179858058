// @flow
import Button from 'components/Button';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import AffiliateSelect from 'containers/AffiliateSelect';
import withForm from 'hoc/withForm';
import React from 'react';
import {type HOC} from 'recompose';

import schema from './schema';

const AddProductItem = props => (
  <div>
    <h2>Add stock item</h2>
    <AffiliateSelect name="affiliateId" label="Store" />
    <InputText name="code" placeholder="A unique asset identifier" label="Asset ID" />
    <InputSwitch name="allowed" label="Available" />
    <Button kind="primary" loading={props.isSubmitting} big success type="submit">
      save
    </Button>
  </div>
);

type Enhancer = {
  close: Function,
};

const enhancer: HOC<*, Enhancer> = withForm({
  schema,
  onSubmit: props => values => {
    const transformedValues = {
      productVariantId: props.data.productVariantId,
      allowed: values.allowed,
      affiliateId: values.affiliateId,
      code: values.code,
    };

    return props.submit({input: transformedValues});
  },
  onSuccess: props => props.close,
});

export default enhancer(AddProductItem);
