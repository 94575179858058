// @flow
import Checkbox from 'components/Checkbox';
import FieldWrap from 'components/Inputs/FieldWrap';
import withField, {type ReqFieldProps} from 'hoc/withField';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const InputCheckbox = ({handleChange, ...props}) => (
  // NOTE: This spreading is not best practice and should in the future be resolved properly.
  // But for the sake of being able to easily swap between input components it is being re-used.
  <FieldWrap {...props}>
    <Checkbox {...props} onChange={handleChange} />
  </FieldWrap>
);

const enhancer: HOC<*, ReqFieldProps> = compose(
  withField,
  withHandlers({
    handleChange: props => (v: boolean) => props.onChange(v),
  })
);

export default enhancer(InputCheckbox);
