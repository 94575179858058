// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {manufacturerDetailQuery, manufacturerListQuery} from './queries';

export const manufacturerUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation manufacturerUpdateMutation($input: ManufacturerUpdateInput!) {
      manufacturer {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [manufacturerDetailQuery],
};

export const manufacturerCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation manufacturerCreateMutation($input: ManufacturerCreateInput!) {
      manufacturer {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [manufacturerListQuery],
  selector: ['manufacturer', 'create'],
};

export const manufacturerDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation manufacturerDeleteMutation($id: Int!) {
      manufacturer {
        delete(id: $id)
      }
    }
  `,
  refetch: [manufacturerListQuery],
};
