// @flow
import {StyledInput} from 'componentsStyled/Forms';
import {Label} from 'componentsStyled/Typography';
import withField, {type ReqFieldProps} from 'hoc/withField';
import {clone, times} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {Error, InputVariantVariableWrap, VariantVariableLabel} from './styled';

const InputProductVariantVariableOption = props => (
  <div>
    <Label>{props.label}</Label>
    {times(
      index => (
        <InputVariantVariableWrap key={index}>
          <VariantVariableLabel variantVariablesEnabled={!!props.productLabels[0]}>
            {props.productLabels[index]}
          </VariantVariableLabel>
          <StyledInput
            value={props.value[index]}
            onChange={props.handleChange(index)}
            type="text"
            placeholder={'Enter variant name'}
          />
          &nbsp;
        </InputVariantVariableWrap>
      ),
      props.productLabels ? props.productLabels.length : 1
    )}
    <Error>{props.error}</Error>
  </div>
);

type Enhancer = {
  productLabels: string[],
  label: string,
};

const enhancer: HOC<*, {...$Exact<ReqFieldProps>, ...$Exact<Enhancer>}> = compose(
  // $Dunno
  withField,
  withHandlers({
    handleChange: props => k => e => {
      const value = clone(props.value);
      value[k] = e.target.value;
      props.onChange(value);
    },
  })
);

export default enhancer(InputProductVariantVariableOption);
