// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import {refundPaymentMutation} from 'data/payment/mutations';
import withForm from 'hoc/withForm';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {compose, withHandlers, withProps, withStateHandlers} from 'recompose';

import {RefundWrap} from './styled';

type Props = {
  paymentId: number,
  isSubmitting: boolean,
  paymentAmount: number,
  amount: number,
  refundAmount: number,
  handleRefund: Function,
};

const RefundForm = ({isSubmitting, amount, paymentAmount, handleRefund}: Props) => (
  <RefundWrap>
    <InputText name="amount" type="number" inputmode="numeric" className={'noMargin'} />
    <Button
      onClick={handleRefund}
      loading={isSubmitting}
      disabled={amount === undefined || amount <= 0 || amount * 100 > paymentAmount}
    >
      Refund
    </Button>
  </RefundWrap>
);

export default compose(
  connect(null, {notificationSuccess, notificationError}),
  withStateHandlers(
    {
      confirmSubmission: undefined,
    },
    {
      setConfirmSubmission: () => (v: boolean) => ({confirmSubmission: v}),
    }
  ),
  withMutation(refundPaymentMutation),
  withForm({
    onSubmit: props => values =>
      props.submitMutation({
        paymentId: props.paymentId,
        amount: values.amount * 100,
      }),
    onSuccess: props => data => {
      const previousRefundAmount = props.refundAmount;
      const newRefundAmount = data.payment.refundPayment.payment.refundAmount;
      // No change in refund amount means charge has failed
      if (previousRefundAmount === newRefundAmount) {
        props.notificationError('Refund failed');
      } else {
        props.notificationSuccess('Refund applied');
      }
    },
  }),
  withHandlers({
    handleRefund: props => () => {
      const confirmSubmission = window.confirm('Are you sure?');
      props.setConfirmSubmission(confirmSubmission);
    },
  }),
  withProps(props => ({amount: props.values.amount}))
)(RefundForm);
