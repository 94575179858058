// @flow
// @author: T04435
// Date: 22/7/21

import withForm from 'hoc/withForm';
import {path} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import Button from '../../../../components/Button';
import type {TenantAffiliate} from '../../../../data/tenant-affiliate/types';
import AffiliateSelect from '../../../AffiliateSelect';
import ManufacturerSelect from '../../../ManufacturerSelect';
import schema from './schema';

const EditTenantAffiliatesModal = ({values}) => {
  return (
    <div>
      <h2>Edit Brand Affiliate for tenant</h2>
      <AffiliateSelect name="affiliateId" label="Affiliate" />
      <ManufacturerSelect
        name="manufacturerIds"
        label="Vendors"
        affiliateId={values.affiliateId ? values.affiliateId : []}
        isMulti
      />
      <Button kind="primary">update</Button>
    </div>
  );
};

type Enhancer = {
  close: Function,
  data: TenantAffiliate,
};

const enhancer: HOC<*, Enhancer> = compose(
  //  $Fixme
  withForm({
    schema,
    mapProps: props => ({
      affiliateId: path(['data', 'affiliateId'], props),
      manufacturerIds: path(['data', 'manufacturerIds'], props),
    }),
    onSubmit: props => values => {
      const transformedValues = {
        id: path(['data', 'id'], props),
        tenantId: path(['data', 'tenantId'], props),
        affiliateId: path(['affiliateId'], values),
        manufacturerIds: path(['manufacturerIds'], values),
      };
      return props.submit({input: transformedValues});
    },
    onSuccess: props => props.close,
  })
);

export default enhancer(EditTenantAffiliatesModal);
