// @flow
import {formatParam} from 'data/productVariant/paramHelpers';
import type {ProductParameter} from 'data/productVariant/types';
import {compose, map, prop, sortBy} from 'ramda';
import React from 'react';

type Props = {
  data: ProductParameter[],
};

// NOTE: No longer used due to removal of parameters:
// https://app.clubhouse.io/awayco/story/1230/differentiate-variants-by-name-not-parameters
const ProductParameters = ({data}: Props) => {
  const transformedParams = compose(map(formatParam), sortBy(prop('order')))(data);

  return (
    <div>
      {transformedParams.map((v, i) => (
        <p key={i}>{v}</p>
      ))}
    </div>
  );
};

export default ProductParameters;
