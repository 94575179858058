// @flow
import React from 'react';
import {type HOC, compose, withHandlers, withProps} from 'recompose';

import type {ColumnDef} from '../types';
import {Indicator, StyledTh} from './styled';

const Th = ({isSortable, column, sortingBy, sortAscending, handleClick}) => {
  const {title} = column;

  return (
    <StyledTh sortable={isSortable} onClick={handleClick}>
      <h4>{title}</h4>
      {isSortable && <Indicator desc={!sortAscending} active={sortingBy === column} />}
    </StyledTh>
  );
};

type Enhancer = {
  column: ColumnDef<*>,
  sortingBy?: ColumnDef<*>,
  sortAscending: boolean,
  onSort: Function,
};

const enhancer: HOC<*, Enhancer> = compose(
  withProps(props => ({
    isSortable: Boolean(props.column.value && props.column.title !== 'actions'),
  })),
  withHandlers({
    handleClick: props => () => props.isSortable && props.onSort(props.column),
  })
);

export default enhancer(Th);
