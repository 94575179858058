import {ApolloClient} from 'apollo-client';
import {from, split} from 'apollo-link';
import DebounceLink from 'apollo-link-debounce';
import {createHttpLink} from 'apollo-link-http';
import {getMainDefinition} from 'apollo-utilities';
import config from 'global/config';

import cache from './cache';
import customFetch from './customFetch';
import linkErrors from './linkErrors';
import linkExtractTokens from './linkExtractTokens';
import linkSetHeaders from './linkSetHeaders';
import wsLink from './linkWebSocket';

const DEBOUNCE_TIMEOUT = 500;

const httpChain = from([
  linkErrors,
  linkSetHeaders,
  linkExtractTokens,
  new DebounceLink(DEBOUNCE_TIMEOUT),
  createHttpLink({uri: config.api, fetch: customFetch}),
]);

const apolloClient = new ApolloClient({
  link: split(
    // split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpChain
  ),
  cache,
});

export default apolloClient;
