import gql from 'graphql-tag';

export const baseEmailFragment = gql`
  fragment baseEmailFragment on Email {
    to
    from
    bcc
    subject
    createdAt
    tenant {
      id
      name
    }
  }
`;
