// @flow
// @author: t04435
// Date: 8/7/21

import {formatBool} from 'common/formatters';
import ProductItemStatusPill from 'components/Pills/ProductItemStatusPill';
import type {TableDefinition} from 'components/Table/types';
import type {ProductItem} from 'data/productItem/types';
import {path, prop} from 'ramda';

import EditItemLink from './EditItemLink';

/* TODO(t04435): TABLE_ACTIONS? edit */
const tableDefinition: TableDefinition<ProductItem> = {
  columns: [
    {
      title: 'Asset ID',
      value: prop('code'),
      // $Dunno
      type: EditItemLink,
      props: v => ({data: v}),
    },
    {
      title: 'Store',
      // $Ramda
      value: path(['affiliate', 'name']),
    },
    {
      title: 'Status',
      value: v => v.status,
      type: ProductItemStatusPill,
      props: v => ({status: v.status}),
    },
    {
      title: 'Available',
      value: v => formatBool(v.allowed),
    },
  ],
};

// NOTE(Russell): "Hidden not removed" -> Things to hide (https://www.notion.so/Labels-in-admin-no-longer-match-terminology-used-090256f7e8284b01ad5185d49c97532c)
// if (config.featureFlags.enableBuyIt) {
//   tableDefinition.columns.push({
//     title: 'Buy It RRP',
//     // TODO(ray): Wire in the currency
//     value: v => formatCurrencyFromCents(v.buyItRrp),
//   });
// }

export default tableDefinition;
