// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {locationFragment} from './fragments';
import type {Location} from './types';

export const locationListQuery: Query<Location[]> = {
  gql: gql`
    query locationsListQuery {
      location {
        listAllLocations {
          ...locationFragment
        }
      }
    }
    ${locationFragment}
  `,
  selector: ['location', 'listAllLocations'],
};

export const locationDetailQuery: Query<Location> = {
  gql: gql`
    query locationDetailQuery($id: Int!) {
      location {
        detail(id: $id) {
          ...locationFragment
        }
      }
    }
    ${locationFragment}
  `,
  selector: ['location', 'detail'],
};
