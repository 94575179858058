// @flow
import Button from 'components/Button';
import {CenterContent} from 'componentsStyled/Layout';
import {Label} from 'componentsStyled/Typography';
import PricingTemplateSelect from 'containers/PricingTemplateSelect';
import ProductSelect from 'containers/ProductSelect';
import VariantSelect from 'containers/VariantSelect';
import type {BundleComponent} from 'data/bundle/types';
import withForm from 'hoc/withForm';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {VariantSelectWrap} from './styled';

const EditBundleComponent = props => {
  return (
    <div>
      <h2>Edit Bundle Component</h2>
      <VariantSelectWrap>
        <Label>Product</Label>
        <ProductSelect
          // productId={props.productId}
          name="productId"
          label=""
          onChange={props.setProduct}
          value={props.productId}
        />
        <Label>Variant</Label>
        {props.productId ? (
          <VariantSelect
            productId={props.productId}
            name="variantId"
            label=""
            onChange={props.setVariant}
            value={props.variantId}
          />
        ) : (
          <p>Please select a product.</p>
        )}
      </VariantSelectWrap>
      <PricingTemplateSelect {...props} name="pricingTemplateId" label="Pricing Template" />
      <CenterContent>
        <Button kind="primary" loading={props.isSubmitting} big success type="submit">
          Save
        </Button>
      </CenterContent>
    </div>
  );
};

type Enhancer = {
  close: Function,
  data: BundleComponent,
};

const enhancer: HOC<*, Enhancer> = compose(
  withStateHandlers(
    ({data}) => {
      return {
        productId: data.productVariant.product.id,
        variantId: data.productVariant.id,
      };
    },
    {
      setProduct: props => v => ({productId: v, variantId: undefined}),
      setVariant: props => v => ({variantId: v}),
    }
  ),
  withForm({
    mapProps: props => ({
      pricingTemplateId: props.data.pricingTemplate.id,
    }),
    onSubmit: props => values => {
      // No schema since withform doesn't detect the value in `VariantSelect` component
      if (!props.productId || !props.variantId) {
        return Promise.resolve();
      }
      const transformedValues = {
        id: props.data.id,
        productVariantId: props.variantId,
        pricingTemplateId: values.pricingTemplateId,
      };
      return props.submit({input: transformedValues});
    },
    onSuccess: props => props.close,
  })
);

export default enhancer(EditBundleComponent);
