// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import CategorySelect from 'containers/CategorySelect';
import withForm from 'hoc/withForm';
import React from 'react';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
};

const CategoryEdit = ({isSubmitting}: Props) => (
  <div>
    <InputText name="name" label="name" />
    <CategorySelect name="relatedCategories" label="related category" isMulti />
    <Button kind="primary" loading={isSubmitting}>
      save
    </Button>
  </div>
);

export default withForm({
  schema,
  mapProps: props => {
    // Case where we are creating a new category
    if (!props.data) {
      return props;
    }
    const relatedCategories = props.data.relatedCategories
      ? props.data.relatedCategories.map(p => p.categoryId)
      : [];
    return {
      ...props.data,
      relatedCategories,
    };
  },
  onSubmit: props => values => props.submit({input: values}),
})(CategoryEdit);
