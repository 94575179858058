// @flow
import {getRouterVariables} from 'common/graphql/routing';
import {removeTypenames, transformResponse} from 'common/graphql/utils';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router';
import {type Component, compose} from 'recompose';
import {type Mutation} from 'types/Graphql';

import {getPaginationVariables} from './withQuery/pagination';

const getRefetchQueries = <A, B>(mutation: Mutation<A, B>, props: {}, hasStringId?: boolean) => {
  if (!mutation.refetch) return [];
  return mutation.refetch.map(query => ({
    query: query.gql,
    variables: query.pagination
      ? {
          filter: {
            ...getPaginationVariables(query.selector, true),
            ...query.defaultFilter,
          },
        }
      : getRouterVariables(props, hasStringId),
  }));
};

type Added<Variables, Result> = {
  submitMutation: Variables => Promise<Result>,
};

type Config = {
  hasStringId?: boolean,
};

function withMutation<Enhancer, Variables, Result>(
  mutation: Mutation<Variables, Result>,
  config: Config = {}
): (
  Component<{
    ...$Exact<Added<Variables, Result>>,
    ...$Exact<Enhancer>,
  }>
) => Component<Enhancer> {
  return compose(
    withRouter,
    graphql(mutation.gql, {
      props: ({mutate, ownProps}) => ({
        submitMutation: (variables: Variables) =>
          mutate({
            variables: removeTypenames(variables),
            refetchQueries: getRefetchQueries(mutation, ownProps, config.hasStringId),
          }).then(({data}) => transformResponse(mutation.selector)(data)),
      }),
    })
  );
}

export default withMutation;
