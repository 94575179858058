// @flow
import {
  categoryCreateMutation,
  categoryDeleteMutation,
  categoryUpdateMutation,
} from 'data/product/mutations';
import {categoryDetailQuery, categoryListQuery} from 'data/product/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import CategoryDetail from './CategoryDetail';
import CategoryEdit from './CategoryEdit';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: CategoryEdit,
  updateMutation: categoryUpdateMutation,
  detail: CategoryDetail,
  detailQuery: categoryDetailQuery,
  listQuery: categoryListQuery,
  createMutation: categoryCreateMutation,
  // TODO(ray): Show error message if deletion fails, which is likely, e.g.
  // because the category is being used
  deleteMutation: categoryDeleteMutation,
  tableDefinition,
  title: 'Categories',
  entityName: 'Category',
  route: urls.category,
  idName: 'categoryId',
});

const enhancer = withHelpCenter({category: 'products'});

export default enhancer(Page);
