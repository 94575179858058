import {WebSocketLink} from 'apollo-link-ws';
import config from 'global/config';

import crypto from './crypto';

const subscriptionMiddleware = {
  applyMiddleware(options, next) {
    options.auth = crypto.getAccessToken();
    next();
  },
};

const wsLink = new WebSocketLink({
  uri: config.ws.replace(/^http/i, 'ws'),
  options: {
    reconnect: true,
  },
});

wsLink.subscriptionClient.use([subscriptionMiddleware]);

export default wsLink;
