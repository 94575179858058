// @flow
// $Import
import 'rxjs/add/observable/from';
// $Import
import 'rxjs/add/observable/fromEvent';
// $Import
import 'rxjs/add/observable/fromPromise';
// $Import
import 'rxjs/add/observable/of';
// $Import
import 'rxjs/add/operator/catch';
// $Import
import 'rxjs/add/operator/map';
// $Import
import 'rxjs/add/operator/switchMap';

import userEpics from 'data/auth/epics';
import {combineEpics, createEpicMiddleware} from 'redux-observable';

const epicMiddleware = createEpicMiddleware();

export const rootEpic = combineEpics(...userEpics);
export default epicMiddleware;
