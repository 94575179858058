// @flow
// @author: t04435
// Date: 8/7/21

import {faMasksTheater} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import type {TableDefinition} from 'components/Table/types';
import {createMasqueradeMutation} from 'data/auth/graphql/mutations';
import type {UserMinimal} from 'data/user/types';
import apolloClient from 'global/apolloClient';
import urls from 'pages/urls';
import {prop} from 'ramda';

import Pill from '../../components/Pills';
import {TABLE_COLUMN_ACTION_TITLE} from '../../components/Table/Td';
import {getPillPropsFromRole} from '../../data/user/helpers';
import withTooltip from '../../hoc/withTooltip';

const tableDefinition: TableDefinition<UserMinimal> = {
  columns: [
    {
      title: 'email',
      value: prop('email'),
    },
    {
      title: 'role',
      value: prop('role'),
      type: Pill,
      props: v => getPillPropsFromRole(v.role),
    },
    {
      title: TABLE_COLUMN_ACTION_TITLE,
      value: prop('email'),
      type: withTooltip(FontAwesomeIcon),
      props: v => ({
        icon: faMasksTheater,
        size: 'lg',
        'data-tip': 'Masquerade',
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          apolloClient
            .mutate({
              mutation: createMasqueradeMutation.gql,
              variables: {userId: v.id},
            })
            .then(result => {
              window.open(result.data.auth.createMasquerade.url, 'masquerade');
            });
        },
      }),
    },
  ],
  rowLink: v => urls.user.link.detail(v.id),
};
export default tableDefinition;
