// @flow
import {dayMap} from 'common/days';
import type {ExceptionDay, Hours, RegularDay} from 'data/affiliate/types';
import type {Coordinates} from 'data/other/types';
import moment from 'moment';
import {test} from 'ramda';
import * as yup from 'yup';

import type {Validator} from './types';

export const optional = yup.mixed().nullable();
export const required = yup.string().required('This field is required');
export const requiredNum = yup.number().required('This field is required');
export const boolean = yup.boolean();

export const email = yup
  .string()
  .email('Please enter a valid email address')
  .required('This field is required');

const hourRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/;
const hourValid = test(hourRegex);
const pauseValid = (x: Hours) => {
  if ((x.pauseStart && !x.pauseEnd) || (!x.pauseStart && x.pauseEnd)) return false;

  if (!x.pauseStart && !x.pauseEnd) return true;

  return hourValid(x.pauseStart) && hourValid(x.pauseEnd);
};
const hoursValid = (x: Hours) => {
  if (!x) return false;

  return hourValid(x.start) && hourValid(x.end);
};
const dayValid = (x: RegularDay) => {
  if (x.closed) return true;

  if (x.hours) return hoursValid(x.hours) && pauseValid(x.hours);

  return false;
};
const specialDayValid = (x: ExceptionDay) => {
  return dayValid(x) && moment(x.date).isValid();
};
export const openingHours = yup.object().test('openingHours', 'Invalid opening hours', v =>
  Object.keys(dayMap)
    .map(k => v[k])
    .every(dayValid)
);

export const openingHoursExceptions = yup
  .array()
  .test('openingHoursExceptions', 'Invalid special opening hours', v =>
    v.map(specialDayValid).every(x => !!x)
  );

export const images = yup.array().min(1).required('This field is required');

const isCoordinatesValid = (coordinate: Coordinates) =>
  coordinate.lng && coordinate.lat && coordinate.inTimezone;

export const coordinates = yup
  .object()
  .test('coordinates', 'Invalid coordinates', v => !!v && isCoordinatesValid(v));

export const priceDaysValid = yup
  .array()
  .test('pricing template days', 'invalid pricing template days', v => v && v.every(x => !!x));

export const priceAccessoriesValid = yup
  .array()
  .test(
    'pricing template accessories',
    'Price of at least 0 is required',
    v => v && v.every(x => x.prices.every(y => y || y === 0))
  );

export const numberOptional: Validator<?number> = yup.number().nullable();

export const numberRequired: Validator<number> = numberOptional.required('This field is required');

export const rangeOptional = (
  min: number,
  max: number,
  transform?: (n: number) => number
): Validator<?number> =>
  numberOptional
    .min(min, `Must be greater or equal to ${transform === undefined ? min : transform(min)}`)
    .max(max, `Must be less or equal to ${transform === undefined ? max : transform(max)}`);

export const rangeRequired = (
  min: number,
  max: number,
  transform?: (n: number) => number
): Validator<?number> =>
  numberRequired
    .min(min, `Must be greater or equal to ${transform === undefined ? min : transform(min)}`)
    .max(max, `Must be less or equal to ${transform === undefined ? max : transform(max)}`);

export const integerRequired: Validator<?number> = numberRequired.integer();

export const nonNegativeInteger = integerRequired.min(0, 'Must be greater or equal to 0');
export const nonNegativeNumber = numberRequired.min(0, 'Must be greater or equal to 0');
export const nonNegativeNumberOptional = numberOptional.min(0, 'Must be greater or equal to 0');

export const integerRangeRequired = (
  min: number,
  max: number,
  transform?: (n: number) => number
): Validator<?number> =>
  integerRequired
    .min(min, `Must be greater or equal to ${transform === undefined ? min : transform(min)}`)
    .max(max, `Must be less or equal to ${transform === undefined ? max : transform(max)}`);

export const requiredText = 'This field is mandatory';

export const stringOptional: Validator<?string> = yup.string().nullable();

export const stringRequired: Validator<string> = stringOptional
  .required(requiredText)
  .test('is-not-whitespace', 'Must contain non-empty characters', v => /^\S+$/.test(v));

export const dateRequired: Validator<string> = stringRequired.test(
  'invalidDate',
  'Invalid Date',
  v => moment(v).isValid()
);

export const variantVariablesStringArrayInput = yup
  .array()
  .of(yup.string())
  .test(
    'list of variant variable strings',
    'All fields must not be empty',
    v => v && v.every(x => !!x.trim())
  )
  .test(
    'invalid character',
    "Use of '|' character not allowed",
    v => v && v.every(x => !x.includes('|'))
  );

export const variantVariablesCreationInput = variantVariablesStringArrayInput.test(
  'allowed array sizes',
  'There must be more than one variable if option is enabled',
  v => v && v.length !== 1
);

/**
 * Perform a test on the coordinates object, and output the results into the
 * input field that the coordinates object was derived from.
 * @param {string} path The name of the input that the location object is derived from. Any error messages
 * will be printed out below it.
 * @returns a yup error object
 */
export const validateCoordinates = (path: string) =>
  yup.object().test({
    name: 'location',
    message: 'Invalid location',
    test: function (v) {
      if (!!v && isCoordinatesValid(v)) {
        return true;
      }
      return this.createError({
        path,
        message: 'To proceed, please select a valid option from the address suggestions dropdown',
      });
    },
  });

export const bufferDays = (min: number, max: number): Validator<?number> => {
  const errorMsg = `Padding must be between ${min} and ${max} days`;
  return integerRequired.min(min, errorMsg).max(max, errorMsg);
};

export const deliveryCharge = nonNegativeNumberOptional.when('allowDeliveryFulfilment', {
  is: true,
  then: nonNegativeNumber,
});

export const deliveryChargeThreshold = nonNegativeNumberOptional.when(
  ['allowDeliveryFulfilment', 'enableDeliveryChargeThreshold'],
  {
    is: true, //both true
    then: nonNegativeNumber,
  }
);
