// @flow
import gql from 'graphql-tag';

export const manufacturerFragment = gql`
  fragment manufacturerFragment on Manufacturer {
    id
    name
    code
  }
`;
