// @flow
import {formatBool} from 'common/formatters';
import TableStat from 'components/TableStat';
import {Label} from 'componentsStyled/Typography';
import type {PricingTemplate} from 'data/pricingTemplate/types';
import {path, unnest} from 'ramda';
import React from 'react';

import accessoriesTableDefinition from './accessoriesTableDefinition';
import daysTableDefinition from './daysTableDefinition';
import productItemsTableDefintion from './productItemsTableDefintion';
import {StyledSpread as Spread} from './styled';

const getCurrency = data => path(['affiliate', 'location', 'country', 'currency'], data);

type Props = {
  data: PricingTemplate,
};

const PricingTemplateDetail = ({data}: Props) => {
  const currency = getCurrency(data);

  const accessoriesPricing =
    data.accessories &&
    data.accessories.map(acc =>
      acc.prices.map((price, index) => ({
        name: acc.name,
        day: index + 1,
        price: price,
        currency: currency,
      }))
    );

  return (
    <div>
      <Label>Name</Label>
      <p>{data.name}</p>
      <Label>Primary</Label>
      <p>{formatBool(data.isPrimary)}</p>
      <Spread>
        <TableStat
          title="Days"
          tableDefinition={daysTableDefinition}
          value={data.prices.map((price, index) => ({
            price,
            day: index + 1,
            currency: currency,
          }))}
        />
        {data.accessories && (
          <TableStat
            title="Accessories"
            tableDefinition={accessoriesTableDefinition}
            // $Ramda
            value={unnest(accessoriesPricing)}
          />
        )}
      </Spread>
      <TableStat
        title="Product items"
        tableDefinition={productItemsTableDefintion}
        value={data.productItems}
      />
    </div>
  );
};

export default PricingTemplateDetail;
