// @flow
import ErrorOutlineIcon from 'components/Icons/ErrorOutlinedIcon';
import InputCheckbox from 'components/Inputs/InputCheckbox';
import InputNumber from 'components/Inputs/InputNumber';
import {Divider} from 'componentsStyled/Layout';
import React from 'react';

import {
  CheckboxWrapper,
  StyledBufferInput,
  StyledBufferSection,
  StyledParagraph,
  TopLabel,
  WarningWrapper,
} from './styled';

const BufferInput = ({
  label,
  name,
  hint,
  max,
  disabled,
  onBlur,
  'data-cy': dataCy,
}: {
  label: string,
  name: string,
  hint: string,
  max?: number,
  disabled?: boolean,
  onBlur?: Function,
  'data-cy'?: string,
}) => (
  <StyledBufferInput>
    <TopLabel disabled={disabled}>{label}</TopLabel>
    <InputNumber
      maxWidth={8}
      fieldWrapProps={{
        labelPosition: 'right',
        label: 'days',
      }}
      min={0}
      max={max}
      interval={1}
      name={name}
      hint={hint}
      disabled={disabled}
      onBlur={onBlur}
      data-cy={dataCy}
    />
  </StyledBufferInput>
);

export type Props = {
  formValues: {
    enableDeliveryBuffers: boolean,
    inboundBufferDays: number,
    outboundBufferDays: number,
    preBookingBufferDays: number,
    postBookingBufferDays: number,
    allowDeliveryFulfilment: boolean,
    automaticallyEndPostProcessing: boolean,
  },
  setFieldToZeroIfEmpty: Function,
};

const RentalBuffersSection = ({formValues, setFieldToZeroIfEmpty}: Props) => {
  const {enableDeliveryBuffers, allowDeliveryFulfilment} = formValues;

  const deliverySectionDisabled = !allowDeliveryFulfilment;
  const deliveryBufferDisabled = deliverySectionDisabled || !enableDeliveryBuffers;

  return (
    <StyledBufferSection title="Rental Buffers">
      <WarningWrapper>
        <ErrorOutlineIcon />
        <StyledParagraph>
          Modifying these settings may cause availability clashes for existing bookings. An email
          will be sent daily with affected items.
        </StyledParagraph>
      </WarningWrapper>
      <StyledParagraph>
        Rental buffers allow you to make items unavailable before and after booked rentals. This
        accommodates processing needs such as preparation, checks, cleaning or repairs.
      </StyledParagraph>
      <BufferInput
        label="Pre-processing buffer"
        name="preBookingBufferDays"
        hint="Period before booking"
        max={7}
        onBlur={() => setFieldToZeroIfEmpty('preBookingBufferDays')}
        data-cy="pre-processing-days"
      />
      <BufferInput
        label="Post-processing buffer"
        name="postBookingBufferDays"
        hint="Period after booking"
        max={7}
        onBlur={() => setFieldToZeroIfEmpty('postBookingBufferDays')}
        data-cy="post-processing-days"
      />
      <CheckboxWrapper>
        <InputCheckbox
          label="Automatically end post-processing"
          labelPosition="right"
          name="automaticallyEndPostProcessing"
          data-cy="automatically-end-post-processing"
        />
      </CheckboxWrapper>
      <Divider />
      <CheckboxWrapper>
        <InputCheckbox
          label="Additional delivery buffers"
          labelPosition="right"
          name="enableDeliveryBuffers"
          disabled={deliverySectionDisabled}
          data-cy="enable-transit-buffers"
        />
      </CheckboxWrapper>
      <StyledParagraph>
        Delivery buffers allow you to make items unavailable before and after booked rentals, plus
        any rental buffers. This is to accommodate the period that an item is in transit.
      </StyledParagraph>
      <BufferInput
        label="Outbound transit"
        name="outboundBufferDays"
        hint="Period before booking and after any pre-processing buffer"
        disabled={deliveryBufferDisabled}
        onBlur={() => setFieldToZeroIfEmpty('outboundBufferDays')}
        data-cy="outbound-transit-days"
      />
      <BufferInput
        label="Inbound transit"
        name="inboundBufferDays"
        hint="Period after booking and before any post-processing buffer"
        disabled={deliveryBufferDisabled}
        onBlur={() => setFieldToZeroIfEmpty('inboundBufferDays')}
        data-cy="inbound-transit-days"
      />
    </StyledBufferSection>
  );
};

export default RentalBuffersSection;
