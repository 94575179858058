// @flow
import gql from 'graphql-tag';
import type {Mutation} from 'types/Graphql';

import {reservationDetailQuery, reservationListQuery} from './queries';

export const reservationCancelMutation: Mutation<{}, {}> = {
  gql: gql`
    mutation reservationCancelMutation($id: Int!) {
      reservation {
        cancel(reservationId: $id)
      }
    }
  `,
  refetch: [reservationListQuery],
};

export const reservationUpdateStatusMutation: Mutation<{}, {}> = {
  gql: gql`
    mutation reservationUpdateStatusMutation($id: Int!, $input: UpdateStatusInput!) {
      reservation {
        updateStatus(id: $id, input: $input) {
          id
        }
      }
    }
  `,
  refetch: [reservationListQuery],
};

export const reservationAdhocChargeMutation: Mutation<{}, {}> = {
  gql: gql`
    mutation reservationAdhocChargeMutation($input: AdhocChargeInput!) {
      reservation {
        adhocCharge(input: $input) {
          id
          status
        }
      }
    }
  `,
  refetch: [reservationDetailQuery],
};
