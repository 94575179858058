import EmailProjectionDetail from 'pages/Email/EmailProjection/EmailProjectionDetail';
import EmailProjectionList from 'pages/Email/EmailProjection/EmailProjectionList';
import urls from 'pages/urls';
import {Redirect, Route, Switch} from 'react-router-dom';

const Page = () => {
  return (
    <Switch>
      <Route path={urls.emailProjection.path.list} component={EmailProjectionList} />
      <Route path={urls.emailProjection.path.detail} component={EmailProjectionDetail} />
      <Redirect to={urls.emailProjection.path.list} />
    </Switch>
  );
};

export default Page;
