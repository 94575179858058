import styled, {css} from 'styled-components';

/* TODO(T04435): This should be a reusable component */
export const WindowControlViewsSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 1rem;

  :first-child {
    button {
      border-radius: 0;
      &:first-of-type {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
      }

      &:last-of-type {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
      }
    }
  }

  ${props =>
    !props.isFullscreen &&
    css`
      margin-left: 0;
      margin-right: 0;
    `}
  ${props =>
    !props.isFullscreen &&
    props.className === 'collapseTopMargin' &&
    css`
      margin-top: 0;
    `}
  button {
    padding: 0 2rem;
  }

  svg {
    font-size: 24px;
  }
`;

// eslint-disable-next-line no-mixed-operators
export const WindowControlViewsWrapper = styled.div`
  position: ${props => (props.isFullscreen ? 'fixed' : 'relative')};
  ${props =>
    props.isFullscreen &&
    css`
      background: ${props => props.theme.palette.light};
      z-index: 2;
      width: 100vw;
      left: 0;
      height: 100vh;
      top: 0;
      overflow: auto;
    `}
  ${props =>
    props.className === 'collapseTopMargin' &&
    css`
      margin-top: -2rem;
    `}
`;

export const WindowControlButtonLabel = styled.span`
  margin-left: 1rem;
`;
