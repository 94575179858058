// @flow
import {center} from 'componentsStyled/Shared';
import styled, {css} from 'styled-components';

export const CheckboxWrap = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid ${props => props.theme.palette.greyDark};
  border-radius: 0.5rem;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;

  ${p =>
    p.big &&
    css`
      height: 4.8rem;
      width: 4.8rem;
    `}

  ${p =>
    p.checked &&
    css`
      background: ${props => props.theme.palette.primary};
      border-color: ${props => props.theme.palette.primary};
    `}
  
  ${props =>
    props.disabled &&
    css`
      border: 0.1rem solid ${props => props.theme.palette.grey};
      background: ${props => props.theme.palette.greyLighter};
      cursor: default;

      svg path {
        stroke: ${props => props.theme.palette.grey} !important;
      }
    `}

  svg {
    ${center};
  }
`;
