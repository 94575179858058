// @flow
import InputSelect from 'components/Inputs/InputSelect';
import type {EmailProjectionAction} from 'data/email/types';
import * as React from 'react';

type Props = {name: string, label?: string};

const options: {id: EmailProjectionAction, name: string}[] = [
  {id: 'scheduleWatchdogBeforeClosingHour', name: 'Before Closing Hour Watchdog'},
  {id: 'scheduleWatchdogReturnToday', name: 'Return Today Watchdog'},
  {id: 'scheduleWatchdogPickup', name: 'Pickup Watchdog'},
  {id: 'scheduleWatchdogPayments', name: 'Payments Watchdog'},
  {id: 'scheduleWatchdogSendExtensionDeadlineReminders', name: 'Send Email Reminders'},
  {id: 'scheduleWatchdogOverdueDeliveryReminders', name: 'Send Overdue Delivery Reminders'},
];

const EmailProjectionActionSelect = (props: Props) => <InputSelect {...props} options={options} />;

export default EmailProjectionActionSelect;
