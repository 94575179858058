import {getRefreshTokenMutation} from 'data/auth/graphql/mutations';

import crypto from './crypto';
import {mutate} from './helpers';
// this fetch handles fetching new access token using refresh tokens

const customFetch = (uri, options) =>
  fetch(uri, options).then(res => {
    // leave as is, if not auth error
    if (res.status !== 401) {
      return res;
    }

    const email = crypto.getEmailFromAccessToken();
    const refreshToken = crypto.getRefreshToken();
    if (!email || !refreshToken) {
      throw new Error('auth error');
    }

    crypto.purge();
    return mutate(getRefreshTokenMutation, {
      refreshToken,
      email,
    }).then(accessToken => {
      crypto.setAccessToken(accessToken);
      options.headers.authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    });
  });

export default customFetch;
