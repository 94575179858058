// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {Product} from 'data/product/types';
import urls from 'pages/urls';
import {path, prop} from 'ramda';

// $Ramda
const tableDefinition: TableDefinition<Product> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
    {
      title: 'category',
      value: path(['category', 'name']),
    },
  ],
  rowLink: v => urls.bundle.link.detail(v.id),
};

export default tableDefinition;
