// @flow
import Table from 'components/Table';
import withQuery from 'hoc/withQuery';
import {clone} from 'ramda';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {upcomingReservationsQuery} from './queries';
import tableDefinition from './tableDefinition';

const Reservations = ({data, statusFormatter}) => {
  const td = clone(tableDefinition);
  if (statusFormatter) {
    td.columns[2].value = statusFormatter;
  }
  return (
    <div>
      <Table tableDefinition={td} data={data} />
    </div>
  );
};
const enhancer: HOC<*, {|status: string, affiliateId: string|}> = compose(
  withQuery(upcomingReservationsQuery, undefined, undefined, ({status, affiliateId}) => ({
    status,
    affiliateId,
  }))
);

export default enhancer(Reservations);
