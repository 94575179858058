// @flow
// @author: t04435
// Date: 8/7/21

import {formatBool} from 'common/formatters';
import type {TableDefinition} from 'components/Table/types';
import type {ProductItemInventory} from 'data/productItem/types';
import urls from 'pages/urls';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<ProductItemInventory> = {
  columns: [
    {
      title: 'Asset ID',
      value: prop('code'),
    },
    {
      title: 'product name',
      value: prop('productName'),
    },
    {
      title: 'variant',
      value: v => v.variantName,
    },
    {
      title: 'pricing template',
      value: prop('pricingTemplateName'),
    },
    {
      title: 'active',
      value: v => formatBool(v.online),
    },
  ],
  rowLink: v => urls.inventory.link.detail(v.id),
};
export default tableDefinition;
