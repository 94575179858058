import styled, {css} from 'styled-components';

export const StyledTh = styled.th`
  position: sticky;
  top: 0;
  left: 0;
  height: 3.2rem;
  border-radius: 0.4rem;
  color: ${props => props.theme.palette.greyDarker};
  background-color: ${props => props.theme.palette.greyLighter};
  padding: 0 1.6rem;
  white-space: nowrap;

  h4 {
    margin: 0;
    display: inline-block;
  }

  ${props =>
    props.sortable &&
    css`
      transition: color 0.25s ease;
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.palette.dark};
      }
    `};
`;

export const Indicator = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  margin-left: 1.5rem;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    transition: border 0.25s ease;
  }

  &::before {
    border-bottom: 0.7rem solid
      ${props =>
        props.active && !props.desc ? props.theme.palette.grey : props.theme.palette.greyLight};
    margin-bottom: 0.4rem;
  }
  &::after {
    border-top: 0.7rem solid
      ${props =>
        props.active && props.desc ? props.theme.palette.grey : props.theme.palette.greyLight};
  }
`;
