// @flow
import React from 'react';

import AccessoriesCreate from './AccessoriesCreate';
import AccessoriesUpdate from './AccessoriesUpdate';

export const dimensions = {width: 110, height: 110};

const AccessoriesEdit = (props: any) => {
  return props.data ? <AccessoriesUpdate {...props} /> : <AccessoriesCreate {...props} />;
};

export default AccessoriesEdit;
