// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {countryFragment} from './fragments';
import type {Country} from './types';

export const countryListQuery: Query<Country[]> = {
  gql: gql`
    query locationsListQuery {
      location {
        listAllCountries {
          ...countryFragment
        }
      }
    }
    ${countryFragment}
  `,
  selector: ['location', 'listAllCountries'],
};
