// @flow
import {tenantCreateMutation, tenantUpdateMutation} from 'data/tenant/mutations';
import {tenantDetailQuery, tenantListQuery} from 'data/tenant/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import urls from 'pages/urls';

import tableDefinition from './tableDefinition';
import TenantDetail from './TenantDetail';
import TenantEdit from './TenantEdit';

export default createEntityCRUD({
  edit: TenantEdit,
  updateMutation: tenantUpdateMutation,
  detail: TenantDetail,
  detailQuery: tenantDetailQuery,
  listQuery: tenantListQuery,
  createMutation: tenantCreateMutation,
  tableDefinition,
  title: 'Tenants',
  entityName: 'Tenant',
  route: urls.tenant,
});
