// @flow
import {TitleMa} from 'componentsStyled/Typography';
import ForgotPassword from 'containers/Modals/ForgotPassword';
import {openModal} from 'data/modals/actions';
import withRouter, {parseSearchFromLocation} from 'hoc/withRouter';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers, withProps} from 'recompose';

import LogoIcon from '../../containers/App/Header/LogoLink/LogoIcon';
import config from '../../global/config';
import LoginForm from './LoginForm';
import {LoginBrand, LoginContent, LoginHeader, LoginWrap, WelcomeMessage} from './styled';

const brandImage = filename => `${config.brandUrl}/${filename}`;

const LoginPage = ({openForgotPassword, showWelcome}) => (
  <React.Fragment>
    <LoginBrand>
      <img id="circle" src={brandImage('dashed-circle.png')} alt="" loading="lazy" />
      <img id="logo" src={brandImage('logo.png')} alt="" loading="lazy" width={357} height={357} />
    </LoginBrand>
    <LoginContent>
      <LoginHeader>
        <LogoIcon />
      </LoginHeader>
      {showWelcome && (
        <WelcomeMessage>
          Welcome to Awayco! Please use the email and password you provided during signup to log in.
        </WelcomeMessage>
      )}
      <LoginWrap data-cy={'login-form'}>
        <div className={'center'}>
          <TitleMa>Sign In</TitleMa>
          <p>Sign in using your account details to manage your Awayco rental store</p>
          <br />
        </div>
        <LoginForm openForgotPassword={openForgotPassword} />
      </LoginWrap>
    </LoginContent>
  </React.Fragment>
);

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, {}> = compose(
  withRouter,
  connect(() => ({}), mapDispatchToProps),
  withProps(props => {
    const parameters = parseSearchFromLocation(props.location);
    return {
      showWelcome: !!parameters['show-welcome'],
    };
  }),
  withHandlers({
    openForgotPassword: props => () => {
      props.openModal(ForgotPassword, {});
    },
  })
);

export default enhancer(LoginPage);
