// @flow
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import {Hint} from '../../componentsStyled/Typography';
import {StyledSwitch, StyledSwitchWrap} from './styled';

const Switch = ({value, handleChange, filterLabel, small, ...rest}) => (
  <StyledSwitchWrap>
    <StyledSwitch value={value} {...rest} onClick={handleChange} isSmall={small} />
    {filterLabel && small && <Hint>{filterLabel}</Hint>}
  </StyledSwitchWrap>
);

type Enhancer = {
  value: boolean,
  onChange: Function,
  filterLabel?: string,
  small?: boolean,
  'data-cy'?: string,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleChange: props => () => props.onChange(!props.value),
});

export default enhancer(Switch);
