import React from 'react';

import {HtmlViewerWrapper, RawHtml} from './styled';

type Props = {
  html: string,
};

const HtmlViewer = (props: Props) => {
  const {html} = props;
  const ENV = process.env.NODE_ENV;
  return (
    <React.Fragment>
      <HtmlViewerWrapper dangerouslySetInnerHTML={{__html: html}} />
      {ENV === 'development' && (
        <React.Fragment>
          <h4>
            <pre>ONLY ON DEVELOPMENT</pre>
          </h4>
          <RawHtml>{html}</RawHtml>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HtmlViewer;
