// @flow
import {
  productVariantCreateMutation,
  productVariantDeleteMutation,
  productVariantUpdateMutation,
} from 'data/productVariant/mutations';
import {productVariantDetailQuery} from 'data/productVariant/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import urls from 'pages/urls';

import VariantDetail from './VariantDetail';
import VariantEdit from './VariantEdit';

export default createEntityCRUD({
  detail: VariantDetail,
  edit: VariantEdit,
  detailQuery: productVariantDetailQuery,
  createMutation: productVariantCreateMutation,
  updateMutation: productVariantUpdateMutation,
  // NOTE(ray): There is no list view of variants because the table is intended
  // to be used inside the product page. As such, after deletion the user is
  // redirected to a blank page. This is undesirable but acceptable for now.
  deleteMutation: productVariantDeleteMutation,
  title: 'Variants',
  entityName: 'Variant',
  route: urls.variant,
});
