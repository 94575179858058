// @flow
import gql from 'graphql-tag';

export const pricingTemplateFragment = gql`
  fragment pricingTemplateFragment on PricingTemplate {
    id
    name
    isPrimary
  }
`;
export const pricingTemplateDetailFragment = gql`
  fragment pricingTemplateDetailFragment on PricingTemplate {
    id
    name
    prices
    accessories {
      name
      prices
    }
    isPrimary
    productItems {
      id
      code
    }
    affiliate {
      id
      location {
        id
        country {
          id
          currency
        }
      }
    }
  }
`;
