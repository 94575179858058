// @flow
import {formatNullableValue} from 'common/formatters';
import * as React from 'react';

type Props = {
  title: string,
  value: React.Node,
  formatter?: Function,
};

const DetailStat = ({title, value, formatter}: Props) => (
  <div>
    <h4>{title}</h4>
    <p>{formatNullableValue(value, formatter)}</p>
  </div>
);

export default DetailStat;
