// @flow
import gql from 'graphql-tag';

import {orderFragment} from '../order/fragments';

export const bookingFragment = gql`
  fragment bookingFragment on Booking {
    bookingId
    start
    end
    externalId
    order {
      ...orderFragment
    }
  }
  ${orderFragment}
`;
