// @flow
import {Field} from 'formik';
import {path} from 'ramda';
import * as React from 'react';

export type ReqFieldProps = {
  name: string,
  label?: string,
  onBlur?: Function,
};

export type OptionalFieldProps = {
  helperText: ?string,
};

type AddedProps = {
  value: any,
  onChange: any => void,
  onChangeComplete: any => void,
  error: ?string,
  setFieldValue: (any, any) => void,
  defaultValue: any,
  setStatus: any => void,
  status: any,
  options: any,
};

const handleChange = props => e => {
  const value = e && e.target ? e.target.value : e;
  // resets form error on change
  props.form.setFieldValue(props.field.name, value);
  props.form.setStatus({...props.form.status, error: null});
};

const handleBlur = (fieldProps, props) => e => {
  const formikOnBlur = path(['field', 'onBlur'], fieldProps);
  const propsOnBlur = props.onBlur;

  formikOnBlur && formikOnBlur(e);
  propsOnBlur && propsOnBlur(e);
};

// type WithField = (React.ComponentType<*>) => any => React.ComponentType<{}>
function withField<Enhanced>(
  Component: React.ComponentType<{
    ...$Exact<AddedProps>,
    ...$Exact<Enhanced>,
    ...$Exact<ReqFieldProps>,
  }>
): React.ComponentType<Enhanced & ReqFieldProps> {
  return props => (
    <Field
      {...props}
      render={fieldProps => {
        const showError =
          path(['form', 'status', 'submitted'], fieldProps) ||
          fieldProps.form.touched[fieldProps.field.name] === true;

        return (
          <Component
            {...props}
            {...fieldProps.field}
            error={showError && fieldProps.form.errors[fieldProps.field.name]}
            onChange={handleChange(fieldProps)}
            onChangeComplete={handleChange(fieldProps)}
            onBlur={handleBlur(fieldProps, props)}
          />
        );
      }}
    />
  );
}

export default withField;
