// @flow
import 'global/global-styles';

import {ConnectedRouter} from 'connected-react-router';
import App from 'containers/App';
import apolloClient from 'global/apolloClient';
import config from 'global/config';
import history from 'global/history';
import store, {persistor} from 'global/store';
import theme from 'global/theme';
import React from 'react';
import {ApolloProvider} from 'react-apollo';
import ReactDOM from 'react-dom';
// `$Import
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/es/integration/react';
import {ThemeProvider} from 'styled-components';

// Set the title for local development
// NOTE: On production it will be written directly into index.html
document.title = `${config.tenantTitle} Admin`;

const MOUNT_NODE = document.getElementById('app');

if (MOUNT_NODE) {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>,
    MOUNT_NODE
  );
}
