// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {tenantAffiliatesFragment} from './fragments';
import type {TenantAffiliate} from './types';

export const tenantAffiliatesListQuery: Query<TenantAffiliate[]> = {
  gql: gql`
    query tenantAffiliatesListQuery($id: Int, $filter: TenantAffiliateFilter) {
      tenantAffiliate {
        list(id: $id, filter: $filter) {
          ...tenantAffiliatesFragment
        }
      }
    }
    ${tenantAffiliatesFragment}
  `,
  selector: ['tenantAffiliate', 'list'],
  debounceKey: 'tenantAffiliateListQuery',
};
