// @flow
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import type {EmailIdentifier} from './types';

export const emailTemplateListQuery: Query<EmailIdentifier[]> = {
  gql: gql`
    query emailTemplateListQuery {
      emailTemplate {
        list {
          id
          name
        }
      }
    }
  `,
  selector: ['emailTemplate', 'list'],
};

export const emailTemplateDetailQuery: Query<String> = {
  gql: gql`
    query emailTemplateDetailQuery($id: String!) {
      emailTemplate {
        detail(id: $id)
      }
    }
  `,
  selector: ['emailTemplate', 'detail'],
};
