// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {tenantAffiliatesListQuery} from './queries';

export const tenantAffiliateCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation tenantAffiliateCreateMutation($input: TenantAffiliateCreateInput!) {
      tenantAffiliate {
        create(payload: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantAffiliatesListQuery],
  selector: ['tenantAffiliate', 'create'],
};

export const tenantAffiliateUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation tenantAffiliateUpdateMutation($input: TenantAffiliateUpdateInput!) {
      tenantAffiliate {
        update(payload: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantAffiliatesListQuery],
  selector: ['tenantAffiliate', 'update'],
};

export const tenantAffiliateRemoveMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation tenantAffiliateRemoveMutation($input: TenantAffiliateRemoveInput!) {
      tenantAffiliate {
        remove(payload: $input) {
          id
        }
      }
    }
  `,
  refetch: [tenantAffiliatesListQuery],
  selector: ['tenantAffiliate', 'remove'],
};
