// @flow
import {compose, isNil, not, prop} from 'ramda';
import type {Selector} from 'types/Redux';

import type {User} from './graphql/types';
import type {AuthReducerState} from './reducer';

const base: Selector<AuthReducerState> = prop('auth');

export const selectUser: Selector<?User> = compose(prop('user'), base);
export const selectLoggedIn: Selector<boolean> = compose(not, isNil, selectUser);
