// @flow
import CurrencyInput from 'components/CurrencyInput';
import FieldWrap, {type Props as FieldWrapProps} from 'components/Inputs/FieldWrap';
import type {ReqFieldProps} from 'hoc/withField';
import withField from 'hoc/withField';
import * as React from 'react';
import type {HOC} from 'recompose';

const InputCurrency = ({fieldWrapProps, disabled, currencyName, error, ...props}) => (
  <FieldWrap disabled={disabled} error={error} {...fieldWrapProps}>
    <CurrencyInput disabled={disabled} currencyName={currencyName} {...props} />
  </FieldWrap>
);

type UsedFieldWrapProps = {
  children: React.Node,
  error: ?string,
  disabled?: boolean,
};

type Outter = {|
  ...$Exact<ReqFieldProps>,
  disabled?: boolean,
  currencyName?: string,
  fieldWrapProps?: $Diff<FieldWrapProps, UsedFieldWrapProps>,
  'data-cy'?: string,
|};

const enhancer: HOC<*, Outter> = withField;

export default enhancer(InputCurrency);
