import {required} from 'common/validators';
import * as yup from 'yup';

export default yup
  .object()
  .shape({
    oldPassword: required,
    newPassword: required,
    confirmNewPassword: required,
  })
  .test('passwordsDoNotMatch', function (values) {
    if (!values.newPassword || !values.confirmNewPassword) {
      return false;
    } else if (values.newPassword !== values.confirmNewPassword) {
      return this.createError({
        path: 'passwordsDoNotMatch',
        message: 'Passwords do not match',
      });
    } else {
      return true;
    }
  });
