// @flow
import Button from 'components/Button';
import InputAccessories from 'components/Inputs/InputAccessories';
import InputPrices from 'components/Inputs/InputPrices';
import InputText from 'components/Inputs/InputText';
import AffiliateSelect from 'containers/AffiliateSelect';
import {formatFromCentum, formatToCentum} from 'data/units/money/formatters';
import crypto from 'global/apolloClient/crypto';
import enums from 'global/enums';
import withForm from 'hoc/withForm';
import {path} from 'ramda';
import React from 'react';

import schema from './schema';
import {StyledSpread} from './styled';

const getCurrency = data => {
  const currency = path(['affiliate', 'location', 'country', 'currency'], data);
  return currency ? currency.toString() : '';
};

const getAffiliateSelect = (createAction: boolean, role: string) =>
  createAction && (role === enums.roles.ADMIN || role === enums.roles.SUPERADMIN) ? (
    <AffiliateSelect name="affiliateId" label="store" />
  ) : (
    ''
  );

type Props = {
  isSubmitting: boolean,
  data: any,
};

const PricingTemplateEdit = ({isSubmitting, data}: Props) => (
  <div>
    {getAffiliateSelect(!data, crypto.getRoleFromAccessToken())}
    <InputText name="name" label="name" />
    <StyledSpread>
      <InputPrices
        name="prices"
        label={`prices (${getCurrency(data)})`}
        currency={getCurrency(data)}
      />
      <InputAccessories name="accessories" label="accessories" />
    </StyledSpread>
    <Button kind="primary" loading={isSubmitting}>
      {data ? 'save' : 'Create pricing template'}
    </Button>
  </div>
);

export default withForm({
  schema,
  mapProps: props => {
    const currency = getCurrency(props.data);

    return {
      ...props.data,
      prices:
        props.data && props.data.prices
          ? props.data.prices.map(price => formatFromCentum(price, currency))
          : [1, 1, 1, 1, 1],
      accessories:
        props.data && props.data.accessories
          ? props.data.accessories.map(acc => ({
              ...acc,
              prices: acc.prices.map(p => formatFromCentum(p, currency)),
            }))
          : [],
    };
  },
  onSubmit: props => values =>
    props.submit({
      input: {
        ...values,
        prices: values.prices.map(price => formatToCentum(price)),
        accessories: values.accessories.map(acc => ({
          ...acc,
          prices: acc.prices.map(p => formatToCentum(p)),
        })),
      },
    }),
})(PricingTemplateEdit);
