// @flow
import FieldWrap from 'components/Inputs/FieldWrap';
import withField, {type ReqFieldProps} from 'hoc/withField';
// $Import
import React, {ChangeEvent} from 'react';
import type {HOC} from 'recompose';
import {compose, withHandlers} from 'recompose';

import {StyledInputColor, StyledInputColorValue, StyledInputColorWrap} from './styled';

const InputColor = ({handleChange, ...props}) => (
  <FieldWrap {...props}>
    <StyledInputColorWrap>
      <StyledInputColorValue {...props} onChange={e => handleChange(e)} />
      <StyledInputColor {...props} type="color" onChange={e => handleChange(e)} />
    </StyledInputColorWrap>
  </FieldWrap>
);

const enhancer: HOC<*, ReqFieldProps> = compose(
  withField,
  withHandlers({
    handleChange: props => (event: ChangeEvent<HTMLInputElement>) => {
      const color = event.target.value;
      props.onChange(color.toUpperCase());
    },
  })
);

export default enhancer(InputColor);
