import React, {useEffect, useMemo, useState} from 'react';
import SelectCreatable from 'react-select/creatable';

/**
 * Newly created option will be of the form {label, value} with a __isNew__ flag.
 * This wrapper is used to correctly get the value/label for this new option.
 */
const creatableGetOptionLabelWrapper =
  (labelGetter: SelectOption => string) => (option: SelectOption) =>
    option.__isNew__ ? option.label : labelGetter(option);
const creatableGetOptionValueWrapper =
  (valueGetter: SelectOption => SimpleValue) => (option: SelectOption) =>
    option.__isNew__ ? option.value : valueGetter(option);

const createNewOption = value => ({
  __isNew__: true,
  label: value,
  value,
});

const SelectCreatableComponent = ({
  getOptionLabel,
  getOptionValue,
  onCreateOption,
  isMulti,
  isLoading,
  value,
  onChange,
  options,
  ...rest
}) => {
  const [createIsLoading, setCreateIsLoading] = useState(false);

  /**
   * When we want to create a new option, we must wait for it to be
   * created in the backend asynchronously to get its ID and set it
   * properly. While we wait, we set the new option as the `tempCreatedOption`
   * in order to display it as the selected value (or in the list of
   * selected values when using multiple values).
   */
  const [tempCreatedOption, setTempCreatedOption] = useState(null);
  const selectedValue = useMemo(() => {
    if (isMulti && Array.isArray(value)) {
      return [...value, tempCreatedOption].filter(Boolean);
    }
    return tempCreatedOption ?? value;
  }, [isMulti, value, tempCreatedOption]);

  /**
   * Wrap our label and value getters in order to be able to display
   * newly created options.
   */
  const getLabelFn = creatableGetOptionLabelWrapper(getOptionLabel);
  const getValueFn = creatableGetOptionValueWrapper(getOptionValue);

  /**
   * When we create a new option we create a temporary option object
   * as `tempCreatedOption` while we wait for the option to be created
   * asynchornously via `onCreateOption`. Once the option is created
   * we call the onChange handler to set the new option as the selected
   * value.
   * NOTE: we don't clear the temporary object (`tempCreatedOption`) after
   * successfully creating the new option and calling the onChange handler.
   * This is because the options list will not yet be updated with the newly
   * created option and hence it will not display properly. We only clear
   * the temporary object once we see it has been added to the options list
   * (see useEffect below).
   */
  const handleCreateOption = async v => {
    try {
      setCreateIsLoading(true);
      setTempCreatedOption(createNewOption(v));
      const createdOption = await onCreateOption(v);
      if (isMulti) {
        onChange([...(value || []), createdOption]);
      } else {
        onChange(createdOption);
      }
    } catch (e) {
      setTempCreatedOption(null);
      setCreateIsLoading(false);
    }
  };

  /**
   * When we see the temporarily created option is now amongst the options list
   * we can clear it.
   */
  useEffect(() => {
    if (
      tempCreatedOption &&
      options &&
      options.some(option => getLabelFn(option) === getLabelFn(tempCreatedOption))
    ) {
      setTempCreatedOption(null);
      setCreateIsLoading(false);
    }
  }, [options, getLabelFn, tempCreatedOption]);

  return (
    <SelectCreatable
      {...rest}
      options={options}
      isMulti={isMulti}
      onChange={onChange}
      getOptionLabel={getLabelFn}
      getOptionValue={getValueFn}
      onCreateOption={handleCreateOption}
      isLoading={isLoading || createIsLoading}
      value={selectedValue}
    />
  );
};

export default SelectCreatableComponent;
