// @flow
// @author: T04435
// Date: 9/7/21

import {faBarsFilter, faTrashCanList} from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import {isEmpty, mapObjIndexed, omit, values} from 'ramda';
import * as React from 'react';
import {Fragment} from 'react';
import {Collapse} from 'react-collapse/lib/Collapse';
import {withStateHandlers} from 'recompose';

import {ClearFilterButton, FilterControls, FilterInputs, FilterWrap} from './styled';

type ComponentProps = {
  value: any,
  onChange: Function,
};

export type FilterConfig = {
  // Always have the "query" key first
  [string]: {
    type: React.ComponentType<ComponentProps>,
    props?: {},
  },
};

type Props = {
  filterConfig: FilterConfig,
  update: (k: string, v: any) => void,
  clear: () => void,
  filter: {},
  defaultFilters: string[],
  setDefaultFilters: Function,
  isOpen: boolean,
  toggleOpen: () => void,
};

const Filter = ({
  filterConfig,
  update,
  filter,
  clear,
  defaultFilters,
  setDefaultFilters,
  isOpen,
  toggleOpen,
}: Props) => {
  /**
   * Set the values on the filter when provided in the filterConfig
   */
  const setInitialValue = () => {
    const defaultValues = [];
    if (isEmpty(filter)) {
      values(
        mapObjIndexed((v, k) => {
          const props = v.props ? v.props : {};
          const name = props.name ? props.name : k;
          const value = props.value ? props.value : null;
          if (value) {
            defaultValues.push(name);
            // $Ramda
            update(name, value);
          }
        }, filterConfig)
      );
    }
    setDefaultFilters(defaultValues);
    return null;
  };

  /**
   * Helper to count the active filters this count excludes any {@link defaultFilters} and the 'query'
   * @returns {number} Active filter count
   */
  const getActiveFilterCount = (): number => {
    // $Ramda
    const isActiveFilter = value => !isEmpty(value) && value != null; // Only consider values that are not empty or null/undefined
    // $Ramda
    return Object.values(omit(['query', ...defaultFilters], filter)).filter(isActiveFilter).length;
  };

  /**
   * Helper to know if there are any filter other than the query in the {@link FilterConfig}
   * @returns {boolean} true if any filter other than query, otherwise false
   */
  const hasFilters = (): boolean => {
    return Boolean(Object.keys(omit(['query', ...defaultFilters], filterConfig)).length);
  };

  /**
   * Helper to get the content of button Filters
   * @returns {string}
   */
  const renderFilters = () => {
    const count = getActiveFilterCount();
    switch (count) {
      case 0:
        return 'filter';
      case 1:
        return '1 filter';
      default:
        return `${count} filters`;
    }
  };

  return (
    <Fragment>
      {setInitialValue()}
      <FilterWrap>
        <FilterInputs>
          {Object.keys(filterConfig)
            .filter(key => key === 'query')
            .map(key => {
              const value = filterConfig[key];
              const Component = value.type;
              const props = value.props ? value.props : {};
              return (
                <Component
                  className="query"
                  key={key}
                  onChange={v => update(key, v)}
                  value={filter[key]}
                  {...props}
                />
              );
            })}
        </FilterInputs>
        {hasFilters() && (
          <FilterControls>
            <Button kind="tertiary" iconStart={faBarsFilter} onClick={toggleOpen}>
              {renderFilters()}
            </Button>
          </FilterControls>
        )}
      </FilterWrap>
      <Collapse isOpened={isOpen}>
        <FilterWrap>
          <FilterInputs>
            {Object.keys(filterConfig)
              .filter(key => key !== 'query')
              .map(key => {
                const value = filterConfig[key];
                const Component = value.type;
                const props = value.props ? value.props : {};
                return (
                  <Component
                    key={key}
                    onChange={v => update(key, v)}
                    value={filter[key]}
                    {...props}
                  />
                );
              })}
          </FilterInputs>
          <FilterControls>
            <ClearFilterButton
              kind="primary"
              danger
              onClick={clear}
              disabled={getActiveFilterCount() === 0}
              iconStart={faTrashCanList}
            />
          </FilterControls>
        </FilterWrap>
      </Collapse>
    </Fragment>
  );
};

const enhancer = withStateHandlers(
  {
    defaultFilters: [],
    isOpen: false,
  },
  {
    setDefaultFilters: props => value => {
      if (value.length) {
        props.defaultFilters = value;
      }
    },
    toggleOpen: props => () => ({isOpen: !props.isOpen}),
  }
);
export default enhancer(Filter);
