import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './introspectionQueryResultData';

// needed for dynamic params in products
// source: https://www.apollographql.com/docs/react/recipes/fragment-matching.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// NOTE(Barry): We need to define type policies for objects that follow the data modelling guidelines. This is because
// the apollo cache uses the `id` field by default as the unique ID used to cache objects. Since our objects don't have
// these fields we need to tell apollo what fields to use. If you don't you will end up with unexpected crashes.
// Ref: https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
// Ref: https://stackoverflow.com/questions/44403930/error-network-error-error-writing-result-to-store-for-query-apollo-client
const typePolicies = {
  Booking: {
    keyFields: ['bookingId'],
  },
  Order: {
    keyFields: ['orderId'],
  },
  Category: {
    keyFields: ['categoryId'],
  },
};

const cache = new InMemoryCache({
  fragmentMatcher,
  typePolicies,
});

export default cache;
