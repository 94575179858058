// @flow
import type {FilterConfig} from 'components/Filter';
import Table from 'components/Table';
import {type TableDefinition} from 'components/Table/types';
import crypto from 'global/apolloClient/crypto';
import withFilter from 'hoc/withFilter';
import withQuery from 'hoc/withQuery';
import withToastAndRedirect from 'hoc/withToastAndRedirect';
import type {CRUDRoute} from 'pages/urls';
import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import type {Mutation, Query} from 'types/Graphql';

import Section from '../../components/Section';
import Controls from './Controls';
import Title from './Title';

const isFilterRole = (filterConfigRoles?: string[], role: string) =>
  filterConfigRoles ? filterConfigRoles.includes(role) : true;

export type DetailProps<T> = $Supertype<{
  data: T,
}>;

export type EditProps = {
  create: boolean,
  data: *,
};

export type EditAllProps = any;

type Config<Detail, List> = {
  edit?: React.ComponentType<EditProps>,
  // The name of the Entity used on the CRUD actions ie: `add "product"`
  entityName?: string,
  detail?: React.ComponentType<DetailProps<Detail>>,
  // The Page title on the CRUD pages
  title: string,
  tableDefinition?: TableDefinition<List>,
  detailQuery?: Query<Detail>,
  listQuery?: Query<List[]>,
  deleteMutation?: Mutation<*, *>,
  deleteMessage?: string,
  updateMutation?: Mutation<*, *>,
  updateAllMutation?: Mutation<*, *>,
  createMutation?: Mutation<*, *>,
  route: CRUDRoute,
  filterConfig?: FilterConfig,
  filterConfigRoles?: string[],
  editAll?: React.ComponentType<EditAllProps>,
  // Specifies the key of the ID value used, intended for use with tables that follow the updated data modelling
  // guidelines where the identifier is not called id
  idName?: string,
};

function createEntityCRUD<D, T>({
  title,
  edit,
  entityName,
  detail,
  tableDefinition,
  detailQuery,
  listQuery,
  deleteMutation,
  deleteMessage,
  updateMutation,
  updateAllMutation,
  createMutation,
  filterConfig,
  route,
  filterConfigRoles,
  editAll,
  idName,
}: Config<D, T>) {
  // The detailHOC(withQuery) is needed by both detail and/or update

  // Entity has an edit page if: there is an mutation to update the entity, there is an edit page component and there is a query to fetch the detail of the entity
  const hasEdit = !!updateMutation && !!edit && detailQuery;
  // Entity has a detail page if: there is a detail component and there is a query to fetch the detail of the entity
  const hasDetail = !!detail && detailQuery;
  // Entity has a detail/Edit HOC if: there is a detail OR an Edit page
  const hasStringId = idName !== undefined;
  const editAndDetailHoc =
    (hasDetail || hasEdit) && detailQuery && withQuery(detailQuery, {hasStringId});
  // $FalsePositive
  const DetailComponent = hasDetail && editAndDetailHoc(detail);

  const EditComponent =
    hasEdit &&
    withToastAndRedirect(
      // $FalsePositive
      updateMutation,
      match =>
        hasDetail ? route.link.detail(match.params.id) : route.link.update(match.params.id),
      {hasStringId}
      // $FalsePositive
    )(editAndDetailHoc(edit));

  const hasEditAll = !!updateAllMutation;
  const editAllHoc =
    hasEditAll &&
    withToastAndRedirect(
      // $Dunno
      updateAllMutation,
      match => route.link.list,
      {hasStringId}
    );

  // $Dunno
  const EditAllComponent = hasEditAll && editAllHoc(editAll);

  const hasCreate = !!createMutation;
  const CreateComponent =
    hasCreate &&
    // $FalsePositive
    withToastAndRedirect(createMutation, (_, res) => {
      const id = idName ? res[idName] : res.id;
      return hasDetail ? route.link.detail(id) : route.link.update(id);
    })(
      // $FalsePositive
      edit
    );

  const hasList = !!listQuery;
  const ListComponent =
    // $FalsePositive
    hasList && withQuery(listQuery, {noLoader: true, noEmpty: true})(Table);

  const ControlsComponent = deleteMutation
    ? // $FalsePositive
      withToastAndRedirect(deleteMutation, () => route.link.list, {pageTitle: title})(Controls)
    : Controls;

  return () => {
    const isRoleFilter = isFilterRole(filterConfigRoles, crypto.getRoleFromAccessToken());
    const FilteredListComponent =
      filterConfig && isRoleFilter
        ? // $FalsePositive
          withFilter(ListComponent)
        : ListComponent;

    return (
      <React.Fragment>
        <Title name={title} nameSingular={entityName || title} route={route} />

        <Switch>
          {hasList && (
            <Route
              path={route.path.list}
              render={() => (
                <Section>
                  {/* $Dunno */}
                  <FilteredListComponent
                    tableDefinition={tableDefinition}
                    filterConfig={filterConfig && isRoleFilter ? filterConfig : null}
                  />
                </Section>
              )}
            />
          )}
          {detailQuery && <Route path={route.path.detail} component={DetailComponent} />}
          {hasEdit && <Route path={route.path.update} component={EditComponent} />}
          {hasCreate && (
            // $Dunno
            <Route path={route.path.create} render={() => <CreateComponent create />} />
          )}
          {hasEditAll && <Route path={route.path.editAll} component={EditAllComponent} />}

          <Redirect to={route.path.list} />
        </Switch>

        {/* $Dunno */}
        <ControlsComponent
          route={route}
          hasCreate={!!createMutation}
          hasEdit={hasEdit}
          hasDetail={hasDetail}
          hasEditAll={hasEditAll}
          deleteMessage={deleteMessage}
          title={title}
          entityName={entityName || title}
        />
      </React.Fragment>
    );
  };
}

export default createEntityCRUD;
