// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {bundleDetailQuery, bundleListQuery} from './queries';

export const bundleUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation bundleUpdateMutation($input: BundleUpdateInput!) {
      bundle {
        updateBundle(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [bundleDetailQuery],
};

export const bundleCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation bundleCreateMutation($input: BundleCreateInput!) {
      bundle {
        createBundle(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [bundleListQuery],
  selector: ['bundle', 'createBundle'],
};

export const bundleDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation bundleDeleteMutation($id: Int!) {
      bundle {
        deleteBundle(id: $id)
      }
    }
  `,
  refetch: [bundleListQuery],
};

export const bundleComponentCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation bundleComponentCreateMutation($input: BundleComponentCreateInput!) {
      bundle {
        createBundleComponent(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [bundleDetailQuery],
  selector: ['bundle', 'createBundleComponent'],
};

export const bundleComponentUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation bundleComponentUpdateMutation($input: BundleComponentUpdateInput!) {
      bundle {
        updateBundleComponent(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [bundleDetailQuery],
};
