// @flow
import {assocPath, path, pathEq, pathOr} from 'ramda';

import {getPaginationPath} from './pagination';

export const getDataPath = (selector: string[], pagination: boolean) => {
  // $Fixme - CRA migration
  if (pagination) return selector.concat('data');

  return selector;
};

export const mergeData = (prev: mixed[], next: mixed[], selector: string[]) => {
  const dataPath = getDataPath(selector, true);
  const paginationPath = getPaginationPath(selector);

  const oldData = path(dataPath, prev);
  const newData = path(dataPath, next);
  // $Ramda
  const withNewData = assocPath(dataPath, [...oldData, ...newData], prev);

  const newPagination = path(paginationPath, next);

  return assocPath(paginationPath, newPagination, withNewData);
};

export const mergeSubscriptionData = (
  prev: mixed[],
  subscriptionData: mixed[],
  selector: string[],
  subscriptionSelector: string[]
) => {
  const dataPath = getDataPath(selector, true);

  const subscriptionPath = getDataPath(subscriptionSelector, false);

  const oldData = path(dataPath, prev);
  const newData = path(subscriptionPath, subscriptionData);

  // $Ramda
  return assocPath(dataPath, [...oldData, ...[newData]], prev);
};

export const notFound = (data: {}) => {
  const gqlErrors = pathOr([], ['error', 'graphQLErrors'], data);
  return gqlErrors.find(pathEq(['data', 'code'], 'E_NOT_FOUND'));
};
