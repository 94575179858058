// @flow
import Select from 'components/Select';
import {Label} from 'componentsStyled/Typography';
import {productListQueryMinimal} from 'data/product/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';

const ProductSelect = ({data, ...rest}) => (
  <div>
    <Label>Product</Label>
    <Select options={data} {...rest} />
  </div>
);

export default withQuery(productListQueryMinimal)(ProductSelect);
