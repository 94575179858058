// @flow
import {reservationDetailQuery, reservationListQuery} from 'data/reservations/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import filterConfig from './filterConfig';
import ReservationDetail from './ReservationDetail';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  detail: ReservationDetail,
  detailQuery: reservationDetailQuery,
  listQuery: reservationListQuery,
  tableDefinition,
  filterConfig,
  title: 'Reservations',
  entityName: 'Reservation',
  route: urls.reservation,
});

const enhancer = withHelpCenter({category: 'renting'});

export default enhancer(Page);
