// @flow
import {noopAction} from 'common/actions';
import {push} from 'connected-react-router';
import {notificationError} from 'data/notifications/actions';
import {purge} from 'global/apolloClient/helpers';
import enums from 'global/enums';
import urls from 'pages/urls';
import {ofType} from 'redux-observable';
import {of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {logout, logoutRedirect} from './actions';

const loginEpic = (action$: any) =>
  action$.pipe(
    ofType('LOGIN'),
    switchMap(x => {
      if (
        ![
          enums.roles.ADMIN,
          enums.roles.AFFILIATE,
          enums.roles.AFFILIATE_EMPLOYEE,
          enums.roles.SUPERADMIN,
        ].includes(x.payload.role)
      ) {
        return of(notificationError('Only admin or affiliate user can log in!'), logoutRedirect());
      }
      return of(noopAction());
    })
  );

const logoutRedirectEpic = (action$: any) =>
  action$.pipe(
    ofType('LOGOUT_REDIRECT'),
    switchMap(() => {
      return of(push(urls.login), logout());
    })
  );

const logoutEpic = (action$: any) =>
  action$.pipe(
    ofType('LOGOUT'),
    map(() => {
      purge();
      return noopAction();
    })
  );

// eslint-disable-next-line import/no-anonymous-default-export
export default [loginEpic, logoutRedirectEpic, logoutEpic];
