// @flow
import Filter from 'components/Filter';
import Loader from 'components/Loader';
import withRouter from 'hoc/withRouter';
import {pathOr, reverse, sortBy} from 'ramda';
import React from 'react';
import type {HOC} from 'recompose';
import {compose, withHandlers, withProps, withStateHandlers} from 'recompose';

import {isScrollEnd} from '../../common/events';
import {StyledTable, TableWrap} from './styled';
import Td from './Td';
import Th from './Th';
import type {ColumnDef, TableDefinition} from './types';

const defaultSort = (column: ColumnDef<*>) => (x: any) => {
  if (!column.value(x)) return '';

  const value = column.value(x);
  return typeof value === 'string' ? value.toLowerCase() : value;
};

const Table = ({
  sortedData,
  tableDefinition: {columns, rowLink, rowLinkState},
  sortingBy,
  sortAscending,
  handleSort,
  loading,
  handleLoadMore,
  filterConfig,
  filter,
  updateFilter,
  clearFilter,
  history,
}) => {
  const handleRowClick = item => {
    if (rowLink) {
      const url = rowLink(item);
      const linkState = rowLinkState ? rowLinkState(item) : undefined;
      history.push(url, linkState);
    }
  };

  return (
    <React.Fragment>
      {filterConfig && (
        <Filter
          filterConfig={filterConfig}
          filter={filter}
          update={updateFilter}
          clear={clearFilter}
        />
      )}
      <TableWrap onScroll={handleLoadMore} data-cy={'table'}>
        <StyledTable>
          <thead>
            <tr>
              {columns.map(def => (
                <Th
                  key={def.title}
                  column={def}
                  // $Dunno
                  sortingBy={sortingBy}
                  sortAscending={sortAscending}
                  onSort={handleSort}
                />
              ))}
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody>
              {Array.isArray(sortedData) &&
                sortedData.map((item, rowIndex) => (
                  <tr
                    data-link={Boolean(rowLink)}
                    key={rowIndex}
                    onClick={() => handleRowClick(item)}
                  >
                    {columns.map(def => (
                      <Td key={def.title} item={item} definition={def} />
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
        </StyledTable>
      </TableWrap>
    </React.Fragment>
  );
};

type Enhancer<T> = {
  loading?: boolean,
  data: T[],
  tableDefinition: TableDefinition<T>,
  loadMore?: Function,
};

type State = {
  sortingBy: ?ColumnDef<*>,
  sortAscending: boolean,
};

const enhancer: HOC<*, Enhancer<*>> = compose(
  withRouter,
  withStateHandlers(
    ({
      sortingBy: null,
      sortAscending: true,
    }: State),
    {
      handleSort: props => (columnDefinition: ColumnDef<*>) => ({
        sortingBy: columnDefinition,
        sortAscending: props.sortingBy === columnDefinition ? !props.sortAscending : true,
      }),
    }
  ),
  withProps(props => {
    const data = props.data || [];
    // $Ramda
    const sortFunction = pathOr(defaultSort, ['sortingBy', 'sortFunction'], props);
    const sorted = props.sortingBy ? sortBy(sortFunction(props.sortingBy), data) : data;
    return {
      sortedData: props.sortAscending ? sorted : reverse(sorted),
    };
  }),
  //$Fixme CRA migration
  withHandlers({
    handleLoadMore: props => e => {
      if (isScrollEnd(e) && props.loadMore) {
        props.loadMore();
      }
    },
  })
);

export default enhancer(Table);
