// @flow
import type {Selector} from 'common/redux/types';
import {compose, prop} from 'ramda';

import type {AppState} from './reducer';
import type {AppConfig} from './types';

const base: Selector<AppState> = prop('app');

export const selectAppConfig: Selector<?AppConfig> = compose(prop('appConfig'), base);
