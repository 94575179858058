// @flow
import {formatBool} from 'common/formatters';
import {Link} from 'componentsStyled/Typography';
import type {DetailResult} from 'data/reservations/queries';
import {formatCurrencyFromCents} from 'data/units/money/formatters';
import urls from 'pages/urls';
import React from 'react';

import Section from '../../../components/Section';
import {LayoutFlex} from '../../../componentsStyled/Layout';
import AdhocChargeForm from './AdhocChargeForm';
import DamagesList from './DamagesList';
import ReservationPaymentsTable from './ReservationPaymentsTable';

type Props = {
  data: DetailResult,
};

const ReservationDetail = ({data}: Props) => {
  const {reservation, damages} = data;
  const {pricingDetail} = reservation;

  return (
    <React.Fragment>
      {/* TODO(T04435): Add reservation status to page title from Crud */}
      <Section title="booking details">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>reservation code</h4>
            <p>{reservation.externalId}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>booking code</h4>
            <p>{reservation.booking.externalId}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>order code</h4>
            <p>{reservation.booking.order.externalId}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>dates</h4>
            <p>
              {reservation.booking.start} - {reservation.booking.end}
            </p>
          </LayoutFlex>
        </LayoutFlex>
        <LayoutFlex>
          <LayoutFlex column col half>
            <h4>Store</h4>
            <Link
              to={urls.shop.link.detail(reservation.productItem.affiliate.id)}
              children={reservation.productItem.affiliate.name}
              component="a"
              redirect={false}
            />
          </LayoutFlex>
          <LayoutFlex column col half>
            <h4>customer</h4>
            {reservation.booking.order.customer.user ? (
              <Link
                to={urls.customers.link.detail(reservation.booking.order.customer.user.id)}
                children={reservation.booking.order.customer.user.email}
                redirect={false}
                component="a"
              />
            ) : (
              <p>Walkin</p>
            )}
          </LayoutFlex>
        </LayoutFlex>
      </Section>
      <Section title="product details">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Asset ID</h4>
            <p>{reservation.productItem.code}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>price (before discount)</h4>
            <p>
              {pricingDetail &&
                formatCurrencyFromCents(pricingDetail.fullPrice, undefined, pricingDetail.currency)}
            </p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Tax</h4>
            <p>{formatCurrencyFromCents(pricingDetail.tax, undefined, pricingDetail.currency)}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Total price</h4>
            <p>
              {pricingDetail &&
                formatCurrencyFromCents(
                  pricingDetail.totalPrice,
                  undefined,
                  pricingDetail.currency
                )}
            </p>
          </LayoutFlex>
        </LayoutFlex>
      </Section>
      <Section title="discounts">
        {/* TODO(T04435): Redesign this not currently in Figma */}
        {pricingDetail && pricingDetail.discounts && pricingDetail.discounts.length > 0 ? (
          pricingDetail.discounts.map(d => (
            <p>
              <h4>Type:</h4> {d.type} &nbsp; &nbsp;
              <h4>Percentage:</h4> {d.percentage} &nbsp; &nbsp;
              <h4>Details:</h4> {d.detail || `-`} &nbsp; &nbsp;
            </p>
          ))
        ) : (
          <p className={'capitalized'}>no discounts</p>
        )}
      </Section>
      <Section title="payments">
        <ReservationPaymentsTable data={reservation.reservationTransactions} />
        <br />
        <AdhocChargeForm reservationId={reservation.id} />
      </Section>
      <Section title="other">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Deleted</h4>
            {/* $Fixme - CRA migration */}
            <p>{formatBool(reservation.deletedAt)}</p>
          </LayoutFlex>
        </LayoutFlex>
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Damages</h4>
            <DamagesList data={damages} />
          </LayoutFlex>
        </LayoutFlex>
      </Section>
    </React.Fragment>
  );
};

export default ReservationDetail;
