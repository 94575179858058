// @flow
import InputCheckbox from 'components/Inputs/InputCheckbox';
import InputCurrency from 'components/Inputs/InputCurrency';
import InputNumber from 'components/Inputs/InputNumber';
import {Divider} from 'componentsStyled/Layout';
import {locationListQuery} from 'data/location/queries';
import {type Location} from 'data/location/types';
import withQuery from 'hoc/withQuery';
import {
  CurrencyLabel,
  InlineNumberLabel,
  StyledDeliverySection,
  StyledTextEditor,
} from 'pages/Affiliate/AffiliateEdit/DeliveryFulfilmentSection/styled';
import {path} from 'ramda';
import React from 'react';

export type Props = {
  formValues: {
    allowDeliveryFulfilment: boolean,
    allowDeliveryBookingExtension: boolean,
    enableDeliveryChargeThreshold: boolean,
    locationId: number,
  },
  setFieldToZeroIfEmpty: Function,
  data: Location[],
};

export const DeliveryFulfilmentSection = ({formValues, data, setFieldToZeroIfEmpty}: Props) => {
  const {
    allowDeliveryFulfilment,
    allowDeliveryBookingExtension,
    enableDeliveryChargeThreshold,
    locationId,
  } = formValues || {};

  const selectedLocation = data && data.find(location => location.id === locationId);
  // $Ramda
  const currency: number | void = path(['country', 'currency'], selectedLocation);

  const disabled = !allowDeliveryFulfilment;
  const bookingExtensionDisabled = disabled || !allowDeliveryBookingExtension;
  const thresholdDisabled = disabled || !enableDeliveryChargeThreshold;

  return (
    <StyledDeliverySection includeTitle title="DELIVERY FULFILMENT">
      <InputCheckbox
        label="Enable"
        labelPosition="right"
        name="allowDeliveryFulfilment"
        data-cy="enable-delivery-fulfillment"
      />
      <InputCheckbox
        label="Allow delivery bookings to be extended"
        labelPosition="right"
        name="allowDeliveryBookingExtension"
        disabled={disabled}
        data-cy="enable-delivery-booking-extensions"
      />
      <InlineNumberLabel disabled={bookingExtensionDisabled}>
        Up to
        <InputNumber
          maxWidth={8}
          min={0}
          interval={1}
          name="deliveryExtensionCutoffDays"
          disabled={bookingExtensionDisabled}
          fieldWrapProps={{hideErrorText: true}}
          onBlur={() => setFieldToZeroIfEmpty('deliveryExtensionCutoffDays')}
          data-cy="delivery-booking-extension-cutoff-days"
        />
        days before the rental period ends
      </InlineNumberLabel>
      <StyledTextEditor
        name="deliveryFulfilmentInstructions"
        label="Delivery fulfilment instructions"
        hint="Appears when staff are checking out a delivery booking."
        disabled={disabled}
        data-cy="delivery-fulfillment-instructions"
      />
      <Divider />
      <CurrencyLabel disabled={disabled}>Delivery charge</CurrencyLabel>
      <InputCurrency
        name="deliveryCharge"
        fieldWrapProps={{
          labelPosition: 'right',
          label: 'per booking for this store',
        }}
        disabled={disabled}
        currencyName={currency}
        data-cy="delivery-charge"
      />
      <InputCheckbox
        name="enableDeliveryChargeThreshold"
        label="No charge for total over"
        labelPosition="right"
        disabled={disabled}
        data-cy="enable-delivery-charge-threshold"
      />
      <InputCurrency
        name="deliveryChargeThreshold"
        disabled={thresholdDisabled}
        currencyName={currency}
        data-cy="delivery-charge-threshold"
      />
      <Divider />
      <StyledTextEditor
        name="deliverySummary"
        label="Delivery summary"
        hint="Appears on the product page when a customer chooses Delivery. This should be a short
         description of your delivery service, e.g. Delivery available in Greater Sydney."
        disabled={disabled}
        data-cy="delivery-summary"
      />
      <StyledTextEditor
        name="logisticsSummary"
        label="Logistics summary"
        hint="Appears when customers are choosing their rental dates, and in their delivery 
        notification email. This should describe how a customer’s rental will be delivered, 
        e.g. Your delivery will arrive on the first day of your booking via courier."
        disabled={disabled}
        data-cy="logistics-summary"
      />
      <StyledTextEditor
        name="deliveryExplanation"
        label="Delivery explanation"
        hint="Appears when customers are checking out, and in their booking confirmation email.
         This should explain how delivery and return of rentals works for your store."
        disabled={disabled}
        data-cy="delivery-explanation"
      />
      <StyledTextEditor
        label="Return Instructions"
        name="returnInstructions"
        hint="Appears in return reminder emails. This should detail the steps for returning a 
        rental, e.g. Place the return label on the outside of the package and drop it off at 
        your local post office."
        disabled={disabled}
        data-cy="delivery-return-instructions"
      />
    </StyledDeliverySection>
  );
};

export default withQuery(locationListQuery)(DeliveryFulfilmentSection);
