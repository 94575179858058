import {emailLogDetailQuery, emailLogListQuery} from 'data/email/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import EmailDetail from 'pages/Email/EmailDetail';
import {StyledEmailLog} from 'pages/Email/EmailLog/styled';
import filterConfig from 'pages/Email/EmailTableConfig/filterConfig';
import baseTableDefinition from 'pages/Email/EmailTableConfig/tableDefinition';
import urls from 'pages/urls';

const tableDefinition = {
  ...baseTableDefinition,
  rowLink: v => urls.emailLogs.link.detail(v.emailId),
};

const Page = createEntityCRUD({
  title: 'Email Log',
  entityName: 'Email Log',
  route: urls.emailLogs,
  tableDefinition,
  detail: EmailDetail,
  filterConfig,
  detailQuery: emailLogDetailQuery,
  listQuery: emailLogListQuery,
  idName: 'emailId',
});

const StyledPage = () => (
  <StyledEmailLog>
    <Page />
  </StyledEmailLog>
);

export default StyledPage;
