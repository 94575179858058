import styled from 'styled-components';

import {pageSize} from '../../hoc/withQuery/pagination';

export const TableWrap = styled.div`
  overflow-y: auto;
  position: relative;
  max-height: calc(${props => props.theme.metrics.tableRowHeight} * ${pageSize});
`;

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tbody tr[data-link='true'] {
    cursor: pointer;
    transition: background-color 250ms ease-in;
    :hover {
      background-color: ${props => props.theme.palette.greyLighter};
    }
  }
`;
