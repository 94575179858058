// @flow
// @author: t04435
// Date: 8/7/21

import React from 'react';

import type {ColumnDef} from '../types';
import {StyledTd} from './styled';

type propTypes = {
  definition: ColumnDef<*>,
  item: any,
};

export const TABLE_COLUMN_ACTION_TITLE = 'actions';

const Td = ({definition, item}: propTypes) => {
  const {type, props: defProps, value: defValue, title, titleAttribute} = definition;
  const value = (defValue && defValue(item)) || '-';
  if (type) {
    const Component = type;
    const props = defProps ? defProps(item) : {};
    const {onClick, ...rest} = props;
    return (
      <StyledTd
        isAction={title === TABLE_COLUMN_ACTION_TITLE}
        title={titleAttribute && titleAttribute(item)}
      >
        <Component {...rest} onClick={e => (onClick ? onClick(e) : null)} value={value} />
      </StyledTd>
    );
  }
  return <StyledTd title={titleAttribute && titleAttribute(item)}>{value}</StyledTd>;
};

export default Td;
