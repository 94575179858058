// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {pricingTemplateDetailQuery, pricingTemplateListQuery} from './queries';

export const pricingTemplateCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation pricingTemplateCreateMutation($input: PricingTemplateInput!) {
      pricingTemplate {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [pricingTemplateListQuery],
  selector: ['pricingTemplate', 'create'],
};

export const pricingTemplateUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation pricingTemplateUpdateMutation($input: PricingTemplateUpdateInput!) {
      pricingTemplate {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [pricingTemplateDetailQuery],
  selector: ['pricingTemplate', 'update'],
};

export const addProductItemsMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation addProductItemsMutation($input: AddItemsInput!) {
      pricingTemplate {
        addProductItems(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [pricingTemplateDetailQuery],
  selector: ['pricingTemplate', 'addProductItems'],
};
