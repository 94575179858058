// @flow
import {affiliateFragment} from 'data/affiliate/fragments';
import gql from 'graphql-tag';

export const productItemFragment = gql`
  fragment productItemFragment on ProductItem {
    id
    code
    buyItRrp
    allowed
    status
    deletedAt
    affiliate {
      ...affiliateFragment
    }
    pricingTemplate {
      id
      name
    }
  }
  ${affiliateFragment}
`;
