// @flow

import styled, {css} from 'styled-components';

const tileSize = '12rem';
const tileGap = '1rem';

export const FileUploadGrid = styled.div`
  display: grid;
  min-height: calc(2 * ${tileSize} + ${tileGap});
  grid-auto-flow: row dense;
  grid-gap: ${tileGap};
  grid-template-columns: repeat(auto-fill, minmax(${tileSize}, 1fr));
  grid-auto-rows: ${tileSize};
`;

export const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
  padding: 0.8rem;
  border-radius: 0.4rem;
  min-height: calc(2 * ${tileSize} + ${tileGap});
  color: ${props => props.theme.palette.grey};
  border: 0.2rem dashed currentColor;
  text-align: center;
  cursor: pointer;

  svg,
  h4 {
    color: ${props => props.theme.palette.greyDark};
    /* TODO(t04435): ADMIN_UI Discuss with @toby  the ideal transition value and add it to theme/global
         so its defined in a single place. 
         */
    transition: color 250ms ease-in;
  }

  :hover {
    color: ${props => props.theme.palette.primary};
    svg,
    h4 {
      color: ${props => props.theme.palette.primary};
    }
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.palette.primary};
      svg,
      h4 {
        color: ${props => props.theme.palette.primary};
      }
    `}

  svg {
    margin-bottom: 1rem;
  }
`;

export const AllowedFilesInfo = styled.div`
  margin-top: 2rem;
`;

export const FilePreviewDelete = styled.button`
  opacity: 0;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  background-color: ${props => props.theme.palette.light};
  border: none;
  border-radius: 50%;
  color: ${props => props.theme.palette.error};
  transition: opacity 250ms ease-in;
`;

export const FilePreview = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.4rem;
  border: 0.2rem solid ${props => props.theme.palette.grey};

  &[draggable='true'] {
    cursor: move;
  }

  &:first-of-type {
    grid-area: span 2 / span 2;
  }

  & + ${StyledDropzone} {
    min-height: 100%;
    grid-column: inherit;

    ${AllowedFilesInfo} {
      display: none;
    }
  }

  &:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: background-color 250ms ease-in;
  }

  &:hover {
    &:before {
      background-color: ${props => props.theme.palette.greyDarker35};
    }

    > ${FilePreviewDelete} {
      opacity: 1;
    }
  }
`;
