// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';
import type {AppConfig} from './types';

export type AppState = {|
  /** Config passed from the backend to the frontend */
  appConfig: ?AppConfig,
|};

const initialState: AppState = {
  appConfig: undefined,
};

type Action = ExtractActionTypes<typeof actions>;

function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case 'APP/SET_CONFIG':
      return {
        ...state,
        appConfig: action.payload,
      };
    default:
      return state;
  }
}

export default appReducer;
