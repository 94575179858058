// @flow
import {
  faCalendarRange,
  faMoneyCheckDollarPen,
  faPlayCircle,
  faShirt,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Pill from 'components/Pills';
import Select from 'components/Select';
import AffiliateSelectNoForm from 'containers/AffiliateSelectNoForm';
import {dashboardStatisicsQuery} from 'data/statistics/queries';
import {momentToShortFormat} from 'data/units/date/formatters';
import theme from 'global/theme';
import withQuery from 'hoc/withQuery';
import type Moment from 'moment';
import moment from 'moment';
import React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';
import styled from 'styled-components';

import Section from '../../components/Section';
import {SectionWrap} from '../../components/Section/styled';
import {formatCurrencyFromCents, formatFromCentum} from '../../data/units/money/formatters';
import Chart from './chart';
import Reservations from './Reservations';
import StatCard from './StatCard';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  & > div {
    display: flex;
    align-items: center;
  }

  h1 {
    margin: 0;
    margin-right: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(152px, 1fr);
  grid-gap: 24px;

  h1 {
    display: flex;
    align-items: center;
  }

  strong {
    margin-left: 1.6rem;
    font-size: 1rem;
  }

  ${SectionWrap} {
    margin-bottom: 0;
  }

  [data-chart='true'] {
    grid-column: span 3;
    grid-row: span 2;
  }

  [data-guide='true'] {
    grid-row: span 2;
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      flex-grow: 1;

      & > a {
        color: inherit;
        text-decoration: none;
        display: block;

        & > * {
          display: inline-block;
        }

        & > div {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: inline-flex;
          background: ${props => props.theme.palette.primaryLight};
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          margin-right: 16px;
          color: ${props => props.theme.palette.primary};
        }

        & > p {
          font-size: 14px;
          line-height: 24px;
        }

        &:hover {
          color: ${props => props.theme.palette.primary};
        }
      }
    }
  }

  [data-table='true'] {
    grid-column: span 2;
  }
`;

const spotlightStatConfigs = {
  revenue: {
    chart_title: 'Revenue Chart',
    'y-axis-id': 'revenue-y-axis',
    currentPeriod: 'revenueForCurrentPeriod',
    previousPeriod: 'revenueForPreviousPeriod',
    tick_callback: value => formatCurrencyFromCents(value * 100),
    conversion: value => formatFromCentum(value),
  },
  days_booked: {
    chart_title: 'Days Booked Chart',
    'y-axis-id': 'days-booked-y-axis',
    currentPeriod: 'daysBookedForCurrentPeriod',
    previousPeriod: 'daysBookedForPreviousPeriod',
    tick_callback: value => value,
  },
  rentals_active: {
    chart_title: 'Rentals Active Chart',
    'y-axis-id': 'rentals-active-y-axis',
    currentPeriod: 'reservationsActiveForCurrentPeriod',
    previousPeriod: 'reservationsActiveForPreviousPeriod',
    tick_callback: value => value,
  },
};

const convertToChartData = (data, spotlightStat) => {
  const statConfig = spotlightStatConfigs[spotlightStat];
  const sampleData = {
    labels: [],
    datasets: [
      {
        label: 'Current period',
        backgroundColor: theme.palette.primary,
        borderColor: [theme.palette.primary],
        borderWidth: 4,
        pointRadius: 0,
        pointHoverRadius: 12,
        pointHoverBorderColor: theme.palette.light,
        pointHoverBackgroundColor: theme.palette.primary,
        pointHoverBorderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.5,
        data: [],
      },
      {
        label: 'Previous period',
        backgroundColor: theme.palette.primaryLight,
        borderColor: [theme.palette.primaryLight],
        borderWidth: 4,
        pointRadius: 0,
        pointHoverRadius: 12,
        pointHoverBorderColor: theme.palette.light,
        pointHoverBackgroundColor: theme.palette.primaryLight,
        pointHoverBorderWidth: 2,
        cubicInterpolationMode: 'monotone',
        tension: 0.5,
        data: [],
      },
    ],
  };
  const statConversion = statConfig['conversion'] ? statConfig['conversion'] : value => value;
  if (data && data[statConfig['currentPeriod']]) {
    data[statConfig['currentPeriod']].days.forEach((day, index) => {
      sampleData.labels.push(momentToShortFormat(moment(day.date)));
      sampleData.datasets[0].data.push({
        x: index,
        y: statConversion(day.value),
      });
    });
    data[statConfig['previousPeriod']].days.forEach((day, index) => {
      sampleData.datasets[1].data.push({
        x: index,
        y: statConversion(day.value),
      });
    });
  }
  return sampleData;
};

const generateOptions = (startDate, spotlightStat, selectedPeriod) => {
  startDate = startDate.clone();
  return {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        type: 'linear',
        ticks: {
          stepSize: periodOptionsStepSize.filter(value => value.value === selectedPeriod)[0]
            .stepSize,
          callback: value => {
            return momentToShortFormat(startDate.clone().add(value, 'days'));
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        min: 0,
        ticks: {
          beginAtZero: true,
          stepSize: 100,
          callback: spotlightStatConfigs[spotlightStat]['tick_callback'],
        },
      },
    },
  };
};

const periodOptions = [
  {label: '7 Days', value: 7},
  {label: '14 Days', value: 14},
  {label: '30 Days', value: 30},
  {label: '1 Year', value: 365},
];

const periodOptionsStepSize = [
  {value: 7, stepSize: 1},
  {value: 14, stepSize: 2},
  {value: 30, stepSize: 7},
  {value: 365, stepSize: 30},
];

const Dashboard = ({
  data,
  spotlightStat,
  selectedPeriod,
  setAffiliateId,
  selectedAffiliateId,
  selectSpotlightStat,
  handlePeriodChange,
  currentFilter: {start},
}) => (
  <div>
    <HeaderWrapper>
      <div>
        <h1>Dashboard</h1>
        <AffiliateSelectNoForm
          value={selectedAffiliateId}
          onChange={setAffiliateId}
          isFilter
          placeholder="All Stores"
        />
      </div>
      <Select
        name="period"
        label="period"
        options={periodOptions}
        defaultValue={periodOptions[0].value + ''}
        value={selectedPeriod}
        isClearable={false}
        onChange={handlePeriodChange}
        map={false}
      />
    </HeaderWrapper>
    <Grid>
      <StatCard
        title="Revenue"
        currentPeriodValue={data.revenueForCurrentPeriod.total}
        previousPeriodValue={data.revenueForPreviousPeriod.total}
        comment="Compared to the previous period"
        onClick={() => selectSpotlightStat('revenue')}
        spotlightStat={spotlightStat}
        statName="revenue"
        type={'currency'}
      />
      <StatCard
        title="Days Booked"
        currentPeriodValue={data.daysBookedForCurrentPeriod.total}
        previousPeriodValue={data.daysBookedForPreviousPeriod.total}
        comment="Compared to the previous period"
        onClick={() => selectSpotlightStat('days_booked')}
        spotlightStat={spotlightStat}
        statName="days_booked"
      />
      <StatCard
        title="Rentals Active"
        currentPeriodValue={data.reservationsActiveForCurrentPeriod.total}
        previousPeriodValue={data.reservationsActiveForPreviousPeriod.total}
        comment="Compared to the previous period"
        onClick={() => selectSpotlightStat('rentals_active')}
        spotlightStat={spotlightStat}
        statName="rentals_active"
      />
      <StatCard
        title="Carbon Saved"
        currentPeriodValue={data.reservationsActiveForCurrentPeriod.total * 180}
        previousPeriodValue="0"
        comment="Approx kg carbon saved"
        showChange={false}
        spotlightStat={spotlightStat}
        statName="carbon_saved"
      />
      <Section title={spotlightStatConfigs[spotlightStat]['chart_title']} isTitle data-chart={true}>
        <Chart
          data={convertToChartData(data, spotlightStat)}
          options={generateOptions(start, spotlightStat, selectedPeriod)}
          height={602}
        />
      </Section>
      <Section title="Support &amp; Guides" isTitle data-guide={true}>
        <a
          href="https://help.awayco.com/content/setup-guide-4K1Y5"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faPlayCircle} size={'lg'} />
          </div>
          <p>Setting up your store</p>
        </a>
        <a
          href="https://help.awayco.com/content/adding-products-H2raw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faTag} size={'lg'} />
          </div>
          <p>Adding products</p>
        </a>
        <a
          href="https://help.awayco.com/content/creating-product-variants"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faShirt} size={'lg'} />
          </div>
          <p>Creating product variants</p>
        </a>
        <a
          href="https://help.awayco.com/content/managing-reservations"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faCalendarRange} size={'lg'} />
          </div>
          <p>Managing reservations</p>
        </a>
        <a
          href="https://help.awayco.com/content/refunding-a-reservation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <FontAwesomeIcon icon={faMoneyCheckDollarPen} size={'lg'} />
          </div>
          <p>Refunding a reservation</p>
        </a>
      </Section>
      <Section title="Upcoming Reservations" isTitle data-table={true}>
        {/* $Fixme: status formatter should be on the Table definition */}
        <Reservations
          status={'confirmed'}
          affiliateId={selectedAffiliateId}
          statusFormatter={v => {
            const today = moment().startOf('day');
            const tomorrow = moment().add(1, 'day').endOf('day');
            const start = moment(v.booking.start);
            if (start.isBefore(today)) {
              return <Pill status="alert">Overdue</Pill>;
            }
            if (start.isBefore(tomorrow)) {
              return <Pill status="success">Tomorrow</Pill>;
            }
            return <Pill>{momentToShortFormat(start)}</Pill>;
          }}
        />
      </Section>
      <Section title="Upcoming Returns" isTitle data-table={true}>
        {/* FIXME(Russel): The status formatter has to go in the table definition */}
        {/* $Fixme */}
        <Reservations
          status={'checked_out'}
          affiliateId={selectedAffiliateId}
          statusFormatter={v => {
            const today = moment().startOf('day');
            const tomorrow = moment().add(1, 'day').endOf('day');
            const end = moment(v.booking.end);
            if (end.isBefore(today)) {
              return <Pill status="alert">Overdue</Pill>;
            }
            if (end.isBefore(tomorrow)) {
              return <Pill status="success">Tomorrow</Pill>;
            }
            return <Pill>{momentToShortFormat(end)}</Pill>;
          }}
        />
      </Section>
    </Grid>
  </div>
);

const enhancer: HOC<*, {}> = compose(
  withStateHandlers(
    {
      selectedAffiliateId: undefined,
      selectedPeriod: periodOptions[0].value,
      spotlightStat: 'revenue',
      currentFilter: {
        start: moment().endOf('day').subtract(7, 'days'),
        end: moment().endOf('day'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      previousFilter: {
        start: moment().endOf('day').subtract(14, 'days'),
        end: moment().endOf('day').subtract(7, 'days'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    {
      setPeriod: () => value => ({selectedPeriod: value}),
      setFilterDates:
        () =>
        (currentStart: Moment, currentEnd: Moment, previousStart: Moment, previousEnd: Moment) => ({
          currentFilter: {
            start: currentStart,
            end: currentEnd,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          previousFilter: {
            start: previousStart,
            end: previousEnd,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }),
      setAffiliateId: () => value => ({selectedAffiliateId: value}),
      selectSpotlightStat: () => value => ({spotlightStat: value}),
    }
  ),
  withQuery(
    dashboardStatisicsQuery,
    {
      variables: props => {
        return {
          currentFilter: props.currentFilter,
          previousFilter: props.previousFilter,
          affiliateId: props.selectedAffiliateId,
        };
      },
    },
    undefined
  ),
  withHandlers({
    handlePeriodChange: props => value => {
      props.setPeriod(value);
      const end = moment().endOf('day');
      const start = end.clone();
      start.subtract(value, 'days');
      const previousEnd = start.clone();
      const previousStart = previousEnd.clone().subtract(value, 'days');
      props.setFilterDates(start, end, previousStart, previousEnd);
    },
  })
);

export default enhancer(Dashboard);
