// @flow
export type Id = string | number;

export type CRUDRoute = {
  index: string,
  link: {
    list: string,
    detail: Id => string,
    update: Id => string,
    create: string,
    editAll: string,
  },
  path: {
    list: string,
    detail: string,
    update: string,
    create: string,
    editAll: string,
  },
};

const CRUDifyRoute = (route: string): CRUDRoute => ({
  index: route,
  link: {
    list: `${route}/list`,
    detail: (id: Id) => `${route}/detail/${id}`,
    update: (id: Id) => `${route}/update/${id}`,
    create: `${route}/create`,
    editAll: `${route}/editAll`,
  },
  path: {
    list: `${route}/list`,
    detail: `${route}/detail/:id`,
    update: `${route}/update/:id`,
    create: `${route}/create`,
    editAll: `${route}/editAll`,
  },
});

const urls = {
  /*
   * Placeholder route due to removal of page but wanting to keep components
   * relatively intact by changing them to reference a dummy route.
   */
  placeholder: CRUDifyRoute('/placeholder'),

  /** Root URL which would then redirect to the appropriate page */
  root: '/',

  accessories: CRUDifyRoute('/accessories'),
  account: '/account',
  customers: CRUDifyRoute('/customers'),
  /** Debug page to simulate errors etc */
  debug: '/debug',
  dashboard: CRUDifyRoute('/dashboard'),
  employee: CRUDifyRoute('/employee'),
  inventory: CRUDifyRoute('/inventory'),
  location: CRUDifyRoute('/location'),
  login: '/login',
  loginWithWelcome: '/login?show-welcome=true',
  manufacturer: CRUDifyRoute('/vendor'),
  payment: CRUDifyRoute('/payment'),
  pricingTemplate: CRUDifyRoute('/pricing-template'),
  pricingTemplateProduct: CRUDifyRoute('/pricing-template-product'),
  product: CRUDifyRoute('/product'),
  bundle: CRUDifyRoute('/bundle'),
  category: CRUDifyRoute('/category'),
  reservation: CRUDifyRoute('/reservation'),
  settings: '/settings',
  shop: CRUDifyRoute('/store'),
  settingsLegal: '/rental-agreement',
  tenant: CRUDifyRoute('/tenant'),
  email: '/email',
  emailTemplates: CRUDifyRoute('/email/templates'),
  emailLogs: CRUDifyRoute('/email/logs'),
  emailProjection: CRUDifyRoute('/email/projection'),
  theme: '/theme',
  user: CRUDifyRoute('/user'),
  userGuide: 'https://help.awayco.com/',
  verifySignupEmail: '/verify-signup-email',
  variant: CRUDifyRoute('/variant'),

  /** Links for "Storybook */
  storybook: {
    index: '/storybook',
    palette: '/storybook/palette',
    typography: '/storybook/typography',
    inputs: '/storybook/inputs',
    fileUpload: '/storybook/fileUpload',
    pills: '/storybook/pills',
    table: '/storybook/table',
    tooltip: '/storybook/tooltip',
    formatters: '/storybook/formatters',
  },
};

export default urls;
