// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import ProductVariantSelect from 'containers/ProductVariantSelect';
import withForm from 'hoc/withForm';
import React from 'react';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
};

const ProductItemCreate = ({isSubmitting}: Props) => (
  <div>
    <ProductVariantSelect name="productVariantId" label="variant" />
    <InputText name="code" label="Asset ID" />
    <Button kind="primary" loading={isSubmitting}>
      Create stock item
    </Button>
  </div>
);

export default withForm({
  schema,
  onSubmit: props => values => props.submit({input: values}),
})(ProductItemCreate);
