// @flow
import gql from 'graphql-tag';

export const tenantFragment = gql`
  fragment tenantFragment on Tenant {
    id
    name
    title
    website
    helloEmailAddress
    bccEmailAddress
    isEmailVerified
    allowed
    createdAt
    groupReservations
    showMinimalProductTileInfo
    areBundlesEnabled
  }
`;
