import {optional, required, variantVariablesCreationInput} from 'common/validators';
import * as yup from 'yup';

export default yup.object().shape({
  id: optional,
  name: required,
  description: optional,
  manufacturerId: required,
  categories: required,
  images: optional,
  variantVariables: variantVariablesCreationInput,
  isVisible: required,
});
