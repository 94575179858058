// @flow
import type {Id} from 'pages/urls';
import {path} from 'ramda';

export const getRouterVariables = (props: {}, stringId?: boolean) => {
  const id: Id = path(['match', 'params', 'id'], props);
  if (id) {
    return {id: stringId ? id : parseInt(id, 10)};
  }
  return {};
};
