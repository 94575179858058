import {StyledFieldWrap} from 'components/Inputs/FieldWrap/styled';
import Section from 'components/Section';
import TextEditor from 'components/TextEditor';
import {Label} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const CheckboxWrapper = styled.span``;

export const StyledInStoreSection = styled(Section)`
  ${StyledFieldWrap} {
    min-height: 0;
    margin-bottom: 2.4rem;
  }

  label {
    text-transform: none;
  }
`;

export const InlineNumberLabel = styled(Label)`
  margin-bottom: 2.4em;

  ${StyledFieldWrap} {
    display: inline;
    margin: 0 0.8rem;
  }
`;

export const StyledTextEditor = styled(TextEditor)`
  max-width: 640px;
`;
