// @flow
import * as React from 'react';
import styled from 'styled-components';

export type WithLabelProps = {
  label?: string,
  labelFor?: string,
};

const LabelWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

function withLabel<ComponentProps>(
  Component: React.ComponentType<ComponentProps>
): React.ComponentType<ComponentProps & WithLabelProps> {
  return (props: ComponentProps & WithLabelProps) => {
    const {label, labelFor, ...rest} = props;

    return (
      <LabelWrapper>
        {label && <label htmlFor={labelFor}>{label}</label>}
        <Component {...rest} />
      </LabelWrapper>
    );
  };
}

export default withLabel;
