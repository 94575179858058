// @flow
import * as React from 'react';
import {type Component, withStateHandlers} from 'recompose';

type Added = {
  filter: {[string]: number | string},
  updateFilter: (k: string, v: any) => void,
  clearFilter: () => void,
};

function withFilter<Enhancer>(
  // $Dunno
  Component: React.ComponentType<{...$Exact<Added>, ...$Exact<Enhancer>}>
  // $Dunno
): Component<Enhancer> {
  return withStateHandlers(
    {filter: {}},
    {
      updateFilter: props => (k, v) => ({
        filter: {
          ...props.filter,
          [k]: v,
        },
      }),
      clearFilter: props => () => {
        // field query should not be cleared
        if (props.filter.hasOwnProperty('query')) {
          return {filter: {query: props.filter.query}};
        }
        return {filter: {}};
      },
    }
  )(Component);
}

export default withFilter;
