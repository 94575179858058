// @flow
import gql from 'graphql-tag';
import type {Mutation} from 'types/Graphql';

import {userFragment} from './fragments';

export const getRefreshTokenMutation: Mutation<
  {
    email: string,
    refreshToken: string,
  },
  string
> = {
  gql: gql`
    mutation getRefreshTokenMutation($email: LowercaseString!, $refreshToken: String!) {
      auth {
        getAccessToken(email: $email, refreshToken: $refreshToken) {
          accessToken
        }
      }
    }
  `,
  selector: ['auth', 'getAccessToken', 'accessToken'],
};

export const loginMutation: Mutation<{}, {}> = {
  gql: gql`
    mutation loginMutation($email: LowercaseString!, $password: String!) {
      auth {
        login(email: $email, password: $password) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'login', 'user'],
};

export const createMasqueradeMutation: Mutation<{}, {}> = {
  gql: gql`
    mutation createMasqueradeMutation($userId: Int!) {
      auth {
        createMasquerade(userId: $userId) {
          url
        }
      }
    }
  `,
  selector: ['auth', 'login', 'user'],
};
