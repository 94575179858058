import React from 'react';

import {SectionContent, SectionWrap} from './styled';

type Props = {
  title?: string,
  isTitle?: boolean,
  includeTitle: boolean,
};

const Section = (props: Props) => {
  const {title, isTitle, includeTitle, children, ...rest} = props;

  return (
    <SectionWrap {...rest}>
      {includeTitle !== false && (isTitle ? <h3>{title}</h3> : <h4>{title}</h4>)}
      <SectionContent>{children}</SectionContent>
    </SectionWrap>
  );
};

export default Section;
