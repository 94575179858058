// @flow
import gql from 'graphql-tag';
import type {Partial} from 'ramda';
import type {Query} from 'types/Graphql';

import type {Theme} from './types';

export const getThemeQuery: Query<Theme> = {
  gql: gql`
    query getThemeQuery {
      theme {
        getTheme {
          header {
            html
            css
          }
          footer {
            html
            css
          }
          colors {
            primaryAccent
            primaryAccentDark
            primaryText
            secondaryText
            background
          }
          borderRadiuses {
            button
            filterButton
            input
          }
          fonts {
            externalFontCssUrl
            bodyFontFamily
            headingFontFamily
          }
          logo {
            logo
            logoLink
          }
          advancedHeader
          enableStoreCarousel
        }
      }
    }
  `,
  selector: ['theme', 'getTheme'],
};

export const getThemeLogoQuery: Query<Partial<Theme, 'logo'>> = {
  gql: gql`
    query getThemeLogoQuery {
      theme {
        getTheme {
          logo {
            logo
            logoLink
          }
        }
      }
    }
  `,
  selector: ['theme', 'getTheme'],
};
