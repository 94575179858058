// @flow
import type {TableDefinition} from 'components/Table/types';
import type {BundleComponent} from 'data/bundle/types';

import {TABLE_COLUMN_ACTION_TITLE} from '../../../components/Table/Td';
import EditBundleComponentLink from './EditBundleComponentLink';

/* TODO(t04435): TABLE_ACTIONS? product_name */
const tableDefinition: TableDefinition<BundleComponent> = {
  columns: [
    {
      title: 'product name',
      value: v => v.productVariant.product.name,
    },
    {
      title: 'variant name',
      value: v => v.productVariant.name,
    },
    {
      title: 'id',
      value: v => v.id,
    },
    {
      title: TABLE_COLUMN_ACTION_TITLE,
      type: EditBundleComponentLink,
      props: v => ({data: v}),
      value: v => v.id,
    },
  ],
};
export default tableDefinition;
