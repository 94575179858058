// @flow
import React from 'react';

const NotFoundPage = () => (
  <React.Fragment>
    <h1>Not found</h1>
    <p>lorem ipsum</p>
  </React.Fragment>
);

export default NotFoundPage;
