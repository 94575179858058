// @flow
import {CurrencyInputWrapper, CurrencySymbolWrapper} from 'components/CurrencyInput/styled';
import {currencySymbols, specialCurrencies} from 'data/units/money/formatters';
import usePrevious from 'hooks/usePrevious';
// $Import
import React, {useCallback, useEffect} from 'react';

export type Props = {
  onChange: (value: number | null) => any,
  value: number,
  currencyName?: string, //3 letter name for currency, default to $ symbol if not provided
  className?: string,
  disabled?: boolean,
};

const getCurrencySymbol = (currencyName?: string) => {
  if (!currencyName) {
    return undefined;
  }

  return currencySymbols[currencyName];
};

const doesCurrencyAllowDecimal = (currencyName?: string) =>
  !specialCurrencies.includes(currencyName);

const isValidNumber = value => value != null && value !== '' && !Number.isNaN(Number(value));

const CurrencyInput = ({onChange, value, currencyName, className, disabled, ...rest}: Props) => {
  const currencySymbol = getCurrencySymbol(currencyName);
  const allowDecimal = doesCurrencyAllowDecimal(currencyName);
  const previousAllowDecimal = usePrevious(allowDecimal);

  //To prevent the user scrolling inside the focused input causing the value to change
  //we must blur the input.
  const stopScrollChangingValue = e => e.target.blur();

  const handleChange = e => {
    value = e.target.value;
    if (isValidNumber(value)) {
      onChange(Number(value));
    } else {
      onChange(null);
    }
  };

  const roundValue = useCallback(() => {
    if (isValidNumber(value)) {
      const roundTo = allowDecimal ? 2 : 0;
      // $Dunno
      onChange(Number(value).toFixed(roundTo));
    }
  }, [value, onChange, allowDecimal]);

  useEffect(() => {
    //If the we change from allowing to not allowing a decimal (or vice-versa) then
    //Rerun the rounding
    if (allowDecimal !== previousAllowDecimal) {
      roundValue();
    }
  }, [allowDecimal, roundValue, previousAllowDecimal]);

  return (
    <CurrencyInputWrapper disabled={disabled} className={className}>
      {currencySymbol && <CurrencySymbolWrapper>{currencySymbol}</CurrencySymbolWrapper>}
      <input
        {...rest}
        onWheel={stopScrollChangingValue}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        type="number"
        inputmode="numeric"
        min={allowDecimal ? 0.01 : 1}
        step={allowDecimal ? 0.01 : 1}
        onBlur={roundValue}
      />
    </CurrencyInputWrapper>
  );
};

export default CurrencyInput;
