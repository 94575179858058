// @flow
import {faSave} from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {notificationSuccess} from 'data/notifications/actions';
import {changePasswordMutation} from 'data/user/mutations';
import withForm from 'hoc/withForm';
import withHelpCenter from 'hoc/withHelpCenter';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import Section from '../../components/Section';
import schema from './schema';
import {Error} from './styled';

type Props = {
  isSubmitting: boolean,
  errors: {
    passwordsDoNotMatch?: string,
  },
};

const ChangePasswordForm = (props: Props) => {
  return (
    <React.Fragment>
      <h1>Account</h1>
      <Section title={'change password'}>
        <InputText type="password" name="oldPassword" label="Old password" />
        <InputText type="password" name="newPassword" label="New password" />
        <InputText type="password" name="confirmNewPassword" label="Confirm new password" />
        {props.errors && props.errors.passwordsDoNotMatch && (
          <Error>{props.errors.passwordsDoNotMatch}</Error>
        )}
        <Button kind="primary" iconStart={faSave} loading={props.isSubmitting}>
          Change password
        </Button>
      </Section>
    </React.Fragment>
  );
};

const enhancer = compose(
  connect(null, {notificationSuccess}),
  withMutation(changePasswordMutation),
  withForm({
    schema,
    onSubmit: props => values => {
      return props.submitMutation({
        oldPassword: values.oldPassword,
        password: values.newPassword,
      });
    },
    onSuccess: props => () => {
      props.notificationSuccess('Password changed');
      props.resetForm({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    },
  }),
  withHelpCenter({category: 'users'})
);

const EnhancedChangePasswordForm = enhancer(ChangePasswordForm);

const AccountPage = () => <EnhancedChangePasswordForm />;

export default AccountPage;
