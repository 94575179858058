// @flow
import {paginationFragment} from 'data/other/fragments';
import {productVariantFragment} from 'data/productVariant/fragments';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {
  categoryFragment,
  productFragment,
  productFragmentWithVariants,
  productMinimalFragment,
} from './fragments';
import type {CategoryObject, Product} from './types';

export const productListQuery: Query<Product[]> = {
  gql: gql`
    query productListQuery($filter: ProductFilter) {
      product {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productFragment
          }
        }
      }
    }
    ${productFragment}
    ${paginationFragment}
  `,
  selector: ['product', 'list'],
  pagination: true,
  debounceKey: 'productListQuery',
};

export const listProductCatalog: Query<{products: any}> = {
  gql: gql`
    query listProductCatalog($filter: ProductCatalogFilter!) {
      product {
        listProductCatalog(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${productFragmentWithVariants}
  `,
  selector: ['product', 'listProductCatalog'],
  pagination: true,
  debounceKey: 'listProductCatalog',
};

export const listProductCatalogGroups: Query<CategoryObject> = {
  gql: gql`
    query categoryListQuery {
      category {
        listCatalogGroups {
          ...categoryFragment
        }
      }
    }
    ${categoryFragment}
  `,
  selector: ['category', 'listCatalogGroups'],
};

export const productListQueryMinimal: Query<Product[]> = {
  gql: gql`
    query productListQueryMinimal($filter: ProductFilter) {
      product {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...productMinimalFragment
          }
        }
      }
    }
    ${productMinimalFragment}
    ${paginationFragment}
  `,
  selector: ['product', 'list'],
  pagination: true,
  defaultFilter: {limit: 999999},
};

export const productDetailQuery: Query<Product> = {
  gql: gql`
    query productDetailQuery($id: Int!) {
      product {
        fullDetail(productId: $id) {
          ...productFragment
          variants {
            ...productVariantFragment
          }
        }
      }
    }
    ${productFragment}
    ${productVariantFragment}
  `,
  selector: ['product', 'fullDetail'],
};

export const categoryListQuery: Query<CategoryObject> = {
  gql: gql`
    query categoryListQuery {
      category {
        list {
          categoryId
          name
          discountOptOuts {
            id
            discountId
          }
        }
      }
    }
  `,
  // Add id based on index position for compatibility with existing
  // select components
  transform: x => x.map((value, index) => ({...value, id: index})),
  selector: ['category', 'list'],
};

export const categoryDetailQuery: Query<CategoryObject> = {
  gql: gql`
    query categoryDetailQuery($id: String!) {
      category {
        detail(id: $id) {
          ...categoryFragment
        }
      }
    }
    ${categoryFragment}
  `,
  selector: ['category', 'detail'],
};
