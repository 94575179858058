// @flow
import {affiliatesListQuery} from 'data/affiliate/queries';
import {categoryListQuery} from 'data/product/queries';
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

export const updateOptOutsMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation updateOptOutsMutation($input: UpdateOptOutsInput!) {
      discount {
        updateOptOuts(input: $input) {
          id
        }
      }
    }
  `,
  selector: ['discounts', 'updateOptOuts'],
  refetch: [affiliatesListQuery, categoryListQuery],
};
