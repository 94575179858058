// @flow
import {userFragment} from 'data/auth/graphql/fragments';
import {paginationFragment} from 'data/other/fragments';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

import {profileFragment, userFragmentFull, userPublicFragmentMinimal} from './fragments';
import type {Profile, UserFull, UserMinimal} from './types';

export const userListQuery: Query<UserMinimal[]> = {
  gql: gql`
    query userListQuery($filter: UserFilter) {
      user {
        list(filter: $filter) {
          data {
            ...userPublicFragmentMinimal
          }
          pageInfo {
            ...paginationFragment
          }
        }
      }
    }
    ${userPublicFragmentMinimal}
    ${paginationFragment}
  `,
  selector: ['user', 'list'],
  pagination: true,
  debounceKey: 'userListQuery',
};

export const userDetailQuery: Query<UserFull> = {
  gql: gql`
    query userDetailQuery($id: Int!) {
      user {
        getUserFullDetail(id: $id) {
          ...userFragmentFull
        }
      }
    }
    ${userFragmentFull}
  `,
  selector: ['user', 'getUserFullDetail'],
  fetchPolicy: 'network-only',
};

export const userById: Query<UserFull> = {
  gql: gql`
    query userById($id: Int!) {
      user {
        getUser(id: $id) {
          ...userFragment
        }
      }
    }
    ${userFragment}
  `,
  selector: ['user', 'getUser'],
  fetchPolicy: 'no-cache',
};

export const profileDetailQuery: Query<Profile> = {
  gql: gql`
    query profileDetailQuery($id: Int) {
      profile {
        get(id: $id) {
          ...profileFragment
        }
      }
    }
    ${profileFragment}
  `,
  selector: ['profile', 'get'],
  fetchPolicy: 'network-only',
};
