// @flow
import {closeNotification} from 'data/notifications/actions';
import {selectTopmostNotification} from 'data/notifications/selectors';
import React from 'react';
import {connect} from 'react-redux';
import type {HOC} from 'recompose';

import NotificationItem from './NotificationItem';

const Notifications = ({notification, closeNotification}) => {
  if (!notification) return null;

  return (
    <NotificationItem
      key={notification.id}
      notification={notification}
      closeNotification={closeNotification}
    />
  );
};

const mapStateToProps = state => ({
  notification: selectTopmostNotification(state),
});

const mapDispatchToProps = {
  closeNotification,
};

const enhancer: HOC<*, {}> = connect(mapStateToProps, mapDispatchToProps);

export default enhancer(Notifications);
