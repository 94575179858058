// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {
  categoryDetailQuery,
  categoryListQuery,
  productDetailQuery,
  productListQuery,
} from './queries';

export const productUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productUpdateMutation($input: ProductUpdateInput!) {
      product {
        update(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productDetailQuery],
};

export const productCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productCreateMutation($input: ProductInput!) {
      product {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [productListQuery],
  selector: ['product', 'create'],
};

export const productDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation productDeleteMutation($id: Int!) {
      product {
        delete(id: $id)
      }
    }
  `,
  refetch: [productListQuery],
};

export const categoryCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation categoryCreateMutation($input: CategoryCreateInput!) {
      category {
        create(input: $input) {
          categoryId
        }
      }
    }
  `,
  refetch: [categoryListQuery],
  selector: ['category', 'create'],
};

export const categoryUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation categoryUpdateMutation($input: CategoryUpdateInput!) {
      category {
        update(input: $input) {
          categoryId
        }
      }
    }
  `,
  refetch: [categoryDetailQuery],
};

export const categoryDeleteMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation categoryDeleteMutation($id: String!) {
      category {
        delete(id: $id)
      }
    }
  `,
  refetch: [categoryListQuery],
};
