import gql from 'graphql-tag';

import type {Mutation} from '../../types/Graphql';
import {getLegalQuery} from './queries';
import type {LegalInput} from './types';

export const saveLegalMutation: Mutation<LegalInput, boolean> = {
  gql: gql`
    mutation saveLegalMutation($input: LegalInput!) {
      legal {
        saveLegal(input: $input)
      }
    }
  `,
  selector: ['legal', 'saveLegal'],
  refetch: [getLegalQuery],
};
