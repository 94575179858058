// @flow
import classnames from 'classnames';
import {Error, Hint, Label} from 'componentsStyled/Typography';
import * as React from 'react';

import {StyledFieldMessage, StyledFieldWrap} from './styled';

export type LabelPosition = 'top' | 'right';

export type Props = {
  children: React.Node,
  error: ?string,
  hideErrorText?: boolean, //Will apply error styling to input but hide the text
  label?: string,
  labelPosition?: LabelPosition,
  component?: string,
  errors?: Object,
  hint?: String,
  alignment?: 'right',
  disabled?: boolean,
  className?: string,
};

const FieldWrap = ({
  children,
  error,
  hideErrorText,
  disabled,
  label,
  labelPosition = 'top',
  component = 'div',
  errors,
  hint,
  alignment,
  className,
  ...props
}: Props) => {
  const displayError = error || (errors && errors[label]);
  const displayErrorText = displayError && !hideErrorText;

  const renderErrorOrHint = () => {
    return (
      (displayError || hint) && (
        <StyledFieldMessage>
          {displayErrorText ? <Error>{displayError}</Error> : hint && <Hint>{hint}</Hint>}
        </StyledFieldMessage>
      )
    );
  };

  return (
    <StyledFieldWrap
      component={component}
      position={labelPosition}
      className={classnames({error: displayError}, className)}
      alignment={alignment}
    >
      {label && <Label disabled={disabled}>{label}</Label>}
      {children}
      {renderErrorOrHint()}
    </StyledFieldWrap>
  );
};
export default FieldWrap;
