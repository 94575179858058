// @flow
import {baseEmailFragment} from 'data/email/fragments';
import type {Email} from 'data/email/types';
import {paginationFragment} from 'data/other/fragments';
import {toReadableDateTime} from 'data/units/date/formatters';
import gql from 'graphql-tag';
import type {Query} from 'types/Graphql';

export const emailLogDetailQuery: Query<Email> = {
  gql: gql`
    query emailLogDetailQuery($id: String) {
      email {
        get(id: $id) {
          emailId
          content
          ...baseEmailFragment
        }
      }
    }
    ${baseEmailFragment}
  `,
  selector: ['email', 'get'],
};

export const emailLogListQuery: Query<Email[]> = {
  gql: gql`
    query emailLogListQuery($filter: EmailFilter) {
      email {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            emailId
            type
            ...baseEmailFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${baseEmailFragment}
  `,
  pagination: true,
  debounceKey: 'emailLogListQuery',
  selector: ['email', 'list'],
};

export const emailProjectionListQuery: Query<Email[]> = {
  gql: gql`
    query emailProjectionListQuery($filter: DebuggingEmailFilter) {
      email {
        listFutureEmails(filter: $filter) {
          content
          type
          ...baseEmailFragment
        }
      }
    }
    ${baseEmailFragment}
  `,
  fetchPolicy: 'network-only',
  selector: ['email', 'listFutureEmails'],
  transform: emails =>
    emails.map((email, index) => ({
      ...email,
      createdAt: toReadableDateTime(email.createdAt),
      emailId: index,
    })),
};
