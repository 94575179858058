import styled, {css} from 'styled-components';

export const inputStyle = css`
  border: 0.1rem solid ${props => props.theme.palette.greyDark};
  padding: 0.8rem;
  height: 4rem;
  background: ${props => props.theme.palette.light};
  border-radius: 0.4rem;
  width: ${props => (props.maxWidth ? `${props.maxWidth}rem` : '100%')};
  color: ${props => props.theme.palette.dark};
  outline: none;

  transition: ${props => {
    const duration = props.theme.durations.inputColors;
    return `background-color ${duration}, border-color ${duration},color ${duration}`;
  }};

  ::placeholder {
    color: ${props => props.theme.palette.grey};
  }

  &:focus {
    border: 0.2rem solid ${props => props.theme.palette.primary};
  }

  &:disabled {
    border: 0.1rem solid ${props => props.theme.palette.grey};
    background: ${props => props.theme.palette.greyLighter};
    color: ${props => props.theme.palette.grey};
    cursor: default;
  }

  &:-webkit-autofill {
    background: none !important;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error & {
    border: 0.2rem solid ${props => props.theme.palette.error};
    background: ${props => props.theme.palette.errorLight};
  }
`;

export const StyledInput = styled.input`
  ${inputStyle};
`;

export const StyledNumberInput = styled.input`
  ${inputStyle};

  //Add stepper arrows back to input
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: auto;
  }
`;

export const StyledInputTextArea = styled.textarea`
  ${inputStyle};
  height: 15rem;
  padding: 2rem;
`;

export const StyledSelect = styled.select`
  ${inputStyle};
  -webkit-appearance: none;
`;

export const FieldSet = styled.fieldset`
  //Remove fieldset styling (only using fieldset for semantic purposes)
  border: none;
  margin: 0;
  padding: 0;
`;
