// @flow
import {compose, head, prop} from 'ramda';
import type {Selector} from 'types/Redux';

import type {Notification} from './actions';

const base: Selector<*> = prop('notifications');

// composes upon the base selector to select specific properties
export const selectTopmostNotification: Selector<?Notification> = compose(head, base);
