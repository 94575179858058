// @flow
import InputSelect from 'components/Inputs/InputSelect';
import {categoryListQuery} from 'data/product/queries';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

import {categoryCreateMutation} from '../../data/product/mutations';

type Category = {
  categoryId: string,
  name: string,
};

type Enhancer = {
  name: string,
  canCreate?: boolean,
};

export const categoryOptionGetters = {
  getOptionLabel: (option: Category) => option.name,
  getOptionValue: (option: Category) => option.categoryId,
};

const CategorySelect = ({data, canCreate, submitMutation, ...rest}) => {
  const creatableProps = canCreate
    ? {
        canCreate,
        onCreateOption: v =>
          submitMutation({
            input: {name: v},
          }),
      }
    : {};

  return <InputSelect {...rest} options={data} {...creatableProps} {...categoryOptionGetters} />;
};

const enhancer: HOC<*, Enhancer> = compose(
  withQuery(categoryListQuery, {noEmpty: true}),
  withMutation(categoryCreateMutation)
);

export default enhancer(CategorySelect);
