// @flow

const WAIT_MILLIS = 500;
let timeoutHandle;
let queue: number[] = [];

function debugLog(message: string) {
  const enabled = false;
  if (enabled) {
    console.log(message);
  }
}

function setCategoryIfWidgetIsInitialized(categoryNumber: number) {
  if (window.hcWidget) {
    debugLog('*** Setting category: ' + categoryNumber);
    window.hcWidget.setCategory(categoryNumber);
    return true;
  }
  debugLog('*** Widget not yet initialized');
}

function workerLoop() {
  debugLog('*** Executing worker loop');
  if (queue.length > 0) {
    debugLog('*** Queue has length: ' + queue.length);
    const categoryNumber = queue[0];
    const successful = setCategoryIfWidgetIsInitialized(categoryNumber);
    if (successful) {
      queue.shift();
    }
    debugLog('*** Scheduling next iteration');
    timeoutHandle = window.setTimeout(workerLoop, WAIT_MILLIS);
  } else {
    debugLog('*** Queue is empty');
    window.clearTimeout(timeoutHandle);
    timeoutHandle = undefined;
  }
}

/**
 * Sets the category to be shown in the HelpCenter widget.
 * If the HelpCenter widget has not been initialized then check periodically.
 */
export async function setHelpCenterWidgetCategory(categoryNumber: number) {
  debugLog('*** Initializing queue for category: ' + categoryNumber);
  // NOTE: Clears any queued messages
  queue = [
    categoryNumber,
    // HACK(ray): Try a few times because it takes a while for the widget to
    // initialize properly (and no way of knowing if/when).
    categoryNumber,
    categoryNumber,
  ];

  // Kick off worker loop if it's not already in progress
  if (!timeoutHandle) {
    debugLog('*** Kicking off worker loop');
    workerLoop();
  }
}
