// @flow
import urls from 'pages/urls';
import React from 'react';

import LogoIcon from './LogoIcon';
import {StyledLogoLink} from './styled';

const LogoLink = () => (
  <StyledLogoLink to={urls.dashboard.index}>
    <LogoIcon />
  </StyledLogoLink>
);

export default LogoLink;
