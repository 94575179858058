import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  gap: 48px 18px;
`;

export const Tile = styled.a`
  position: relative;
  display: flex;
  background-color: ${props => props.theme.palette.light};
  border-radius: 4px;
  padding: 1.6rem;
  text-decoration: none;
`;

export const Icon = styled.div`
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${props => props.theme.palette.greyLighter};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 24px;
    color: ${props => props.theme.palette.greyDark};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0;
    color: ${props => props.theme.palette.primary};
  }

  p {
    margin-bottom: 0;
    color: ${props => props.theme.palette.dark};
  }
`;

export const ExternalIcon = styled.i`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
`;
