// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {ReservationTableDefinition} from 'data/reservations/types';
import {formatDate} from 'data/units/date/formatters';
import urls from 'pages/urls';
import {path, prop} from 'ramda';

import Pill from '../../components/Pills/index';
import {getPillPropsFromTableValue} from '../../components/Table/helper';

const tableDefinition: TableDefinition<ReservationTableDefinition> = {
  columns: [
    {
      title: 'reservation code',
      value: prop('externalId'),
    },
    {
      title: 'booking code',
      value: v => v.booking.externalId,
    },
    {
      title: 'order code',
      value: v => v.booking.order.externalId,
    },
    {
      title: 'start',
      value: v => formatDate(v.booking.start),
    },
    {
      title: 'end',
      value: v => formatDate(v.booking.end),
    },
    {
      title: 'status',
      value: prop('status'),
      type: Pill,
      props: v => getPillPropsFromTableValue(v.status),
    },
    {
      title: 'email',
      // $Ramda
      value: path(['booking', 'order', 'customer', 'user', 'email']),
    },
  ],
  rowLink: v => urls.reservation.link.detail(v.id),
};

export default tableDefinition;
