// @flow
import DetailStat from 'components/DetailStat';
import {Col, Row} from 'componentsStyled/Layout';
import type {UserFull} from 'data/user/types';
import React from 'react';

import Pill from '../../../components/Pills';
import Section from '../../../components/Section';
import {getPillPropsFromRole} from '../../../data/user/helpers';

type Outter = {
  data: UserFull,
};

const UserDetail = ({data}: Outter) => {
  const {email, role, phoneNumber} = data;
  return (
    <Section>
      <Row>
        <Col>
          <DetailStat title="Email" value={email} />
        </Col>
        <Col>
          <div>
            <h4>Role</h4>
            <Pill {...getPillPropsFromRole(role)} />
          </div>
        </Col>
        {!!phoneNumber && (
          <Col>
            <DetailStat title="Phone Number" value={phoneNumber} />
          </Col>
        )}
      </Row>
    </Section>
  );
};

export default UserDetail;
