import styled from 'styled-components';

import {StyledInput} from '../../../componentsStyled/Forms';

export const StyledInputColorWrap = styled.div`
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
`;

export const StyledInputColor = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  padding: 0;
  margin: 0;
  border: 0;
  border: 0.1rem solid ${props => props.theme.palette.greyDark};
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  outline: none;
  -webkit-appearance: none;
  background: none;
  overflow: hidden;

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-color-swatch {
    border: none;
  }

  .error & {
    border: 0.2rem solid ${props => props.theme.palette.error} !important;
  }
`;

export const StyledInputColorValue = styled(StyledInput)`
  padding-left: 4.8rem;

  &:focus + ${StyledInputColor} {
    border: 0.2rem solid ${props => props.theme.palette.primary};
  }
`;
