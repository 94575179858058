// @flow
import type {FilterConfig} from 'components/Filter';
import TextInput from 'components/TextInput';

const filterConfig: FilterConfig = {
  query: {
    type: TextInput,
    props: {
      placeholder: 'Search email and name ...',
    },
  },
  excludeRole: {
    type: TextInput,
    props: {
      value: 'user',
      type: 'hidden',
      disabled: true,
    },
  },
};

export default filterConfig;
