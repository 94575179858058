// @flow
import appReducer, {type AppState} from 'data/app/reducer';
import authReducer, {type AuthReducerState} from 'data/auth/reducer';
import modalsReducer, {type ModalReducerState} from 'data/modals/reducer';
import notificationsReducer, {type NotificationsReducerState} from 'data/notifications/reducer';
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage';

export type StoreState = {
  app: AppState,
  auth: AuthReducerState,
  notifications: NotificationsReducerState,
  modals: ModalReducerState,
  router: any,
};

const reducers = combineReducers({
  app: persistReducer({key: 'app', storage}, appReducer),
  auth: persistReducer({key: 'auth', storage}, authReducer),
  notifications: notificationsReducer,
  modals: modalsReducer,
});

export default reducers;
