// @flow
import withField, {type ReqFieldProps} from 'hoc/withField';
import * as React from 'react';
import type {HOC} from 'recompose';

import InputSwitch from '../InputSwitch';

type Props = {
  on: React.Node,
  off: React.Node,
};

/**
 * Control component that switches between displaying an 'on' and 'off' ui
 * Tightly coupled to input switch display logic.
 * @param {React.Node} on react fragment to display when controller is on
 * @param {React.Node} off react fragment to display when controller is off
 */
const InputControl = ({on, off, name, label, value, ...props}) => {
  return (
    <React.Fragment>
      <InputSwitch name={name} label={label} labelPosition="right" {...props} />
      {value && <React.Fragment>{on}</React.Fragment>}
      {!value && <React.Fragment>{off}</React.Fragment>}
    </React.Fragment>
  );
};

const enhancer: HOC<*, {...$Exact<Props>, ...$Exact<ReqFieldProps>}> = withField;

export default enhancer(InputControl);
