import 'react-quill/dist/quill.snow.css';

import {StyledReactQuill} from 'components/TextEditor/styled';
import React from 'react';

import withField from '../../hoc/withField';
import FieldWrap from '../Inputs/FieldWrap';

type Props = {
  value?: string,
  label?: string,
  error?: string,
  hint?: string,
  onChange: Function,
  allowVideo?: boolean,
  useAdvancedToolbar?: boolean,
  className?: string,
  disabled?: boolean,
  'data-cy'?: string,
};

const ADVANCED_TOOLBAR = [
  {header: [false, 1, 2, 3, 4, 5, 6]},
  'bold',
  'italic',
  {list: 'ordered'},
  {list: 'bullet'},
  'link',
  {color: []},
  {background: []},
  {align: []},
];

const BASIC_TOOLBAR = ['bold', 'italic', {list: 'ordered'}, {list: 'bullet'}, 'link'];

const TextEditor = (props: Props) => {
  const {
    value,
    label,
    error,
    allowVideo,
    hint,
    onChange,
    className,
    disabled,
    useAdvancedToolbar,
    'data-cy': dataCy,
  } = props;

  const toolbar = useAdvancedToolbar ? ADVANCED_TOOLBAR : BASIC_TOOLBAR;

  /**
   * Cleans the content returned by QuillEditor as the empty value(visually) is still a string "<tag>...more tags</tag>". So we want to return an empty string if the content is only empty tags.
   * @param {string} content: editor content
   */
  const cleanContent = (content: string) => {
    const clean = content.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? '' : content;

    onChange(clean);
  };

  return (
    <FieldWrap className={className} disabled={disabled} error={error} label={label} hint={hint}>
      {/*NOTE: this div is added here so FieldWrap `display: flex` does not break ReactQuill's Layout */}
      <div data-cy={dataCy}>
        <StyledReactQuill
          modules={{toolbar: [...toolbar, allowVideo ? 'video' : '']}}
          defaultValue={value}
          onChange={cleanContent}
          readOnly={disabled}
        />
      </div>
    </FieldWrap>
  );
};

export default withField(TextEditor);
