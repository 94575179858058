// @flow
// @author: T04435
// Date: 10/7/21

import * as React from 'react';
import {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';

import theme from '../global/theme';

/**
 * Use on any component/page to add a tooltip on a child element
 * @param WrappedComponent
 * @returns {function(*)} WrappedComponent + ReactTooltip component
 */
const withTooltip = (WrappedComponent: any) => (props: any) => {
  return (
    <Fragment>
      <WrappedComponent {...props} />
      <ReactTooltip
        effect="solid"
        delayHide={500}
        backgroundColor={theme.palette.greyDarker}
        wrapper="span"
      />
    </Fragment>
  );
};
export default withTooltip;
