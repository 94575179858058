// @flow
import {type Payment} from 'data/payment/types';
import {formatDate} from 'data/units/date/formatters';
import urls from 'pages/urls';
import {pathOr, prop} from 'ramda';
import React from 'react';

import Section from '../../../components/Section';
import Table from '../../../components/Table';
import type {ColumnDef, TableDefinition} from '../../../components/Table/types';
import {LayoutFlex} from '../../../componentsStyled/Layout';
import type {Booking} from '../../../data/bookings/types';
import type {PricingDetail} from '../../../data/reservations/types';
import {formatCurrencyFromCents} from '../../../data/units/money/formatters';
import originalReservationTableDefinition from '../../Reservation/tableDefinition';
import RefundForm from './RefundForm';

type Props = {
  data: Payment,
};

type LinkedReservationTableData = {
  id: number,
  externalId: string,
  booking: Booking,
  bookingExternalId: string,
  orderExternalId: string,
  start: string,
  end: string,
  status: string,
  pricingDetail: PricingDetail,
  totalPrice: number,
};

const columns: ColumnDef<LinkedReservationTableData>[] = [
  // NOTE(Barry): I don't want to copy/paste column definitions from the reservation table, but I do need to remove the
  // email column. The only way I could do this and not have flow complain about the types not matching up was to
  // typecast through any. A little gross, but it gets the job done
  ...((originalReservationTableDefinition.columns.filter(
    col => col.title !== 'email'
  ): any): ColumnDef<LinkedReservationTableData>[]),
  {
    title: 'Price',
    value: v =>
      formatCurrencyFromCents(v.pricingDetail.totalPrice, 'en-US', v.pricingDetail.currency),
  },
];

const linkedReservationsTableDefinition: TableDefinition<LinkedReservationTableData> = {
  columns,
  rowLink: v => urls.reservation.link.detail(v.id),
};

const PaymentDetail = ({data}: Props) => {
  // $Ramda
  const additionalInfo = pathOr('Not available', ['details', 'additionalInfo'], data);
  const customerEmail =
    data.reservations.length > 0 ? data.reservations[0].booking.order.customer.user.email : 'N/A';

  return (
    <React.Fragment>
      <Section title="payment details">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Charge Token</h4>
            <p>{data.chargeToken}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Amount</h4>
            <p>{formatCurrencyFromCents(data.amount, undefined, data.currency)}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Tax</h4>
            <p>{formatCurrencyFromCents(data.tax, undefined, data.currency)}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Currency</h4>
            <p>{data.currency}</p>
          </LayoutFlex>
        </LayoutFlex>
      </Section>
      <Section title={'linked to reservations'}>
        <Table tableDefinition={linkedReservationsTableDefinition} data={data.reservations} />
      </Section>
      <Section title="refund details">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Refund Amount</h4>
            <p>{formatCurrencyFromCents(data.refundAmount, undefined, data.currency)}</p>
          </LayoutFlex>
          <LayoutFlex column col fullWidth>
            <h4>Refund</h4>
            <RefundForm
              paymentId={data.id}
              paymentAmount={data.amount}
              refundAmount={data.refundAmount}
            />
          </LayoutFlex>
        </LayoutFlex>
      </Section>
      <Section title="other">
        <LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Status</h4>
            <p>{prop('status', data)}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Paid On</h4>
            <p>{data.paidAt ? formatDate(data.paidAt) : '-'}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Email</h4>
            <p>{customerEmail}</p>
          </LayoutFlex>
          <LayoutFlex column col oneQuater>
            <h4>Additional Info</h4>
            <p>{additionalInfo}</p>
          </LayoutFlex>
        </LayoutFlex>
      </Section>
    </React.Fragment>
  );
};

export default PaymentDetail;
