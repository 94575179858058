// @flow
import React from 'react';

type Props = {
  title: string,
};

export const withStory =
  (Component: any) =>
  ({title, ...rest}: Props) =>
    (
      <React.Fragment>
        <h1>
          Storybook / <span style={{textTransform: 'capitalize'}}>{title}</span>
        </h1>
        <Component {...rest} />
      </React.Fragment>
    );
