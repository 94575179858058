// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {ProductVariantTableDefintion} from 'data/productVariant/types';
import urls from 'pages/urls';
import {prop, propOr} from 'ramda';

import Pill from '../../../components/Pills';
import {getPillPropsFromTableValue} from '../../../components/Table/helper';

const tableDefinition: TableDefinition<ProductVariantTableDefintion> = {
  columns: [
    {
      title: 'name',
      // $Ramda
      value: propOr('(no name)', 'name'),
    },
    {
      title: 'status',
      value: v => v.isConsistent,
      type: Pill,
      props: v => getPillPropsFromTableValue('ConsistentMeta', v.isConsistent),
    },
    {
      title: 'Stock Location',
      value: prop('shelfLocation'),
    },
  ],
  rowLink: v => urls.variant.link.detail(v.id),
};
export default tableDefinition;
