import styled, {css} from 'styled-components';

export const StyledTd = styled.td`
  height: ${props => props.theme.metrics.tableRowHeight};
  padding: 0 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 25ch;

  // Added to give priority to actions onClick
  ${props =>
    props.isAction &&
    css`
      position: relative;
      z-index: ${props.theme.zIndices.minimal};
      color: ${props.theme.palette.grey};
      transition: color 250ms ease-in;

      :hover {
        color: ${props.theme.palette.primaryDark};
      }
    `}
`;
