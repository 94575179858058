// @flow
import gql from 'graphql-tag';
import {path} from 'ramda';
import type {Query} from 'types/Graphql';

import {productVariantFragment} from './fragments';
import type {
  Parameters,
  ProductVariant,
  ProductVariantInventoryItem,
  ProductVariantWithInventory,
} from './types';

export const getVariantParametersQuery: Query<Parameters> = {
  gql: gql`
    query getVariantParametersQuery($id: Int!) {
      product {
        getVariantParameters(productVariantId: $id) {
          id
          name
          type
          enum {
            multiple
            values {
              id
              value
            }
          }
          values {
            ... on ParameterEnumValue {
              enumValue
              enumValueId
            }
            ... on ParameterFloatValue {
              floatValue
              unit
            }
            ... on ParameterStringValue {
              stringValue
            }
            ... on ParameterIntValue {
              intValue
              unit
            }
          }
        }
      }
    }
  `,
  selector: ['product', 'getVariantParameters'],
};

const transformProductVariantItems = (variant: ProductVariant): ProductVariantWithInventory => {
  const items = variant.items.map((pi): ProductVariantInventoryItem => {
    // TODO: handle multiple categories
    const categories = path(['product', 'categories'], variant);
    const category = categories && categories.length > 0 && categories[0].name;
    // $Ramda
    return {
      id: pi.id,
      code: pi.code,
      buyItRrp: pi.buyItRrp,
      allowed: pi.allowed,
      online: !pi.deletedAt,
      variantId: variant.id,

      // NOTE(ray): Inexplicably the "product" field is not available sometimes
      // (likely to do with caching) so we have to use `path` to allow for that
      productId: path(['product', 'id'], variant),
      category,
      manufacturerId: path(['product', 'manufacturer', 'id'], variant),
      pricingTemplateId: pi.pricingTemplate.id,
      affiliate: pi.affiliate,
      status: pi.status,
    };
  });

  return {
    id: variant.id,
    name: variant.name,
    shelfLocation: variant.shelfLocation,
    parameters: variant.parameters,
    items,
    product: variant.product,
  };
};

export const productVariantDetailQuery: Query<ProductVariant> = {
  gql: gql`
    query productVariantDetailQuery($id: Int!) {
      product {
        getVariant(id: $id) {
          ...productVariantFragment
        }
      }
    }
    ${productVariantFragment}
  `,
  transform: transformProductVariantItems,
  selector: ['product', 'getVariant'],
  fetchPolicy: 'network-only',
};

export const listProductVariantsInventoryQuery: Query<ProductVariant[]> = {
  gql: gql`
    query listProductVariantsInventoryQuery($filter: ProductVariantInventoryFilter!) {
      product {
        listProductVariantsInventory(filter: $filter) {
          id
          name
        }
      }
    }
  `,
  selector: ['product', 'listProductVariantsInventory'],
  fetchPolicy: 'network-only',
};
