// @flow
// $Import
import React, {useCallback} from 'react';
import ReactSelect from 'react-select';

import CreatableSelect from './Creatable';
import {styles, theme} from './styled';

type SimpleValue = number | string;
type InputValue = ?SimpleValue | ?(SimpleValue[]);
type SelectOption = {[string]: any};
type SelectProps = {
  options: SelectOption[],
  isMulti?: boolean,
  isClearable?: boolean,
  onChange: any => any,
  value?: InputValue,
  defaultValue?: InputValue,
  map?: boolean,
  isFilter?: boolean,
  placeholder?: string,
  name?: string,
  idName?: string,
  getOptionValue?: SelectOption => SimpleValue,
  getOptionLabel?: SelectOption => string,
  canCreate?: boolean,
};

/**
 * Functions to get the label and value of options data when the 'map' prop is true.
 * Used for option data of the form {name, id} or {value, id}.
 * */
const mappedGetOptionLabel = (option: SelectOption) => option.name || option.value;
const mappedGetOptionValue = (option: SelectOption) => option.id;

/**
 * Default functions for getting label and value of options data. (by default we expect
 * options of the form {label, value}.
 */
const defaultGetOptionLabel = (option: SelectOption) => option.label;
const defaultGetOptionValue = (option: SelectOption) => option.value;

const SelectComponent = ({
  options = [],
  onChange,
  map = true,
  value,
  defaultValue,
  placeholder,
  isFilter,
  name,
  getOptionValue,
  getOptionLabel,
  isMulti = false,
  isClearable = true,
  canCreate,
  ...rest
}: SelectProps) => {
  const Select = canCreate ? CreatableSelect : ReactSelect;
  const defaultPlaceholder = canCreate ? 'Select or type to create...' : 'Select...';

  /**
   * Getters for the value and label of an option. Use the getOption prop if provided, else
   * fallback to map/default getters based on 'map' prop.
   * */
  const getValueFn = getOptionValue || (map ? mappedGetOptionValue : defaultGetOptionValue);
  const getLabelFn = getOptionLabel || (map ? mappedGetOptionLabel : defaultGetOptionLabel);

  /**
   * Converts the SimpleValue to its SelectOption equivalent by finding the matching
   * object in the 'options' array. This is done as react-select requires the 'value' be an
   * object of the same form of those in the 'options' array and not a string/number.
   *
   * NOTE: The final return is coalesced to null as setting the value to undefined sets the
   * input as an uncontrolled input instead of clearing it.
   * See: https://github.com/JedWatson/react-select/issues/3066
   */
  const simpleValueToOption = (value: InputValue) => {
    if (isMulti) {
      return options.filter(option => {
        const optionValue = getValueFn(option);
        return Array.isArray(value) ? value.includes(optionValue) : optionValue === value;
      });
    }
    return options.find(option => getValueFn(option) === value) ?? null;
  };

  /**
   * Converts the SelectOption back to SimpleValue to pass to onChange. Need useCallback for
   * use in useEffect below.
   */
  const handleChange = useCallback(
    value => {
      if (!value) return onChange();

      if (isMulti && Array.isArray(value)) {
        if (value.length === 0) {
          return onChange();
        }

        return onChange(value.map(x => getValueFn(x)));
      }

      return onChange(getValueFn(value));
    },
    [onChange, getValueFn, isMulti]
  );

  return (
    <Select
      {...rest}
      className="select-component"
      classNamePrefix="select-component"
      styles={styles}
      theme={theme}
      isFilter={isFilter}
      name={name}
      inputId={name}
      isMulti={isMulti}
      options={options}
      getOptionLabel={getLabelFn}
      getOptionValue={getValueFn}
      placeholder={defaultValue || placeholder || defaultPlaceholder}
      value={simpleValueToOption(value)}
      defaultValue={simpleValueToOption(defaultValue)}
      onChange={handleChange}
      isClearable={isClearable}
    />
  );
};

export default SelectComponent;
