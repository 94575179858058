// @flow
import {dissoc, identity, is, isNil, map, path} from 'ramda';

// leave arrays as is, calling dissoc on them would transform them into objects
const dissocTypename = x => {
  if (!isNil(x.length)) return x;

  return dissoc('__typename', x);
};

export const removeTypenames = (x: mixed) => {
  if (!is(Object, x)) return x;
  const dissoced = dissocTypename(x);
  return map(removeTypenames, dissoced);
};

export const transformResponse =
  (selector: string[] = [], transform?: Function = identity, pagination?: boolean) =>
  (data: {error?: {message: string}}) => {
    if (data.error) {
      throw new Error('Error from Apollo processing of GraphQL query: ' + data.error.message);
    }
    const p = pagination ? selector.concat('data') : selector;
    const selectedData = path(p, data);

    return isNil(selectedData) ? undefined : transform(selectedData);
  };
