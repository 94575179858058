// @flow
import React from 'react';
import {compose} from 'recompose';
import * as yup from 'yup';

import {optional} from '../../common/validators';
import Table from '../../components/Table';
import {reservationListQuery} from '../../data/reservations/queries';
import withFilter from '../../hoc/withFilter';
import withForm from '../../hoc/withForm';
import withQuery from '../../hoc/withQuery';
import filterConfig from '../Reservation/filterConfig';
import tableDefinition from '../Reservation/tableDefinition';
import {withStory} from './withStory';

const TablesStory = ({data, filter, updateFilter, clearFilter}) => (
  <Table
    title="Reservations"
    tableDefinition={tableDefinition}
    data={data}
    filterConfig={filterConfig}
    filter={filter}
    updateFilter={updateFilter}
    clearFilter={clearFilter}
  />
);

const enhancer = compose(
  withForm({
    schema: yup.object().shape({
      loading: optional,
    }),
    mapProps: () => ({
      loading: false,
    }),
  }),
  withFilter,
  withQuery(reservationListQuery)
);

export default enhancer(withStory(TablesStory));
