// @flow
import Table from 'components/Table';
import type {Email} from 'data/email/types';
import baseTableDefinition from 'pages/Email/EmailTableConfig/tableDefinition';
import urls from 'pages/urls';
import * as React from 'react';

import {TableWrap} from './styled';

type Props = {
  loading: boolean,
  data: Email[],
};

const tableDefinition = {
  ...baseTableDefinition,
  rowLink: item => urls.emailProjection.link.detail(item.emailId),
  rowLinkState: item => ({emailData: item}),
};

const EmailProjectionTable = ({loading, data}: Props) => {
  return (
    <TableWrap>
      <Table tableDefinition={tableDefinition} data={data} loading={loading} />
    </TableWrap>
  );
};

export default EmailProjectionTable;
