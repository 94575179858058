// @flow
import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'componentsStyled/Typography';
import EditBundleComponent from 'containers/Modals/EditBundleComponent';
import {bundleComponentUpdateMutation} from 'data/bundle/mutations';
import type {BundleComponent} from 'data/bundle/types';
import {openModal} from 'data/modals/actions';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, withHandlers} from 'recompose';

import withTooltip from '../../../../hoc/withTooltip';

const EditBundleComponentLink = ({data, editItem}) => (
  <Link onClick={editItem} component="a" redirect={false}>
    <FontAwesomeIcon icon={faEdit} size="lg" data-tip="Edit" />
  </Link>
);

const mapDispatchToProps = {
  openModal,
};

type Enhancer = {
  data: BundleComponent,
};

const enhancer: HOC<*, Enhancer> = compose(
  connect(null, mapDispatchToProps),
  withTooltip,
  withMutation(bundleComponentUpdateMutation),
  withHandlers({
    editItem: props => () =>
      props.openModal(EditBundleComponent, {
        data: props.data,
        submit: props.submitMutation,
      }),
  })
);

export default enhancer(EditBundleComponentLink);
