// @flow
import Button from 'components/Button';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import type {EditProps} from 'factories/createEntityCRUD';
import withForm from 'hoc/withForm';
import React from 'react';
import type {HOC} from 'recompose';

import InputFileUpload, {IMAGE_MIME_TYPE} from '../../../../components/Inputs/InputFileUpload';
import schema from './schema';

const AccessoriesCreate = ({isSubmitting}) => (
  <div>
    <InputText name="name" placeholder="name" label="name" />
    <InputFileUpload name="url" label="url" bucket="accessoryIcons" accept={IMAGE_MIME_TYPE} />
    <InputSwitch name="preselect" label="Is pre-selected on checkout?" />
    <Button kind="primary" loading={isSubmitting} big success type="submit">
      save
    </Button>
  </div>
);

const enhancer: HOC<*, EditProps> = withForm({
  schema,
  onSubmit: props => values => {
    values.url = values.url[0].url;
    return props.submit({
      input: {
        ...values,
        preselect: Boolean(values.preselect),
      },
    });
  },
});

export default enhancer(AccessoriesCreate);
