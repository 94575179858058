// @flow
import * as React from 'react';
import type {ReduxAction} from 'types/Redux';

type ModalActionType = 'OPEN_MODAL' | 'CLOSE_MODAL';

export type ModalType = React.ComponentType<any>;

type OpenModalAction = ReduxAction<ModalActionType, 'OPEN_MODAL', *>;

type CloseModalAction = ReduxAction<ModalActionType, 'CLOSE_MODAL', *>;

export type ModalAction = OpenModalAction | CloseModalAction;

export const openModal = (type: ModalType, props?: {}): OpenModalAction => ({
  type: 'OPEN_MODAL',
  payload: {
    type,
    props,
  },
});

export const closeModal = (): CloseModalAction => ({
  type: 'CLOSE_MODAL',
  payload: null,
});
