// @flow
import gql from 'graphql-tag';
import type {IdResponse, Mutation} from 'types/Graphql';

import {profileDetailQuery, userDetailQuery, userListQuery} from './queries';

export const getRecoveryEmailMutation: Mutation<{|email: string, appType: string|}, IdResponse> = {
  gql: gql`
    mutation getRecoveryEmailMutation($email: LowercaseString!, $appType: String) {
      auth {
        resetPasswordCode(email: $email, appType: $appType)
      }
    }
  `,
};

export const userCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation userCreateMutation($input: createAffiliateUserInput!) {
      user {
        createAffiliateUser(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [userListQuery],
  selector: ['user', 'createAffiliateUser'],
};

export const userUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation userUpdateMutation($input: ProfileUpdateInput) {
      profile {
        update(input: $input) {
          firstName
        }
      }
    }
  `,
  refetch: [userDetailQuery],
  selector: ['profile', 'update'],
};

export const profileCreateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation profileCreateMutation($input: ProfileCreateInput) {
      profile {
        create(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [profileDetailQuery],
  selector: ['profile', 'create'],
};

export const profileUpdateMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation profileUpdateMutation($input: ProfileUpdateInput) {
      profile {
        update(input: $input) {
          firstName
        }
      }
    }
  `,
  refetch: [userDetailQuery, profileDetailQuery],
  selector: ['profile', 'update'],
};

export const profileRemoveMutation: Mutation<{}, IdResponse> = {
  gql: gql`
    mutation profileRemoveMutation($input: ProfileRemoveInput) {
      profile {
        remove(input: $input) {
          id
        }
      }
    }
  `,
  refetch: [userDetailQuery],
};

export const changePasswordMutation: Mutation<
  {|
    oldPassword: string,
    password: string,
  |},
  boolean
> = {
  gql: gql`
    mutation changePasswordMutation($oldPassword: String!, $password: String!) {
      user {
        changePassword(oldPassword: $oldPassword, password: $password)
      }
    }
  `,
};
