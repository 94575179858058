import styled from 'styled-components';

import {mq} from '../../global/queries';

const loginBrandWidth = '40rem';

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.laptop} {
    background-color: ${props => props.theme.palette.light};
    position: fixed;
    height: 100vh;
    width: calc(100vw - ${loginBrandWidth});
    top: 0;
    left: ${loginBrandWidth};
    justify-content: center;
  }
`;

export const LoginBrand = styled.div`
  display: none;

  ${mq.laptop} {
    display: block;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: ${loginBrandWidth};
  height: 100vh;
  background-color: ${props => props.theme.palette.successDark};
  z-index: ${props => props.theme.zIndices.minimal};

  img {
    position: absolute;

    &#circle,
    &#logo {
      bottom: 0;
      left: 0;
    }

    &#circle {
      width: calc(100% - 50px);
      height: auto;
    }

    &#logo {
      width: 65%;
      height: auto;
    }
  }
`;
export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.laptop} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${props => props.theme.zIndices.minimal};
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 3rem;

    svg {
      color: ${props => props.theme.palette.light};
    }
  }
`;
export const LoginWrap = styled.div`
  width: 100%;
  margin: 2rem auto;
  background-color: ${props => props.theme.palette.light};
  padding: 4rem 1.6rem;

  ${mq.tablet} {
    max-width: 57.6rem;
    padding: 6rem;
  }
`;

export const WelcomeMessage = styled.div`
  width: 100%;
  max-width: 35rem;
  margin: 2rem auto;
  padding: 2rem;

  color: ${p => p.theme.palette.light};
  background-color: ${p => p.theme.palette.success};
`;
