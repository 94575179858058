// @flow
import type {ProductDamage} from 'data/reservations/types';
import withEmpty from 'hoc/withEmpty';
import React from 'react';

type Props = {
  data: ProductDamage[],
};
const DamagesList = ({data}: Props) => (
  <div>
    {data.map(d => (
      <p key={d.id}>{d.damageType.type}</p>
    ))}
  </div>
);

export default withEmpty(undefined, 'no damages')(DamagesList);
