// @flow
import {accessoriesCreateMutation, accessoriesUpdateMutation} from 'data/accessories/mutations';
import {accessoriesDetailQuery, accessoriesListQuery} from 'data/accessories/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import withHelpCenter from 'hoc/withHelpCenter';
import urls from 'pages/urls';

import AccessoriesDetail from './AccessoriesDetail';
import AccessoriesEdit from './AccessoriesEdit';
import filterConfig from './filterConfig';
import tableDefinition from './tableDefinition';

const Page = createEntityCRUD({
  edit: AccessoriesEdit,
  createMutation: accessoriesCreateMutation,
  updateMutation: accessoriesUpdateMutation,
  listQuery: accessoriesListQuery,
  detailQuery: accessoriesDetailQuery,
  detail: AccessoriesDetail,
  tableDefinition,
  filterConfig,
  title: 'Accessories',
  entityName: 'Accessory',
  route: urls.accessories,
});

const enhancer = withHelpCenter({category: 'accessories'});

export default enhancer(Page);
