// @flow
import Loader from 'components/Loader';
import {TitleMa} from 'componentsStyled/Typography';
import {verifySignupEmailMutation} from 'data/tenant/mutations';
import withMutation from 'hoc/withMutation';
import withOnMount from 'hoc/withOnMount';
import withRouter, {parseSearchFromLocation} from 'hoc/withRouter';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import Button from '../../components/Button';
import {LayoutFlex} from '../../componentsStyled/Layout';
import LogoIcon from '../../containers/App/Header/LogoLink/LogoIcon';
import config from '../../global/config';
import theme from '../../global/theme';
import urls from '../urls';
import {ErrorMessage, Page, SuccessBox} from './styled';

const brandImage = filename => `${config.brandUrl}/${filename}`;

function VerifySignupEmailPage({status, history}) {
  function renderInner() {
    if (status.kind === 'in-progress') {
      return (
        <React.Fragment>
          <h1>Verifying email address</h1>
          <Loader color={theme.palette.primary} />
        </React.Fragment>
      );
    } else if (status.kind === 'success') {
      return (
        <SuccessBox>
          <div className={'center'}>
            <img
              src={brandImage('email-verification.png')}
              alt=""
              loading="lazy"
              width={280}
              height={160}
            />
            <TitleMa>Verification Success</TitleMa>
            <p>Your email address has been verified!</p>
            <p>Thank you for signing up with Awayco. We're excited to have you on board.</p>
            <Button fullWidth big success kind={'primary'} onClick={() => history.push(urls.login)}>
              Sign in
            </Button>
          </div>
        </SuccessBox>
      );
    } else {
      return (
        <ErrorMessage>
          <h1>Verification Error</h1>
          <p>Sorry we were not able to verify your email address.</p>
          <p>
            Please try signing up again. If the issue continues, contact our support team at
            support@awayco.com. We would love to help out.
          </p>
        </ErrorMessage>
      );
    }
  }
  return (
    <Page>
      <LayoutFlex justifyContentCenter>
        <LogoIcon />
      </LayoutFlex>
      <br />
      <br />
      <br />
      {renderInner()}
    </Page>
  );
}

const enhancer: HOC<*, {}> = compose(
  withRouter,
  withStateHandlers({status: {kind: 'in-progress'}}, {setStatus: () => v => ({status: v})}),
  withMutation(verifySignupEmailMutation),
  withOnMount(props => {
    void (async function () {
      const parameters = parseSearchFromLocation(props.location);

      // Provide `debugState` query parameter for debugging
      if (parameters.debugState) {
        props.setStatus({kind: parameters.debugState});
        return;
      }

      try {
        const response = await props.submitMutation({
          input: {
            code: parameters.code,
          },
        });
        if (response === true) {
          props.setStatus({kind: 'success'});
        } else {
          props.setStatus({kind: 'failure'});
        }
      } catch (error) {
        props.setStatus({kind: 'failure'});
      }
    })();
  })
);

export default enhancer(VerifySignupEmailPage);
