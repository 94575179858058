import Button from 'components/Button';
import withRouter from 'hoc/withRouter';
import EmailDetail from 'pages/Email/EmailDetail';
import urls from 'pages/urls';
import {path} from 'ramda';
import {Redirect} from 'react-router-dom';

const EmailProjectionDetail = props => {
  const data = path(['location', 'state', 'emailData'], props);

  return data ? (
    <>
      <EmailDetail data={data} />
      <Button onClick={() => props.history.goBack()} kind="tertiary">
        Back
      </Button>
    </>
  ) : (
    <Redirect to={urls.emailProjection.path.list} />
  );
};

export default withRouter(EmailProjectionDetail);
