// @flow
import React from 'react';

const ErrorOutlineIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9996 14.4645C11.3098 14.4645 10.7497 15.0246 10.7497 15.7144C10.7497 16.4042 11.3098 16.9643 11.9996 16.9643C12.6894 16.9643 13.2495 16.4042 13.2495 15.7144C13.2495 15.0246 12.691 14.4645 11.9996 14.4645ZM11.0622 7.90247V12.2771C11.0622 12.7947 11.4821 13.2146 11.9996 13.2146C12.5171 13.2146 12.937 12.7947 12.937 12.2771V7.90247C12.937 7.38493 12.5171 6.96504 11.9996 6.96504C11.4821 6.96504 11.0622 7.41813 11.0622 7.90247ZM21.4403 10.6952L13.3081 2.52789C12.5656 1.82404 11.3532 1.82404 10.6497 2.52789L2.55777 10.6952C1.81408 11.3975 1.81408 12.6103 2.55777 13.3126L10.6899 21.4409C11.3938 22.1846 12.6058 22.1846 13.3097 21.4409L21.4418 13.3126C22.1863 12.6091 22.1863 11.3983 21.4403 10.6952ZM11.9996 20.1007L3.90024 12.0037L11.9543 3.87544H11.9656C11.9818 3.87544 11.9968 3.87918 12.0069 3.88292C12.0118 3.88421 12.0144 3.88543 12.0169 3.88665L20.0983 12.0032L11.9996 20.1007Z"
      fill="#1A202C"
    />
  </svg>
);

export default ErrorOutlineIcon;
