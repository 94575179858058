import type {User} from 'data/auth/graphql/types';
import config from 'global/config';
/*
 * These functions work in conjunction with the snippet added to the base page.
 * The identity and page functions are designed to be used with logged in users,
 * the former to provide user information into appcues, the latter to indicate
 * a page change within the application.
 * See documentation for more information.
 * https://docs.appcues.com/article/161-javascript-api
 */

/*
 * Extracts the relevant properties from the user object and passes it into
 * the appcues identify function.
 * @param {User} user
 * @returns {void}
 */
export const identify = (user: User) => {
  const {
    id,
    role,
    createdAt,
    metrics: {
      totalBookings,
      totalLocations,
      totalProducts,
      totalCustomers,
      totalStaff,
      totalVariants,
      totalItems,
    },
    tenantName,
  } = user;
  const envName = config.environment;
  // Prefix the userId with the environment name when not in the production environment
  // to prevent pollution of user data.
  const userId = envName === 'prod1' ? id : `${envName}-${String(id)}`;

  window.AppcuesReady(() =>
    window.Appcues.identify(userId, {
      'user-role': role,
      'created-date': createdAt,
      'merchant-name': tenantName,
      'total-bookings': totalBookings,
      'total-locations': totalLocations,
      'total-products': totalProducts,
      'total-customers': totalCustomers,
      'total-staff': totalStaff,
      'total-variants': totalVariants,
      'total-items': totalItems,
    })
  );
};

/*
 * Notify appcues that the page has changed so that it can re-evaluate whether or not
 * to show flows. It requires that the `identify` function already has been called
 * once, or the identity parameters already be cached.
 * @returns {void}
 */
export const page = () => {
  window.AppcuesReady(() => window.Appcues.page());
};

/*
 * Use this function only at the request of the business, there are significant cost consequences
 * to anonymous tracking. https://docs.appcues.com/article/438-anonymous-users
 * @returns {void}
 */
export const anonymous = () => {
  window.AppcuesReady(() => window.Appcues.anonymous());
};
