import styled from 'styled-components';

export const StyledUl = styled.ul`
  list-style-type: none;
`;

export const StyledLi = styled.li`
  list-style-type: none;
`;
export const ListText = styled.p`
  margin-left: 3rem;
`;

export const RowWrap = styled.ul`
  display: flex;
  align-items: center;
  > * {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;
