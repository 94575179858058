import styled from 'styled-components';

export const AllowedInputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

export const CodeInputWrap = styled.div`
  display: block;
  width: 100%;
`;

export const ColumnWrap = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const RevenueWrap = styled.div`
  padding-top: 1rem;
`;

export const VariantSelectWrap = styled.div`
  margin-bottom: 5rem;
  display: block;
`;
