// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import CountrySelect from 'containers/CountrySelect';
import withForm from 'hoc/withForm';
import {path} from 'ramda';
import React from 'react';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
  create: boolean,
};

const LocationEdit = ({isSubmitting, create}: Props) => (
  <div>
    <InputText name="name" label="name" />
    <InputText
      name="lat"
      label="latitude"
      type="number"
      inputmode="numeric"
      step="0.000001"
      max="90"
      min="-90"
    />
    <InputText
      name="lng"
      label="longitude"
      type="number"
      inputmode="numeric"
      step="0.000001"
      max="180"
      min="-180"
    />
    <CountrySelect name="countryId" label="country" />
    <Button kind="primary" loading={isSubmitting}>
      {create ? 'Create location' : 'save'}
    </Button>
  </div>
);

export default withForm({
  schema,
  mapProps: props => ({
    ...props.data,
    countryId: path(['data', 'country', 'id'], props),
  }),
  onSubmit: props => values => props.submit({input: values}),
})(LocationEdit);
