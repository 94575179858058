// @flow
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {Link} from 'componentsStyled/Typography';
import {login} from 'data/auth/actions';
import {loginMutation} from 'data/auth/graphql/mutations';
import withForm from 'hoc/withForm';
import withMutation from 'hoc/withMutation';
import * as React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose} from 'recompose';

import {LayoutFlex} from '../../../componentsStyled/Layout';
import schema from './schema';

type Props = {
  isSubmitting: boolean,
  openForgotPassword(): void,
};

const LoginForm = ({isSubmitting, openForgotPassword}: Props) => (
  <React.Fragment>
    <InputText name="email" type="email" placeholder="email" label="Your email address" />
    <InputText name="password" type="password" placeholder="password" label="Password" />
    <LayoutFlex column>
      <Button kind="primary" loading={isSubmitting} fullWidth big data-cy={'login-button'}>
        Enter store
      </Button>
      <br />
      <LayoutFlex justifyContentCenter>
        <Link component="a" onClick={openForgotPassword} marketing>
          Forgot password?
        </Link>
      </LayoutFlex>
    </LayoutFlex>
  </React.Fragment>
);

const mapDispatchToProps = {
  login,
};

type Outter = {|
  openForgotPassword: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  connect(null, mapDispatchToProps),
  withMutation(loginMutation),
  withForm({
    schema,
    onSubmit: props => props.submitMutation,
    onSuccess: props => props.login,
  })
);

export default enhancer(LoginForm);
