// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import {formatDate} from 'data/units/date/formatters';
import urls from 'pages/urls';
import {prop} from 'ramda';

import Pill from '../../../components/Pills';
import {getPillPropsFromTableValue} from '../../../components/Table/helper';

type TableData = {
  id: number,
  code: string,
  start: string,
  end: string,
  status: string,
};

const tableDefinition: TableDefinition<TableData> = {
  columns: [
    {
      title: 'code',
      value: prop('code'),
    },
    {
      title: 'start',
      value: v => formatDate(v.start),
    },
    {
      title: 'end',
      value: v => formatDate(v.end),
    },
    {
      title: 'status',
      value: prop('status'),
      type: Pill,
      props: v => getPillPropsFromTableValue(v.status),
    },
  ],
  rowLink: v => urls.reservation.link.detail(v.id),
};
export default tableDefinition;
