// @flow
import {paginationFragment} from 'data/other/fragments';
import type {Payment} from 'data/payment/types';
import gql from 'graphql-tag';
import {map, prop} from 'ramda';
import type {Query} from 'types/Graphql';

import {paymentFragment} from './fragments';

export const paymentListQuery: Query<Payment[]> = {
  gql: gql`
    query paymentListQuery($filter: ListPaymentFilter!) {
      payment {
        listPayments(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...paymentFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${paymentFragment}
  `,
  selector: ['payment', 'listPayments'],
  pagination: true,
};

/**
 * Lists payments by first fetching charges from Stripe.
 * NOTE(ray): Unused due to it not returning any data
 * (https://app.clubhouse.io/awayco/story/1465/fix-empty-payments-list-in-admin-app),
 * but keeping here to be illustrative of a non-standard pagination method.
 */
export const paymentListFromChargesQuery: Query<Payment[]> = {
  gql: gql`
    query paymentListFromChargesQuery($filter: ListChargesWithPaymentFilter!) {
      payment {
        listChargesWithPayment(filter: $filter) {
          pageInfo {
            hasNextPage
            nextOffset
          }
          data {
            payment {
              ...paymentFragment
            }
          }
        }
      }
    }
    ${paymentFragment}
  `,
  selector: ['payment', 'listChargesWithPayment'],
  transform: map(prop('payment')),
  pagination: true,
};

export const paymentDetailQuery: Query<Payment> = {
  gql: gql`
    query paymentDetailQuery($id: Int!) {
      payment {
        get(id: $id) {
          ...paymentFragment
        }
      }
    }
    ${paymentFragment}
  `,
  selector: ['payment', 'get'],
};
