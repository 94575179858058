// @flow
import type {Image} from 'data/other/types';
import withEmpty from 'hoc/withEmpty';
import {isNil, prop, sortBy} from 'ramda';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import ImageItem from '../ImageItem';
import {GalleryWrap} from './styled';

const Gallery = ({data}) => (
  <GalleryWrap>
    {data.map((x, i) => (
      <ImageItem key={i} order={x.order} url={x.url} />
    ))}
  </GalleryWrap>
);

type Enhancer = {
  data: Image[],
};

const enhancer: HOC<*, Enhancer> = compose(
  withEmpty(undefined, 'no images'),
  withProps(props => {
    if (!isNil(props.data[0].order)) {
      return {data: sortBy(prop('order'), props.data)};
    }

    return {};
  })
);

export default enhancer(Gallery);
