// @flow
import type {ReduxAction} from 'types/Redux';

type CommonActionType = 'NOOP';

export type NoopAction = ReduxAction<CommonActionType, 'NOOP', *>;

export const noopAction = (): NoopAction => ({
  type: 'NOOP',
  payload: null,
});
