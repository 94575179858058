// @flow
import {ButtonGroup} from 'componentsStyled/Shared';
import {Hint, Label} from 'componentsStyled/Typography';
import styled, {css} from 'styled-components';

export const InputVariantVariableWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Error = styled(Hint)`
  color: ${props => props.theme.palette.red};
  min-height: 1.1em;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const VariantVariableLabel = styled(Label)`
  min-width: 0rem;
  ${props =>
    props.variantVariablesEnabled &&
    css`
      min-width: 10rem;
    `}
`;
