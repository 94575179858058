// @flow
// @author: t04435
// Date: 8/7/21

import type {TableDefinition} from 'components/Table/types';
import type {CategoryObject} from 'data/product/types';
import urls from 'pages/urls';
import {prop} from 'ramda';

const tableDefinition: TableDefinition<CategoryObject> = {
  columns: [
    {
      title: 'name',
      value: prop('name'),
    },
  ],
  rowLink: v => urls.category.link.detail(v.categoryId),
};
export default tableDefinition;
