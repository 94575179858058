// @flow
import {Spread} from 'componentsStyled/Flex';
import styled from 'styled-components';

export const StyledSpread = styled(Spread)`
  display: flex;
  justify-content: left;
  margin-top: 1rem;

  > * {
    margin-right: 2rem;
  }

  margin-bottom: 4rem;
`;
