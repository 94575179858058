// @flow
import {getUniqueId} from 'common/unique';
import type {ReduxAction} from 'types/Redux';

type NotificationActionType = 'SHOW_NOTIFICATION' | 'CLOSE_NOTIFICATION';

export type NotificationType = 'SUCCESS' | 'WARNING' | 'ERROR';

export type Notification = {
  text: string,
  type: NotificationType,
  id: number,
};

type ShowNotificationAction = ReduxAction<
  NotificationActionType,
  'SHOW_NOTIFICATION',
  Notification
>;

type CloseNotificationAction = ReduxAction<
  NotificationActionType,
  'CLOSE_NOTIFICATION',
  Notification
>;

export type NotificationAction = ShowNotificationAction | CloseNotificationAction;

type ShowNotification = NotificationType => string => ShowNotificationAction;
const showNotification: ShowNotification = type => text => ({
  type: 'SHOW_NOTIFICATION',
  payload: {
    text,
    type,
    id: getUniqueId(),
  },
});

type CloseNotification = Notification => CloseNotificationAction;
export const closeNotification: CloseNotification = n => ({
  type: 'CLOSE_NOTIFICATION',
  payload: n,
});

export const notificationSuccess = showNotification('SUCCESS');
export const notificationWarning = showNotification('WARNING');
export const notificationError = showNotification('ERROR');
