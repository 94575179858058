// @flow
import type {FilterConfig} from 'components/Filter';
import TextInput from 'components/TextInput';

const filterConfig: FilterConfig = {
  query: {
    type: TextInput,
    props: {
      placeholder: 'Search ...',
    },
  },
};

export default filterConfig;
