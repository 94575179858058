// @flow
import {faMapMarkerAlt} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FieldWrap from 'components/Inputs/FieldWrap';
import React from 'react';
import GooglePlacesAutoComplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import type {HOC} from 'recompose';
import {compose, withHandlers} from 'recompose';
import tzlookup from 'tz-lookup';

import type {ReqFieldProps} from '../../../hoc/withField';
import withField from '../../../hoc/withField';
import {
  GooglePlacesAutoCompleteWrapper,
  PlaceAutocomplete,
  PlacesList,
  PlacesListItem,
} from './styled';

const AddressAutocomplete = ({types, value, label, onChange, onAddressChange, error}) => {
  const PLACEHOLDER = 'Start typing your address';

  // finalise address only after the user selects an option from the dropdown list
  const handleInputChange = value => {
    onChange(value);
    onAddressChange({});
  };
  const handleInputSelect = value => {
    geocodeByAddress(value).then(results => {
      let formattedAddress = results[0].formatted_address;
      onChange(formattedAddress);
      getLatLng(results[0]).then(({lat, lng}) => {
        // TODO(Anyone): Rethink how to handle the results from the autocomplete.
        // We are using this one component to handle 2 inputs of the schema (the other being
        // `coordinates`) and need to deliberately funnel the error from `coordinates` into
        // this component `address` from the validator
        onAddressChange({lat, lng, inTimezone: tzlookup(lat, lng)});
      });
    });
  };

  return (
    <FieldWrap label={label} error={error}>
      <GooglePlacesAutoComplete
        onSelect={handleInputSelect}
        onChange={handleInputChange}
        value={value}
        placeholder={PLACEHOLDER}
        debounce={450}
        searchOptions={{
          types,
        }}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <GooglePlacesAutoCompleteWrapper>
            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
            <PlaceAutocomplete
              {...getInputProps({
                placeholder: PLACEHOLDER,
              })}
              id="address"
              autocomplete={false}
              showingSuggestion={suggestions.length > 0}
              data-cy={'address-autocomplete'}
            />
            {suggestions.length > 0 && (
              <PlacesList>
                {loading && <PlacesListItem>Loading...</PlacesListItem>}
                {suggestions.map(suggestion => (
                  <PlacesListItem
                    key={suggestion.placeId}
                    isActive={suggestion.active}
                    {...getSuggestionItemProps(suggestion)}
                    data-cy={'address-autocomplete-suggestion'}
                  >
                    {suggestion.description}
                  </PlacesListItem>
                ))}
              </PlacesList>
            )}
            <input name="coordinates" type="hidden" />
          </GooglePlacesAutoCompleteWrapper>
        )}
      </GooglePlacesAutoComplete>
    </FieldWrap>
  );
};

type Outter = {
  types: string[],
  onChangeCoordinates(): void,
};

// $Dunno
const enhancer: HOC<Outter, ReqFieldProps> = compose(
  withField,
  withHandlers({
    onAddressChange: props => res =>
      // $Dunno - Jude to fix on his locations fix PR which touches this
      props.onChangeCoordinates({
        lat: res.lat,
        lng: res.lng,
        inTimezone: res.inTimezone,
      }),
  })
);

export default enhancer(AddressAutocomplete);
