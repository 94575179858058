// @flow
import Loader from 'components/Loader';
import {propEq} from 'ramda';
import * as React from 'react';

// $Ramda
const defaultPredicate = propEq('loading', true);

function withLoader<Props>(
  predicate?: Props => boolean = defaultPredicate
): (React.ComponentType<Props>) => React.ComponentType<Props> {
  return Component => (props: Props) => {
    if (predicate(props)) return <Loader />;

    return <Component {...props} />;
  };
}

export default withLoader;
