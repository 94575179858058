import {transformResponse} from 'common/graphql/utils';
import {persistor} from 'global/store';

import crypto from './crypto';
import apolloClient from './index';

export const purge = () => {
  crypto.purge();
  persistor.purge();
  return apolloClient.resetStore();
};

export function query<Res, Params>(
  {gql, transform, selector, fetchPolicy}: Query<Res, Params>,
  variables?: Params
): Promise<Res> {
  return apolloClient
    .query({
      query: gql,
      variables,
      fetchPolicy,
    })
    .then(({data}) => transformResponse(selector, transform)(data));
}

export function mutate<Res, Params>(
  {gql, selector}: Mutation<Params, Res>,
  variables: Params
): Promise<Res> {
  return apolloClient
    .mutate({
      mutation: gql,
      variables,
    })
    .then(transformResponse(selector));
}
