// @flow
import {dayMap} from 'common/days';
import Button from 'components/Button';
import FieldWrap from 'components/Inputs/FieldWrap';
import withField, {type ReqFieldProps} from 'hoc/withField';
import {assoc, mapObjIndexed, remove, update, values} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import DayInput from './DayInput';
import {DaysWrap} from './styled';

const InputOpeningHours = ({value, handleChange, handleRemove, addException, special, ...rest}) => (
  <FieldWrap {...rest}>
    <DaysWrap>
      {!special &&
        values(
          mapObjIndexed(
            (v, k) => (
              <DayInput key={k} value={value[k]} label={v} onChange={handleChange(k)} data-cy={k} />
            ),
            dayMap
          )
        )}
      {special &&
        value.map((v, i) => (
          <DayInput
            id={`exception-date-${i}`}
            key={i}
            value={v}
            label={v.date}
            onChange={handleChange(i)}
            onRemove={handleRemove(i)}
            special
            data-cy={`exception-date-${i}`}
          />
        ))}
    </DaysWrap>
    {special && (
      <Button id="add-exception" kind="primary" onClick={addException} data-cy={'add-exception'}>
        add exception
      </Button>
    )}
  </FieldWrap>
);

type Enhancer = {
  special?: boolean,
  'data-cy'?: string,
};

const enhancer: HOC<*, {...$Exact<ReqFieldProps>, ...$Exact<Enhancer>}> = compose(
  withField,
  withHandlers({
    handleChange: props => k => v => {
      // for special cases it's an array, for regular it's object
      const updateFn = props.special ? update : assoc;
      props.onChange(updateFn(k, v, props.value));
    },
    handleRemove: props => (k: number) => () => props.onChange(remove(k, 1, props.value)),
    addException: props => (e: SyntheticInputEvent<*>) => {
      e.preventDefault();
      props.onChange(
        props.value.concat({
          date: '',
          closed: true,
          hours: null,
        })
      );
    },
  })
);

export default enhancer(InputOpeningHours);
