// @flow
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import type {CRUDRoute} from 'pages/urls';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {type HOC, withHandlers} from 'recompose';

import {ControlBarWrap} from './styled';

/**
 * @TODO: Fix the implementation of the back navigation in 'renderGoBack'.
 * The current implementation of going back to the 'list' view does
 * not always make sense as a list view may not exist (e.g. for Variants).
 * In lieu of a proper fix we are temporatily removing the go back button.
 */
const renderGoBack = (route, title) => {
  return null;
  // return (
  //   <GoBack component={'p'} to={route.link.list}>
  //     <FontAwesomeIcon icon={faArrowLeft} size={'1x'} /> {title}
  //   </GoBack>
  // );
};

const ControlBar = ({
  route,
  submit,
  handleDelete,
  hasCreate,
  hasEdit,
  hasDetail,
  hasEditAll,
  title,
  entityName,
}: Enhancer) => (
  <Switch>
    {(hasCreate || hasEditAll) && (
      <Route exact path={route.path.list}>
        <ControlBarWrap top>
          {hasEditAll && (
            <Button kind="tertiary" to={route.link.editAll}>
              edit all {title}
            </Button>
          )}
          {hasCreate && (
            <Button
              kind="primary"
              iconStart={faPlus}
              to={route.link.create}
              data-cy={'create-entity'}
            >
              add {entityName}
            </Button>
          )}
        </ControlBarWrap>
      </Route>
    )}
    <Route exact path={route.path.create} render={() => renderGoBack(route, title)} />
    <Route
      exact
      path={route.path.detail}
      render={props => (
        <>
          {renderGoBack(route, title)}
          <ControlBarWrap>
            {/* hide delete button if no delete mutation is present */}
            {submit && (
              <Button kind="primary" danger onClick={() => handleDelete(props.match.params.id)}>
                delete {entityName}
              </Button>
            )}
            {hasEdit && (
              <Button
                kind="secondary"
                to={route.link.update(props.match.params.id)}
                data-cy={'edit-entity'}
              >
                edit {entityName}
              </Button>
            )}
            {/* FIXME: hack to hide cancel button on variant detail */}
            {route.index !== '/variant' && (
              <Button kind="tertiary" to={route.link.list}>
                cancel
              </Button>
            )}
          </ControlBarWrap>
        </>
      )}
    />
    <Route
      path={route.path.update}
      render={props => (
        <>
          {renderGoBack(route, title)}
          <ControlBarWrap update>
            {/* hide delete button if no delete mutation is present */}
            {submit && !hasDetail && (
              <Button kind="primary" danger onClick={() => handleDelete(props.match.params.id)}>
                delete {title}
              </Button>
            )}
            <Button
              kind="tertiary"
              to={hasDetail ? route.link.detail(props.match.params.id) : route.link.list}
            >
              cancel
            </Button>
          </ControlBarWrap>
        </>
      )}
    />
  </Switch>
);

type Enhancer = {
  route: CRUDRoute,
  // is the deleteMutation from HOC
  submit?: Function,
  deleteMessage?: string,
  hasCreate: boolean,
  hasEdit: boolean,
  hasDetail: boolean,
  hasEditAll: boolean,
  title: string,
  // text to be show in the control buttons: edit {entityName}
  entityName: string,
  // added by withHandlers HOC
  handleDelete: Function,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleDelete: props => id => {
    const warning = props.deleteMessage
      ? props.deleteMessage
      : 'Are you sure you want to delete this item?';

    if (window.confirm(warning)) {
      // $Fixme - CRA migration
      props.submit({id});
    }
  },
});

export default enhancer(ControlBar);
