// @flow
import RawHtml from 'components/RawHtml';
import {Card} from 'componentsStyled/Card';
import type {Email} from 'data/email/types';
import {toReadableDateTime} from 'data/units/date/formatters';
import {
  EmailDate,
  EmailHeader,
  EmailPrimaryInfo,
  EmailSecondaryInfo,
  EmailTitle,
} from 'pages/Email/EmailDetail/styled';
import * as React from 'react';

type Props = {
  data: Email,
};

const EmailDetail = ({data}: Props) => {
  const {tenant, from, to, subject, bcc, createdAt, content} = data;

  return (
    <>
      <EmailHeader>
        <EmailPrimaryInfo>
          <EmailTitle maxLines={2}>{subject}</EmailTitle>
          <p>
            <strong>{tenant.name}</strong> &lt;{from}&gt;
          </p>
          <p>To: {to}</p>
          {bcc && <p>Bcc: {bcc}</p>}
        </EmailPrimaryInfo>
        {createdAt && (
          <EmailSecondaryInfo>
            <EmailDate>{toReadableDateTime(createdAt)}</EmailDate>
          </EmailSecondaryInfo>
        )}
      </EmailHeader>
      <Card>
        <RawHtml data-cy="email-content" html={content} />
      </Card>
    </>
  );
};

export default EmailDetail;
