// @flow
import InputSelect from 'components/Inputs/InputSelect';
import {pricingTemplateListQuery} from 'data/pricingTemplate/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';

const PricingTemplateSelect = ({data, ...rest}) => <InputSelect options={data} {...rest} />;

export default withQuery(pricingTemplateListQuery, undefined, undefined, props => ({
  affiliateId: props.values.affiliateId,
}))(PricingTemplateSelect);
