// @flow
import {emailTemplateDetailQuery, emailTemplateListQuery} from 'data/emailTemplate/queries';
import createEntityCRUD from 'factories/createEntityCRUD';
import EmailTemplateDetail from 'pages/Email/EmailTemplate/EmailTemplateDetail';
import tableDefinition from 'pages/Email/EmailTemplate/tableDefinition';
import urls from 'pages/urls';

export default createEntityCRUD({
  detail: EmailTemplateDetail,
  detailQuery: emailTemplateDetailQuery,
  listQuery: emailTemplateListQuery,
  tableDefinition,
  title: 'Email Templates',
  route: urls.emailTemplates,
  // HACK(Jude): When this parameter is passed through, the id of the entity
  // (in this case the email template) that is passed through to `withQuery`
  // is treated as a string instead of a number. This is an interim solution
  // for giving the developer the ability to see the delivery version of
  // certain emails. For example an "id" of 0-delivery will give the email
  // with the id of 0 shipment information and have its display show the relevant
  // information. Normally this field would be used on CRUD pages where the
  // entity has a table in the db that follows the new naming convention, i.e.,
  // primary key is a UUID.
  idName: 'HACK',
});
