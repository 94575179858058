// @flow
import Button from 'components/Button';
import InputProductVariantVariableOption from 'components/Inputs/InputProductVariantVariableOption';
import InputText from 'components/Inputs/InputText';
import type {ProductVariant} from 'data/productVariant/types';
import withForm from 'hoc/withForm';
import {clone} from 'ramda';
import React from 'react';
import {type HOC, compose, withProps} from 'recompose';

import schema from './schema';

type Props = {
  isSubmitting: boolean,
  variantVariableLabels: string[],
  warningMessage: string,
};

const VariantEdit = ({isSubmitting, variantVariableLabels, warningMessage}: Props) => {
  // input will just be an array restricted to the number of variant variables
  return (
    <div>
      <InputProductVariantVariableOption
        name="name"
        label={variantVariableLabels.length > 1 ? 'Variant Variables' : 'Name'}
        productLabels={variantVariableLabels}
      />
      <InputText name="shelfLocation" label="Stock Location" />
      <p className="error">{warningMessage}</p>
      <Button kind="primary" loading={isSubmitting}>
        save
      </Button>
    </div>
  );
};

type Enhancer = {
  data: ProductVariant,
};

const enhancer: HOC<*, Enhancer> = compose(
  withProps(props => {
    console.log(props);
    const variantVariableLabels = props.data.product.variantVariables
      ? props.data.product.variantVariables.split(' | ')
      : [''];
    const variantVariableOptions = props.data.name.split(' | ');

    // Create warning message if the number of variant variables has been changed and the variant variable name is inconsistent with data
    let warningMessage = '';
    if (variantVariableLabels.length > variantVariableOptions.length) {
      warningMessage =
        'More variables have been added, please fill in all the empty fields for the existing variant variable label';
    } else if (variantVariableLabels.length < variantVariableOptions.length) {
      warningMessage =
        'Variables have been removed, please ensure the input fields are correct and then save';
    }

    // create array that will be mapped to the input boxes
    const inputArray = variantVariableLabels.map((_, index) => {
      if (index < variantVariableOptions.length) {
        return variantVariableOptions[index];
      }
      return '';
    });

    return {
      variantVariableLabels: variantVariableLabels,
      warningMessage: warningMessage,
      inputArray: inputArray,
    };
  }),
  withForm({
    schema,
    mapProps: props => {
      return {
        ...props.data,
        name: props.inputArray,
      };
    },
    onSubmit: props => values => {
      let newValues = clone(values);
      newValues.name = newValues.name.map(name => name.trim());
      newValues.name = newValues.name.join(' | ');

      return props.submit({input: newValues});
    },
  })
);

export default enhancer(VariantEdit);
