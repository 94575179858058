//@flow
import {StyledInput} from 'componentsStyled/Forms';
import {Label} from 'componentsStyled/Typography';
import withPricingSchedule from 'hoc/withPricingSchedule';
import {clone, times} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {InputWrap, StyledButton as Button, StyledInputWrap} from './styled';

const InputPrices = ({prices, accessoryName, removeDay, addDay, handleChange}) => (
  <div>
    {times(
      index => (
        <StyledInputWrap key={index}>
          <InputWrap>
            <Label>{`Day ${index + 1})`} </Label>
            <StyledInput
              name={accessoryName}
              value={prices[index]}
              onChange={handleChange(accessoryName, index)}
              type="number"
              inputmode="numeric"
              min="0"
              step="0.01"
            />
          </InputWrap>
        </StyledInputWrap>
      ),
      prices.length
    )}
    <Button kind="primary" onClick={addDay} disabled={prices.length > 6}>
      Add Day
    </Button>
    <Button kind="primary" onClick={removeDay} disabled={prices.length < 2}>
      Remove Day
    </Button>
  </div>
);

type Enhancer = {
  prices: number[],
  accessoryName: string,
  onChange: Function,
  value: any,
};

const enhancer: HOC<*, Enhancer> = compose(
  withHandlers({
    handleChange: props => (acc, index) => e => {
      const price = e.target.value ? Number(e.target.value) : null;
      const accessoryArray = clone(props.value);
      const accessoryToUpdate = accessoryArray.find(a => a.name === acc);
      accessoryToUpdate.prices[index] = price;
      props.onChange(accessoryArray);
    },
  }),
  withPricingSchedule
);

export default enhancer(InputPrices);
