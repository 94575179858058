import {Hint} from 'componentsStyled/Typography';
import styled from 'styled-components';

export const AffiliateAddressWrap = styled.div`
  margin-left: 2rem;
`;

export const SmallWarning = styled(Hint)`
  color: ${props => props.theme.palette.red};
`;

export const FakeDisabledFormField = styled.p`
  min-height: 5rem;
  line-height: 5rem;
  margin-left: 1.7rem;
`;
