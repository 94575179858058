import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {hasSufficientEnvironment, hasSufficientRoles} from 'common/permissions';
import globalConfig from 'global/config';
import React from 'react';
import {connect} from 'react-redux';

import type {SideBarTopLevelItem} from '../../containers/App/Sidebar';
import {generateSideBar, SideBarNames} from '../../containers/App/Sidebar';
import {selectAppConfig} from '../../data/app/selectors';
import type {AppConfig} from '../../data/app/types';
import {selectUser} from '../../data/auth/selectors';
import {Container, Content, ExternalIcon, Icon, Tile} from './styled';

type Props = {
  landing: SideBarNames,
  user: any,
  appConfig: ?AppConfig,
};

/**
 * Renders landing page grid tile
 */
const LandingContent = (props: Props) => {
  const {appConfig, user, landing} = props;
  const {role} = user;
  const sidebar: Array<SideBarTopLevelItem> = generateSideBar(appConfig);

  const sideBarWithRoleForLanding = sidebar
    .filter(value => value.roles.includes(role))
    .filter(value => value.name === landing);
  const subItems =
    sideBarWithRoleForLanding.length > 0 ? sideBarWithRoleForLanding[0].subItems : null;

  return (
    <React.Fragment>
      <h1>{landing}</h1>
      <Container>
        {subItems ? (
          subItems
            .filter(
              value =>
                hasSufficientRoles(value.roles, role) &&
                hasSufficientEnvironment(value.environments, globalConfig.environment)
            )
            .map(value => {
              const {name, icon, description, to} = value;
              const isExternalLink = to.startsWith('http');
              return (
                <Tile href={to} target={isExternalLink ? '_blank' : '_self'} key={`tile-${name}`}>
                  <Icon>
                    <FontAwesomeIcon icon={icon} />
                  </Icon>
                  <Content>
                    <h2>{name}</h2>
                    <p>{description}</p>
                  </Content>
                  {isExternalLink && (
                    <ExternalIcon>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </ExternalIcon>
                  )}
                </Tile>
              );
            })
        ) : (
          <pre>No content found</pre>
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
  user: selectUser(state),
});

export default connect(mapStateToProps, {})(LandingContent);
