// @flow
import gql from 'graphql-tag';

export const userFragment = gql`
  fragment userFragment on UserPublic {
    id
    email
    role
    createdAt
    customer
    tenantName
    metrics {
      totalBookings
      totalLocations
      totalProducts
      totalCustomers
      totalStaff
      totalVariants
      totalItems
    }
  }
`;
