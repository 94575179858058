// @flow
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import withMutation from 'hoc/withMutation';
import {connect} from 'react-redux';
import {type Component, compose, withProps, withState} from 'recompose';
import type {Mutation} from 'types/Graphql';

import {createErrorMessage} from '../helpers/CreateErrorMessage';

type AddedProps = {
  submit: Function,
  loading: boolean,
};

type Config = {
  pageTitle?: String,
  hasStringId?: boolean,
};

function withToastAndRedirect<Enhancer>(
  mutation: Mutation<any, any>,
  redirect: Function,
  config?: Config = {}
): (
  // $Dunno
  Component<{...$Exact<AddedProps>, ...$Exact<Enhancer>}>
) => Component<Enhancer> {
  return compose(
    withState('loading', 'setLoading', false),
    withMutation(mutation, {hasStringId: config.hasStringId}),
    connect(null, {notificationSuccess, notificationError}),
    withProps(props => ({
      submit: x => {
        props.setLoading(true);
        return props
          .submitMutation(x)
          .then(res => {
            console.log('***** response is ', res);
            props.notificationSuccess('Success');
            props.setLoading(false);
            props.history.push(redirect(props.match, res)); // eslint-disable-line
          })
          .catch(error => {
            props.setLoading(false);
            const errorMsg = createErrorMessage(config.pageTitle, error);
            // TODO(Jude): For Aesthetics - use existing notification component to show error message
            // Will be a good idea to add time parameter to notification component so that the user
            // has enough time to read more detailed messages
            alert(errorMsg);
          });
      },
    }))
  );
}

export default withToastAndRedirect;
