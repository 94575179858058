import styled from 'styled-components';

export const RefundWrap = styled.div`
  display: flex;
  width: 100%;

  button {
    margin-left: 1rem;
  }
`;
