import Pill from 'components/Pills/index';
import {getProductItemStatusConfig} from 'components/Pills/ProductItemStatusPill/helper';
import type {ProductItemStatus} from 'data/productItem/types';

type Props = {|
  status: ProductItemStatus,
|};

const ProductItemStatusPill = ({status}: Props) => {
  const {text, color} = getProductItemStatusConfig(status);

  return <Pill color={color}>{text}</Pill>;
};

export default ProductItemStatusPill;
