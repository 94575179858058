// @flow
import Accessories from 'pages/Accessories';
import AccountPage from 'pages/Account';
import AffiliatePage from 'pages/Affiliate';
import BundlePage from 'pages/Bundle';
import CategoryPage from 'pages/Category';
import CustomersPage from 'pages/Customers';
import Dashboard from 'pages/Dashboard';
import DebugPage from 'pages/Debug';
import EmailLandingPage from 'pages/Email';
import EmailPage from 'pages/Email/EmailLog';
import EmailProjection from 'pages/Email/EmailProjection';
import EmailTemplate from 'pages/Email/EmailTemplate';
import Inventory from 'pages/Inventory';
import LocationPage from 'pages/Location';
import LoginPage from 'pages/Login';
import ManufacturerPage from 'pages/Manufacturer';
import NotFoundPage from 'pages/NotFound';
import PaymentPage from 'pages/Payment';
import PricingTemplate from 'pages/PricingTemplate';
import PricingTemplateProduct from 'pages/PricingTemplateProduct';
import ProductPage from 'pages/Product';
import ReservationPage from 'pages/Reservation';
import SettingsPage from 'pages/Settings';
import TenantPage from 'pages/Tenant';
import Theme from 'pages/Theme';
import urls from 'pages/urls';
import UserPage from 'pages/User';
import VariantPage from 'pages/Variant';
import VerifySignupEmailPage from 'pages/VerifySignupEmail';
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import SettingsLegalPage from '../../../pages/SettingsLegal';
import StorybookPage from '../../../pages/Storybook';
import LandingRedirect from './LandingRedirect';
import LoggedInRoute from './LoggedInRoute';
import LoggedOutRoute from './LoggedOutRoute';

const Routes = () => (
  <Switch>
    {/* Routes for logged in and logged out */}
    <Route path={urls.debug} component={DebugPage} />

    {/* Routes for logged out only */}
    <LoggedOutRoute path={urls.login} component={LoginPage} />
    <LoggedOutRoute path={urls.verifySignupEmail} component={VerifySignupEmailPage} />

    {/* Routes for logged in only */}
    <LoggedInRoute exact path={urls.root} component={LandingRedirect} />
    <LoggedInRoute path={urls.dashboard.index} component={Dashboard} />
    <LoggedInRoute path={urls.reservation.index} component={ReservationPage} />
    <LoggedInRoute path={urls.customers.index} component={CustomersPage} />
    <LoggedInRoute path={urls.shop.index} component={AffiliatePage} />
    <LoggedInRoute path={urls.product.index} component={ProductPage} />
    <LoggedInRoute path={urls.bundle.index} component={BundlePage} />
    <LoggedInRoute path={urls.category.index} component={CategoryPage} />
    <LoggedInRoute path={urls.payment.index} component={PaymentPage} />
    <LoggedInRoute path={urls.manufacturer.index} component={ManufacturerPage} />
    <LoggedInRoute path={urls.variant.index} component={VariantPage} />
    <LoggedInRoute path={urls.user.index} component={UserPage} />
    <LoggedInRoute path={urls.location.index} component={LocationPage} />
    <LoggedInRoute path={urls.pricingTemplate.index} component={PricingTemplate} />
    <LoggedInRoute path={urls.accessories.index} component={Accessories} />
    <LoggedInRoute path={urls.pricingTemplateProduct.index} component={PricingTemplateProduct} />
    <LoggedInRoute path={urls.inventory.index} component={Inventory} />
    <LoggedInRoute path={urls.theme} component={Theme} />
    <LoggedInRoute path={urls.tenant.index} component={TenantPage} />
    <LoggedInRoute path={urls.account} component={AccountPage} />
    <LoggedInRoute path={urls.settings} component={SettingsPage} />
    <LoggedInRoute path={urls.settingsLegal} component={SettingsLegalPage} />
    <LoggedInRoute path={urls.storybook.index} component={StorybookPage} />
    <LoggedInRoute path={urls.email} component={EmailLandingPage} exact />
    <LoggedInRoute path={urls.emailTemplates.index} component={EmailTemplate} />
    <LoggedInRoute path={urls.emailLogs.index} component={EmailPage} />
    <LoggedInRoute path={urls.emailProjection.index} component={EmailProjection} />
    {/* 404 */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
