// @flow
import React from 'react';
import type {HOC} from 'recompose';
import {withHandlers} from 'recompose';

import CheckIcon from './CheckIcon';
import {CheckboxWrap} from './styled';

const Checkbox = ({value, handleChange, big, disabled, 'data-cy': dataCy}) => (
  <CheckboxWrap
    onClick={handleChange}
    checked={!!value}
    big={big}
    disabled={disabled}
    data-cy={dataCy}
    value={value}
  >
    {value && <CheckIcon />}
  </CheckboxWrap>
);

type Enhancer = {
  value: boolean,
  onChange: Function,
  big?: boolean,
  disabled?: boolean,
  'data-cy'?: string,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleChange: props => () => !props.disabled && props.onChange(!props.value),
});

export default enhancer(Checkbox);
