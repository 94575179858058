// @flow
import Button from 'components/Button';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputText from 'components/Inputs/InputText';
import {CenterContent} from 'componentsStyled/Layout';
import AffiliateSelect from 'containers/AffiliateSelect';
import PricingTemplateSelect from 'containers/PricingTemplateSelect';
import type {ProductItem} from 'data/productItem/types';
import {formatFromCentum, formatToCentum} from 'data/units/money/formatters';
import withForm from 'hoc/withForm';
import useUpdateEffect from 'hooks/useUpdateEffect';
import {path} from 'ramda';
import React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import schema from './schema';
import {AllowedInputWrap, CodeInputWrap, ColumnWrap} from './styled';

const EditItemModal = props => {
  useUpdateEffect(() => {
    props.setFieldValue('pricingTemplateId', undefined);
  }, [props.values.affiliateId, props.setFieldValue]);

  // NOTE(Russell): "Hidden not removed" -> Things to hide (https://www.notion.so/Labels-in-admin-no-longer-match-terminology-used-090256f7e8284b01ad5185d49c97532c)
  // function renderBuyIt() {
  //   return (
  //     <ColumnWrap>
  //       <FieldWrap error={null} label="Buy It Enabled">
  //         <Checkbox
  //           name="buyItEnabled"
  //           label="Buy It Enabled"
  //           value={props.buyItEnabled}
  //           onChange={props.setBuyItEnabled}
  //         />
  //       </FieldWrap>
  //       {props.buyItEnabled && (
  //         <CodeInputWrap>
  //           <InputText name="buyItRrp" placeholder="Buy It RRP" label="Buy It RRP" />
  //         </CodeInputWrap>
  //       )}
  //     </ColumnWrap>
  //   );
  // }
  return (
    <div>
      <h2>Edit Stock Item</h2>
      <ColumnWrap>
        <CodeInputWrap>
          <InputText name="code" placeholder="A unique asset identifier" label="Asset ID" />
        </CodeInputWrap>
        <AllowedInputWrap>
          <InputSwitch name="allowed" label="available" />
        </AllowedInputWrap>
      </ColumnWrap>
      {/* NOTE(Russell): "Hidden not removed" -> Things to hide (https://www.notion.so/Labels-in-admin-no-longer-match-terminology-used-090256f7e8284b01ad5185d49c97532c) */}
      {/* {config.featureFlags.enableBuyIt && renderBuyIt()} */}
      {/* <VariantSelectWrap>
        <Label>Vendor</Label>
        <ManufacturerSelect
          category={props.category}
          name="manufacturerId"
          label=""
          onChange={props.setManufacturer}
          value={props.manufacturerId}
        />
        <Label>Product</Label>
        {props.manufacturerId ? (
          <ProductSelect
            category={props.category}
            manufacturerId={props.manufacturerId}
            productId={props.productId}
            name="productId"
            label=""
            onChange={props.setProduct}
            value={props.productId}
          />
        ) : (
          <p>Please select a vendor.</p>
        )}
        <Label>Variant</Label>
        {props.manufacturerId && props.productId ? (
          <VariantSelect
            productId={props.productId}
            name="variantId"
            label=""
            onChange={props.setVariant}
            value={props.variantId}
          />
        ) : !props.manufacturerId ? (
          <p>Please select a vendor.</p>
        ) : (
          <p>Please select a product.</p>
        )}
      </VariantSelectWrap> */}
      <AffiliateSelect name="affiliateId" label="Store" />
      <PricingTemplateSelect {...props} name="pricingTemplateId" label="Pricing Template" />
      <CenterContent>
        <Button
          kind="primary"
          loading={props.isSubmitting}
          big
          success
          type="submit"
          disabled={!props.variantId}
        >
          save
        </Button>
      </CenterContent>
    </div>
  );
};

type Enhancer = {
  close: Function,
  data: ProductItem,
};

const enhancer: HOC<*, Enhancer> = compose(
  withStateHandlers(
    ({data}) => ({
      category: path(['category'], data),
      productId: path(['productId'], data),
      manufacturerId: path(['manufacturerId'], data),
      variantId: path(['variantId'], data),
      buyItEnabled: !!path(['buyItRrp'], data),
    }),
    {
      setManufacturer: props => v => ({
        manufacturerId: v,
        productId: undefined,
        variantId: undefined,
      }),
      setProduct: props => v => ({productId: v, variantId: undefined}),
      setVariant: props => v => ({variantId: v}),
      setBuyItEnabled: props => v => ({buyItEnabled: v}),
    }
  ),
  withForm({
    schema,
    mapProps: props => {
      return {
        ...props.data,
        // TODO(ray): Wire in the currency
        buyItRrp: props.data.buyItRrp ? formatFromCentum(props.data.buyItRrp) : null,
      };
    },
    onSubmit: props => values => {
      const transformedValues = {
        id: props.data.id,
        productVariantId: props.variantId,
        allowed: values.allowed,
        affiliateId: values.affiliateId,
        code: values.code,
        pricingTemplateId: values.pricingTemplateId,
        buyItRrp: props.buyItEnabled && values.buyItRrp ? formatToCentum(values.buyItRrp) : null,
      };

      return props.submit({input: transformedValues});
    },
    onSuccess: props => props.close,
  })
);

export default enhancer(EditItemModal);
