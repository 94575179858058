// @flow
import gql from 'graphql-tag';

export const bundleFragment = gql`
  fragment bundleFragment on Bundle {
    id
    name
    description
    category {
      categoryId
      name
    }
  }
`;

export const bundleWithComponentsFragment = gql`
  fragment bundleFragment on Bundle {
    id
    name
    description
    category {
      categoryId
      name
    }
    components {
      id
      productVariant {
        id
        name
        product {
          id
          name
        }
      }
      pricingTemplate {
        id
      }
    }
    images {
      id
      alt
      url
      order
    }
  }
`;
