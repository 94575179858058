// @flow
import type {UpdateAction} from 'data/auth/actions';
import type {User} from 'data/auth/graphql/types';
import type {Profile, UserRole} from 'data/user/types';
import {find, propEq} from 'ramda';

import type {Props as PillProps} from '../../components/Pills';
import apolloClient from '../../global/apolloClient';
import {userById} from './queries';
import {type UserFull} from './types';

export const selectPrimary = (u: UserFull) => u.profiles.find(p => p.isPrimary) || u.profiles[0];

export const selectActiveProfile = (id: number, profiles: Profile[]) =>
  find(propEq('id', id), profiles) || profiles[0];

/**
 *  * Gets the {@link Pill} props from value
 * @param value
 * @returns {{children: UserRole}}
 */
export const getPillPropsFromRole = (value: UserRole): PillProps => {
  switch (value) {
    case 'affiliate_employee':
      return {
        children: 'staff',
      };
    case 'user':
      return {
        children: 'customer',
      };
    case 'admin':
      return {
        children: 'admin',
        status: 'success',
      };
    case 'superadmin':
      return {
        children: 'superadmin',
        status: 'success',
      };
    case 'affiliate':
      return {
        children: 'manager',
        status: 'success',
      };

    default:
      return {
        children: value,
      };
  }
};

export const updateUser = async (user: ?User, update: User => UpdateAction) => {
  if (user) {
    const userData = await apolloClient.query({
      query: userById.gql,
      selector: userById.selector,
      variables: {id: user.id},
      fetchPolicy: userById.fetchPolicy,
    });
    // update app user store
    update(userData.data.user.getUser);
  }
};
