import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
`;

export const List = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 2rem;
  flex-direction: column;
`;
