// @flow
import {update} from 'data/auth/actions';
import {selectUser} from 'data/auth/selectors';
import {withFormik} from 'formik';
import {compose, keys, merge, path, pick, prop} from 'ramda';
import * as React from 'react';
import {connect} from 'react-redux';
import {type Component} from 'recompose';

import {updateUser} from '../../data/user/helpers';
import {Form, FormError} from './styled';

type Config = {
  schema?: any,
  onSubmit?: any => any => Promise<any>,
  onError?: any => any => void,
  onSuccess?: any => any => void,
  mapProps?: any => any,
};

const mapStateToProps = state => ({
  user: selectUser(state),
});

const mapDispatchToProps = {
  update,
};
type Added = {
  isSubmitting: boolean,
  setFieldError: (string, string) => void,
  errors: Object,
  values: Object,
  setFieldValue: (string, any) => void,
};

function withForm<Enhancer>({
  schema,
  onSubmit = prop('submitMutation'),
  onSuccess,
  onError,
  mapProps = prop('data'),
}: Config): (Component<Added & Enhancer>) => Component<Enhancer> {
  return Component =>
    compose(
      connect(mapStateToProps, mapDispatchToProps),
      withFormik({
        mapPropsToValues: props => {
          const values = mapProps(props);
          if (values) {
            if (props.formData && schema) {
              return compose(merge(props.formData), pick(keys(schema.fields)))(values);
            }
            return schema ? pick(keys(schema.fields))(values) : values;
          }
          return {};
        },
        validationSchema: schema,
        handleSubmit: (values, other) => {
          other.setStatus({...other.status, error: null});
          if (other.props.confirmSubmission === false) {
            return other.setSubmitting(false);
          }
          return onSubmit(other.props)(values)
            .then(async result => {
              await updateUser(other.props.user, other.props.update);
              other.setStatus({
                ...other.status,
                submitSucceeded: true,
              });
              other.setSubmitting(false);
              if (onSuccess) {
                onSuccess({
                  ...other.props,
                  resetForm: other.resetForm,
                })(result);
              }
            })
            .catch(error => {
              other.setStatus({
                ...other.status,
                error: error.message,
              });
              other.setSubmitting(false);
              if (onError) {
                onError(other.props)(error);
              }
            });
        },
      })
    )(props => {
      const error = path(['status', 'error'], props);
      return (
        <Form
          onSubmit={e => {
            props.setStatus({...props.status, submitted: true});
            return props.handleSubmit(e);
          }}
        >
          {error && <FormError>{error}</FormError>}
          <Component {...props} />
        </Form>
      );
    });
}

export default withForm;
