// @flow
import {StyledInput} from 'componentsStyled/Forms';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

const TextInput = ({value = '', handleChange, ...rest}) => (
  <StyledInput value={value} {...rest} onChange={handleChange} />
);

type Enhancer = {
  value: boolean,
  onChange: Function,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleChange: props => e => {
    props.onChange(e.target.value);
  },
});

export default enhancer(TextInput);
