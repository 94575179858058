// @flow
import InputCheckbox from 'components/Inputs/InputCheckbox';
import InputNumber from 'components/Inputs/InputNumber';
import {
  InlineNumberLabel,
  StyledInStoreSection,
  StyledTextEditor,
} from 'pages/Affiliate/AffiliateEdit/InStoreFulfilmentSection/styled';
import React from 'react';

// We need to explicitly define the formValue variables as this component is
// not wrapped in the withForm HOC and so doesn't have the full form type definition.
export type Props = {
  formValues: {
    allowInStoreFulfilment: boolean,
    allowInStoreBookingExtension: boolean,
  },
  setFieldToZeroIfEmpty: Function,
};

export const InStoreFulfilmentSection = ({formValues, setFieldToZeroIfEmpty}: Props) => {
  const {allowInStoreFulfilment, allowInStoreBookingExtension} = formValues || {};

  //disabled logic for dependent fields
  const disabled = !allowInStoreFulfilment;

  //special disabled logic for
  const bookingExtensionDisabled = disabled || !allowInStoreBookingExtension;

  return (
    <StyledInStoreSection includeTitle title="IN-STORE FULFILMENT">
      <InputCheckbox
        label="Enable"
        labelPosition="right"
        name="allowInStoreFulfilment"
        data-cy="enable-instore-fulfillment"
      />
      <InputCheckbox
        label="Allow in-store bookings to be extended"
        labelPosition="right"
        name="allowInStoreBookingExtension"
        disabled={disabled}
        data-cy="enable-instore-booking-extensions"
      />
      <InlineNumberLabel disabled={bookingExtensionDisabled}>
        Up to
        <InputNumber
          maxWidth={8}
          min={0}
          interval={1}
          name="inStoreExtensionCutoffDays"
          disabled={bookingExtensionDisabled}
          onBlur={() => setFieldToZeroIfEmpty('inStoreExtensionCutoffDays')}
          fieldWrapProps={{hideErrorText: true}}
          data-cy="instore-booking-extension-cutoff-days"
        />
        days before the rental period ends
      </InlineNumberLabel>
      <StyledTextEditor
        name="inStoreFulfilmentInstructions"
        label="In-store fulfilment instructions"
        hint="Appears when staff are fulfilling an in-store booking."
        disabled={disabled}
        data-cy="instore-fulfillment-instructions"
      />
    </StyledInStoreSection>
  );
};

export default InStoreFulfilmentSection;
