// @flow
import * as Sentry from '@sentry/react';
import Modals from 'containers/Modals';
import Notifications from 'containers/Notifications';
import {setAppConfig} from 'data/app/actions';
import {appConfigQuery} from 'data/app/queries';
import {selectAppConfig} from 'data/app/selectors';
import {logout, logoutRedirect, update} from 'data/auth/actions';
import {selectUser} from 'data/auth/selectors';
import {notificationError} from 'data/notifications/actions';
import {query} from 'global/apolloClient/helpers';
import config from 'global/config';
import {identify, page} from 'helpers/Appcues';
import withOnMount from 'hoc/withOnMount';
import withRouter from 'hoc/withRouter';
import {equals} from 'ramda';
import * as React from 'react';
import {connect} from 'react-redux';
import {type HOC, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';

import {PageContent, PageMain} from '../../componentsStyled/Layout';
import {updateUser} from '../../data/user/helpers';
import ErrorPage from './Error';
import Header from './Header';
import Routes from './Routes';
import Sidebar from './Sidebar';
import {AppWrap, Body, SidebarWrap} from './styled';

type State = {
  error?: any,
};

class App<P: *> extends React.Component<P, State> {
  state = {
    error: null,
  };

  constructor(props) {
    super(props);
    if (config.sentryDsn) {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.environment,
      });
    }
  }

  // catches javascript runtime errors
  componentDidCatch(error) {
    this.setState({error: error.message});
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />;
    }

    const {user} = this.props;

    return (
      <AppWrap>
        {user && <Header user={user} />}
        <Body>
          {user && (
            <SidebarWrap>
              <Sidebar role={user.role} />
            </SidebarWrap>
          )}
          <PageMain>
            <PageContent>
              <Routes />
            </PageContent>
          </PageMain>
        </Body>
        <Notifications />
        <Modals />
      </AppWrap>
    );
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  logout,
  update,
  logoutRedirect,
  notificationError,
  setAppConfig,
};

const enhancer: HOC<*, {}> = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    {
      loading: true,
    },
    {
      setLoading: () => v => ({loading: v}),
    }
  ),
  withHandlers({
    prefetchQueries: props => async () => {
      props.setLoading(true);
      query(appConfigQuery)
        .then(appData => {
          console.log('Loaded app config:', appData);
          props.setAppConfig(appData);
          props.setLoading(false);
        })
        .catch(() => props.setLoading(false));

      let updatedUser = props.user;

      // Function updates the redux store and the `updatedUser` variable with
      // the `user` argument. This solution is a quick fix, as we want to pass
      // up-to-date user information into the `identify` function.
      // TODO(Anyone): Explore using Redux epics to get desired behaviour.
      const updateStoreAndUser = user => {
        updatedUser = user;
        return props.update(user);
      };

      try {
        await updateUser(props.user, updateStoreAndUser);
        if (updatedUser) {
          identify(updatedUser);
        }
      } catch (e) {
        props.logout();
      }
    },
  }),
  withOnMount(props => {
    props.prefetchQueries();
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      // If the user is logged in and the user properties change
      if (this.props.user && !equals(prevProps.user, this.props.user)) {
        identify(this.props.user);
      }
      // If the user is logged in but the user information has not changed
      else if (this.props.user) {
        page();
      }
      // If the user is not logged in, i.e., anonymous
      else {
        /*
        If the appcues anonymous tracking is going to be used, this line should be uncommented and function
        imported. This should only be done at the request of the business, there are significant cost consequences
        to anonymous tracking. https://docs.appcues.com/article/438-anonymous-users
        */
        // anonymous()
      }
    },
  })
);

export default enhancer(App);
