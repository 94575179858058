// @flow
import {faCreditCard} from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/Button';
import InputText from 'components/Inputs/InputText';
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import {reservationAdhocChargeMutation} from 'data/reservations/mutations';
import withForm from 'hoc/withForm';
import withMutation from 'hoc/withMutation';
import React from 'react';
import {connect} from 'react-redux';
import {compose, withHandlers, withProps, withStateHandlers} from 'recompose';

import {LayoutFlex} from '../../../../componentsStyled/Layout';
import {formatCurrencyFromCents} from '../../../../data/units/money/formatters';

type Props = {
  reservationId: number,
  amount: number,
  isSubmitting: boolean,
  onCharge: Function,
};

const AdhocChargeForm = ({amount, isSubmitting, onCharge}: Props) => (
  <React.Fragment>
    <LayoutFlex>
      <LayoutFlex col oneQuater>
        <InputText name="amount" type="number" inputmode="numeric" label="Amount" />
      </LayoutFlex>
      <LayoutFlex col fullWidth>
        <InputText name="description" type="text" inputmode="text" label="Description" />
      </LayoutFlex>
    </LayoutFlex>
    <LayoutFlex>
      <Button
        kind="secondary"
        onClick={onCharge}
        loading={isSubmitting}
        iconStart={faCreditCard}
        disabled={amount === undefined || amount <= 0}
      >
        charge customer
      </Button>
    </LayoutFlex>
  </React.Fragment>
);

export default compose(
  connect(null, {notificationSuccess, notificationError}),
  withStateHandlers(
    {
      confirmSubmission: undefined,
    },
    {
      setConfirmSubmission: () => (v: boolean) => ({confirmSubmission: v}),
    }
  ),
  withMutation(reservationAdhocChargeMutation),
  withForm({
    onSubmit: props => values =>
      props.submitMutation({
        input: {
          reservationId: props.reservationId,
          amount: values.amount * 100,
          description: values.description,
        },
      }),
    onSuccess: props => data => {
      if (data.reservation.adhocCharge.status === 'success') {
        props.notificationSuccess('Ad hoc charge successful');
      } else {
        props.notificationError('Card charge failed');
      }
    },
  }),
  withHandlers({
    onCharge: (props: {values: any, setConfirmSubmission: Function}) => () => {
      const confirmSubmission = window.confirm(
        `Are you sure you wish to make an ad hoc charge of ${formatCurrencyFromCents(
          props.values.amount * 100
        )}?`
      );
      props.setConfirmSubmission(confirmSubmission);
    },
  }),
  withProps(props => ({amount: props.values.amount}))
)(AdhocChargeForm);
